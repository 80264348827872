import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import messages from "../../assets/locale/messages";
import "./ViewInputData.scss";

const ViewInputData = ({
  label,
  content,
  labelBesideField,
  dotClass,
  className,
  contentClassName,
  contentIcon,
  multipleContents,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  return (
    <div
      className={`view-input-data-wrapper mb-3 ${
        labelBesideField
          ? "d-flex justify-content-start align-items-center"
          : ""
      }`}>
      {label && (
        <label className={`view-field-label ${className}`}>
          {label}
          {labelBesideField ? (
            <span
              className={`mx-2 fweight-500 ${dotClass ? "custom-dots" : ""}`}>
              :
            </span>
          ) : (
            ""
          )}
        </label>
      )}
      <div className={`mb-0 view-input-content-wrapper ${contentClassName}`}>
        {content || content === 0 ? (
          <>
            {contentIcon && <span className="me-2">{contentIcon}</span>}
            {content}
          </>
        ) : multipleContents ? (
          <>{multipleContents}</>
        ) : (
          <div className="view-input-content-placeholder">{shared.none}</div>
        )}
      </div>
    </div>
  );
};

export default ViewInputData;

ViewInputData.propTypes = {
  label: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
  ]),
  labelBesideField: PropTypes.bool,
  dotClass: PropTypes.bool,
  contentIcon: PropTypes.element,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  multipleContents: PropTypes.element,
};
