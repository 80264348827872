import React from "react";
import PropTypes from "prop-types";
import { IconButton as MuiIconButton } from "@mui/material";

const IconButton = ({ className, children, onClick }) => (
  <MuiIconButton className={`${className}`} onClick={onClick}>
    {children}
  </MuiIconButton>
);

IconButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
  onClick: PropTypes.func,
};

export default IconButton;
