import { put, call, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/auth";
import * as types from "./types";
import * as actions from "./actions";
import history from "../../routes/History";
import { clearAdminData, setAdmin, setDeviceId } from "../../utils/Auth";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";
import {
  clearFirebaseToken,
  generateFirebaseToken,
} from "../../firebase/helpers";
import store from "../index";
import { setUnseenNotificationsCount } from "../Notifications/actions";

function* loginSaga({ payload }) {
  try {
    const response = yield call(
      payload.userType === "admin" ? apis.login : apis.userAppLogin,
      payload.data
    );
    const device_id = payload.data.device_id;
    yield put(actions.loginResponse(response.data.data));
    yield put(
      setUnseenNotificationsCount(
        response.data.data.admin_portal_unseen_notifications_count
      )
    );
    yield localStorage.setItem(
      "unseenNotificationsCount",
      response.data.data.admin_portal_unseen_notifications_count
    );
    yield setAdmin(response.data.data);
    yield localStorage.setItem("userType", payload.userType);
    yield setDeviceId(device_id);
    yield history.push(
      response.data.data.must_change_password
        ? ROUTE_PATHS.changePassword
        : payload.userType === "admin"
        ? ROUTE_PATHS.events
        : ROUTE_PATHS.evaluations
    );
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* logoutSaga({ payload }) {
  try {
    const userType = localStorage.getItem("userType");
    yield call(
      userType === "admin" ? apis.logout : apis.userAppLogout,
      payload
    );
    yield put(actions.loginResponse({}));
    yield clearAdminData();
    yield clearFirebaseToken();
    yield generateFirebaseToken();

    yield history.push(ROUTE_PATHS.login);
  } catch (error) {
    console.log("Logout error", error);
  }
}

function* changePasswordSaga({ payload }) {
  try {
    const response = yield call(apis.changePassword, payload.data);
    if (payload.from === "change") {
      const adminData = store.getState().auth.login;
      yield put(
        actions.loginResponse({
          ...adminData,
          must_change_password: response?.data?.data?.must_change_password,
        })
      );
      yield setAdmin({
        ...adminData,
        must_change_password: response?.data?.data?.must_change_password,
      });
      const userType = localStorage.getItem("userType");
      yield history.push(
        userType === "admin" ? ROUTE_PATHS.events : ROUTE_PATHS.evaluations
      );
    } else {
      yield history.push(ROUTE_PATHS.profile);
    }
    yield showSuccessMsg("changePassword");
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* forgetPasswordSaga({ payload }) {
  try {
    yield call(apis.forgetPassword, payload);
    yield showSuccessMsg("forgetPassword");
    yield history.push(ROUTE_PATHS.login);
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

export default function* AuthSagas() {
  yield takeEvery(types.LOGIN_REQUEST, loginSaga);
  yield takeEvery(types.LOGOUT_REQUEST, logoutSaga);
  yield takeEvery(types.CHANGE_PASSWORD_REQUEST, changePasswordSaga);
  yield takeEvery(types.FORGET_PASSWORD_REQUEST, forgetPasswordSaga);
}
