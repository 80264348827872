import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
};

const applicantsTimeSheetRequest = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_APPLICANTS_WORKING_SLOTS_RESPONSE:
      return {
        ...state,

        list: action.payload.list,
        meta: action.payload.meta,
      };

    default:
      return state;
  }
};

export default applicantsTimeSheetRequest;
