import { LOGIN_RESPONSE } from "./types";

const INITIAL_STATE = {
  login: JSON.parse(localStorage.getItem("admin")) || {},
};

const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_RESPONSE:
      return { ...state, login: action.payload };
    default:
      return state;
  }
};

export default auth;
