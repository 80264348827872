const forgetPassword = {
  title: "Reset account password",
  title2: "Enter email address ",
  labels: {
    email: "Email",
  },
  placeholders: {
    email: "Enter email",
    password: "Enter password",
  },
  actionBTN: "Reset password",
  enterNewPassword: "Enter a new password for ",
};

export default forgetPassword;
