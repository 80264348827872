import React, { useState } from "react";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { Menu as MuiMenu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";

const Menu = ({ options, onSelectItem, triggerIcon, paperProps }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const lang = useSelector((state) => state.locale.lang);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectItem = (selectedOption) => {
    handleClose();
    onSelectItem(selectedOption);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>{triggerIcon}</IconButton>
      <MuiMenu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={paperProps}
        anchorOrigin={{
          vertical: "top",
          horizontal: lang === "en" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: lang === "en" ? "right" : "left",
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={() => handleSelectItem(option)}>
            {option}
          </MenuItem>
        ))}
      </MuiMenu>
    </div>
  );
};

Menu.propTypes = {
  options: PropTypes.array.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  triggerIcon: PropTypes.element.isRequired,
  paperProps: PropTypes.string,
};

export default Menu;
