const timeSheet = {
  eventName: "Event Name",
  startDate: "Start Date",
  endDate: "End Date",
  type: "Event Type",
  link: "Report Link",
  status: "Status",
  listTimeSheetBreadcrumb: "Timesheet List",
  search: "Search by event name",
  role: "Role",
  attendanceReport: "Attendance Report",
  placeholder: "No reports found.",
  date: "Date",
  applicantName: "Applicant Name",
  checkin: "Checkin",
  checkinPlaceHolder: "Enter Check In Time",
  checkoutPlaceHolder: "Enter Check Out Time",
  EditStatus: "Edit status for",

  addStatus: "Add Slot",
  addStatusFor: "Add Status for",
  checkout: "Checkout",
  datePlaceholder: "Enter Chech In Date",
  searchByApplicant: "Search by applicant name",
  payroll: "Expected Payroll",
  numberOfWorkingDays: "Number of working days",
  dailyReport: {
    name: "Daily Event Report",
    workingHours: "Total Working Hours",
    endDateValidation: "End date can't be before start date",
  },
  monthlyReport: {
    name: "Monthly Event Report",
    workingHours: "Total Working Hours/Month",
    month: "Month",
    checkoutStatus: "Checkout Status",
  },
  applicantReport: {
    name: "Applicant Report",
    workingHours: "Working hours/Day",
  },
  deleteMsg: "Are you sure you want to delete this report ? ",
  exportBtn: "Export Reports To Excel Sheet",
  selectColumnsToExport: "Select desired columns to be exported",
  exportErr: "You must select one column at least",
  dailyPayroll: "Payroll/Day",
  dailyExportSheet: {
    nameAr: "Full Name in Arabic",
    nameEn: "Full Name in English",
    role: "Role",
    nationalIqama: "National ID/Iqama ID",
    nationality: "Nationality",
    selfCheckoutDates: "Self-Checkout Dates",
    automaticCheckoutDates: "Automatic Checkout Dates",
    mobile: "Mobile Number",
    email: "Email",
    birthDate: "Date of Birth",
    workingDays: "Number of Working Days",
    totalWorkingHours: "Total Working Hours",
    payrollDay: "Payroll/Day",
    expectedPayroll: "Expected Payroll",
    iban: "IBAN",
    signature: "Signature",
    stcNumber: "Stc Number",
  },
};

export default timeSheet;
