import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@mui/material";

import messages from "../../assets/locale/messages";
import "./RadioButton.scss";

const RadioButtonsGroup = ({
  wrapperClassName,
  containerClassName,
  label,
  labelClassName,
  required,
  className,
  handleChange,
  value,
  listOfRadios,
  endAdornment,
  disabled,
  hasDisabledReplacementBody,
  disabledReplacementBody,
  alignedColumn,
  alignedColumnClasses,
  hideStar,
  isInputHasErr,
  errMsg,
  helperText,
  helperTextClass,
  radioBtnClass,
  name,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations } = messages[lang];

  return (
    <div className={`radio-btns-wrapper ${containerClassName}`}>
      <FormControl component="fieldset" className={wrapperClassName}>
        <FormLabel className={labelClassName}>
          {label}
          {required && !hideStar ? <span className="err-asterisk">*</span> : ""}
          {endAdornment}
        </FormLabel>
        {hasDisabledReplacementBody ? (
          disabledReplacementBody
        ) : (
          <RadioGroup
            aria-label={name}
            name={name}
            value={value}
            onChange={handleChange}
            className={className}>
            {listOfRadios.map((radio, index) => (
              <div
                className={`${
                  alignedColumn ? alignedColumnClasses : ""
                } ${radioBtnClass}`}
                key={index}>
                <FormControlLabel
                  value={radio.value}
                  control={<Radio />}
                  label={radio.label}
                  disabled={disabled ? disabled : radio.disabled}
                />
                <div className="d-flex align-items-center ">
                  {radio.value == value && radio.children}
                </div>
              </div>
            ))}
          </RadioGroup>
        )}
      </FormControl>
      <FormHelperText
        error={isInputHasErr}
        className={`${helperTextClass || "font-medium fsize-12"} ${
          lang === "en" ? "" : "text-end"
        }`}>
        {isInputHasErr ? inputsValidations[errMsg] : helperText}
      </FormHelperText>
    </div>
  );
};
export default RadioButtonsGroup;

RadioButtonsGroup.propTypes = {
  wrapperClassName: PropTypes.string,
  name: PropTypes.string,
  containerClassName: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  listOfRadios: PropTypes.array,
  endAdornment: PropTypes.element,
  disabled: PropTypes.bool,
  hasDisabledReplacementBody: PropTypes.bool,
  disabledReplacementBody: PropTypes.element,
  alignedColumn: PropTypes.bool,
  alignedColumnClasses: PropTypes.string,
  hideStar: PropTypes.bool,
  isInputHasErr: PropTypes.bool,
  errMsg: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  helperTextClass: PropTypes.string,
  radioBtnClass: PropTypes.string,
};
