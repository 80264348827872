import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { isAuth } from "../utils/Auth";
import { ROUTE_PATHS } from "../utils/RoutesPaths";

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: ROUTE_PATHS.root }} />
      )
    }
  />
);

PublicRoute.propTypes = {
  component: PropTypes.func,
};

export default PublicRoute;
