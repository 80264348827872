import React from "react";

export const OtherDocument = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    fill="none"
    viewBox="0 0 16 18"
  >
    <path
      fill="#4E5D78"
      stroke="#4E5D78"
      strokeWidth="0.25"
      d="M3.98 7.504c-.226-.047-.451-.075-.676-.14-1.202-.348-2.15-1.455-2.28-2.694-.198-1.717.872-3.219 2.552-3.594C5.34.673 7.179 1.921 7.45 3.713c.019.094.028.188.047.272v.535c0 .028-.019.047-.019.075-.197 1.36-.938 2.28-2.233 2.74-.235.084-.488.113-.723.16H3.99l-.01.009zM1.745 4.248a2.509 2.509 0 002.496 2.505 2.503 2.503 0 002.505-2.496 2.509 2.509 0 00-2.496-2.505 2.503 2.503 0 00-2.505 2.496z"
    ></path>
    <path
      fill="#4E5D78"
      stroke="#4E5D78"
      strokeWidth="0.25"
      d="M4.617 4.623v.985a.37.37 0 01-.244.366c-.244.094-.498-.084-.507-.366v-.844-.14H2.9c-.244 0-.404-.151-.404-.376 0-.216.16-.376.404-.376h.957v-.13-.836c0-.244.16-.404.375-.404.225 0 .375.16.375.404v.957h.986c.187 0 .328.103.375.272.047.15 0 .32-.14.404a.433.433 0 01-.245.065h-.985l.019.02z"
    ></path>
    <path
      fill="#4E5D78"
      stroke="#4E5D78"
      strokeWidth="0.25"
      d="M2.644 17.253c-.124-.052-.253-.09-.362-.165-.286-.185-.414-.46-.414-.795V8.165c0-.208.08-.327.238-.364.166-.038.319.042.361.203.02.066.02.142.02.213v7.977c0 .355.09.45.447.45h11c.357 0 .452-.09.452-.446V2.558c0-.355-.09-.45-.447-.45H7.94c-.057 0-.114 0-.171-.004a.3.3 0 01-.272-.303c0-.156.11-.274.277-.298.047-.004.09-.004.138-.004h6.073c.628 0 1.014.383 1.014 1.007v13.75c0 .524-.19.77-.776 1.007H2.644v-.01z"
    ></path>
  </svg>
);
