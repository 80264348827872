import React from "react";
import { useSelector } from "react-redux";

import messages from "../../../../assets/locale/messages";
import CreateFormSectionTitle from "../../CreateFormSectionTitle";
import ViewRolesFields from "./ViewRolesFields";
import DynamicFiledsPlaceholder from "../DynamicFiledsPlaceholder";
import "../ViewEvent.scss";

const RolesTab = () => {
  const lang = useSelector((state) => state.locale.lang);
  const event = useSelector((state) => state.events.event);
  const { events } = messages[lang];

  return (
    <>
      <div className="mb-3 step-part-content-wrapper">
        <div className="mb-4 d-flex justify-content-between top-wrapper">
          <CreateFormSectionTitle title={events.viewEvents.roles} />
        </div>
        {event?.event_roles?.length == 0 ? (
          <DynamicFiledsPlaceholder content={events.viewEvents.noRoles} />
        ) : (
          event?.event_roles?.map((role, index) => (
            <ViewRolesFields role={role} key={`role-${index}`} />
          ))
        )}
      </div>
    </>
  );
};

export default RolesTab;
