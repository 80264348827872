const login = {
  title: "Welcome",
  subTitle: "Sign in to Get Started",
  subTitle2: "in",
  labels: {
    email: "Email",
    password: "Password",
    remember: "Remember me next time",
    forgetPassword: "Forget your password?",
    signin: "Sign in",
    admin: "Admin",
    supervisor: "Supervisor",
  },
  placeholders: {
    email: "Enter email",
    password: "Enter password",
  },
};

export default login;
