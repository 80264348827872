import * as types from "./types";

// list
export const getLocationsRequest = (payload) => ({
  type: types.GET_LOCATIONS_REQUEST,
  payload,
});
export const getLocationsResponse = (payload) => ({
  type: types.GET_LOCATIONS_RESPONSE,
  payload,
});

export const deleteLocationRequest = (payload) => ({
  type: types.DELETE_LOCATION_REQUEST,
  payload,
});
