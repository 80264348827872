import React from "react";

export const CalendarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="19"
    fill="none"
    viewBox="0 0 17 19"
    {...props}>
    <path
      stroke="#6B778C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
      d="M14.448 2.646H2.925c-.909 0-1.646.737-1.646 1.647v11.523c0 .909.737 1.646 1.646 1.646h11.523c.91 0 1.646-.737 1.646-1.646V4.293c0-.91-.737-1.647-1.646-1.647zM11.98 1v3.292M5.395 1v3.292M1.28 7.585h14.814"></path>
  </svg>
);
