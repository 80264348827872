import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TableCell, TableRow, Chip } from "@mui/material";
import { useHistory } from "react-router-dom";

import Breadcrumbs from "../../components/BreadCrumbs";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import EmptyState from "../../components/EmptyState";
import evaluationPlaceholder from "../../assets/images/evaluation-screen/evaluation-placeholder.svg";
import messages from "../../assets/locale/messages";
import Filters from "../../components/Filter";
import {
  formatDate,
  renderTextEllipsis,
  textContainOnlySpaces,
} from "../../utils/Helpers";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { getEvaluationsRoundsListRequest } from "../../store/Evaluations/actions";
import { evaluationFormStatus } from "../../utils/Constants";
import "./Evaluations.scss";

const Evaluations = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userType = localStorage.getItem("userType");

  const lang = useSelector((state) => state.locale.lang);
  const { evaluations, shared } = messages[lang];
  const isLoading = useSelector((state) => state.loader);
  const meta = useSelector((state) => state.evaluations.rounds.meta);
  const list = useSelector((state) => state.evaluations.rounds.list);

  const [filterEvaluationStatus, setFilterEvaluationStatus] = useState(null);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const statusOptions = [
    {
      id: 1,
      name: evaluations.statusOptions.pending,
      value: evaluationFormStatus.pending,
    },
    {
      id: 2,
      name: evaluations.statusOptions.submitted,
      value: evaluationFormStatus.submitted,
    },
  ];

  useEffect(() => {
    if (filterEvaluationStatus) {
      const localizedFilterValue = statusOptions.find(
        (option) => option.id === filterEvaluationStatus.id
      );
      setFilterEvaluationStatus(localizedFilterValue);
    }
  }, [lang]);

  const getEvaluationRoundsRequests = (pageNo, search, status) => {
    dispatch(
      getEvaluationsRoundsListRequest({
        page: pageNo,
        items: 10,
        evaluation_name: search,
        status:
          userType === "admin" ? status?.value : evaluationFormStatus.pending,
        order_by_created_at: "desc",
      })
    );
  };

  useEffect(() => {
    getEvaluationRoundsRequests(page, searchValue, filterEvaluationStatus);
  }, [page, lang]);

  useEffect(() => {
    if (
      (searchValue.length >= 3 || searchValue.length === 0) &&
      !textContainOnlySpaces(searchValue)
    ) {
      setPage(1);
      getEvaluationRoundsRequests(1, searchValue, filterEvaluationStatus);
    }
  }, [searchValue]);

  useEffect(() => {
    setPage(1);
    getEvaluationRoundsRequests(1, searchValue, filterEvaluationStatus);
  }, [filterEvaluationStatus]);

  const renderEvaluationsList = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column my-4 pt-5">
            <EmptyState
              description={evaluations.listEvaluations.noData}
              imgSrc={evaluationPlaceholder}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="bg-white rounded-4 mt-2 pb-3">
            <Table
              className="rounded-4"
              headlines={[
                evaluations.listEvaluations.evaluationName,
                evaluations.listEvaluations.creationDate,
                evaluations.listEvaluations.totalNumber,
                evaluations.listEvaluations.evaluatedApplicants,
                evaluations.listEvaluations.remainingApplicants,
                shared.status,
              ]}
              hasActions={false}
              hasSizeLimitation={false}
              rows={list?.map((row, i) => (
                <TableRow
                  key={i}
                  className="row-hover"
                  onClick={() => {
                    sessionStorage.setItem("zonesEventId", row.event?.id);
                    history.push(
                      ROUTE_PATHS[
                        row.status === evaluationFormStatus.pending
                          ? "inprogressEvaluations"
                          : "completedEvaluations"
                      ].replace(":id", row.id),
                      { evaluation_name: row.name }
                    );
                  }}>
                  <TableCell>{renderTextEllipsis(row.name)}</TableCell>
                  <TableCell className="mobile-style">
                    {row.created_at ? formatDate(row.created_at) : "-"}
                  </TableCell>
                  <TableCell className="mobile-style">
                    {row.number_of_applicants || 0}
                  </TableCell>
                  <TableCell className="mobile-style">
                    {row.number_of_submitted_forms || 0}
                  </TableCell>
                  <TableCell className="mobile-style">
                    {row.remaining_applicants || 0}
                  </TableCell>
                  <TableCell className="mobile-style">
                    <Chip
                      label={evaluations.statusOptions[row.status]}
                      className={`fsize-14 fweight-400 evaluation-status-container ${row.status}`}
                    />
                  </TableCell>
                </TableRow>
              ))}
            />
          </div>
          <div className="d-flex justify-content-center py-3 mt-4">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div className="evaluation-list-container">
      <div className="page-title-margin">
        <Breadcrumbs
          list={[
            {
              path: "",
              label: evaluations.listEvaluations.title,
            },
          ]}
          BreadCrumbHasBackIcon={false}
        />
      </div>
      <Filters
        searchInput={{
          placeholder: evaluations.listEvaluations.searchPlaceholder,
          name: "search",
          value: searchValue,
          onChange: (value) => setSearchValue(value),
        }}
        filters={
          userType === "admin"
            ? [
                {
                  type: "filter",
                  name: "status",
                  placeholder: shared.status,
                  value: filterEvaluationStatus,
                  options: statusOptions,
                  onChange: (value) => setFilterEvaluationStatus(value),
                },
              ]
            : []
        }
      />
      <div className="d-flex flex-column justify-content-between">
        {renderEvaluationsList()}
      </div>
    </div>
  );
};

export default Evaluations;
