const employees = {
  employeesList: "Employees List",
  viewEmployee: "View Employee",
  editProfile: "Edit Profile",
  searchPlaceholder: "Search for an employee",
  noDataDescription: "Sorry, No employees found.",
  profile: "Profile",
  email: "Email",
  mobileNumber: "Mobile Number",
  addProjectManager: {
    createEmployee: "Add Employee",
    addEmployee: "Add Employee",
    name: "Name",
    email: "Email",
    mobileNumber: "Mobile number",
    jobTitle: "Job title",
    labels: {
      name: "Name",
      email: "Email",
      mobileNumber: "Mobile number",
      jobTitle: "Job title",
      employeeImage: "Employee Image",
      uploadLabel: "Upload Image",
    },
    placeholders: {
      name: "Enter name",
      email: "Enter email",
      mobileNumber: "Enter mobile number",
      jobTitle: "Select Job title",
    },
  },
  listEmployees: {
    name: "Name",
    email: "Email",
    mobile: "Mobile Number",
    jobTitle: "Job Title",
  },
};
export default employees;
