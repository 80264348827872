import * as types from "./types";

const INITIAL_STATE = {
  currentCreateEventFormStep: 0,
  createdEventId: null,
  activePublishMode: false,
};

const routing = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_CREATE_EVENT_FORM_STEP:
      return {
        ...state,
        currentCreateEventFormStep: action.payload,
      };
    case types.SET_CREATED_EVENT_ID:
      return {
        ...state,
        createdEventId: action.payload,
      };
    case types.SET_ACTIVE_PUBLISH_MODE:
      return {
        ...state,
        activePublishMode: action.payload,
      };
    default:
      return state;
  }
};

export default routing;
