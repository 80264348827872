import React, { useState } from "react";
import messages from "../../../../../assets/locale/messages";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import { TextField, Button, Box, Snackbar } from "@mui/material";
import Modal from "../../../../../components/Modal";

const ZoneNotificationForm = ({
  handleCloseNotification,
  handleSubmitNotification,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared, events } = messages[lang];

  const validationSchema = Yup.object({
    message: Yup.string().required(`${events.viewEvents.messageRequired}`),
  });

  const [notificationMessage, setNotificationMessage] = useState(false);
  const [sendShowModal, setSendShowModal] = useState(false);
  const handleCloseModal = () => setSendShowModal(false);
  const initialValues = {
    message: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    handleSubmitNotification(values.message);
    handleCloseNotification();
    setNotificationMessage(true);
  };

  const handleCancel = (resetForm) => {
    resetForm();
    handleCloseNotification();
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          resetForm,
          submitForm,
        }) => (
          <Form>
            <Box display="flex" flexDirection="column" gap={2} className="mt-4">
              <Field
                as={TextField}
                name="message"
                label={events.viewEvents.enterPushNotification}
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                error={touched.message && Boolean(errors.message)}
                helperText={touched.message && errors.message}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
              />
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"end"}
                gap={2}
                mt={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setSendShowModal(true)}
                >
                  {events.viewEvents.sendNotification}
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleCancel(resetForm)}
                >
                  {shared.cancel}
                </Button>
              </Box>
            </Box>
            <Modal
              open={sendShowModal}
              maxWidth="xs"
              cancelBtnTxt={shared.cancel}
              handleCancel={handleCloseModal}
              handleClose={handleCloseModal}
              confirmBtnTxt={shared.confirm}
              handleConfirm={submitForm}
              content={
                <h4 className="text-center">
                  {events.viewEvents.submitEventPushNotification}
                </h4>
              }
            />
          </Form>
        )}
      </Formik>
      <Snackbar
        open={notificationMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: lang === "en" ? "right" : "left",
        }}
        autoHideDuration={2000}
        message={events.viewEvents.notificationSend}
        className="notification-message"
      />
    </>
  );
};

ZoneNotificationForm.propTypes = {
  handleCloseNotification: PropTypes.func.isRequired,
  handleSubmitNotification: PropTypes.func,
};

export default ZoneNotificationForm;
