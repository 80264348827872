import React from "react";

export const Education = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="19"
    fill="none"
    viewBox="0 0 16 19"
  >
    <path
      fill="#4E5D78"
      stroke="#4E5D78"
      strokeWidth="0.2"
      d="M13.472 1.627v1.83c0 .157-.017.303-.181.387-.165.078-.302.01-.439-.079-.191-.125-.383-.256-.58-.366a.293.293 0 00-.23 0c-.214.115-.406.272-.62.377a.611.611 0 01-.394.052c-.154-.037-.181-.188-.181-.34V1.376c0-.266.12-.376.4-.376h3.178c.586 0 1.008.408 1.008.962v15.593c0 .57-.421.973-1.024.973H2.025C1.41 18.528 1 18.125 1 17.54V1.988C1 1.403 1.416 1 2.025 1H9.45c.257 0 .41.12.405.314 0 .193-.153.313-.41.313H2.122c-.378 0-.47.09-.47.45v15.38c0 .36.092.449.47.449h12.182c.378 0 .47-.095.47-.45V2.076c0-.36-.098-.449-.47-.449h-.839.006zm-.669 1.375V1.638h-1.277v1.395c.433-.517.85-.454 1.277-.026v-.005z"
    ></path>
    <path
      fill="#4E5D78"
      stroke="#4E5D78"
      strokeWidth="0.2"
      d="M12.184 9.526c-.208.084-.383.147-.548.236-.043.026-.065.12-.065.183 0 .559-.011 1.123 0 1.683 0 .23-.077.376-.296.486-2.05 1.004-4.099 1.004-6.143-.016-.197-.099-.268-.235-.268-.439.005-.575 0-1.145 0-1.72 0-.115-.033-.177-.148-.22-.29-.114-.576-.24-.86-.36-.154-.063-.258-.157-.253-.319 0-.167.115-.25.263-.313 1.381-.58 2.757-1.171 4.137-1.746a.602.602 0 01.428 0c1.38.575 2.756 1.165 4.137 1.746.186.078.274.203.274.392v3.21c0 .234-.132.365-.334.36-.203 0-.318-.136-.318-.376v-2.54-.241l-.006-.006zm-1.27.544c-.1.037-.17.063-.236.089-.735.314-1.475.622-2.209.94a.552.552 0 01-.488 0c-.4-.172-.8-.334-1.2-.506-.23-.1-.317-.267-.235-.45.082-.178.268-.22.51-.12.34.141.668.293 1.013.418a.494.494 0 00.302 0c1.046-.434 2.082-.878 3.123-1.322.05-.021.099-.053.164-.084-.033-.02-.049-.037-.065-.042-1.085-.46-2.17-.925-3.255-1.375a.398.398 0 00-.269.01c-1.052.44-2.099.884-3.145 1.329-.05.02-.093.052-.17.088.17.074.307.142.45.189.23.078.328.22.323.46-.017.433 0 .873 0 1.312 0 .6 0 .617.58.826 1.584.575 3.14.507 4.67-.183.054-.026.13-.084.13-.126.012-.475.006-.946.006-1.453z"
    ></path>
  </svg>
);
