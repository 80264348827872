import { call, put, takeLatest } from "redux-saga/effects";
import { EXPORT_APPLICANT_REQUEST } from "./types";
import * as apis from "../../network/apis/download";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";

function* downloadDataSaga(action) {
  try {
    const { params, token } = action.payload;
    const response = yield call(apis.getDocument, { params, token });
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "applicant_reports.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    yield put(showSuccessMsg("File downloaded successfully!"));
  } catch (error) {
    if (error.response) {
      showErrorMsg("Error downloading file");
    }
  }
}

export default function* DocumentFileSagas() {
  yield takeLatest(EXPORT_APPLICANT_REQUEST, downloadDataSaga);
}
