import React from "react";
import PropTypes from "prop-types";

export const JoiningRequestsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 30 33"
    {...props}>
    <path
      fill={props.selectedIcon ? "#448BCA" : "#6B778C"}
      d="M8.415 6.585c-.6 0-1.098-.497-1.098-1.097v-4.39C7.317.498 7.815 0 8.415 0c.6 0 1.097.498 1.097 1.098v4.39c0 .6-.497 1.097-1.097 1.097zM20.122 6.585c-.6 0-1.098-.497-1.098-1.097v-4.39c0-.6.498-1.098 1.098-1.098.6 0 1.098.498 1.098 1.098v4.39c0 .6-.498 1.097-1.098 1.097zM9.146 19.39c-.19 0-.38-.029-.556-.117a1.69 1.69 0 01-.483-.307 1.54 1.54 0 01-.424-1.04c0-.19.044-.38.117-.555a1.69 1.69 0 01.307-.483 1.69 1.69 0 01.483-.307 1.494 1.494 0 011.595.307c.132.146.234.307.308.483.073.175.117.366.117.556 0 .38-.161.76-.425 1.039a1.539 1.539 0 01-1.039.424zM14.268 19.39c-.38 0-.76-.16-1.039-.424a1.69 1.69 0 01-.307-.483 1.456 1.456 0 01-.117-.556c0-.38.16-.761.424-1.04a1.461 1.461 0 011.595-.307c.176.074.337.176.483.308.264.278.425.658.425 1.039 0 .19-.03.38-.117.556a1.69 1.69 0 01-.308.483 1.688 1.688 0 01-.483.307 1.227 1.227 0 01-.556.117zM9.146 24.512c-.19 0-.38-.044-.556-.117a1.691 1.691 0 01-.483-.307 1.511 1.511 0 01-.424-1.04c0-.19.044-.38.117-.555a1.51 1.51 0 01.307-.483c.147-.132.308-.235.483-.308.527-.234 1.186-.102 1.595.308.132.146.234.292.308.482.073.176.117.366.117.557 0 .395-.161.76-.425 1.039a1.539 1.539 0 01-1.039.424zM26.707 12.57H1.83c-.6 0-1.097-.497-1.097-1.097s.497-1.098 1.097-1.098h24.878c.6 0 1.098.498 1.098 1.098 0 .6-.498 1.097-1.098 1.097zM23.049 32.927a6.957 6.957 0 01-6.951-6.951 6.957 6.957 0 016.95-6.952A6.958 6.958 0 0130 25.976a6.957 6.957 0 01-6.951 6.95zm0-11.707a4.765 4.765 0 00-4.756 4.756 4.765 4.765 0 004.756 4.756 4.765 4.765 0 004.756-4.756 4.765 4.765 0 00-4.756-4.756z"></path>
    <path
      fill={props.selectedIcon ? "#448BCA" : "#6B778C"}
      d="M25.23 27.146h-4.376c-.6 0-1.098-.498-1.098-1.098 0-.6.498-1.097 1.098-1.097h4.375c.6 0 1.098.497 1.098 1.098 0 .6-.483 1.097-1.098 1.097z"></path>
    <path
      fill={props.selectedIcon ? "#448BCA" : "#6B778C"}
      d="M23.049 29.385c-.6 0-1.098-.498-1.098-1.098v-4.375c0-.6.498-1.098 1.098-1.098.6 0 1.097.498 1.097 1.098v4.376c0 .6-.497 1.097-1.097 1.097z"></path>
    <path
      fill={props.selectedIcon ? "#448BCA" : "#6B778C"}
      d="M19.2 31.463H8.415C3.073 31.463 0 28.39 0 23.05v-12.44c0-5.34 3.073-8.414 8.415-8.414h11.707c5.341 0 8.415 3.073 8.415 8.415v11.502c0 .454-.278.864-.717 1.025-.425.16-.908.043-1.215-.308a4.775 4.775 0 00-3.57-1.61 4.765 4.765 0 00-4.757 4.757c0 .863.234 1.712.688 2.444.249.438.57.804.922 1.112.351.292.483.775.322 1.214a1.047 1.047 0 01-1.01.717zM8.415 4.39c-4.186 0-6.22 2.034-6.22 6.22v12.439c0 4.185 2.034 6.22 6.22 6.22h8.517a6.876 6.876 0 01-.834-3.293 6.957 6.957 0 016.95-6.952 6.92 6.92 0 013.294.834V10.61c0-4.186-2.035-6.22-6.22-6.22H8.415z"></path>
  </svg>
);

JoiningRequestsIcon.propTypes = {
  selectedIcon: PropTypes.bool,
};
