import React from "react";
import PropTypes from "prop-types";
import { Switch as MuiSwitch, FormControlLabel } from "@mui/material";

import "./Switch.scss";

const Switch = ({
  label,
  checked,
  onChange,
  id,
  name,
  required,
  disabled,
  color,
  labelPlacement,
  labelClass,
  switchClass,
  wrapperClass,
  formLabelClass,
}) => {
  return (
    <div className={`${wrapperClass} switch-container`}>
      <FormControlLabel
        control={
          <MuiSwitch
            id={id || name}
            name={name}
            color={color}
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            required={required}
            className={switchClass}
          />
        }
        label={<span className={`${labelClass} switch-label`}>{label}</span>}
        labelPlacement={labelPlacement}
        disabled={disabled}
        className={formLabelClass}
      />
    </div>
  );
};

export default Switch;

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  color: PropTypes.string,
  labelPlacement: PropTypes.oneOf(["bottom", "end", "start", "top"]),
  labelClass: PropTypes.string,
  switchClass: PropTypes.string,
  wrapperClass: PropTypes.string,
  formLabelClass: PropTypes.string,
};

Switch.defaultProps = {
  labelPlacement: "start",
  color: "primary",
};
