import * as types from "./types";

// list
export const getApplicantstTimeSheetRequests = (payload) => ({
  type: types.GET_APPLICANTS_WORKING_SLOTS_REQUEST,
  payload,
});

export const getApplicantstTimeSheetResponse = (payload) => ({
  type: types.GET_APPLICANTS_WORKING_SLOTS_RESPONSE,
  payload,
});
export const editApplicantTimeSheetRequest = (payload) => ({
  type: types.EDIT_APPLICANT_SHEET_REQUEST,
  payload,
});

export const addApplicantTimeSheet = (payload) => {
  return {
    type: types.ADD_APPLICANT_TIME_SHEET,
    payload,
  };
};

export const editApplicantTimeSheetResponse = (payload) => ({
  type: types.EDIT_APPLICANT_SHEET_RESPONSE,
  payload,
});

export const deleteApplicantTimeSheetRequest = (payload) => ({
  type: types.DELETE_APPLICANT_SHEET_REQUEST,
  payload,
});
