import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import {
  getApplicantRequest,
  updateApplicantProfileRequest,
} from "../../../store/applicants/actions";
import Breadcrumbs from "../../../components/BreadCrumbs";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import Button from "../../../components/Button";
import DatePicker from "../../../components/DatePicker/index";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { genders, lookupsKeys } from "../../../utils/Constants";
import {
  getCitiesRequest,
  getCountriesRequest,
  getLookupsRequest,
} from "../../../store/Lookups/actions";
import MultipleSelectCheckmarks from "../../../components/MultiSelect";
import shared from "../../../assets/locale/En/shared";
import shared_ar from "../../../assets/locale/Ar/shared";
import dayjs from "dayjs";
import moment from "moment-hijri";
import {
  convertGregorianToHijri,
  convertHijriToGregorian,
} from "../../../utils/Helpers";

const handleFormat = (_date) => {
  const parsedDate = dayjs(_date, "DD-MM-YYYY");
  return parsedDate.format("YYYY-MM-DD");
};

const gregorianFormat = (_date) =>
  moment(_date, "iYYYY-iMM-iDD").format("YYYY-MM-DD");

const EditApplicant = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const lang = useSelector((state) => state.locale.lang);
  const { applicants } = messages[lang];
  const { applicant } = useSelector((state) => state.applicants);
  const cities = useSelector((state) => state.lookups.cities);
  const countries = useSelector((state) => state.lookups.countries);
  const { roles, fieldOfStudies, disabilities, chronicDiseases, banks } =
    useSelector((state) => state.lookups.general);
  const genderOptions = [
    {
      id: 0,
      name: shared.male,
      value: genders.male,
    },
    {
      id: 1,
      name: shared.female,
      value: genders.female,
    },
    {
      id: 2,
      name: shared.notProvided,
      value: genders.notProvided,
    },
  ];
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [studyField, setStudyField] = useState({});
  const [fieldStudyName, setFieldStudyName] = useState(null);
  const [hijriDob, setHijriDob] = useState("");
  const [gregorianDob, setGregorianDob] = useState("");
  const [city, setCity] = useState({});
  const [iban, setIban] = useState(null);
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [iqmaExpireDate, setIqmaExpireDate] = useState("");
  const [iqmaNumber, setIqmaNumber] = useState("");
  const [matloobNumber, setMatloobNumber] = useState("");
  const [nationalExpireDate, setNationalExpireDate] = useState("");
  const [nationality, setNationality] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [rating, setRating] = useState("");
  const [stcNumber, setStcNumber] = useState("");
  const [role, setRole] = useState({});
  const [bank, setBank] = useState({});
  const [isNotifiable, setIsNotifiable] = useState(null);
  const [disability, setDisability] = useState([]);
  const [chronic, setChronic] = useState([]);
  const [genderID, setGenderID] = useState(null);
  const [nationalID, setNationalID] = useState(null);
  const [updateHijri, setUpdateHijri] = useState("");
  useEffect(() => {
    dispatch(
      getLookupsRequest([
        lookupsKeys.roles,
        lookupsKeys.fieldOfStudies,
        lookupsKeys.disabilities,
        lookupsKeys.chronicDiseases,
        lookupsKeys.banks,
        lookupsKeys.languages,
      ])
    );
  }, []);

  useEffect(() => {
    if (countries) {
      setCountryCode(nationality?.dial_code);
    }
  }, [nationality]);

  useEffect(() => {
    dispatch(
      getCitiesRequest({
        page: -1,
        order_by_name_en: lang === "en" ? "asc" : null,
        order_by_name_ar: lang === "ar" ? "asc" : null,
      })
    );
  }, [lang]);

  useEffect(() => {
    dispatch(getCountriesRequest({ page: -1 }));
  }, [lang]);

  const getApplicant = (id) => {
    dispatch(
      getApplicantRequest({
        id,
      })
    );
  };

  useEffect(() => {
    getApplicant(id);
  }, []);

  useEffect(() => {
    if (applicant) {
      setGregorianDob(applicant?.gregorian_date_of_birth);
      setHijriDob(convertHijriToGregorian(applicant?.hijri_date_of_birth));
      setFirstName(applicant?.first_name);
      setMiddleName(applicant?.middle_name);
      setLastName(applicant?.last_name);
      setGender(applicant?.gender);
      setCardHolderName(applicant?.card_holder_name);
      setCountryCode(applicant?.country_code);
      setStudyField(applicant?.field_of_study);
      setHeight(applicant?.height);
      setWeight(applicant?.weight);
      setIban(applicant?.iban);
      setIqmaExpireDate(applicant?.iqama_expiration_date);
      setIqmaNumber(applicant?.iqama_id);
      setMatloobNumber(applicant?.matloob_number);
      setNationalExpireDate(
        applicant?.national_id_expiration_date &&
          handleFormat(applicant?.national_id_expiration_date)
      );
      setNationality(applicant?.nationality);
      setPhoneNumber(applicant?.phone_number);
      setRating(applicant?.rating);
      setRole(applicant?.role);
      setStcNumber(applicant?.stc_number);
      setBank(applicant?.bank);
      setCity(applicant?.city);
      setDisability(applicant?.disabilities);
      setChronic(applicant?.chronic_diseases);
      setFieldStudyName(applicant?.field_of_study_name);
      setNationalID(applicant?.national_id);
      setIsNotifiable(applicant?.is_notifiable);
    }
  }, [applicant, lang]);

  useEffect(() => {
    if (applicant?.gender && applicant?.gender !== null) {
      setGender(
        genderOptions.find(
          (_g) => _g?.value.toLowerCase() === applicant?.gender
        )?.value
      );
    } else {
      setGender(genderOptions[genderOptions?.length - 1]?.name);
    }
    const _gender = genderOptions.find((g) => g.value.toLowerCase() === gender);
    setGenderID(_gender?.id);
  }, [applicant]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      updateApplicantProfileRequest({
        id,
        data: {
          first_name_en: firstName,
          middle_name_en: middleName,
          last_name_en: lastName,
          gender: genderID,
          card_holder_name: cardHolderName,
          country_code: countryCode,
          field_of_study_id: studyField?.id,
          hijri_date_of_birth: updateHijri,
          gregorian_date_of_birth: gregorianDob,
          iqama_expiration_date: iqmaExpireDate,
          iqama_id: iqmaNumber,
          matloob_number: matloobNumber,
          national_id_expiration_date: nationalExpireDate,
          national_id: nationalID,
          phone_number: phoneNumber,
          disability_ids: disability.map((ids) => ids.id),
          stc_number: stcNumber,
          chronic_disease_ids: chronic.map((ids) => ids.id),
          field_of_study_name: fieldStudyName,
          role_id: role?.id,
          is_notifiable: isNotifiable,
          iban,
          height,
          weight,
          nationality_id: nationality?.id,
          rating,
          bank_id: bank?.id,
          city_id: city?.id,
        },
      })
    );
  };

  useEffect(() => {
    setUpdateHijri(convertGregorianToHijri(hijriDob));
  }, [hijriDob]);

  return (
    <div className="applicant-details-page-container">
      <div className="w-100 page-title-margin d-flex justify-content-between align-items-center">
        <Breadcrumbs
          list={[
            {
              path: ROUTE_PATHS.applicants,
              label: applicants.listApplicants.listApplicantsBreadcrumb,
            },
            {
              path: "",
              label: applicant?.["full_name_" + lang],
            },
          ]}
          BreadCrumbHasBackIcon={false}
        />
      </div>
      <div className="title-wrapper col-12 mb-4">
        <span></span>
        <h2 className="mb-0">{lang === 'en' ? 'Edit Profile' : shared_ar.EditProfile}</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="bg-white p-4 mb-md-4 mb-3 rounded-2">
          <div className="d-flex align-items-center gap-4 w-100 flex-xl-nowrap flex-wrap">
            <div className="form-group w-100">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .firstName
                }
              </label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="form-group w-100">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .middleName
                }
              </label>
              <input
                type="text"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="form-group w-100">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .lastName
                }
              </label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-4">
            <div className="form-group w-100 flex-xl-nowrap flex-wrap">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .gender
                }
              </label>
              <select
                value={gender}
                onChange={(e) => {
                  const _genderFind = genderOptions.find(
                    (g) => g.value === e.target.value
                  );
                  setGenderID(_genderFind?.id);
                  setGender(e.target.value);
                }}
              >
                {genderOptions.map((gender) => (
                  <option value={gender.value} key={gender.id}>
                    {gender.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="d-flex align-items-center gap-4 w-100 flex-xl-nowrap flex-wrap">
            <div className="form-group w-100">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={gregorianFormat(hijriDob)}
                  onChange={(newDate) =>
                    setHijriDob(moment(newDate).format("iYYYY-iMM-iDD"))
                  }
                  name="dobhijri"
                  id="dobhijri"
                  label={
                    applicants.applicantDetails.applicantProfile.labels
                      .basicInfo.dateOfBirthHijri
                  }
                  placeholder="Select your date of birth"
                  calendarViews={["year", "month", "day"]}
                  openView="year"
                  wrapperClass="wrapper"
                  inputClass="input-input-wrapper"
                  labelClass="input-label-date"
                  inputDateFormat="MM-DD-YYYY"
                  isInputHasErr={false}
                  disableFuture={false}
                  shouldNotBeLessThanToday={false}
                  preventManualDateEntry={false}
                />
              </LocalizationProvider>
            </div>

            <div className="form-group w-100">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={gregorianDob}
                  onChange={(newDate) => setGregorianDob(newDate)}
                  name="dobGregorian"
                  id="dobGregorian"
                  label={
                    applicants.applicantDetails.applicantProfile.labels
                      .basicInfo.dateOfBirthGregorian
                  }
                  required={false}
                  placeholder="Select your date of birth"
                  calendarViews={["year", "month", "day"]}
                  openView="year"
                  wrapperClass="wrapper"
                  inputClass="input-input-wrapper"
                  labelClass="input-label-date"
                  disableFuture={false}
                  isInputHasErr={false}
                  inputDateFormat="MM-DD-YYYY"
                  shouldNotBeLessThanToday={false}
                  preventManualDateEntry={false}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="d-flex align-items-center gap-4 flex-xl-nowrap flex-wrap">
            <div className="form-group w-100">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .height
                }{" "}
                (cm)
              </label>
              <input
                type="text"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="form-group w-100">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .weight
                }{" "}
                (kg)
              </label>
              <input
                type="text"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-4 w-100 flex-xl-nowrap flex-wrap">
            <div className="form-group w-100">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .nationality
                }
              </label>

              <select
                value={nationality?.name}
                onChange={(e) => {
                  const _nationality = countries.find(
                    (country) => country.name === e.target.value
                  );
                  setNationality(_nationality);
                }}
              >
                {countries.map((country) => (
                  <option value={country.name} key={country.id}>
                    {country?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group w-100">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .countryCode
                }
              </label>
              <input
                type="text"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                className="form-control"
              />
            </div>

            <div className="form-group w-100">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .cityOfResidence
                }
              </label>
              <select
                value={city?.name}
                onChange={(e) => {
                  const selectedCity = cities.find(
                    (city) => city.name === e.target.value
                  );
                  setCity(selectedCity);
                }}
              >
                {cities.map((city) => (
                  <option value={city.name} key={city.id}>
                    {city?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="d-flex align-items-center gap-4 w-100 flex-xl-nowrap flex-wrap">
            <div className="form-group w-100">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .nationalId
                }
              </label>
              <input
                type="text"
                value={nationalID}
                onChange={(e) => setNationalID(e.target.value)}
                className="form-control"
              />
            </div>

            <div className="form-group w-100">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={nationalExpireDate}
                  onChange={(newDate) => setNationalExpireDate(newDate)}
                  name="expireDate"
                  id="expireDate"
                  label={
                    applicants.applicantDetails.applicantProfile.labels
                      .basicInfo.expiryDate
                  }
                  placeholder="National ID Expire Date"
                  calendarViews={["year", "month", "day"]}
                  openView="year"
                  wrapperClass="wrapper"
                  inputClass="input-input-wrapper"
                  labelClass="input-label-date"
                  disableFuture={false}
                  isInputHasErr={false}
                  inputDateFormat="DD-MM-YYYY"
                  shouldNotBeLessThanToday={false}
                  preventManualDateEntry={false}
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className="d-flex align-items-center gap-4">
            <div className="form-group w-100">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .iqamaNumber
                }
              </label>
              <input
                type="text"
                value={iqmaNumber}
                onChange={(e) => setIqmaNumber(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="form-group w-100">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={iqmaExpireDate}
                  onChange={(newDate) => setIqmaExpireDate(newDate)}
                  name="iqamaexpiredate"
                  id="iqamaexpiredate"
                  label={
                    applicants.applicantDetails.applicantProfile.labels
                      .basicInfo.iqmaExpireDate
                  }
                  placeholder="Select your date of birth"
                  calendarViews={["year", "month", "day"]}
                  openView="year"
                  wrapperClass="wrapper"
                  inputClass="input-input-wrapper"
                  labelClass="input-label-date"
                  disableFuture={false}
                  inputDateFormat="MM-DD-YYYY"
                  isInputHasErr={false}
                  shouldNotBeLessThanToday={false}
                  preventManualDateEntry={false}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="d-flex align-items-center gap-4 w-100 flex-xl-nowrap flex-wrap">
            <div className="form-group w-100">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .matlooobNumber
                }
              </label>
              <input
                type="text"
                value={matloobNumber}
                onChange={(e) => setMatloobNumber(e.target.value)}
                className="form-control"
              />
            </div>

            <div className="form-group w-100">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .phoneNumber
                }
              </label>
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group">
            <label>
              {
                applicants.applicantDetails.applicantProfile.labels.basicInfo
                  .role
              }
            </label>
            <select
              value={role?.name}
              onChange={(e) => {
                const _role = roles.find(
                  (role) => role.name === e.target.value
                );
                setRole(_role);
              }}
            >
              {roles?.map((role) => (
                <option value={role?.name} key={role?.id}>
                  {role?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex align-items-center gap-4 w-100 flex-xl-nowrap flex-wrap">
            <div className="form-group w-100">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .disability
                }
              </label>
              <MultipleSelectCheckmarks
                displayProperty="name"
                selectedValues={disability}
                options={disabilities}
                onChange={(selectUpdate) => setDisability(selectUpdate)}
              />
            </div>

            <div className="form-group w-100">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels.basicInfo
                    .chronicDisease
                }
              </label>
              <MultipleSelectCheckmarks
                displayProperty="name"
                selectedValues={chronic}
                options={chronicDiseases}
                onChange={(selectUpdate) => setChronic(selectUpdate)}
              />
            </div>
          </div>
        </div>
        <div className="bg-white p-4 mb-md-4 mb-3 rounded-2">
          <div className="form-group">
            <label>
              {
                applicants.applicantDetails.applicantProfile.labels
                  .educationInfo.filedsOfStudy
              }
            </label>
            <select
              value={studyField?.name}
              onChange={(e) => {
                const _study = fieldOfStudies.find(
                  (study) => study.name === e.target.value
                );
                setStudyField(_study);
              }}
            >
              {fieldOfStudies?.map((study) => (
                <option value={study?.name} key={study?.id}>
                  {study?.name}
                </option>
              ))}
            </select>
          </div>
          {studyField?.name === "Other" && (
            <div className="form-group">
              <label>
                {
                  applicants.applicantDetails.applicantProfile.labels
                    .educationInfo.fieldOfStudyName
                }
              </label>
              <input
                type="text"
                value={fieldStudyName}
                onChange={(e) => setFieldStudyName(e.target.value)}
                className="form-control"
              />
            </div>
          )}
        </div>
        <div className="bg-white p-4 mb-md-4 mb-3 rounded-2">
          <div className="form-group">
            <label>
              {
                applicants.applicantDetails.applicantProfile.labels.bankInfo
                  .title
              }
            </label>
            <select
              value={bank?.name}
              onChange={(e) => {
                const _bank = banks.find(
                  (bank) => bank.name === e.target.value
                );
                setBank(_bank);
              }}
            >
              {banks?.map((bank) => (
                <option value={bank?.name} key={bank?.id}>
                  {bank?.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>
              {
                applicants.applicantDetails.applicantProfile.labels.bankInfo
                  .IBAN
              }
            </label>
            <input
              type="text"
              value={iban}
              onChange={(e) => setIban(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>
              {
                applicants.applicantDetails.applicantProfile.labels.bankInfo
                  .cardHolder
              }
            </label>
            <input
              type="text"
              value={cardHolderName}
              onChange={(e) => setCardHolderName(e.target.value)}
              className="form-control"
            />
          </div>
        </div>

        <div className="bg-white p-4 mb-md-4 mb-3 rounded-2">
          <div className="form-group w-100 ">
            <label>
              {
                applicants.applicantDetails.applicantProfile.labels.basicInfo
                  .stcNumber
              }
            </label>
            <input
              type="text"
              value={stcNumber}
              onChange={(e) => setStcNumber(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group w-100">
            <label>{lang === 'en' ? 'Notifiable' : shared_ar.Notifiable}</label>
            <div className="d-flex align-items-center gap-4">
              <label className="d-flex align-items-center gap-2 cursor-pointer">
                <input
                  type="radio"
                  name="notify"
                  value={isNotifiable}
                  checked={isNotifiable === true}
                  onChange={() => setIsNotifiable(true)}
                />
                Yes
              </label>
              <label className="d-flex align-items-center gap-2 cursor-pointer">
                <input
                  type="radio"
                  name="notify"
                  value={isNotifiable}
                  checked={isNotifiable === false}
                  onChange={() => setIsNotifiable(false)}
                />
                No
              </label>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <Button
            type="submit"
            label={lang === 'en' ? applicants.savedChanging : shared_ar.savedChanging}
            labelClass="py-1 fsize-15 fweight-500 text-white"
            block
          />
        </div>
      </form>
    </div>
  );
};
export default EditApplicant;
