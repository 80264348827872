import * as types from "./types";

// list
export const getPromotionRequestsRequest = (payload) => ({
  type: types.GET_PROMOTION_REQUESTS_REQUEST,
  payload,
});

export const getPromotionRequestsResponse = (payload) => ({
  type: types.GET_PROMOTION_REQUESTS_RESPONSE,
  payload,
});

export const editPromotionRequestsRequest = (payload) => ({
  type: types.EDIT_PROMOTION_REQUEST_REQUEST,
  payload,
});
