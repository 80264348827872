import { call, put, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/location";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg } from "../../utils/Helpers";

function* getLocationsListSaga({ payload }) {
  try {
    const response = yield call(apis.getLocationsList, payload);
    yield put(actions.getLocationsResponse(response.data.data));
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

function* deleteLocationSaga({ payload }) {
  try {
    yield call(apis.deleteLocation, payload.id);
    yield showErrorMsg("Location deleted successfully!");
  } catch (error) {
    yield showErrorMsg(error.response?.data?.message);
  }
}

export default function* LocationsSagas() {
  yield takeEvery(types.GET_LOCATIONS_REQUEST, getLocationsListSaga);
  yield takeEvery(types.DELETE_LOCATION_REQUEST, deleteLocationSaga);
}
