import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
// import dayjs from "dayjs";
// import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";
import * as Yup from "yup";

import Modal from "../../../components/Modal";
// import TimePicker from "../../../components/TimePicker";
import messages from "../../../assets/locale/messages";
import Button from "../../../components/Button";
// import DatePicker from "../../../components/DatePicker";
import DateTimePicker from "../../../components/DateTimePicker";
import {
  addApplicantTimeSheet,
  editApplicantTimeSheetRequest,
} from "../../../store/ApplicantsTimeSheet/actions";
// import { formatDate } from "../../../utils/Helpers";
import "./ApplicantReportModal.scss";

const ApplicantReportModal = ({
  open,
  handleClose,
  selectedItem,
  isDaily,
  dateParam,
}) => {
  const dispatch = useDispatch();
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [selectedItemId, setSelectedItem] = useState();
  const list = useSelector((state) => state.applicantsTimeSheetRequest.list);

  const lang = useSelector((state) => state.locale.lang);
  const { shared, timeSheet } = messages[lang];
  const { full_name_en } = list[0]?.user || "";
  const { full_name_ar } = list[0]?.user || "";

  const { eventid, userid } = useParams();

  const getInitialValues = (slotValues) => {
    return {
      check_in_at: slotValues ? slotValues.check_in_at : "",
      check_out_at: slotValues ? slotValues.check_out_at : "",
    };
    // return {
    //   check_in_at: slotValues
    //     ? formatDate(slotValues.check_in_at, "hh:mm a")
    //     : "",
    //   date: slotValues ? formatDate(slotValues.check_in_at, "YYYY-MM-DD") : "",
    //   check_out_at: slotValues
    //     ? formatDate(slotValues.check_out_at, "hh:mm a")
    //     : "",
    // };
  };

  // const turnTimeToDate = (date, time) => {
  //   const formattedDate = dayjs(
  //     `${date}T${dayjs(time, "hh:mm a").format("HH:mm:ssZ")}`
  //   ).format();
  //   return formattedDate;
  // };

  const {
    setValues,
    handleSubmit,
    values,
    setFieldTouched,
    setFieldValue,
    touched,
    errors,
    setTouched,
    dirty,
    isValid,
  } = useFormik({
    initialValues: getInitialValues(),
    validationSchema: Yup.object({
      check_in_at: Yup.string().required("generalFieldRequired"),
      check_out_at: Yup.string().required("generalFieldRequired"),
      // date: Yup.string().required("requiredDate"),
    }),

    // onSubmit: async ({ date, check_in_at, check_out_at }) => {
    onSubmit: async ({ check_in_at, check_out_at }) => {
      // const formatted_check_in_at = turnTimeToDate(date, check_in_at);
      // const formatted_check_out_at = turnTimeToDate(date, check_out_at);

      if (selectedItemId) {
        dispatch(
          editApplicantTimeSheetRequest({
            data: {
              // check_in_at: formatted_check_in_at,
              // check_out_at: formatted_check_out_at,
              check_in_at,
              check_out_at,
            },
            id: selectedItemId,
            event_id: eventid,
            user_id: userid,
            isDaily,
            dateParam,
          })
        );
      } else {
        dispatch(
          addApplicantTimeSheet({
            data: {
              // check_in_at: formatted_check_in_at,
              // check_out_at: formatted_check_out_at,
              check_in_at,
              check_out_at,
              event_id: eventid,
              user_id: userid,
            },
            isDaily,
            dateParam,
          })
        );
      }
      handleCloseStatusModal();
    },
  });

  useEffect(() => {
    setOpenStatusModal(open);
    setSelectedItem(selectedItem?.id);
  }, [open]);

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
    handleClose(false);
    setValues(getInitialValues());
    setTouched({});
  };

  useEffect(() => {
    setValues(getInitialValues(selectedItem));
  }, [selectedItem, lang]);

  return (
    <Modal
      open={openStatusModal}
      handleClose={handleCloseStatusModal}
      title={`${
        selectedItemId ? timeSheet.EditStatus : timeSheet.addStatusFor
      } ${
        lang === "ar"
          ? selectedItem?.user.full_name_ar || full_name_ar
          : selectedItem?.user.full_name_en || full_name_en
      }`}
      modalClass="applicant-report-modal"
      maxWidth="xl"
      showCloseIcon={false}
      actionsClass="applicant-actions-container"
      content={
        <>
          <div className="form-wrapper">
            <form noValidate onSubmit={handleSubmit}>
              <div className="">
                {/* <div className="date-container mb-4">
                  <DatePicker
                    id="date"
                    name="date"
                    value={values["date"]}
                    onChange={(value) => {
                      setFieldTouched("date");
                      setFieldValue(
                        "date",
                        value ? formatDate(value, "YYYY-MM-DD") : ""
                      );
                    }}
                    disableFuture={true}
                    label={timeSheet.date}
                    labelClassName="font-medium  text-primary"
                    isInputHasErr={!!(touched["date"] && errors["date"])}
                    errMsg={errors["date"]}
                    required
                    placeholder={timeSheet.datePlaceholder}
                  />
                </div> */}

                <div className="applicant-timepicker mb-5">
                  <DateTimePicker
                    id="check-in"
                    name="check_in_at"
                    value={values["check_in_at"]}
                    onChange={(value) => {
                      setFieldTouched("check_in_at");
                      setFieldValue("check_in_at", value || "");
                    }}
                    placeholder={timeSheet.checkinPlaceHolder}
                    label={timeSheet.checkin}
                    labelClass={"check-in-date"}
                    disableFuture
                    isInputHasErr={
                      !!(touched["check_in_at"] && errors["check_in_at"])
                    }
                    errMsg={errors["check_in_at"]}
                    required
                  />
                  {/* <TimePicker
                    id="check-in"
                    name="check_in_at"
                    value={values["check_in_at"]}
                    onChange={(value) => {
                      setFieldTouched("check_in_at");
                      setFieldValue("check_in_at", value || "");
                    }}
                    placeholder={timeSheet.checkinPlaceHolder}
                    label={timeSheet.checkin}
                    labelClass={"check-in-date"}
                    isInputHasErr={
                      !!(touched["check_in_at"] && errors["check_in_at"])
                    }
                    errMsg={errors["check_in_at"]}
                    required
                  /> */}
                </div>
                <div className="mb-4 applicant-timepicker">
                  <DateTimePicker
                    id="check_out"
                    name="check_out_at"
                    value={values["check_out_at"]}
                    onChange={(value) => {
                      setFieldTouched("check_out_at");
                      setFieldValue("check_out_at", value || "");
                    }}
                    placeholder={timeSheet.checkoutPlaceHolder}
                    label={timeSheet.checkout}
                    labelClass={"check-out-date"}
                    required
                    disableFuture
                    isInputHasErr={
                      !!(touched["check_out_at"] && errors["check_out_at"])
                    }
                    errMsg={errors["check_out_at"]}
                  />
                  {/* <TimePicker
                    id="check_out"
                    name="check_out_at"
                    value={values["check_out_at"]}
                    onChange={(value) => {
                      setFieldTouched("check_out_at");
                      setFieldValue("check_out_at", value || "");
                    }}
                    placeholder={timeSheet.checkoutPlaceHolder}
                    label={timeSheet.checkout}
                    labelClass={"check-out-date"}
                    required
                    isInputHasErr={
                      !!(touched["check_out_at"] && errors["check_out_at"])
                    }
                    errMsg={errors["check_out_at"]}
                  /> */}
                </div>
              </div>
              <div className="mt-5 d-flex justify-content-end  btns-container buttom-btns-container">
                <Button
                  type="submit"
                  label={shared.save}
                  labelClass="px-4"
                  className="mx-2 btns continue-btn"
                  disabled={!dirty || !isValid}
                />
                <Button
                  type="button"
                  label={shared.cancel}
                  labelClass="px-4"
                  onClick={handleCloseStatusModal}
                  outlined
                  className="mx-2 btns cancel-btn"
                />
              </div>
            </form>
          </div>
        </>
      }
    />
  );
};

export default ApplicantReportModal;

ApplicantReportModal.propTypes = {
  open: PropTypes.bool,
  selectedItem: PropTypes.string,
  handleClose: PropTypes.func,
  isDaily: PropTypes.bool,
  dateParam: PropTypes.string,
};
