import React from "react";

export const Certificate = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="21"
    fill="none"
    viewBox="0 0 16 21"
  >
    <path
      fill="#4E5D78"
      stroke="#4E5D78"
      strokeWidth="0.3"
      d="M10.839 20.243c-.19-.08-.246-.23-.241-.446.014-.557 0-1.113.01-1.674 0-.18-.048-.23-.218-.23-2.746.004-5.491 0-8.242 0-.713 0-1.148-.457-1.148-1.213V2.238c0-.667.288-1.048.898-1.198.028-.005.057-.025.08-.04h9.782c.066.02.133.045.199.06.41.115.718.511.751.962.01.11.01.226.01.336v6.875c0 .187.085.298.255.331 1.276.266 2.188 1.213 2.41 2.566.18 1.102-.147 2.05-.903 2.826a.457.457 0 00-.146.356c.004 1.483 0 2.966.01 4.455 0 .22-.048.375-.237.47h-.071c-.104-.1-.218-.19-.317-.295l-1.105-1.193c-.043-.05-.095-.09-.185-.18-.056.085-.094.165-.151.225l-1.361 1.444h-.07l-.01.005zM1.515 9.449v7.085c0 .12.005.236.024.356a.49.49 0 00.42.436c.109.015.222.02.336.02h8.047c.26 0 .26 0 .26-.28v-1.75a.437.437 0 00-.141-.34c-.794-.807-1.125-1.794-.894-2.932.284-1.393 1.13-2.214 2.444-2.485.165-.035.193-.095.193-.25V2.207c0-.421-.189-.622-.58-.622h-9.5c-.387 0-.571.18-.6.592-.004.075 0 .15 0 .225V9.45h-.009zm10.959.631c-1.357 0-2.458 1.148-2.462 2.57-.005 1.424 1.1 2.601 2.438 2.607 1.361 0 2.443-1.143 2.457-2.596.015-1.403-1.091-2.58-2.433-2.58z"
    ></path>
    <path
      fill="#4E5D78"
      stroke="#4E5D78"
      strokeWidth="0.3"
      d="M6.874 9.74H3.741c-.057 0-.118 0-.175-.01-.132-.036-.222-.131-.217-.282 0-.14.085-.23.217-.255.071-.015.142-.02.213-.02h6.195c.057 0 .118 0 .175.015.156.03.24.125.227.3-.01.17-.118.241-.26.251-.16.01-.317 0-.477 0H6.874zM6.87 4.297H3.66c-.127 0-.25-.02-.287-.16-.024-.08-.02-.205.023-.256a.387.387 0 01.255-.12c.478-.01.955-.005 1.437-.005h4.943c.227 0 .33.085.34.26.005.166-.08.271-.246.276-.236.005-.472 0-.708 0H6.865l.004.005zM6.868 7.018H3.801c-.07 0-.142 0-.213-.005-.146-.005-.236-.1-.245-.245-.014-.16.08-.256.222-.296.038-.01.08-.005.123-.005h6.341c.227 0 .34.095.34.28 0 .146-.07.236-.207.256-.076.01-.152.01-.232.01H6.863l.005.005zM6.515 15.157c-.96 0-1.914 0-2.874-.005-.09 0-.203-.07-.26-.145-.037-.056-.033-.19.005-.256.038-.065.137-.115.218-.13.137-.02.283-.005.425-.005h5.316c.213 0 .303.075.317.24.014.18-.085.296-.26.301H6.515v0zM6.198 11.904h2.476c.071 0 .166-.02.208.02a.5.5 0 01.16.26c.02.14-.084.226-.207.256-.052.01-.104.005-.16.005H3.612c-.16-.01-.279-.13-.274-.286 0-.145.113-.255.274-.255.36-.005.718 0 1.078 0h1.507zM13.772 12.661c0 .782-.576 1.403-1.304 1.403-.69 0-1.309-.546-1.323-1.393-.014-.777.59-1.393 1.318-1.398.737 0 1.314.606 1.314 1.388h-.005z"
    ></path>
  </svg>
);
