import React from "react";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";

import { GlobalIcon } from "../../utils/Icons";
import messages from "../../assets/locale/messages";
import { setCurrentLang } from "../../store/Lang/actions";
import "./LangSwitcher.scss";

const LangSwitcher = ({ internalSwitcher }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const dispatch = useDispatch();

  const handleChange = (event) => {
    if (internalSwitcher) {
      dispatch(setCurrentLang(lang === "en" ? "ar" : "en"));
    } else {
      dispatch(setCurrentLang(event.target.value));
    }
  };

  return (
    <div className="lang-switcher-container">
      {internalSwitcher ? (
        <div className="icon-switcher d-flex" onClick={handleChange}>
          <GlobalIcon />
        </div>
      ) : (
        <div className="py-2">
          <FormControl fullWidth>
            <Select value={lang} onChange={handleChange}>
              <MenuItem value={"en"}>{shared.englishLang}</MenuItem>
              <MenuItem value={"ar"}>{shared.arabicLang}</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
};

export default LangSwitcher;

LangSwitcher.propTypes = {
  internalSwitcher: PropTypes.bool,
};
