import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  ListItem,
  List,
} from "@mui/material";
import PropTypes from "prop-types";

import FilterInput from "../../../components/Filter/FilterInput";
import SearchInput from "../../../components/Filter/SearchInput";
import Button from "../../../components/Button";
import EmptyState from "../../../components/EmptyState";
import messages from "../../../assets/locale/messages";
import {
  textContainOnlySpaces,
  renderTextEllipsis,
} from "../../../utils/Helpers";
import { getRequestsRequest } from "../../../store/Requests/actions";
import applicantsPlaceholder from "../../../assets/images/event-screen/applicants-placeholder.svg";
import "./ZoneApplicants.scss";

const ModalContent = ({ eventId, roles, onSelectItems }) => {
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterRoles, setFilterRoles] = useState(null);
  const [page, setPage] = useState(1);
  const lang = useSelector((state) => state.locale.lang);
  const { zoneApplicants, shared } = messages[lang];
  const list = useSelector((state) => state.requests.unassignApplicants.list);
  const meta = useSelector((state) => state.requests.unassignApplicants.meta);
  const isLoading = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const handleToggle = (value) => () => {
    const currentIndex = selectedApplicants.indexOf(value);
    const newChecked = [...selectedApplicants];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedApplicants(newChecked);
  };

  useEffect(() => {
    onSelectItems(selectedApplicants);
  }, [selectedApplicants]);

  const getApplicantsList = (pageNo, search, role) => {
    dispatch(
      getRequestsRequest({
        page: pageNo,
        items: 10,
        event_id: eventId,
        status: "approved",
        is_assigned_to_zone: false,
        is_supervisor: false,
        user_name: search,
        role_id: role?.id,
        order_by_created_at: "asc",
        responseType: "unassignApplicants",
      })
    );
  };

  useEffect(() => {
    getApplicantsList(page, searchValue, filterRoles);
  }, [page, lang]);

  useEffect(() => {
    if (
      (searchValue.length >= 3 || searchValue.length === 0) &&
      !textContainOnlySpaces(searchValue)
    ) {
      setPage(1);
      getApplicantsList(1, searchValue, filterRoles);
    }
  }, [searchValue]);

  useEffect(() => {
    getApplicantsList(page, searchValue, filterRoles);
  }, [filterRoles]);

  const handleSetPage = (page) => {
    setPage(page);
  };

  const renderContent = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 pb-3">
            <EmptyState
              description={zoneApplicants.noApplicants}
              imgSrc={applicantsPlaceholder}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else {
      return (
        <List
          dense
          className={`${
            !!list?.length && meta.pages > 1 && page < meta.pages
              ? "rounded-top"
              : "rounded"
          }  col-12`}
          sx={{ width: "100%", bgcolor: "background.paper" }}>
          {list.map((value, index) => {
            const labelId = `checkbox-list-secondary-label-${value?.id}`;
            return (
              <ListItem
                sx={{
                  borderBottom:
                    index != list?.length - 1 ? "1px solid #F4F4F4" : "",
                }}
                key={value?.id}
                disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(value?.id)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      onChange={handleToggle(value?.id)}
                      checked={selectedApplicants.indexOf(value?.id) !== -1}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <div className="row px-4" id={labelId}>
                      <div className="col-12 mb-2 col-md-4 text-right text-dark-primary font-regular">
                        {renderTextEllipsis(value?.user?.["full_name_" + lang])}
                      </div>
                      <div className="col-12 mb-2 col-md-2 text-right text-dark-primary font-regular">
                        {shared.genderOptions[value.user.gender]}
                      </div>
                      <div className="col-12 mb-2 col-md-6 text-right text-dark-primary font-regular word-break">
                        {renderTextEllipsis(value.user.email, 30)}
                      </div>
                    </div>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      );
    }
  };
  return (
    <>
      <div className="containter">
        <div className="row">
          <div className="d-flex align-items-center mb-4 col-md-8 col-12">
            <span className="pe-2 text-main fweight-500">
              {zoneApplicants.applicantsRole}:
            </span>
            <FilterInput
              name="roles"
              placeholder={zoneApplicants.roles}
              options={roles}
              value={filterRoles}
              onChange={(value) => setFilterRoles(value)}
              filterWrapperClass={"w-40"}
            />
          </div>
          <div className="col-12 mb-4 col-md-4 d-flex justify-content-md-end text-gray">
            {selectedApplicants.length} {zoneApplicants.selected}
          </div>
          <div className="mb-4 col-12">
            <SearchInput
              placeholder={zoneApplicants.search}
              name="search"
              value={searchValue}
              inputWrapperClass="search-width"
              onChange={(value) => setSearchValue(value)}
            />
          </div>
          <div className="col-12">{renderContent()}</div>
        </div>
      </div>

      {!!list?.length && meta.pages > 1 && page < meta.pages && (
        <div className="d-flex justify-content-center rounded-bottom bg-white py-3">
          <Button
            label={shared.viewMore}
            labelClass="fsize-16 font-medium text-main"
            outlined
            className="border-0"
            onClick={() => {
              let pageNo = page;
              pageNo++;
              handleSetPage(pageNo);
            }}
          />
        </div>
      )}
    </>
  );
};

ModalContent.propTypes = {
  roles: PropTypes.array,
  eventId: PropTypes.number,
  onSelectItems: PropTypes.func,
};
export default ModalContent;
