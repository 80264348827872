import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import RadioButtonsGroup from "../../../../components/RadioButton";
import Modal from "../../../../components/Modal";
import messages from "../../../../assets/locale/messages";
import {
  updateEventRequest,
  updateSupervisorEventRequest,
} from "../../../../store/Requests/actions";
import "./ApplicantsTab.scss";

const StatusModal = ({
  open,
  selectedOption,
  handleClose,
  isOtherRole,
  selectedItem,
  page,
  eventId,
  searchValue,
  isSupervisor,
}) => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const event = useSelector((state) => state.events.event);
  const { events, shared } = messages[lang];
  const [mappingZones, setMappingZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [isZoneSelected, setIsZoneSelected] = useState(false);
  const modalTitle = {
    [events.viewEvents.applicantsTab.unassign]:
      events.viewEvents.applicantsTab.unassignApplicant,
    [events.viewEvents.applicantsTab.reallocate]:
      events.viewEvents.applicantsTab.reallocateApplicant,
    [events.viewEvents.applicantsTab.assign]:
      events.viewEvents.applicantsTab.assignApplicant,
  };

  useEffect(() => {
    if (!event?.zones?.length) return;
    const zones = event?.zones?.map((zone) => ({
      value: zone.id,
      label:
        isOtherRole && zone?.supervisor?.first_name
          ? [
              zone.name,
              `(${[
                zone?.supervisor?.first_name,
                zone?.supervisor?.middle_name,
                zone?.supervisor?.last_name,
              ].join(" ")})`,
            ].join(" ")
          : zone.name,
    }));
    const filteredZones = zones?.filter(
      (zone) => zone?.value !== selectedItem?.zone?.id
    );
    setMappingZones(filteredZones);
  }, [!!event, selectedItem]);

  useEffect(() => {
    setOpenStatusModal(open);
    setSelectedZone(open ? selectedItem?.zone?.id : null);
  }, [open]);

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
    handleClose(false);
    setIsZoneSelected(false);
  };

  const handleChangeStatus = () => {
    let params = {};
    isSupervisor
      ? (params["supervisor_id"] =
          selectedOption == events.viewEvents.applicantsTab.unassign
            ? null
            : selectedItem?.user?.id)
      : (params["zone_id"] =
          selectedOption == events.viewEvents.applicantsTab.unassign
            ? null
            : selectedZone);
    dispatch(
      (isSupervisor ? updateSupervisorEventRequest : updateEventRequest)({
        data: params,
        id: isSupervisor ? selectedZone : selectedItem?.id,
        listParams: {
          page: page,
          items: 10,
          user_name: searchValue,
          event_id: eventId,
          is_supervisor: isSupervisor,
          status: "approved",
          order_by_created_at: "asc",
          responseType: "applicantsTab",
        },
        eventId,
      })
    );
    handleCloseStatusModal();
  };

  return (
    <Modal
      open={openStatusModal}
      handleClose={handleCloseStatusModal}
      title={modalTitle[selectedOption]}
      content={
        selectedOption == events.viewEvents.applicantsTab.unassign ? (
          events.viewEvents.applicantsTab.unassignConfirmationMsg
        ) : (
          <RadioButtonsGroup
            wrapperClassName="d-flex align-items-center flex-row"
            labelClassName="d-none"
            className="d-flex flex-column justify-content-start align-items-start"
            required
            handleChange={(e) => {
              setSelectedZone(+e.target.value);
              setIsZoneSelected(true);
            }}
            listOfRadios={mappingZones}
            hideStar
          />
        )
      }
      isContentText={false}
      confirmBtnTxt={shared.save}
      maxWidth="xs"
      cancelBtnTxt={shared.cancel}
      handleConfirm={handleChangeStatus}
      handleCancel={handleCloseStatusModal}
      modalClass="status-modal"
      actionsClass="container"
      confirmBtnClass="btn-secondary text-white"
      isDisabled={
        selectedOption != events.viewEvents.applicantsTab.unassign &&
        !isZoneSelected
      }s
    />
  );
};

StatusModal.propTypes = {
  open: PropTypes.bool,
  selectedOption: PropTypes.string,
  handleClose: PropTypes.func,
  isOtherRole: PropTypes.bool,
  selectedItem: PropTypes.object,
  page: PropTypes.number,
  eventId: PropTypes.string,
  searchValue: PropTypes.string,
  isSupervisor: PropTypes.bool,
};

export default StatusModal;
