import { axiosInstance } from ".";

const getApplicantsTimeSheetRequestsList = async (params) =>
  await axiosInstance.get("/working_slots", { params });

const addApplicantTimeSheet = async ({ data }) =>
  await axiosInstance.post("/working_slots", data);

const editApplicantsTimeSheet = async ({ id, data }) =>
  await axiosInstance.put(`/working_slots/${id}`, data);

const deleteApplicantTimeSheet = async ({ id }) =>
  await axiosInstance.delete(`/working_slots/${id}`);

export {
  getApplicantsTimeSheetRequestsList,
  editApplicantsTimeSheet,
  addApplicantTimeSheet,
  deleteApplicantTimeSheet,
};
