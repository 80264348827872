import axios from "axios";

import { requestHandler, successHandler, errorHandler } from "../interceptors";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Handle request process
axiosInstance.interceptors.request.use((request) => requestHandler(request));

// Handle response process
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export const axiosUserAppInstance = axios.create({
  baseURL: process.env.REACT_APP_USER_APP_API_BASE_URL,
});

// Handle request process
axiosUserAppInstance.interceptors.request.use((request) =>
  requestHandler(request)
);

// Handle response process
axiosUserAppInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
