import { put, takeLatest, select, call } from "redux-saga/effects";
import * as types from "./types";
import * as actions from "./actions";
import { getApplicantsRequest } from "../applicants/actions";
import { isNumericValue } from "../../utils/Helpers";

function* fetchFilteredApplicantsSaga() {
  const {
    page,
    searchValue,
    profile_status,
    rowsCount,
    order,
    role_id,
    gender,
    is_assigned_to_event,
    height,
    weight,
    disability_id,
    language_id,
    age,
    chronic_disease_id,
    speaking_proficiency,
    city_id,
  } = yield select((state) => state._filters);
  const _role_id = role_id?.id;
  const searchParams = isNumericValue(searchValue)
    ? { id: searchValue }
    : { name: searchValue };
  yield put(
    getApplicantsRequest({
      page,
      items: rowsCount,
      ...searchParams,
      ...order,
      role_id: _role_id,
      profile_status: profile_status?.value,
      is_assigned_to_event: is_assigned_to_event?.value,
      gender: gender?.value,
      min_height: height?.value[0],
      max_height: height?.value[1],
      min_weight: weight?.value[0],
      min_age: age?.value[0],
      max_age: age?.value[1],
      max_weight: weight?.value[1],
      language_id: language_id?.id,
      disability_id: disability_id?.id || null,
      chronic_disease_id: chronic_disease_id?.id,
      without_disabilities: disability_id?.id === 0 || null,
      without_chronic_diseases: disability_id?.id === 0 || null,
      city_id: city_id?.id,
      speaking_proficiency: speaking_proficiency?.value,
    })
  );
}

function* loadInitialStateSaga() {
  try {
    yield call(fetchFilteredApplicantsSaga);
  } catch (error) {
    console.error("Error loading initial state:", error);
  }
}

function* filtersSaga(action) {
  try {
    const currentFilters = yield select((state) => state._filters);
    const newFilters = action.payload;
    if (JSON.stringify(currentFilters) !== JSON.stringify(newFilters)) {
      yield put(actions.setFilters(newFilters));
      yield call(fetchFilteredApplicantsSaga);
    }
  } catch (error) {
    console.error("Error updating filters:", error);
  }
}

function* pageSaga(action) {
  try {
    const { page } = yield select((state) => state._filters);
    if (action.payload !== page) {
      yield put(actions.setPage(action.payload));
      yield call(fetchFilteredApplicantsSaga);
    }
  } catch (error) {
    console.error("Error setting page:", error);
  }
}

function* rowsCountSaga(action) {
  try {
    const { rowsCount } = yield select((state) => state._filters);
    if (action.payload !== rowsCount) {
      yield put(actions.setRowsCount(action.payload));
      yield call(fetchFilteredApplicantsSaga);
    }
  } catch (error) {
    console.error("Error setting rows count:", error);
  }
}

export default function* filterSaga() {
  yield takeLatest(types.INITIAL_FETCH, loadInitialStateSaga);
  yield takeLatest(types.SET_FILTERS, filtersSaga);
  yield takeLatest(types.SET_PAGE, pageSaga);
  yield takeLatest(types.SET_ROWS_COUNT, rowsCountSaga);
}
