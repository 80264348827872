import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { InfoOutlined } from "@mui/icons-material";

import messages from "../../../../assets/locale/messages";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import RequestsTable from "../../../../components/RequestsTable";
import Tooltip from "../../../../components/Tooltip";
import { getApplicantRequest } from "../../../../store/applicants/actions";

const ApplicantRequests = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { applicants, shared } = messages[lang];
  const { id } = useParams();
  const applicant = useSelector((state) => state.applicants.applicant);

  const headLines = [
    applicants.applicantRequsets.eventName,
    <Tooltip
      key={1}
      title={<p>{shared.hint}</p>}
      content={
        <span>
          {shared.role}
          <span className="px-1">
            <InfoOutlined fontSize="small" />
          </span>
        </span>
      }
    />,
    applicants.applicantRequsets.requestDate,
    applicants.applicantRequsets.actions,
  ];

  const breadcrumbsList = [
    {
      path: ROUTE_PATHS.applicants,
      label: applicants.listApplicants.listApplicantsBreadcrumb,
    },
    {
      path: ROUTE_PATHS.viewApplicant.replace(":id", id),
      label: applicant?.["full_name_" + lang],
    },
    {
      path: "",
      label: applicants.applicantDetails.requests,
    },
  ];

  const getApplicant = () => {
    dispatch(
      getApplicantRequest({
        id,
      })
    );
  };

  useEffect(() => {
    getApplicant();
  }, [id, lang]);

  return (
    <RequestsTable
      isApplicant={true}
      headlines={headLines}
      breadcrumbsList={breadcrumbsList}
      userId={id}
      searchPlaceholder={applicants.search}
    />
  );
};

export default ApplicantRequests;
