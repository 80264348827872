import * as types from "./types";

export const addPm = (payload) => {
  return {
    type: types.ADD_PM,
    payload,
  };
};

// list
export const getEmployeesRequest = (payload) => ({
  type: types.GET_EMPLOYEES_REQUEST,
  payload,
});

export const getEmployeesResponse = (payload) => ({
  type: types.GET_EMPLOYEES_RESPONSE,
  payload,
});

export const getEmployeeRequest = (data) => ({
  type: types.GET_EMPLOYEE_REQUEST,
  payload: data,
});

export const getEmployeeResponse = (data) => ({
  type: types.GET_EMPLOYEE_RESPONSE,
  payload: data,
});

export const editEmployeeRequest = (payload) => ({
  type: types.EDIT_EMPLOYEE_REQUEST,
  payload,
});

export const editEmployeeResponse = (data) => ({
  type: types.EDIT_EMPLOYEE_RESPONSE,
  payload: data,
});
