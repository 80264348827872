import * as types from "./types";

const INITIAL_STATE = {
  list: {},
  isLoading: false,
  error: null,
};

const note = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CREATE_NOTE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case types.CREATE_NOTE_RESPONSE:
      return {
        ...state,
        list: { ...action.payload },
        isLoading: false,
      };
    case types.GET_NOTE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case types.GET_NOTE_RESPONSE:
      return {
        ...state,
        list: { ...action.payload },
        isLoading: false,
      };

    case types.UPDATE_NOTE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.UPDATE_NOTE_RESPONSE:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case types.DELETE_NOTE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case types.DELETE_NOTE_RESPONSE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default note;
