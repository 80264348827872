import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { Breadcrumbs as BreadcrumbsMUI, Link } from "@mui/material";
import "./BreadCrumbs.scss";

const Breadcrumbs = ({ list, className, BreadCrumbHasBackIcon }) => {
  const history = useHistory();
  const clickHandler = (element) => {
    element.path ? history.push(element.path) : "";
    element.isClicked? element.onclick() : null
  }
  return (
    <div className="bread-crumbs">
      <BreadcrumbsMUI className={className}>
        {list?.length > 0 ? (
          list?.map((element, i) => {
            return (
              <Link
                key={element.label}
                underline="none"
                color={`${i === list?.length - 1 ? "#4E5D78" : "#6B778C"}`}
                onClick={() => clickHandler(element)}
                className={`${list.length > 1 ? "pointer" : ""} ${
                  i === list?.length - 1
                    ? "active-bread-crumb-link"
                    : "bread-crumb-link"
                }`}>
                <div
                  className={`${
                    BreadCrumbHasBackIcon
                      ? "d-flex justify-content-center align-items-center"
                      : ""
                  }`}>
                  {element.label}
                </div>
              </Link>
            );
          })
        ) : (
          <></>
        )}
      </BreadcrumbsMUI>
    </div>
  );
};
export default Breadcrumbs;
Breadcrumbs.propTypes = {
  list: PropTypes.array.isRequired,
  className: PropTypes.string,
  BreadCrumbHasBackIcon: PropTypes.bool,
};
