import { axiosInstance, axiosUserAppInstance } from "./";

const login = async (payload) => await axiosInstance.post("/sessions", payload);
const userAppLogin = async (payload) =>
  await axiosUserAppInstance.post("/sessions", {
    ...payload,
    is_supervisor: true,
  });

const logout = async (payload) =>
  await axiosInstance.delete("/sessions", { data: payload });

const userAppLogout = async (payload) =>
  await axiosUserAppInstance.delete("/sessions", { data: payload });

const changePassword = async (payload) =>
  await axiosInstance.post("/admins/change_password", payload);

const forgetPassword = async (payload) =>
  await axiosInstance.post("/admins/forgot_password", payload);

export {
  login,
  userAppLogin,
  logout,
  userAppLogout,
  changePassword,
  forgetPassword,
};
