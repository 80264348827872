import React from "react";
import PropTypes from "prop-types";

export const EventsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="20"
    fill="none"
    viewBox="0 0 37 41"
    {...props}>
    <path
      stroke={props.selectedIcon ? "#448BCA" : "#6B778C"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="3"
      d="M11.167 2v5.5M25.833 2v5.5M2.917 14.998h31.166M35 13.917V29.5c0 5.5-2.75 9.167-9.167 9.167H11.167C4.75 38.667 2 35 2 29.5V13.917c0-5.5 2.75-9.167 9.167-9.167h14.666C32.25 4.75 35 8.417 35 13.917z"></path>
    <path
      stroke={props.selectedIcon ? "#448BCA" : "#6B778C"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M25.274 23.45h.017M25.274 28.95h.017M18.492 23.45h.017M18.492 28.95h.017M11.706 23.45h.018M11.706 28.95h.018"></path>
  </svg>
);

EventsIcon.propTypes = {
  selectedIcon: PropTypes.bool,
};
