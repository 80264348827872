import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Clear } from "@mui/icons-material";

import messages from "../../../../assets/locale/messages";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import Accordion from "../../../../components/Accordion";
import PhoneInputField from "../../../../components/PhoneInputField";
import { eventStatuses } from "../../../../utils/Constants";
import "../CreateEvent.scss";

const ZonesFields = ({
  zone,
  removeClickFunction,
  formik,
  index,
  eventRequests,
  zoneSupervisor,
  zonesCount,
  isEventUpcoming,
}) => {
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const event = useSelector((state) => state.events.event);
  const pmsList = useSelector((state) => state.lookups.projectManagers);
  const activePublishMode = useSelector(
    (state) => state.routing.activePublishMode
  );
  const {
    events: { creatEvent },
    shared,
  } = messages[lang];

  const [pmOptions, setPmOptions] = useState([]);
  const [zoneName, setZoneName] = useState(creatEvent.zone);
  const [supervisorOptions, setSupervisorOptions] = useState([]);

  useEffect(() => {
    setPmOptions([...pmsList, { id: 0, name: shared.other }]);
  }, [pmsList]);

  useEffect(() => {
    if (zone?.name !== "") {
      setZoneName(zone?.name);
    } else {
      setZoneName(creatEvent.zone);
    }
  }, [zone?.name, lang]);

  useEffect(() => {
    if (!eventRequests?.length) return;
    const supervisors = eventRequests.map((request) => {
      return {
        id: request?.user?.id,
        name: [
          request?.user.first_name,
          request?.user.middle_name,
          request?.user.last_name,
        ].join(" "),
      };
    });
    setSupervisorOptions(supervisors);
  }, [!!eventRequests?.length]);

  useEffect(() => {
    if (!zoneSupervisor?.id) return;
    let assignedSupervisor = {
      id: zoneSupervisor?.id,
      name: [
        zoneSupervisor?.first_name,
        zoneSupervisor?.middle_name,
        zoneSupervisor?.last_name,
      ].join(" "),
    };

    let supervisorCopy = [...supervisorOptions];
    const isExist = supervisorCopy?.find(
      (item) => item.id === assignedSupervisor?.id
    );
    if (!isExist) supervisorCopy.push(assignedSupervisor);
    setSupervisorOptions(supervisorCopy);
  }, [zoneSupervisor?.id]);

  return (
    <div>
      <Accordion
        accordionNumber={index}
        title={zoneName}
        isExpandedInitially={activePublishMode}
        extraFunctionBtn={
          (index === 0 && zonesCount === 1) ||
          (id &&
            event?.status === eventStatuses.upcoming &&
            zone?.supervisor) ||
          (id &&
            [
              eventStatuses.ongoing,
              eventStatuses.cancelled,
              eventStatuses.past,
            ].includes(event?.status)) ? (
            <></>
          ) : (
            <span
              className="cursor-pointer"
              onClick={() => removeClickFunction(true)}>
              <Clear />
            </span>
          )
        }
        content={
          <>
            <Input
              id={`zones_attributes.${index}.name`}
              name={`zones_attributes.${index}.name`}
              label={creatEvent.labels.zoneName}
              type="text"
              placeholder={creatEvent.placeholders.zoneName}
              value={zone?.name}
              onChange={(value) => {
                formik.setFieldTouched(`zones_attributes.${index}.name`);
                formik.setFieldValue(
                  `zones_attributes.${index}.name`,
                  value || ""
                );

                if (!value && zone.id) {
                  removeClickFunction(false);
                }
              }}
              inputClass="mb-1"
              labelClassName="font-medium mb-2 text-primary field-label zone-label"
              inputWrapperClass="create-event-input"
              isInputHasErr={
                !!(
                  formik.touched?.zones_attributes?.[index]?.name &&
                  formik.errors?.zones_attributes?.[index]?.name
                )
              }
              errMsg={formik.errors?.zones_attributes?.[index]?.name}
              required={activePublishMode || isEventUpcoming}
              disabled={
                id &&
                [
                  eventStatuses.ongoing,
                  eventStatuses.cancelled,
                  eventStatuses.past,
                ].includes(event?.status)
              }
            />
            <h6 className="text-dark-gray my-3">
              {creatEvent.labels.managerInfo}
            </h6>
            <Select
              id={`zones_attributes.${index}.zone_manager_id`}
              name={`zones_attributes.${index}.zone_manager_id`}
              options={pmOptions}
              label={creatEvent.labels.zoneManagerLabel}
              placeholder={creatEvent.placeholders.zoneManager}
              variant
              block
              value={
                pmOptions.find(
                  (option) => option.id == zone?.zone_manager_id
                ) || null
              }
              onChange={(value) => {
                formik.setFieldTouched(
                  `zones_attributes.${index}.zone_manager_id`
                );
                const newZone = {
                  ...zone,
                  zone_manager_id: value ? value.id : null,
                  manager_name: null,
                  manager_email: value.email || null,
                  manager_phone_number: value.phone_number || null,
                };
                const zonesCopy = [...formik.values.zones_attributes];
                zonesCopy.splice(index, 1, newZone);

                formik.setValues({
                  ...formik.values,
                  zones_attributes: [...zonesCopy],
                });
              }}
              inputClass="mb-1"
              labelClassName="font-medium mb-2 text-primary field-label zone-label"
              inputWrapperClass="create-event-input"
              hasError={
                !!(
                  formik.touched?.zones_attributes?.[index]?.zone_manager_id &&
                  formik.errors?.zones_attributes?.[index]?.zone_manager_id
                )
              }
              errMsg={formik.errors?.zones_attributes?.[index]?.zone_manager_id}
              disabled={
                id &&
                [
                  eventStatuses.ongoing,
                  eventStatuses.cancelled,
                  eventStatuses.past,
                ].includes(event?.status)
              }
              required={activePublishMode || isEventUpcoming}
            />
            {zone?.zone_manager_id == "0" && (
              <Input
                id={`zones_attributes.${index}.manager_name`}
                name={`zones_attributes.${index}.manager_name`}
                label={creatEvent.labels.managerName}
                type="text"
                placeholder={creatEvent.placeholders.zoneManager}
                value={zone?.manager_name || ""}
                onChange={(value) => {
                  formik.setFieldTouched(
                    `zones_attributes.${index}.manager_name`
                  );
                  formik.setFieldValue(
                    `zones_attributes.${index}.manager_name`,
                    value || null
                  );
                }}
                inputClass="mb-1"
                labelClassName="font-medium mb-2 text-primary field-label"
                inputWrapperClass="create-event-input"
                isInputHasErr={
                  !!(
                    formik.touched?.zones_attributes?.[index]?.manager_name &&
                    formik.errors?.zones_attributes?.[index]?.manager_name
                  )
                }
                errMsg={formik.errors?.zones_attributes?.[index]?.manager_name}
                disabled={
                  id &&
                  [
                    eventStatuses.ongoing,
                    eventStatuses.cancelled,
                    eventStatuses.past,
                  ].includes(event?.status)
                }
                required={activePublishMode || isEventUpcoming}
              />
            )}
            <div className="row">
              <div className="col-md-6">
                <Input
                  id={`zones_attributes.${index}.manager_email`}
                  name={`zones_attributes.${index}.manager_email`}
                  label={creatEvent.labels.email}
                  type="text"
                  placeholder={creatEvent.placeholders.email}
                  value={zone?.manager_email || ""}
                  onChange={(value) => {
                    formik.setFieldTouched(
                      `zones_attributes.${index}.manager_email`
                    );
                    formik.setFieldValue(
                      `zones_attributes.${index}.manager_email`,
                      value || null
                    );
                  }}
                  inputClass="mb-1"
                  labelClassName="font-medium mb-2 text-primary field-label"
                  inputWrapperClass="create-event-input"
                  isInputHasErr={
                    !!(
                      formik.touched?.zones_attributes?.[index]
                        ?.manager_email &&
                      formik.errors?.zones_attributes?.[index]?.manager_email
                    )
                  }
                  errMsg={
                    formik.errors?.zones_attributes?.[index]?.manager_email
                  }
                  disabled={
                    (id &&
                      [
                        eventStatuses.ongoing,
                        eventStatuses.cancelled,
                        eventStatuses.past,
                      ].includes(event?.status)) ||
                    !(zone?.zone_manager_id == "0")
                  }
                  required={activePublishMode || isEventUpcoming}
                />
              </div>
              <div className="col-md-6">
                <PhoneInputField
                  id={`zones_attributes.${index}.manager_phone_number`}
                  name={`zones_attributes.${index}.manager_phone_number`}
                  label={creatEvent.labels.mobileNumber}
                  value={zone?.manager_phone_number || ""}
                  onChange={(value) => {
                    formik.setFieldTouched(
                      `zones_attributes.${index}.manager_phone_number`
                    );
                    formik.setFieldValue(
                      `zones_attributes.${index}.manager_phone_number`,
                      value || null
                    );
                  }}
                  country="sa"
                  countryCode={zone?.manager_phone_number_country_code || null}
                  disableDropdown
                  countryCodeEditable={false}
                  labelClassName="font-medium mb-2 text-primary field-label"
                  isInputHasErr={
                    !!(
                      formik.touched?.zones_attributes?.[index]
                        ?.manager_phone_number &&
                      formik.errors?.zones_attributes?.[index]
                        ?.manager_phone_number
                    )
                  }
                  errMsg={
                    formik.errors?.zones_attributes?.[index]
                      ?.manager_phone_number
                  }
                  disabled={
                    (id &&
                      [
                        eventStatuses.ongoing,
                        eventStatuses.cancelled,
                        eventStatuses.past,
                      ].includes(event?.status)) ||
                    !(zone?.zone_manager_id == "0")
                  }
                  required={activePublishMode || isEventUpcoming}
                  helperText={
                    zone?.zone_manager_id && !zone?.manager_phone_number
                      ? creatEvent.noPhoneProvided
                      : null
                  }
                  helperTextClass={"err"}
                />
              </div>
            </div>
            {id && event?.status !== eventStatuses.pending && (
              <div className="row">
                <div className="col-12 my-3">
                  <h6 className="text-dark-gray">
                    {creatEvent.labels.supervisorInfo}
                  </h6>
                </div>
                <div className="col-12">
                  <Select
                    id="supervisorId"
                    name="supervisorId"
                    options={supervisorOptions}
                    label={creatEvent.labels.supervisorName}
                    placeholder={creatEvent.placeholders.selectSupervisor}
                    variant
                    block
                    labelClassName="text-gray-title pb-2"
                    value={
                      supervisorOptions.find(
                        (option) =>
                          option.id ==
                          formik.values["zones_attributes"][index][
                            "supervisor_id"
                          ]
                      ) || null
                    }
                    onChange={(value) => {
                      formik.setFieldTouched(
                        `zones_attributes.${index}.supervisor_id`
                      );
                      formik.setFieldValue(
                        `zones_attributes.${index}.supervisor_id`,
                        value?.id || null
                      );
                    }}
                    disabled={[
                      eventStatuses.ongoing,
                      eventStatuses.cancelled,
                      eventStatuses.past,
                    ].includes(event?.status)}
                  />
                </div>
              </div>
            )}
          </>
        }
      />
    </div>
  );
};

export default ZonesFields;

ZonesFields.propTypes = {
  zone: PropTypes.shape({
    name: PropTypes.string,
    zone_manager_id: PropTypes.string,
    manager_name: PropTypes.string,
    manager_email: PropTypes.string,
    manager_phone_number: PropTypes.string,
    supervisor: PropTypes.any,
    manager_phone_number_country_code: PropTypes.string,
    supervisor_id: PropTypes.number,
    id: PropTypes.number,
    user: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      middle_name: PropTypes.string,
      id: PropTypes.number,
    }),
  }),
  removeClickFunction: PropTypes.func,
  formik: PropTypes.any,
  index: PropTypes.number,
  eventRequests: PropTypes.array,
  zoneSupervisor: PropTypes.object,
  zonesCount: PropTypes.number,
  isEventUpcoming: PropTypes.bool,
};
