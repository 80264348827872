import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  excelLink: null,
};

const timesheet = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_DAILY_EVENTS_WORKING_LOGS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta,
      };
    case types.EXPORT_EXCEL_RESPONSE:
      return {
        ...state,
        excelLink: action.payload,
      };
    default:
      return state;
  }
};

export default timesheet;
