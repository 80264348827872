import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { FormHelperText } from "@mui/material";

import Checkbox from "../../../components/Checkbox";
import Modal from "../../../components/Modal";
import messages from "../../../assets/locale/messages";
import { exportExcelRequest } from "../../../store/TimeSheet/actions";
import { eventTypes, workingLogsColumns } from "../../../utils/Constants";

const SelectExportedColumnsModal = ({ eventType, open, setOpen }) => {
  const dispatch = useDispatch();
  const { eventid } = useParams();
  const lang = useSelector((state) => state.locale.lang);
  const { timeSheet, shared } = messages[lang];

  const [showModalErr, setShowModalErr] = useState(false);
  const [selectedCols, setSelectedCols] = useState([]);

  const dailyColumns = [
    {
      label: timeSheet.dailyExportSheet.nameAr,
      value: workingLogsColumns.dailyNameAr,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.nameEn,
      value: workingLogsColumns.dailyNameEn,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.role,
      value: workingLogsColumns.dailyRole,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.nationalIqama,
      value: workingLogsColumns.dailyIdentificationId,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.nationality,
      value: workingLogsColumns.dailyNationality,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.selfCheckoutDates,
      value: workingLogsColumns.selfCheckoutDates,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.automaticCheckoutDates,
      value: workingLogsColumns.automaticCheckoutDates,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.mobile,
      value: workingLogsColumns.dailyPhoneNumber,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.email,
      value: workingLogsColumns.dailyEmail,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.birthDate,
      value: workingLogsColumns.dailyDobHijri,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.stcNumber,
      value: workingLogsColumns.stcNumber,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.workingDays,
      value: workingLogsColumns.dailyNumberOfDays,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.totalWorkingHours,
      value: workingLogsColumns.dailyWorkingHours,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.payrollDay,
      value: workingLogsColumns.dailyExpectedPayroll,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.expectedPayroll,
      value: workingLogsColumns.dailyTotalExpectedPayroll,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.iban,
      value: workingLogsColumns.dailyIban,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.signature,
      value: workingLogsColumns.dailySignature,
      isChecked: false,
    }
  ];
  const monthlyColumns = [
    {
      label: timeSheet.dailyExportSheet.nameAr,
      value: workingLogsColumns.dailyNameAr,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.nameEn,
      value: workingLogsColumns.dailyNameEn,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.role,
      value: workingLogsColumns.dailyRole,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.nationalIqama,
      value: workingLogsColumns.dailyIdentificationId,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.nationality,
      value: workingLogsColumns.dailyNationality,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.selfCheckoutDates,
      value: workingLogsColumns.selfCheckoutDates,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.automaticCheckoutDates,
      value: workingLogsColumns.automaticCheckoutDates,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.mobile,
      value: workingLogsColumns.dailyPhoneNumber,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.email,
      value: workingLogsColumns.dailyEmail,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.birthDate,
      value: workingLogsColumns.dailyDobHijri,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.stcNumber,
      value: workingLogsColumns.stcNumber,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.workingDays,
      value: workingLogsColumns.dailyNumberOfDays,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.totalWorkingHours,
      value: workingLogsColumns.dailyWorkingHours,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.expectedPayroll,
      value: workingLogsColumns.dailyTotalExpectedPayroll,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.iban,
      value: workingLogsColumns.dailyIban,
      isChecked: false,
    },
    {
      label: timeSheet.dailyExportSheet.signature,
      value: workingLogsColumns.dailySignature,
      isChecked: false,
    }
  ];

  useEffect(() => {
    setSelectedCols(
      eventType === eventTypes.daily ? dailyColumns : monthlyColumns
    );
  }, [lang]);

  const handleExportExcel = (columns) => {
    const colsAttributes = {};
    columns
      .filter((col) => col.isChecked)
      .forEach((col, i) => {
        colsAttributes[col.value] = i;
      });
    dispatch(
      exportExcelRequest({
        event_id: eventid,
        page: -1,
        ...colsAttributes,
      })
    );
  };

  const handleClickCol = (i, isChecked) => {
    setShowModalErr(false);
    const colsCopy = [...selectedCols];
    const selectedCol = colsCopy[i];
    colsCopy.splice(i, 1, { ...selectedCol, isChecked });
    setSelectedCols(colsCopy);
  };

  const renderExportedColumns = () => (
    <div className="columns-wrapper">
      {selectedCols.map(({ label, value, isChecked }, i) => (
        <Checkbox
          key={i}
          label={label}
          checked={isChecked}
          onChange={(checked) => handleClickCol(i, checked)}
          name={value}
          id={value}
        />
      ))}
      {showModalErr && (
        <FormHelperText
          error={true}
          className={`font-medium fsize-12 ${lang === "en" ? "" : "text-end"}`}
        >
          {timeSheet.exportErr}
        </FormHelperText>
      )}
    </div>
  );

  const handleCloseModal = () => {
    const colsCopy = selectedCols.map((col) => ({ ...col, isChecked: false }));
    setSelectedCols(colsCopy);
    setOpen(false);
    setShowModalErr(false);
  };

  const handleConfirmModal = () => {
    const isColSelected = !!selectedCols.find((col) => col.isChecked);
    if (isColSelected) {
      handleExportExcel([...selectedCols]);
      handleCloseModal();
    } else {
      setShowModalErr(true);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        handleClose={handleCloseModal}
        title={timeSheet.selectColumnsToExport}
        content={renderExportedColumns()}
        isContentText={false}
        confirmBtnTxt={shared.confirm}
        maxWidth="xs"
        cancelBtnTxt={shared.cancel}
        handleConfirm={handleConfirmModal}
        handleCancel={handleCloseModal}
        modalClass="status-modal"
        actionsClass="container"
        confirmBtnClass="btn-secondary text-white"
      />
    </div>
  );
};

SelectExportedColumnsModal.propTypes = {
  eventType: PropTypes.oneOf([eventTypes.daily, eventTypes.monthly]),
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
export default SelectExportedColumnsModal;
