import React from "react";

export const AlertBarNotificationsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="none"
    viewBox="0 0 50 50"
    {...props}>
    <circle cx="40" cy="13" r="8" fill="#FEE"></circle>
    <path
      fill="#FF5B50"
      d="M40 21.958c-4.942 0-8.958-4.017-8.958-8.959 0-4.941 4.016-8.958 8.958-8.958s8.958 4.017 8.958 8.958c0 4.942-4.016 8.959-8.958 8.959zM40 5.29c-4.25 0-7.708 3.458-7.708 7.708S35.75 20.708 40 20.708s7.708-3.459 7.708-7.709S44.25 5.291 40 5.291z"></path>
    <path
      fill="#FF5B50"
      d="M37.642 15.984a.618.618 0 01-.442-.183.629.629 0 010-.884l4.717-4.716a.629.629 0 01.883 0 .629.629 0 010 .883l-4.717 4.717a.605.605 0 01-.441.183z"></path>
    <path
      fill="#FF5B50"
      d="M42.358 15.984a.618.618 0 01-.441-.183L37.2 11.084a.629.629 0 010-.883.629.629 0 01.883 0l4.717 4.716a.629.629 0 010 .884.618.618 0 01-.442.183z"></path>
    <path
      fill="#FF5B50"
      d="M25.042 42.772c-4.855 0-9.709-.771-14.313-2.313-1.75-.604-3.083-1.833-3.666-3.438-.605-1.604-.396-3.374.562-4.958l2.396-3.979c.5-.833.958-2.5.958-3.48v-6.02c0-7.75 6.313-14.063 14.063-14.063 7.75 0 14.062 6.313 14.062 14.063v6.02c0 .96.459 2.647.959 3.5l2.374 3.96a5.767 5.767 0 01.459 4.958 5.666 5.666 0 01-3.563 3.437c-4.583 1.542-9.437 2.313-14.291 2.313zm0-35.126c-6.021 0-10.938 4.896-10.938 10.938v6.02c0 1.522-.625 3.772-1.396 5.084l-2.396 3.98c-.458.77-.583 1.583-.312 2.27.25.709.875 1.25 1.73 1.542 8.707 2.917 17.937 2.917 26.645 0a2.544 2.544 0 001.604-1.563c.271-.75.209-1.562-.208-2.25l-2.396-3.979c-.792-1.354-1.396-3.583-1.396-5.104v-6c0-6.042-4.896-10.938-10.937-10.938z"></path>
    <path
      fill="#FF5B50"
      d="M28.917 8.209c-.146 0-.292-.02-.438-.063a14.68 14.68 0 00-1.75-.375c-1.77-.229-3.479-.104-5.083.375-.584.188-1.209 0-1.604-.437a1.545 1.545 0 01-.292-1.625 5.675 5.675 0 015.313-3.625c2.375 0 4.458 1.417 5.312 3.625a1.589 1.589 0 01-.292 1.625c-.312.333-.75.5-1.166.5zM25.042 47.522a7.841 7.841 0 01-5.521-2.292 7.841 7.841 0 01-2.292-5.521h3.125c0 1.23.5 2.438 1.375 3.313a4.72 4.72 0 003.313 1.375 4.695 4.695 0 004.687-4.688h3.125c0 4.313-3.5 7.813-7.812 7.813z"></path>
    <circle cx="40" cy="13" r="8" fill="#FEE"></circle>
    <path
      fill="#FF5B50"
      d="M40 21.958c-4.942 0-8.958-4.017-8.958-8.959 0-4.941 4.016-8.958 8.958-8.958s8.958 4.017 8.958 8.958c0 4.942-4.016 8.959-8.958 8.959zM40 5.29c-4.25 0-7.708 3.458-7.708 7.708S35.75 20.708 40 20.708s7.708-3.459 7.708-7.709S44.25 5.291 40 5.291z"></path>
    <path
      fill="#FF5B50"
      d="M37.642 15.984a.618.618 0 01-.442-.183.629.629 0 010-.884l4.717-4.716a.629.629 0 01.883 0 .629.629 0 010 .883l-4.717 4.717a.605.605 0 01-.441.183z"></path>
    <path
      fill="#FF5B50"
      d="M42.358 15.984a.618.618 0 01-.441-.183L37.2 11.084a.629.629 0 010-.883.629.629 0 01.883 0l4.717 4.716a.629.629 0 010 .884.618.618 0 01-.442.183z"></path>
  </svg>
);
