import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  event: {},
  isInitialStep: false,
  warningAlert: {
    show: false,
    message: "",
    count: 0,
  },
};

const events = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_EVENTS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta,
      };
    case types.GET_EVENT_RESPONSE:
      return { ...state, event: action.payload };
    case types.SET_INITIAL_PUBLISH_VALIDATION_STEP:
      return { ...state, isInitialStep: action.payload };
    case types.SHOW_EVENT_CONFLICT_WARNING_ALERT:
      return {
        ...state,
        warningAlert: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default events;
