import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { TableCell, TableRow, Chip } from "@mui/material";
import { Block } from "@mui/icons-material";

import Breadcrumbs from "../../components/BreadCrumbs";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import EmptyState from "../../components/EmptyState";
import Rate from "../../components/Rate";
import Filters from "../../components/Filter";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import {
  formatDate,
  renderTextEllipsis,
  showErrorMsg,
  textContainOnlySpaces,
} from "../../utils/Helpers";
import { lookupsKeys, evaluationFormStatus } from "../../utils/Constants.js";
import evaluationPlaceholder from "../../assets/images/evaluation-screen/evaluation-placeholder.svg";
import messages from "../../assets/locale/messages";
import { getEvaluationsCompletedPendingListRequest } from "../../store/Evaluations/actions";
import {
  getLookupsRequest,
  getEventZonesRequest,
} from "../../store/Lookups/actions";
import { isSuperAdmin } from "../../utils/Permissions";
import "./Evaluations.scss";

const EvaluationFormsList = ({
  headlines,
  breadcrumbs,
  secondBreadcrumb,
  isCompleted,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const roundEventId = sessionStorage.getItem("zonesEventId");

  const rolesList =
    useSelector((state) => state?.lookups?.general?.roles) || [];
  const zones = useSelector((state) => state?.lookups?.eventZones) || [];

  const lang = useSelector((state) => state.locale.lang);
  const { evaluations, shared } = messages[lang];
  const isLoading = useSelector((state) => state.loader);
  const list = useSelector((state) => state.evaluations.forms.list);
  const meta = useSelector((state) => state.evaluations.forms.meta);

  const userType = localStorage.getItem("userType");

  const [filterEvaluationStatus, setFilterEvaluationStatus] = useState(null);
  const [filterRoles, setFilterRoles] = useState(null);
  const [filterZones, setFilterZones] = useState(null);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const statusOptions = [
    {
      id: 1,
      name: evaluations.inProgressStatusOptions.submitted,
      value: evaluationFormStatus.submitted,
    },
    {
      id: 2,
      name: evaluations.inProgressStatusOptions.pending,
      value: evaluationFormStatus.pending,
    },
  ];

  useEffect(() => {
    dispatch(getLookupsRequest([lookupsKeys.roles]));
    if (userType === "admin") dispatch(getEventZonesRequest(roundEventId));
  }, [lang]);

  useEffect(() => {
    if (filterEvaluationStatus) {
      const localizedFilterValue = statusOptions.find(
        (option) => option.id === filterEvaluationStatus.id
      );
      setFilterEvaluationStatus(localizedFilterValue);
    }
  }, [lang]);

  const getEvaluationFormsRequests = (
    pageNo,
    search,
    filterEvaluationStatus,
    filterRoles,
    filterZones
  ) => {
    dispatch(
      getEvaluationsCompletedPendingListRequest({
        evaluation_id: id,
        page: pageNo,
        items: 10,
        applicant_name: search,
        status: filterEvaluationStatus?.value,
        role_id: filterRoles?.id,
        zone_id: filterZones?.id,
      })
    );
  };

  useEffect(() => {
    getEvaluationFormsRequests(
      page,
      searchValue,
      filterEvaluationStatus,
      filterRoles,
      filterZones
    );
  }, [page, lang, id]);

  useEffect(() => {
    if (
      (searchValue.length >= 3 || searchValue.length === 0) &&
      !textContainOnlySpaces(searchValue)
    ) {
      setPage(1);
      getEvaluationFormsRequests(
        1,
        searchValue,
        filterEvaluationStatus,
        filterRoles,
        filterZones
      );
    }
  }, [searchValue]);

  useEffect(() => {
    setPage(1);
    getEvaluationFormsRequests(
      1,
      searchValue,
      filterEvaluationStatus,
      filterRoles,
      filterZones
    );
  }, [filterEvaluationStatus, filterRoles, filterZones]);

  const renderEvaluationsList = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column my-4 pt-5">
            <EmptyState
              description={evaluations.listForms.noData}
              imgSrc={evaluationPlaceholder}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="bg-white rounded-4 mt-2 pb-3">
            <Table
              className="rounded-4"
              headlines={headlines}
              hasActions={false}
              hasSizeLimitation={false}
              rows={list?.map((row, i) => (
                <TableRow
                  key={i}
                  className="row-hover"
                  onClick={() => {
                    if (
                      userType === "admin" &&
                      !isSuperAdmin() &&
                      row.role?.name_en !== "Supervisor" &&
                      row.status === evaluationFormStatus.pending
                    ) {
                      showErrorMsg(evaluations.noAccessMsg);
                    } else {
                      history.push(
                        ROUTE_PATHS[
                          isCompleted
                            ? "evaluationFormCompletedView"
                            : row.status === evaluationFormStatus.submitted
                            ? "evaluationFormInprogressView"
                            : "evaluationFormInprogressCreate"
                        ]
                          .replace(":id", id)
                          .replace(":formid", row.id),
                        { secondBreadcrumb }
                      );
                    }
                  }}>
                  <TableCell>
                    {renderTextEllipsis(
                      row.applicant?.[
                        lang === "en" ? "full_name_en" : "full_name_ar"
                      ]
                    )}
                    {row.applicant.is_blocked && (
                      <Block color="error" className="mx-2" fontSize="small" />
                    )}
                  </TableCell>

                  <TableCell className="mobile-style">
                    {row.applicant?.email
                      ? renderTextEllipsis(row.applicant?.email)
                      : "-"}
                  </TableCell>
                  <TableCell className="mobile-style">
                    {row.role?.name || "-"}
                  </TableCell>
                  {!isCompleted && userType === "admin" && (
                    <TableCell className="mobile-style">
                      {row.created_at ? formatDate(row.created_at) : "-"}
                    </TableCell>
                  )}
                  {userType === "admin" && (
                    <TableCell className="mobile-style">
                      {row.zone?.name || "-"}
                    </TableCell>
                  )}
                  {isCompleted ? (
                    <TableCell className="mobile-style">
                      <Rate
                        rating={
                          row.average_rate ? row.average_rate.toFixed(2) : 0
                        }
                      />
                    </TableCell>
                  ) : (
                    <TableCell className="mobile-style">
                      <Chip
                        label={evaluations.inProgressStatusOptions[row.status]}
                        className={`fsize-14 fweight-400 evaluation-status-container ${row.status}`}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            />
          </div>
          <div className="d-flex justify-content-center py-3 mt-4">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
        </>
      );
    }
  };
  let filterOptions = [
    {
      type: "filter",
      name: "status",
      placeholder: shared.status,
      value: filterEvaluationStatus,
      options: statusOptions,
      onChange: (value) => setFilterEvaluationStatus(value),
    },
    {
      type: "filter",
      name: "roles",
      placeholder: shared.role,
      value: filterRoles,
      options: rolesList?.map((option) => ({
        id: option.id,
        name: option.name,
      })),
      onChange: (value) => setFilterRoles(value),
    },
    {
      type: "filter",
      name: "zones",
      placeholder: evaluations.listForms.zone,
      value: filterZones,
      options: zones,
      onChange: (value) => setFilterZones(value),
    },
  ];
  if (isCompleted) filterOptions.shift();
  if (userType !== "admin") filterOptions.pop();

  return (
    <div className="evaluation-list-container">
      <div className="page-title-margin">
        <Breadcrumbs list={breadcrumbs} BreadCrumbHasBackIcon={false} />
      </div>
      <Filters
        searchInput={{
          placeholder: evaluations.listForms.searchPlaceholder,
          name: "search",
          value: searchValue,
          onChange: (value) => setSearchValue(value),
        }}
        filters={filterOptions}
      />
      <div className="d-flex flex-column justify-content-between">
        {renderEvaluationsList()}
      </div>
    </div>
  );
};

EvaluationFormsList.propTypes = {
  headlines: PropTypes.array,
  breadcrumbs: PropTypes.array,
  isCompleted: PropTypes.bool,
  secondBreadcrumb: PropTypes.object,
};

export default EvaluationFormsList;
