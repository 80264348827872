import { put, call, takeEvery } from "redux-saga/effects";

import * as apis from "../../network/apis/applicatsTimeSheets";
import * as types from "./types";
import * as actions from "./actions";

import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";

function* getApplicantsTimeSheetRequestsListSaga({ payload }) {
  try {
    const response = yield call(
      apis.getApplicantsTimeSheetRequestsList,
      payload
    );

    yield put(
      actions.getApplicantstTimeSheetResponse({
        list: response?.data?.data,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"],
        },
      })
    );
    if (+response.headers["current-page"] > +response.headers["total-pages"]) {
      yield put(
        actions.getApplicantstTimeSheetRequests({
          ...payload,
          page: +response.headers["total-pages"],
        })
      );
    }
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}
function* addApplicantTimeSheetSaga({ payload }) {
  try {
    yield call(apis.addApplicantTimeSheet, payload);
    yield showSuccessMsg("addedSuccessfully");
    const params = {
      page: 1,
      items: 10,
      event_id: payload.data.event_id,
      user_id: payload.data.user_id,
    };
    if (payload.isDaily) {
      // params.check_in_day = payload.dateParam;
    } else {
      params.check_in_month_in_a_date = payload.dateParam;
    }
    yield put(actions.getApplicantstTimeSheetRequests(params));
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* editApplicantTimeSheetSaga({ payload }) {
  try {
    yield call(apis.editApplicantsTimeSheet, payload);
    yield showSuccessMsg("updatedSuccessfully");
    const params = {
      page: 1,
      items: 10,
      event_id: payload.event_id,
      user_id: payload.user_id,
    };
    if (payload.isDaily) {
      // params.check_in_day = payload.dateParam;
    } else {
      params.check_in_month_in_a_date = payload.dateParam;
    }
    yield put(actions.getApplicantstTimeSheetRequests(params));
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* deleteApplicantsTimeSheetSaga({ payload }) {
  try {
    yield call(apis.deleteApplicantTimeSheet, payload);
    yield showSuccessMsg("deletedSuccessfully");
    const params = {
      page: 1,
      items: 10,
      event_id: payload.eventId,
      user_id: payload.userId,
    };
    if (payload.isDaily) {
      // params.check_in_day = payload.dateParam;
    } else {
      params.check_in_month_in_a_date = payload.dateParam;
    }
    yield put(actions.getApplicantstTimeSheetRequests(params));
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

export default function* ApplicantsTimeSheetRequestsListSaga() {
  yield takeEvery(
    types.GET_APPLICANTS_WORKING_SLOTS_REQUEST,
    getApplicantsTimeSheetRequestsListSaga
  );
  yield takeEvery(types.ADD_APPLICANT_TIME_SHEET, addApplicantTimeSheetSaga);
  yield takeEvery(
    types.EDIT_APPLICANT_SHEET_REQUEST,
    editApplicantTimeSheetSaga
  );
  yield takeEvery(
    types.DELETE_APPLICANT_SHEET_REQUEST,
    deleteApplicantsTimeSheetSaga
  );
}
