import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { PASSWORD_REGEX } from "../../../utils/Patterns";
import messages from "../../../assets/locale/messages";
import { changePasswordRequest } from "../../../store/Auth/actions";
import Breadcrumbs from "../../../components/BreadCrumbs";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import "./ResetPassword.scss";

const ResetPasswordInternally = () => {
  const history = useHistory();
  const lang = useSelector((state) => state.locale.lang);
  const { changePassword, employees, shared } = messages[lang];
  const dispatch = useDispatch();
  const breadcrumb = () => (
    <Breadcrumbs
      list={[
        {
          path: ROUTE_PATHS.profile,
          label: employees.profile,
        },
        {
          path: "",
          label: changePassword.resetPassword,
        },
      ]}
      BreadCrumbHasBackIcon={false}
    />
  );
  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("oldPasswordRequired"),
      new_password: Yup.string()
        .required("newPasswordRequired")
        .matches(PASSWORD_REGEX, "passwordNotFollowREGEX"),
    }),
    onSubmit: ({ old_password, new_password }) => {
      dispatch(
        changePasswordRequest({
          from: "reset",
          data: { old_password, new_password },
        })
      );
    },
  });

  return (
    <div className="reset-password-container container pb-3">
      <div className="page-title-margin">{breadcrumb()}</div>
      <form className="w-100" onSubmit={handleSubmit} noValidate>
        <div className="reset-password-page">
          <Input
            id="oldPassword"
            label={changePassword.resetPage.oldPassword}
            name="old_password"
            type="password"
            placeholder={changePassword.placeholders.oldPassword}
            required={true}
            onChange={(value) => {
              setFieldTouched("old_password");
              setFieldValue("old_password", value.trim());
            }}
            value={values["old_password"]}
            inputClass="mb-1"
            labelClassName="font-medium mb-2"
            inputWrapperClass="mb-4"
            isInputHasErr={
              !!(touched["old_password"] && errors["old_password"])
            }
            errMsg={errors["old_password"]}
          />
          <Input
            id="newPassword"
            label={changePassword.resetPage.newPassword}
            name="new_password"
            type="password"
            placeholder={changePassword.placeholders.newPassword}
            required={true}
            onChange={(value) => {
              setFieldTouched("new_password");
              setFieldValue("new_password", value.trim());
            }}
            value={values["new_password"]}
            inputClass="mb-1"
            labelClassName="font-medium mb-2"
            inputWrapperClass="my-4"
            isInputHasErr={
              !!(touched["new_password"] && errors["new_password"])
            }
            errMsg={errors["new_password"]}
          />
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button
            label={changePassword.actionBTN}
            type="submit"
            className="cancel-btn"
            disabled={!dirty || !isValid}
          />
          <Button
            label={shared.cancel}
            className="mx-3 continue-btn"
            onClick={() => history.push(ROUTE_PATHS.profile)}
            outlined
          />
        </div>
      </form>
    </div>
  );
};
export default ResetPasswordInternally;
