import React from "react";
import { useSelector } from "react-redux";
import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  Stack,
  StepConnector,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";

import "./Stepper.scss";

const ColorlibStepIcon = (props) => {
  const { active, completed, className } = props;

  return (
    <div className={`icon-container ${className}`}>
      {(completed || active) && (
        <Check fontSize="large" className="StepIcon-completedIcon" />
      )}
    </div>
  );
};

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const Stepper = ({ steps, activeStep, WrapperClasses }) => {
  const lang = useSelector((state) => state.locale.lang);
  return (
    <Stack
      sx={{ width: "100%", maxWidth: "500px" }}
      spacing={4}
      className={`stepper-inner-container ${WrapperClasses}`}
    >
      <MuiStepper
        alternativeLabel
        activeStep={activeStep}
        connector={
          <StepConnector
            className={`custom-connector ${lang === "ar" && "connector-ar"}`}
          />
        }
      >
        {steps.map((step) => (
          <Step key={step.id}>
            <StepLabel
              onClick={step.clickAction}
              StepIconComponent={ColorlibStepIcon}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </Stack>
  );
};

export default Stepper;

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  activeStep: PropTypes.number,
  WrapperClasses: PropTypes.string,
};
