import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import "./MultiSelect.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MultipleSelectCheckmarks({
  options,
  selectedValues,
  onChange,
  displayProperty = "name",
}) {
  // Handle the case where selectedValues might be objects or IDs
  const selectedIds =
    selectedValues?.map((item) =>
      typeof item === "object" ? item.id : item
    ) || [];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newSelectedValues = options.filter((option) =>
      value.includes(option.id)
    );
    onChange(newSelectedValues);
  };

  const renderValue = (selected) =>
    selected
      ?.map(
        (id) => options?.find((option) => option.id === id)?.[displayProperty]
      )
      .join(", ");

  return (
    <FormControl sx={{ marginBottom: 1.5, width: "100%" }}>
      <Select
        className="multi-select-drop"
        labelId="multiple-select-checkbox-label"
        id="multiple-select-checkbox"
        multiple
        value={selectedIds}
        onChange={handleChange}
        renderValue={renderValue}
        MenuProps={MenuProps}
      >
        {options?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox checked={selectedIds?.includes(option.id)} />
            <ListItemText primary={option?.[displayProperty]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MultipleSelectCheckmarks.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      language: PropTypes.string,
    })
  ).isRequired,
  selectedValues: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ])
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  displayProperty: PropTypes.oneOf(["name", "language"]).isRequired,
};

export default MultipleSelectCheckmarks;
