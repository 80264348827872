import React from "react";

export const LogOutIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 35 36"
    {...props}>
    <path
      fill="#6B778C"
      d="M23.03 35.648h-.221c-7.581 0-11.235-2.988-11.867-9.681a1.283 1.283 0 011.161-1.4c.683-.069 1.332.46 1.4 1.16.495 5.362 3.023 7.36 9.323 7.36h.222c6.95 0 9.408-2.459 9.408-9.408V12.546c0-6.949-2.459-9.408-9.408-9.408h-.222c-6.335 0-8.862 2.032-9.323 7.496-.085.7-.683 1.23-1.4 1.161a1.283 1.283 0 01-1.178-1.383C11.505 3.617 15.177.577 22.81.577h.222C31.414.577 35 4.163 35 12.547v11.132c0 8.384-3.586 11.97-11.97 11.97z"></path>
    <path
      fill="#6B778C"
      d="M22.619 19.393H3.189c-.701 0-1.282-.58-1.282-1.28 0-.7.581-1.28 1.281-1.28h19.43c.7 0 1.281.58 1.281 1.28 0 .7-.58 1.28-1.28 1.28z"></path>
    <path
      fill="#6B778C"
      d="M6.996 25.113c-.324 0-.649-.12-.905-.375l-5.72-5.72a1.288 1.288 0 010-1.81l5.72-5.72a1.288 1.288 0 011.81 0 1.288 1.288 0 010 1.81l-4.815 4.815 4.815 4.815a1.288 1.288 0 010 1.81c-.239.256-.58.375-.905.375z"></path>
  </svg>
);
