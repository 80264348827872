import { put, call, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/employee";
import * as types from "./types";
import * as actions from "./actions";
import history from "../../routes/History";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";

function* getEmployeesListSaga({ payload }) {
  try {
    const response = yield call(apis.getEmployeesList, payload);
    yield put(
      actions.getEmployeesResponse({
        list: response.data.data,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"],
        },
      })
    );
    if (+response.headers["current-page"] > +response.headers["total-pages"]) {
      yield put(
        actions.getEmployeesRequest({
          ...payload,
          page: +response.headers["total-pages"],
        })
      );
    }
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* getEmployeeSaga({ payload }) {
  try {
    const response = yield call(apis.getEmployee, payload);
    yield put(actions.getEmployeeResponse(response.data.data));
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* addPmSaga({ payload }) {
  try {
    yield call(apis.addPm, payload);
    yield showSuccessMsg("addedSuccessfully");
    yield history.push(ROUTE_PATHS.employees);
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* editEmployeeSaga({ payload }) {
  try {
    yield call(apis.editEmployee, payload);
    yield history.push(ROUTE_PATHS.profile);
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

export default function* EmployeesSagas() {
  yield takeEvery(types.ADD_PM, addPmSaga);
  yield takeEvery(types.GET_EMPLOYEES_REQUEST, getEmployeesListSaga);
  yield takeEvery(types.GET_EMPLOYEE_REQUEST, getEmployeeSaga);
  yield takeEvery(types.EDIT_EMPLOYEE_REQUEST, editEmployeeSaga);
}
