export const EGYPT_MOBILEPHONE_REGEX = /^0?1[0-2,5]{1}[0-9]{8}$/;
export const IBAN_REGEX = /^[a-zA-Z0-9]{29}$/;
export const ARABIC_REGEX = /^[\u0600-\u06FF0-9\s_]+$/;
export const ENGLISH_REGEX = /^[a-zA-Z0-9\s_]+$/;
export const PRICE_REGEX = /^\d+(\.\d{0,2})?$/;
export const ONLY_LITTERS_AND_NUMBERS = /^[A-Za-z0-9_-]*$/;
export const NO_SPACE = /^\S*$/;
export const ONLY_POSITIVE_NUMBERS = /^[1-9][0-9]*$/;
export const ONLY_POSITIVE_NUMBERS_WITH_ZERO = /^[0-9][0-9]*$/;
export const ONLY_POSITIVE_NUMBERS_WITH_MAX_TWO_DECIMAL_PLACES =
  /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/;
export const ONLY_POSITIVE_NUMBERS_WITH_MAX_TWO_DECIMAL_PLACES_WITH_ZERO =
  /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/;
export const FROM_ONE_TO_HUNDRED_ONLY = /^[1-9][0-9]?$|^100$/;
export const FROM_ONE_TO_NINETY_NINE_ONLY = /^[1-9][0-9]?$|^99$/;
export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,})/;
export const MAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const ONLY_URL =
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
export const NAME_REGEX = /^[A-Za-z\u0621-\u064A\s_0-9 ]+$/;
export const ALPHABETIC_REGEX = /^[A-Za-z\u0621-\u064A\s]+$/;
