import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiberManualRecord } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import messages from "../../assets/locale/messages";
import { NotificationsBgIcon } from "../../utils/Icons";
import { formatDate } from "../../utils/Helpers";
import {
  handleNotificationsClickActions,
  setNotificationSeen,
} from "../../firebase/helpers";
import EmptyState from "../../components/EmptyState";
import {
  getNotificationsRequest,
  getNotificationsResponse,
} from "../../store/Notifications/actions";
import Button from "../../components/Button";
import Breadcrumbs from "../../components/BreadCrumbs";
import "./Notifications.scss";

const Notifications = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const notificationsList = useSelector((state) => state.notifications.list);
  const notificationsMeta = useSelector((state) => state.notifications.meta);
  const { notifications } = messages[lang];

  const [page, setPage] = useState(notificationsMeta?.currentPage || 1);

  const NotifcationTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="top-end" {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "transparent",
      color: "#448bca",
      textAlign: "center",
    },
  }));

  useEffect(() => {
    dispatch(
      getNotificationsRequest({
        items: 10,
        page,
      })
    );
  }, [page]);

  useEffect(() => {
    dispatch(
      getNotificationsRequest({
        items: 10,
        page: 1,
      })
    );
  }, [lang]);

  useEffect(() => {
    return () => {
      dispatch(
        getNotificationsResponse({
          list: [],
          meta: {},
          clear: true,
        })
      );
    };
  }, []);

  const handleNotificationSeen = (e, id) => {
    e.stopPropagation();
    setNotificationSeen(id);
  };

  return (
    <div className="notifications-page">
      <div className="page-title-margin">
        <Breadcrumbs
          list={[{ path: "", label: notifications.title }]}
          BreadCrumbHasBackIcon={false}
        />
      </div>
      <div className="content-wrapper">
        {!notificationsList?.length ? (
          <EmptyState
            subTitle={notifications.noNotifications}
            showActionButton={false}
          />
        ) : (
          <>
            <div className="notifications-list">
              {notificationsList.map(
                (
                  {
                    id,
                    title,
                    body,
                    created_at,
                    is_seen,
                    click_action,
                    action_id,
                  },
                  index
                ) => (
                  <div
                    className={`notification-item pointer pe-4 ${
                      index > 0 && "pt-4"
                    } pb-2 border-bottom border-gray-hr-light d-flex ${
                      is_seen ? "seen-notification" : "unseen-notification"
                    }`}
                    key={index}
                    onClick={() => {
                      handleNotificationsClickActions({
                        id,
                        click_action,
                        action_id,
                        is_seen,
                      });
                    }}
                  >
                    <span>
                      <NotificationsBgIcon />
                    </span>
                    <div className={`${lang === "en" ? "ms-3" : "me-3"}`}>
                      <p className={`notification-content mb-3 `}>
                        <span
                          className={`d-block fsize-15 mb-2 ${
                            is_seen ? "font-medium" : "font-bold"
                          }`}
                        >
                          {title}
                        </span>
                        <span className="d-block">{body}</span>
                        <span className="adorment-notifaction">
                          {!is_seen ? (
                            <span className="adorment-notifaction">
                              <NotifcationTooltip
                                title={notifications.markAsRead}
                              >
                                <FiberManualRecord
                                  className="seen-adorment"
                                  onClick={(e) => {
                                    handleNotificationSeen(e, id);
                                  }}
                                />
                              </NotifcationTooltip>
                            </span>
                          ) : null}
                        </span>
                      </p>
                      <p className="notification-date fsize-12">
                        {formatDate(created_at, "DD MMM YYYY")}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
            {notificationsMeta?.count &&
              notificationsMeta?.pages > 1 &&
              page < notificationsMeta?.pages && (
                <div className="show-more-btn-wrapper text-center">
                  <Button
                    outlined
                    label={notifications.seeMore}
                    onClick={() => setPage(page + 1)}
                    className="show-more-btn"
                  />
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default Notifications;
