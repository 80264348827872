const notifications = {
  title: "Notifications",
  markAsRead: "Mark as Read",
  seeAllNotifications: "See all notifications",
  seeMore: "See More",
  noNotifications: "There is no notifications to show",
  notificationsSupportAlert:
    "Kindly note that the notifications are not supported in your current browser version, Please update to the latest one.",
};

export default notifications;
