const forgetPassword = {
  title: "إعادة وضع كلمة المرور",
  title2: "ادخل البريد الالكترونى",
  labels: {
    email: "البريد الإلكتروني",
  },
  placeholders: {
    email: "ادخل البريد الالكتروني",
    password: "ادخل كلمة السر",
  },
  actionBTN: "إستعادة كلمة السر",
  enterNewPassword: "ادخل كلمة سر جديدة",
};

export default forgetPassword;
