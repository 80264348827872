import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import messages from "../../../../../assets/locale/messages";
import Accordion from "../../../../../components/Accordion";
import ViewInputData from "../../../../../components/ViewInputData";

const ViewRequirementsFields = ({ requirement }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { events } = messages[lang];
  return (
    <div>
      <Accordion
        title={requirement.requirement.name}
        content={
          <>
            <ViewInputData
              label={events.creatEvent.labels.nameOfRequirement}
              content={requirement.requirement.name}
            />
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <ViewInputData
                  label={events.creatEvent.labels.quantity}
                  content={requirement.quantity}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <ViewInputData
                  label={events.creatEvent.labels.explanation}
                  content={requirement.explanation}
                />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default ViewRequirementsFields;

ViewRequirementsFields.propTypes = {
  requirement: PropTypes.any,
};
