import React from "react";
import PropTypes from "prop-types";

export const ApplicantsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="20"
    fill="none"
    viewBox="0 0 35 38"
    {...props}>
    <path
      fill={props.selectedIcon ? "#448BCA" : "#6B778C"}
      d="M16.272 20.035c-5.523 0-10.018-4.495-10.018-10.018C6.254 4.495 10.75 0 16.272 0c5.522 0 10.017 4.495 10.017 10.018 0 5.522-4.495 10.017-10.017 10.017zm0-17.422c-4.077 0-7.405 3.328-7.405 7.405 0 4.076 3.328 7.404 7.405 7.404 4.076 0 7.404-3.328 7.404-7.405 0-4.076-3.328-7.404-7.404-7.404zM1.307 37.457A1.316 1.316 0 010 36.15c0-7.439 7.3-13.502 16.272-13.502 1.76 0 3.484.227 5.157.697.697.192 1.097.906.906 1.603a1.297 1.297 0 01-1.603.906 16.493 16.493 0 00-4.46-.592c-7.526 0-13.659 4.878-13.659 10.888 0 .714-.592 1.307-1.306 1.307z"></path>
    <path
      fill={props.selectedIcon ? "#448BCA" : "#6B778C"}
      d="M26.724 37.457c-2.892 0-5.61-1.533-7.073-4.025a8.034 8.034 0 01-1.202-4.25 8.21 8.21 0 013.101-6.464 8.35 8.35 0 015.174-1.812c4.565 0 8.276 3.71 8.276 8.275a8.14 8.14 0 01-1.202 4.269 7.7 7.7 0 01-1.655 1.951c-1.446 1.324-3.38 2.056-5.419 2.056zm0-13.938c-1.289 0-2.508.436-3.536 1.255a5.617 5.617 0 00-2.125 4.407 5.52 5.52 0 00.818 2.91 5.65 5.65 0 004.843 2.752c1.377 0 2.7-.505 3.711-1.41a5.018 5.018 0 001.115-1.325 5.485 5.485 0 00.837-2.927 5.673 5.673 0 00-5.663-5.662z"></path>
    <path
      fill={props.selectedIcon ? "#448BCA" : "#6B778C"}
      d="M25.73 32.213c-.331 0-.662-.122-.924-.383l-1.724-1.725a1.315 1.315 0 010-1.847 1.314 1.314 0 011.846 0l.837.837 2.787-2.58a1.315 1.315 0 011.847.07 1.315 1.315 0 01-.07 1.848l-3.71 3.431a1.363 1.363 0 01-.89.349z"></path>
  </svg>
);

ApplicantsIcon.propTypes = {
  selectedIcon: PropTypes.bool,
};
