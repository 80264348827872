const admins = {
  admins: "Admins",
  list: {
    username: "Name",
    mail: "Mail",
    role: "Role",
    superAdmin: "Super admin",
    admin: "Admin",
    search: "Search for an admin",
    noDataSubTitle: "There are no admins to display yet",
    noDataDescription: "Start now by adding a new admin",
    CreateAdminButton: "Add a new admin",
  },
  addAdmin: {
    title: "Add new admin",
    labels: {
      email: "Email",
      username: "Name",
      role: "Role",
    },
    placeholders: {
      email: "Enter email",
      username: "Enter username",
      role: "Choose role",
    },
  },
  editAdmin: {
    title: "Edit admin",
    labels: {
      edit: "Save",
    },
  },
  deleteAdmin: {
    title: "Delete Admin",
    deleteConfirmationMsg: "Are you sure you want to delete this admin?",
  },
};

export default admins;
