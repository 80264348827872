import * as types from "./types";
export const getNoteRequest = (payload) => ({
  type: types.GET_NOTE_REQUEST,
  payload,
});
export const getNoteResponse = (payload) => ({
  type: types.GET_NOTE_RESPONSE,
  payload,
});

export const updateNoteRequest = (payload) => ({
  type: types.UPDATE_NOTE_REQUEST,
  payload,
});
export const updateNoteResponse = (payload) => ({
  type: types.UPDATE_NOTE_RESPONSE,
  payload,
});

export const deleteNoteRequest = (payload) => ({
  type: types.DELETE_NOTE_REQUEST,
  payload,
});
export const deleteNoteResponse = (payload) => ({
  type: types.DELETE_NOTE_RESPONSE,
  payload,
});
export const createNoteRequest = (payload) => ({
  type: types.CREATE_NOTE_REQUEST,
  payload,
});
export const createNoteResponse = (payload) => ({
  type: types.CREATE_NOTE_RESPONSE,
  payload,
});
