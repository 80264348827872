const pagesTitles = {
  adminsManagement: "Admins management",
  admins: "List admins",
  adminsAdd: "Add new admin",
  adminsEdit: "Edit admin",
  categoriesManagement: "Categories management",
  serviceProviderCategories: "Categories List",
  serviceProviderCategoriesAdd: "Add new category",
  serviceProviderCategoriesEdit: "Edit category",
  serviceProviderCategoriesView: "View category",
  specialtiesManagement: "Specialties management",
  specialties: "Specialties",
  specialtiesAdd: "Add new specialty",
  specialtiesView: "View specialty",
  specialtiesEdit: "Edit specialty",
  servicesManagement: "Services management",
  services: "Services",
  servicesAdd: "Add new service",
  servicesEdit: "Edit service",
  serviceProvidersManagement: "Service Providers management",
  serviceProviders: "Service providers",
  serviceProvidersAdd: "Add new service provider",
  serviceProvidersEdit: "Edit service provider",
  serviceProvidersView: "View service provider",
};

export default pagesTitles;
