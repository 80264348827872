import * as types from "./types";

const initialState = {
  page: 1,
  searchValue: "",
  rowsCount: 10,
  profile_status: null,
  is_assigned_to_event: null,
  gender: null,
  height: null,
  age: null,
  weight: null,
  role_id: null,
  language_id: null,
  chronic_disease_id: null,
  disability_id: null,
  city_id: null,
  speaking_proficiency: null,
  order: {
    order_by_first_name_en: null,
    order_by_first_name_ar: null,
  },
};

const _filters = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case types.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case types.SET_ROWS_COUNT:
      return {
        ...state,
        rowsCount: action.payload,
      };
    case types.ORDER_BY:
      return {
        ...state,
        order: action.payload,
      };
    default:
      return state;
  }
};

export default _filters;
