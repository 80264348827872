import React from "react";

export const SeenNotificationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    {...props}>
    <path
      fill="#6F767E"
      fillRule="evenodd"
      d="M0 14.596c0-.38.155-.745.429-1.01l1.029-.994a2 2 0 00.61-1.444l-.01-3.153A7.97 7.97 0 1118 7.97v3.2a2 2 0 00.586 1.415l1 1a1.414 1.414 0 01-1 2.414H14a4 4 0 01-8 0H1.404A1.404 1.404 0 010 14.596zM8 16a2 2 0 104 0H8zm8-4.828A4 4 0 0017.172 14H2.879a4 4 0 001.19-2.858l-.01-3.153A5.97 5.97 0 1116 7.97v3.2z"
      clipRule="evenodd"></path>
  </svg>
);
