const successMsgs = {
  changePassword: "تم تغيير كلمة المرور بنجاح",
  deletedSuccessfully: "تم الحذف",
  addedSuccessfully: "تم الاضافة بنجاح",
  eventDeletedSuccessfully: "تم حذف الفعالية بنجاح",
  eventCanceledSuccessfully: "تم إلغاء الفعالية بنجاح",
  approveRequest: "تم قبول الطلب بنجاح",
  updatedSuccessfully: "تم التحديث بنجاح",
  acceptRequest: "تم قبول الطلب بنجاح",
  rejectRequest: "تم رفض الطلب",
  forgetPassword: "تم ارسال كلمة مرور جديدة لبريدك الإلكتروني",
  blockedSuccessfully: "تم حظر المستخدم بنجاح",
  unBlockedSuccessfully: "تم الغاء حظر المستخدم بنجاح",
  applicantsInvitedSuccessfully: "تم دعوة المتقدمين بنجاح",
};

export default successMsgs;
