import React from "react";
import PropTypes from "prop-types";

export const EvaluationsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="20"
    fill="none"
    viewBox="0 0 35 34"
    {...props}>
    <path
      fill={props.selectedIcon ? "#448BCA" : "#6B778C"}
      d="M34.853 12.608a2.956 2.956 0 00-2.346-2.012l-8.452-1.373-3.918-7.614a2.967 2.967 0 00-5.277 0l-3.917 7.614-8.452 1.373a2.968 2.968 0 00-1.63 5.019l6.03 6.079-1.306 8.462a2.966 2.966 0 004.27 3.102l7.644-3.857 7.645 3.857a2.966 2.966 0 004.269-3.102l-1.305-8.462 6.03-6.079a2.957 2.957 0 00.715-3.007zm-2.372 1.364l-6.86 6.914 1.486 9.626a.632.632 0 01-.912.663l-8.696-4.387-8.696 4.387a.631.631 0 01-.912-.663l1.485-9.626-6.86-6.914a.634.634 0 01.35-1.073l9.613-1.562 4.456-8.66a.634.634 0 011.128 0l4.456 8.66 9.614 1.562a.634.634 0 01.348 1.073z"></path>
  </svg>
);

EvaluationsIcon.propTypes = {
  selectedIcon: PropTypes.bool,
};
