import { put, call, takeEvery } from "redux-saga/effects";

import * as apis from "../../network/apis/events";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";
import {
  setCreateEventFormStep,
  setCreatedEventId,
  setActivePublishMode,
} from "../Routing/actions";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import history from "../../routes/History";
import { eventStatuses } from "../../utils/Constants";

function* getEventsListSaga({ payload }) {
  try {
    const response = yield call(apis.getEventsList, payload);
    yield put(
      actions.getEventsResponse({
        list: response.data.data,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"],
        },
      })
    );
    if (+response.headers["current-page"] > +response.headers["total-pages"]) {
      yield put(
        actions.getEventsRequest({
          ...payload,
          page: +response.headers["total-pages"],
        })
      );
    }
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* getEventSaga({ payload }) {
  try {
    const response = yield call(apis.getEvent, payload);
    yield put(actions.getEventResponse(response.data.data));
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* addEventSaga({ payload }) {
  try {
    const response = yield call(apis.addEvent, payload);
    yield put(setCreatedEventId(response?.data?.data?.id));
    if (payload.nextStep === "events") {
      history.push({
        pathname: ROUTE_PATHS["events"],
        state: "pending",
      });
    } else {
      yield put(setCreateEventFormStep(payload.nextStep));
    }
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* editEventSaga({ payload }) {
  try {
    let response;
    if (payload.validate) {
      const {
        id,
        data: {
          building_start_at,
          dismantling_end_at,
          event_start_at,
          event_end_at,
        },
      } = payload;
      response = yield call(apis.checkEventConflicts, {
        id,
        params: {
          building_start_at,
          dismantling_end_at,
          event_start_at,
          event_end_at,
        },
      });
      if (response?.data?.number_of_conflicting_resources > 0) {
        yield put(
          actions.showEventConflictWarningAlert({
            show: true,
            message: response.data.message,
            count: response.data.number_of_conflicting_resources,
          })
        );
      }
    }
    if (
      !payload.validate ||
      response?.data?.number_of_conflicting_resources === 0
    ) {
      yield call(apis.editEvent, payload);
      yield put(
        actions.showEventConflictWarningAlert({
          show: false,
          message: "",
          count: 0,
        })
      );
      if (payload.nextStep === "events") {
        history.push({
          pathname: ROUTE_PATHS["events"],
          state: eventStatuses.pending,
        });
      } else if (payload.nextStep === "view-event") {
        history.push(ROUTE_PATHS["viewEvent"].replace(":id", payload.id));
      } else {
        yield put(setCreateEventFormStep(payload.nextStep));
      }
    }
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* publishEventSaga({ payload }) {
  try {
    const response = yield call(apis.editEvent, payload);
    yield call(apis.publishEvent, response?.data?.data?.id);
    history.push({
      pathname: ROUTE_PATHS["events"],
      state: eventStatuses.upcoming,
    });
  } catch (err) {
    if (err.response.config.method === "post") {
      yield put(setCreateEventFormStep(0));
      yield put(setActivePublishMode(true));
      yield put(actions.setInitialPublishValidationStep(true));
    }
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* deleteEventSaga({ payload }) {
  try {
    yield call(apis.deleteEvent, payload);
    yield showSuccessMsg("eventDeletedSuccessfully");
    history.push(ROUTE_PATHS["events"]);
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* cancelEventSaga({ payload }) {
  try {
    yield call(apis.cancelEvent, payload);
    yield showSuccessMsg("eventCanceledSuccessfully");
    history.push({
      pathname: ROUTE_PATHS["events"],
      state: eventStatuses.cancelled,
    });
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* inviteApplicantsSaga({ payload }) {
  try {
    const response = yield call(apis.inviteApplicants, payload);
    yield showSuccessMsg(response.data.message, true);
    history.push(ROUTE_PATHS.viewEvent.replace(":id", payload.event_id));
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* sendNotificationRequestSage({ payload }) {
  try {
    const response = yield call(apis.sendPushNotification, payload);
    yield showSuccessMsg(response.data.message, true);
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

export default function* EventsSagas() {
  yield takeEvery(types.GET_EVENTS_REQUEST, getEventsListSaga);
  yield takeEvery(types.GET_EVENT_REQUEST, getEventSaga);
  yield takeEvery(types.ADD_EVENT_REQUEST, addEventSaga);
  yield takeEvery(types.EDIT_EVENT_REQUEST, editEventSaga);
  yield takeEvery(types.PUBLISH_EVENT_REQUEST, publishEventSaga);
  yield takeEvery(types.DELETE_EVENT_REQUEST, deleteEventSaga);
  yield takeEvery(types.CANCEL_EVENT_REQUEST, cancelEventSaga);
  yield takeEvery(types.INVITE_APPLICANTS_REQUEST, inviteApplicantsSaga);
  yield takeEvery(types.SEND_NOTIFICATION_REQUEST, sendNotificationRequestSage);
}
