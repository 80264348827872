export const ADD_EVALUATION_EVENT_REQUEST = "ADD_EVALUATION_EVENT_REQUEST";

export const GET_EVALUATION_LIST_ROUNDS_REQUEST =
  "GET_EVALUATION_LIST_ROUNDS_REQUEST";
export const GET_EVALUATION_LIST_ROUNDS_RESPONSE =
  "GET_EVALUATION_LIST_ROUNDS_RESPONSE";

export const VIEW_FORM_REQUEST = "VIEW_FORM_REQUEST";
export const VIEW_FORM_RESPONSE = "VIEW_FORM_RESPONSE";

export const SUBMIT_EVALUATION_EVENT_REQUEST =
  "SUBMIT_EVALUATION_EVENT_REQUEST";

export const GET_EVALUATION_COMPLETED_PENDING_LIST_REQUEST =
  "GET_EVALUATION_COMPLETED_PENDING_LIST_REQUEST";
export const GET_EVALUATION_COMPLETED_PENDING_LIST_RESPONSE =
  "GET_EVALUATION_COMPLETED_PENDING_LIST_RESPONSE";
