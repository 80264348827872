import store from "../../store";
import { loginResponse } from "../../store/Auth/actions";
import { showHideLoader } from "../../store/Loader/actions";
import { clearAdminData } from "../../utils/Auth";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import history from "../../routes/History";
import messages from "../../assets/locale/messages";
import { showErrorMsg } from "../../utils/Helpers";
import {
  clearFirebaseToken,
  generateFirebaseToken,
} from "../../firebase/helpers";

export const isHandlerEnabled = (config = {}) => {
  return Object.prototype.hasOwnProperty.call(config, "handlerEnabled") &&
    !config.handlerEnabled
    ? false
    : true;
};

// This is used to handle remove loader only if all pending requests are resolved
let numberOfAjaxCAllPending = 0;

export const requestHandler = (request) => {
  numberOfAjaxCAllPending++;
  if (isHandlerEnabled(request)) {
    store.dispatch(showHideLoader(true));

    // In case of loader not needed in searching data tables
    // !request.params?.search && store.dispatch(showHideLoader(true));

    const locale = localStorage.getItem("lang");
    request.headers["locale"] = locale;
    const requestParams = { ...request.params };
    if (requestParams) {
      request.params = {
        locale,
        ...requestParams,
      };
    } else {
      request.params = { locale };
    }

    const adminData =
      JSON.parse(localStorage.getItem("admin")) ||
      JSON.parse(sessionStorage.getItem("admin"));
    const token = adminData?.auth_token;
    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return request;
};

export const successHandler = (response) => {
  numberOfAjaxCAllPending--;
  if (isHandlerEnabled(response)) {
    if (numberOfAjaxCAllPending === 0) {
      store.dispatch(showHideLoader(false));
    }
  }
  return response;
};

export const errorHandler = (error) => {
  numberOfAjaxCAllPending--;
  if (isHandlerEnabled(error.config)) {
    if (numberOfAjaxCAllPending === 0) {
      store.dispatch(showHideLoader(false));
    }
  }
  const lang = store.getState().locale.lang;
  if (error?.response?.status === 401) {
    store.dispatch(loginResponse({}));
    clearAdminData();
    clearFirebaseToken();
    generateFirebaseToken();
    history.push(ROUTE_PATHS.login);
  } else if (error?.message === "Network Error") {
    showErrorMsg(messages[lang].shared.noInternetConnection);
  } else {
    showErrorMsg(messages[lang].shared.generalErrMsg);
  }
  return Promise.reject({ ...error });
};
