import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { amber } from "@mui/material/colors";
import PropTypes from "prop-types";

const Rate = ({ rating }) => {
  return (
    <>
      <StarIcon sx={{ color: amber[500] }} />
      <span className="ps-1">{rating}</span>
    </>
  );
};

export default Rate;

Rate.propTypes = {
  rating: PropTypes.number.isRequired,
};
