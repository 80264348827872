import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { DesktopDatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { TextField, FormHelperText, IconButton } from "@mui/material";
import { KeyboardArrowDown, Clear } from "@mui/icons-material";
// For localization
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ar-sa";

import messages from "../../../assets/locale/messages";
import "./FilterDatePicker.scss";

const FilterDatePicker = ({
  value,
  onChange,
  name,
  id,
  label,
  placeholder,
  calendarViews,
  openView,
  wrapperClass,
  inputClass,
  helperTextClass,
  closeOnSelect,
  disabled,
  disableFuture,
  disablePast,
  disableMinToday,
  disableMaxToday,
  maxDate,
  minDate,
  inputDateFormat,
  shouldDisableDate,
  shouldDisableMonth,
  shouldDisableYear,
  shouldNotBeLessThanToday,
  readOnly,
  isInputHasErr,
  errMsg,
  helperText,
  preventManualDateEntry,
  clear,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations } = messages[lang];

  const [date, setDate] = useState(null);
  const [invalidDate, setInvalidDate] = useState(false);
  const [lessThanToday, setLessThanToday] = useState(false);
  const today = new Date();

  const isDateLessThanToday = (enteredDate) => {
    let nowDate = new Date();
    let nowYear = +nowDate.getFullYear();
    let nowMonth = +nowDate.getMonth() + 1;
    let nowDay = +nowDate.getDate();
    let enteredDateYear = +enteredDate?.split("-")[0];
    let enteredDateMonth = +enteredDate?.split("-")[1];
    let enteredDateDay = +enteredDate?.split("-")[2]?.split("T")[0];

    if (
      enteredDateYear < nowYear ||
      (enteredDateYear === nowYear && enteredDateMonth < nowMonth) ||
      (enteredDateYear === nowYear &&
        enteredDateMonth === nowMonth &&
        enteredDateDay < nowDay)
    ) {
      return true;
    } else return false;
  };

  // To handle any required formatting on date
  useEffect(() => {
    setDate(dayjs(value));
  }, [value]);

  const handleDateChange = (selectedDate) => {
    if (selectedDate && dayjs(selectedDate).format() === "Invalid Date") {
      setInvalidDate(true);
      setDate(selectedDate);
    } else if (
      shouldNotBeLessThanToday &&
      isDateLessThanToday(dayjs(selectedDate).format())
    ) {
      setInvalidDate(false);
      setLessThanToday(true);
      setDate(selectedDate);
    } else {
      setInvalidDate(false);
      setLessThanToday(false);
      onChange(selectedDate ? dayjs(selectedDate).format() : "");
    }
  };

  useEffect(() => {
    if (clear) {
      handleResetValue();
    }
  }, [clear]);

  const handleResetValue = () => {
    handleDateChange(null);
    setDate(null);
    setInvalidDate(false);
  };

  return (
    <div className={`filter-date-picker-wrapper ${wrapperClass}`}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={lang === "en" ? "en" : "ar-sa"}
      >
        <MuiDatePicker
          value={date}
          onChange={handleDateChange}
          label={label}
          name={name}
          id={id}
          className={`custom-date-picker ${inputClass} ${
            invalidDate || lessThanToday || isInputHasErr ? "error-border" : ""
          }`}
          views={calendarViews}
          openTo={openView}
          closeOnSelect={closeOnSelect}
          disabled={disabled}
          disableFuture={disableFuture}
          disablePast={disablePast}
          maxDate={
            disableMaxToday ? today.setDate(today.getDate() - 1) : maxDate
          }
          minDate={
            disableMinToday ? today.setDate(today.getDate() + 1) : minDate
          }
          inputFormat={inputDateFormat}
          readOnly={readOnly}
          shouldDisableDate={shouldDisableDate}
          shouldDisableMonth={shouldDisableMonth}
          shouldDisableYear={shouldDisableYear}
          components={{
            OpenPickerIcon: KeyboardArrowDown,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={invalidDate || lessThanToday || isInputHasErr}
              InputProps={{
                ...params.InputProps,
                placeholder: placeholder,
                readOnly: preventManualDateEntry,
                startAdornment: value ? (
                  <IconButton
                    className="close-btn"
                    onClick={() => {
                      handleResetValue();
                    }}
                  >
                    <Clear className="clear-icon" />
                  </IconButton>
                ) : null,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "0",
                  padding: "0",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
      <FormHelperText
        error={invalidDate || lessThanToday || isInputHasErr}
        className={`${helperTextClass || "font-medium fsize-12"} ${
          lang === "en" ? "" : "text-end"
        }`}
      >
        {invalidDate
          ? inputsValidations["invalidDate"]
          : lessThanToday
          ? inputsValidations["shouldNotBeBeforeToday"]
          : isInputHasErr
          ? inputsValidations[errMsg]
          : helperText}
      </FormHelperText>
    </div>
  );
};

FilterDatePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  calendarViews: PropTypes.arrayOf(PropTypes.string),
  openView: PropTypes.oneOf(["day", "month", "year"]),
  wrapperClass: PropTypes.string,
  inputClass: PropTypes.string,
  helperTextClass: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableMinToday: PropTypes.bool,
  disableMaxToday: PropTypes.bool,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  inputDateFormat: PropTypes.string,
  shouldDisableDate: PropTypes.func,
  shouldDisableMonth: PropTypes.func,
  shouldDisableYear: PropTypes.func,
  shouldNotBeLessThanToday: PropTypes.bool,
  readOnly: PropTypes.bool,
  isInputHasErr: PropTypes.bool,
  clear: PropTypes.bool,
  errMsg: PropTypes.string,
  helperText: PropTypes.string,
  preventManualDateEntry: PropTypes.bool,
};

FilterDatePicker.defaultProps = {
  calendarViews: ["year", "month", "day"],
  openView: "day",
  closeOnSelect: true,
  inputDateFormat: "DD/MM/YYYY",
};

export default FilterDatePicker;
