import { axiosInstance } from "./";

const getEmployeesList = async (params) =>
  await axiosInstance.get("/admins", { params });

const addPm = async (payload) => await axiosInstance.post("/admins", payload);

const getEmployee = async (id) => await axiosInstance.get(`/admins/${id}`);

const editEmployee = async ({ data, id }) =>
  await axiosInstance.put(`/admins/${id}`, data);

export { getEmployeesList, addPm, getEmployee, editEmployee };
