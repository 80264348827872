import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  applicant: {},
  eligibleApplicants: {
    list: [],
    meta: {},
  },
};

const applicants = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_APPLICANTS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta,
      };
    case types.GET_APPLICANT_RESPONSE:
      return {
        ...state,
        applicant: action.payload,
      };
    case types.GET_ELIGIBLE_APPLICANTS_RESPONSE:
      return {
        ...state,
        eligibleApplicants: {
          ...state.eligibleApplicants,
          list: action.payload.list,
          meta: action.payload.meta,
        },
      };
    case types.UPDATE_APPLICANT_RESPONSE:
      try {
        const updatedApplicant = action.payload;
        const newList = state.list.map((applicant) =>
          applicant.id === updatedApplicant.id ? updatedApplicant : applicant
        );
        return {
          ...state,
          list: newList,
          applicant: updatedApplicant,
        };
      } catch (error) {
        console.error("Error updating applicant:", error);
        return state;
      }
    default:
      return state;
  }
};

export default applicants;
