const requests = {
  listRequests: {
    list: "قائمه طلبات الفعاليات",
    search: "ابحث باستخدام اسم المتقدم او اسم الفعاليه",
    status: "حاله",
    eventName: "اسم الفعالية",
    applicantName: "اسم المتقدم",
    note: "ملاحظة",
    nationality: "الجنسية",
    date: "التاريخ",
    rating: "تقييم",
    all: "الكل",
    approved: "مقبول",
    pending: "قيد الانتظار",
    cancelled: "ملغي",
    auto_cancelled: "ملغي تلقائيا",
    placeholder: "لا يوجد طلبات حاليا ",
    warningMessage:
      "الحد الأقصى  لعدد الجنس المحدد  إكتمل ، هل انت متاكد من المتابعة ؟",
    warningTitle: "موافقه على تنبيه",
    cityOfResidence: "المدينة",
    speaking: "إجادة التحدث",
    changeRoleTitle: "تغيير الوظيفة ل",
    areYouSure: "هل أنت متأكد أنك تريد الموافقة على الطلبات المحددة؟",
    approvedRequest: "الموافقة على الطلبات",
    language: "لغة",
    searchPlaceholder: "ابحث عن طريق اسم المتقدم، ورقم هوية المتقدم",
  },
};

export default requests;
