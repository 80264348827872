import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { TableCell, TableRow } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";

import EmptyState from "../../../components/EmptyState";
import Button from "../../../components/Button";
import Filters from "../../../components/Filter";
import Table from "../../../components/Table";
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination";
import Breadcrumbs from "../../../components/BreadCrumbs";
import IconButton from "../../../components/IconButton";
import RadioButtonsGroup from "../../../components/RadioButton";
import Avatar from "../../../components/Avatar";
import ModalContent from "./ModalContent";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { genders } from "../../../utils/Constants";
import {
  renderTextEllipsis,
  textContainOnlySpaces,
} from "../../../utils/Helpers";
import messages from "../../../assets/locale/messages";
import applicantsPlaceholder from "../../../assets/images/event-screen/applicants-placeholder.svg";
import zoneApplicantsPlaceholder from "../../../assets/images/event-screen/zone-applicants-placeholder.svg";
import {
  bulkAssign,
  getRequestsRequest,
  updateEventRequest,
  updateSupervisorEventRequest,
} from "../../../store/Requests/actions";
import { getEventRequest } from "../../../store/Events/actions";
import { setCreateEventFormStep } from "../../../store/Routing/actions";
import { AssignIcon } from "../../../utils/Icons/Assign";
import "./ZoneApplicants.scss";

const ZoneApplicants = () => {
  const { eventId, zoneId } = useParams();
  const lang = useSelector((state) => state.locale.lang);
  const { zoneApplicants, shared, events } = messages[lang];
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [filterRoles, setFilterRoles] = useState(null);
  const [filterGenders, setFilterGenders] = useState(null);
  const [roles, setRoles] = useState([]);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openUnassignModal, setOpenUnassignModal] = useState(false);
  const [openSupervisorAssignModal, setOpenSupervisorAssignModal] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [selectedEventRequests, setSelectedEventRequests] = useState([]);
  const [mappingSupervisors, setMappingSupervisors] = useState([]);
  const dispatch = useDispatch();
  const meta = useSelector((state) => state.requests.zoneApplicants.meta);
  const lists = useSelector((state) => state.requests.zoneApplicants.list);
  const supervisorList = useSelector(
    (state) => state.requests.requestsList.list
  );
  const event = useSelector((state) => state.events.event);
  const isLoading = useSelector((state) => state.loader);
  const [isUnableDoingAction, setIsUnableDoingAction] = useState(null);
  const zoneSupervisor = event?.zones?.filter((zone) => zone.id == zoneId)[0]?.[
    "supervisor"
  ];

  const breadcrumb = () => (
    <Breadcrumbs
      list={[
        {
          path: ROUTE_PATHS.events,
          label: events.listEvents.listEventsBreadcrumb,
        },
        {
          path: "",
          label: shared.eventStatusOptions[event?.status],
          isClicked: true,
          onclick: () =>
            history.push({
              pathname: ROUTE_PATHS.events,
              state: event?.status,
            }),
        },
        {
          path: ROUTE_PATHS["viewEvent"].replace(":id", eventId),
          label: `${event?.name} ${events.listEvents.details}`,
          isClicked: true,
          onclick: () => dispatch(setCreateEventFormStep(0)),
        },
        {
          path: ROUTE_PATHS["viewEvent"].replace(":id", eventId),
          label: event?.zones?.filter((zone) => zone.id == zoneId)[0]?.["name"],
          isClicked: true,
          onclick: () => dispatch(setCreateEventFormStep(2)),
        },
        {
          path: "",
          label: zoneApplicants.applicants,
        },
      ]}
      BreadCrumbHasBackIcon={false}
    />
  );

  const handleAssignClick = () => {
    setOpenAssignModal(true);
  };

  const handleUnassignClick = (selectedItem) => {
    setSelectedItem(selectedItem);
    setOpenUnassignModal(true);
  };

  const handleAssignSupervisorClick = () => {
    setOpenSupervisorAssignModal(true);
  };

  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
  };

  const handleCloseUnassignModal = () => {
    setOpenUnassignModal(false);
  };

  const handleCloseAssignSupervisorModal = () => {
    setOpenSupervisorAssignModal(false);
  };

  const handleChangeStatus = () => {
    const isSupervisor = selectedItem?.role.name === "Supervisor";
    let params = {};
    isSupervisor
      ? (params["supervisor_id"] = null)
      : (params["zone_id"] = null);
    dispatch(
      (isSupervisor ? updateSupervisorEventRequest : updateEventRequest)({
        data: params,
        id: isSupervisor ? zoneId : selectedItem?.id,
        listParams: {
          page: page,
          items: 10,
          event_id: eventId,
          zone_id: zoneId,
          is_supervisor: false,
          user_name: searchValue,
          gender: filterGenders?.value,
          role_id: filterRoles?.id,
          order_by_created_at: "asc",
          responseType: "zoneApplicants",
        },
      })
    );
    handleCloseUnassignModal();
  };

  const handleSelectedItems = (selectedItems) => {
    setSelectedEventRequests(selectedItems);
  };

  const handleBulkAssign = () => {
    let params = {
      zone_id: +zoneId,
      event_request_ids: selectedEventRequests,
    };
    dispatch(
      bulkAssign({
        data: params,
        listParams: {
          page: page,
          items: 10,
          event_id: eventId,
          zone_id: zoneId,
          user_name: searchValue,
          is_supervisor: false,
          gender: filterGenders?.value,
          role_id: filterRoles?.id,
          order_by_created_at: "asc",
          responseType: "zoneApplicants",
        },
      })
    );
    handleCloseAssignModal();
  };

  const handleSupervisorChangeStatus = () => {
    let params = { supervisor_id: selectedSupervisor };
    dispatch(
      updateSupervisorEventRequest({
        data: params,
        id: zoneId,
        listParams: {
          page: -1,
          event_id: eventId,
          status: "approved",
          is_assigned_to_zone: false,
          is_supervisor: true,
          order_by_created_at: "asc",
          responseType: "requests",
        },
        eventId,
      })
    );
    handleCloseAssignSupervisorModal();
  };

  const genderOptions = [
    {
      id: 1,
      name: shared.male,
      value: genders.male,
    },
    {
      id: 2,
      name: shared.female,
      value: genders.female,
    },
    {
      id: 3,
      name: shared.notProvided,
      value: genders.notProvided,
    },
  ];

  const getApplicantsList = (pageNo, search, gender, role) => {
    dispatch(
      getRequestsRequest({
        page: pageNo,
        items: 10,
        event_id: eventId,
        zone_id: zoneId,
        user_name: search,
        is_supervisor: false,
        gender: gender?.value,
        role_id: role?.id,
        order_by_created_at: "asc",
        responseType: "zoneApplicants",
      })
    );
  };

  const getUnassignSupervisorList = () => {
    dispatch(
      getRequestsRequest({
        page: -1,
        event_id: eventId,
        status: "approved",
        is_assigned_to_zone: false,
        is_supervisor: true,
        order_by_created_at: "asc",
        responseType: "requests",
      })
    );
  };

  useEffect(() => {
    getUnassignSupervisorList();
  }, [lang]);

  useEffect(() => {
    const unassignedSupervisors = supervisorList?.map((supervisor) => ({
      value: supervisor?.user?.id,
      label: supervisor?.user?.["full_name_" + lang],
    }));
    setMappingSupervisors(unassignedSupervisors);
  }, [supervisorList?.length, supervisorList]);

  useEffect(() => {
    if (eventId) {
      dispatch(getEventRequest(eventId));
      dispatch(setCreateEventFormStep(2));
      setIsUnableDoingAction(
        event?.status == "past" || event?.status == "canceled"
      );
    }
    return () => {
      dispatch(setCreateEventFormStep(0));
    };
  }, [eventId, lang]);

  useEffect(() => {
    if (!event?.event_roles?.length) return;
    const mappingRoles = event?.event_roles
      .map((role) => role.role)
      .filter((role) => role.name != "Supervisor");
    setRoles(mappingRoles);
  }, [!!event?.event_roles?.length]);

  useEffect(() => {
    getApplicantsList(page, searchValue, filterGenders, filterRoles);
  }, [page, lang]);

  useEffect(() => {
    if (
      (searchValue.length >= 3 || searchValue.length === 0) &&
      !textContainOnlySpaces(searchValue)
    ) {
      setPage(1);
      getApplicantsList(1, searchValue, filterGenders, filterRoles);
    }
  }, [searchValue]);

  useEffect(() => {
    getApplicantsList(page, searchValue, filterGenders, filterRoles);
  }, [filterGenders]);

  useEffect(() => {
    getApplicantsList(page, searchValue, filterGenders, filterRoles);
  }, [filterRoles]);

  const handleSetPage = (page) => {
    setPage(page);
  };

  const headLines = [
    "",
    zoneApplicants.name,
    zoneApplicants.email,
    shared.gender,
    shared.role,
    shared.actions,
  ];

  if (isUnableDoingAction) {
    headLines.pop();
  }

  const renderApplicantList = () => {
    if (!isLoading && !lists?.length) {
      return (
        <>
          <div className="bg-white rounded-4 mt-4 mb-5 py-5">
            <EmptyState
              description={zoneApplicants.placeholder}
              imgSrc={zoneApplicantsPlaceholder}
              showActionButton={!isUnableDoingAction}
              buttonLabel={zoneApplicants.assignApplicants}
              handleCreate={() => {
                handleAssignClick();
              }}
              imgClass={"small-zone-applicants-empty-img"}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="bg-white rounded-4 mt-4 pb-3">
            <Table
              className="rounded-4"
              headlines={headLines}
              hasActions={false}
              rows={lists?.map((row, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      <Avatar
                        imgSrcAlt="user"
                        imgSrc={row.user.image}
                        name={row.user.full_name_en}
                        size={30}
                      />
                    </TableCell>
                    <TableCell className="pointer">
                      {renderTextEllipsis(
                        [
                          row.user.first_name,
                          row.user.middle_name,
                          row.user.last_name,
                        ].join(" ")
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.user?.email
                        ? renderTextEllipsis(row.user.email)
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {row?.user?.gender
                        ? shared.genderOptions[row?.user?.gender]
                        : "-"}
                    </TableCell>
                    <TableCell>{row.role.name}</TableCell>
                    {!isUnableDoingAction && (
                      <TableCell>
                        <IconButton
                          onClick={() => handleUnassignClick(row)}
                          className="icon-btn">
                          <RemoveIcon sx={{ fontSize: 15 }} />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            />
          </div>
          <div className="d-flex justify-content-center py-3 mt-4">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => {
                  handleSetPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div>
      <div className="conatiner">
        <div className="row align-items-center">
          <div className="col-sm-9 col-md-9 my-4">{breadcrumb()}</div>
          {!!lists?.length && !isUnableDoingAction && (
            <div className="col-sm-3 col-md-3 button-wrapper d-flex justify-content-md-end my-4">
              <Button
                onClick={() => handleAssignClick()}
                label={
                  <span className="d-flex align-items-center">
                    <span className="ps-1">
                      {zoneApplicants.assignApplicants}
                    </span>
                  </span>
                }
                labelClass="text-white text-nowrap"
                block
              />
            </div>
          )}
          <Filters
            searchInput={{
              placeholder: zoneApplicants.search,
              name: "search",
              value: searchValue,
              onChange: (value) => setSearchValue(value),
            }}
            filters={[
              {
                type: "filter",
                name: "role",
                placeholder: shared.role,
                value: filterRoles,
                options: roles,
                onChange: (value) => setFilterRoles(value),
              },
              {
                type: "filter",
                name: "gender",
                placeholder: shared.gender,
                value: filterGenders,
                options: genderOptions,
                onChange: (value) => setFilterGenders(value),
              },
            ]}
          />
          <div className="col-12 d-flex align-items-center">
            <span className="text-main font-bold pe-1 text-main">
              {events.creatEvent.labels.supervisorName} :
            </span>
            <div className="d-flex align-items-center hover-action">
              <span className="px-1">
                {zoneSupervisor?.full_name_en
                  ? zoneSupervisor?.["full_name_" + lang]
                  : zoneApplicants.notAssigned}
              </span>
              <div
                className="display-onhover pointer"
                onClick={() => handleAssignSupervisorClick()}>
                <AssignIcon />
              </div>
            </div>
          </div>
          <div className="col-12">{renderApplicantList()}</div>
        </div>
        <Modal
          open={openAssignModal}
          handleClose={handleCloseAssignModal}
          title={zoneApplicants.assignModal.title}
          contentClass="mb-3 text-primary"
          content={
            <ModalContent
              eventId={eventId}
              roles={roles}
              onSelectItems={(selectedItems) =>
                handleSelectedItems(selectedItems)
              }
            />
          }
          isContentText={false}
          confirmBtnTxt={shared.save}
          isDisabled={!selectedEventRequests?.length}
          maxWidth="sm"
          cancelBtnTxt={shared.cancel}
          handleConfirm={handleBulkAssign}
          handleCancel={handleCloseAssignModal}
          modalClass="assign-modal py-4"
          actionsClass="container px-4"
          confirmBtnClass="btn-secondary text-white"
        />
        <Modal
          open={openUnassignModal}
          handleClose={handleCloseUnassignModal}
          title={zoneApplicants.unassignModal.title}
          contentClass="mb-3 text-primary"
          content={
            <span>{zoneApplicants.unassignModal.unassignConfirmationMsg}</span>
          }
          isContentText={false}
          confirmBtnTxt={shared.save}
          maxWidth="xs"
          cancelBtnTxt={shared.cancel}
          handleConfirm={handleChangeStatus}
          handleCancel={handleCloseUnassignModal}
          modalClass="unassign-modal"
          actionsClass="container px-4"
          confirmBtnClass="btn-secondary text-white"
        />
        <Modal
          open={openSupervisorAssignModal}
          handleClose={handleCloseAssignSupervisorModal}
          title={zoneApplicants.assignSupervisor}
          contentClass="mb-3 text-primary"
          hasActions={!!mappingSupervisors.length}
          content={
            !mappingSupervisors.length ? (
              <div className="bg-white rounded-4 pb-3">
                <EmptyState
                  description={zoneApplicants.noASupervisors}
                  imgSrc={applicantsPlaceholder}
                  showActionButton={false}
                />
              </div>
            ) : (
              <RadioButtonsGroup
                wrapperClassName="d-flex align-items-center flex-row"
                labelClassName="d-none"
                className="d-flex flex-column justify-content-start align-items-start"
                required
                handleChange={(e) => setSelectedSupervisor(+e.target.value)}
                listOfRadios={mappingSupervisors}
                hideStar
              />
            )
          }
          isContentText={false}
          confirmBtnTxt={shared.save}
          maxWidth="sm"
          cancelBtnTxt={shared.cancel}
          handleConfirm={handleSupervisorChangeStatus}
          handleCancel={handleCloseAssignSupervisorModal}
          actionsClass="container px-4"
          confirmBtnClass="btn-secondary text-white"
        />
      </div>
    </div>
  );
};
export default ZoneApplicants;
