const successMsgs = {
  changePassword: "Password is changed successfully",
  deletedSuccessfully: "Deleted successfully ",
  addedSuccessfully: "Added successfully",
  eventDeletedSuccessfully: "Event deleted successfully",
  eventCanceledSuccessfully: "Event cancelled successfully",
  approveRequest: "The request has been approved successfully",
  updatedSuccessfully: "Updated successfully",
  acceptRequest: "Request accepted successfully",
  rejectRequest: "Request rejected",
  forgetPassword: " New password is sent to your email ",
  blockedSuccessfully: "The user has been blocked successfully",
  unBlockedSuccessfully: "The user has been unblocked successfully",
  applicantsInvitedSuccessfully: "Applicants have been invited successfully",
};

export default successMsgs;
