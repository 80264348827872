import * as types from "./types";

// list
export const getDailyAndMonthlyReportsRequests = (payload) => ({
  type: types.GET_DAILY_EVENTS_WORKING_LOGS_REQUEST,
  payload,
});

export const getDailyAndMonthlyReportsResponse = (payload) => ({
  type: types.GET_DAILY_EVENTS_WORKING_LOGS_RESPONSE,
  payload,
});
export const exportExcelRequest = (payload) => ({
  type: types.EXPORT_EXCEL_REQUEST,
  payload,
});
export const exportExcelResponse = (payload) => ({
  type: types.EXPORT_EXCEL_RESPONSE,
  payload,
});
