import React from "react";
import PropTypes from "prop-types";

export const EmployeesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="20"
    fill="none"
    viewBox="0 0 35 35"
    {...props}>
    <path
      fill={props.selectedIcon ? "#448BCA" : "#6B778C"}
      d="M17.696 19.99h-.277c-3.695-.113-6.463-2.995-6.463-6.543 0-3.614 2.947-6.561 6.561-6.561s6.56 2.947 6.56 6.56c-.016 3.566-2.8 6.43-6.332 6.545h-.049zm-.195-10.678a4.123 4.123 0 00-4.119 4.118 4.102 4.102 0 003.956 4.119c.049-.016.212-.016.374 0a4.127 4.127 0 003.907-4.119 4.123 4.123 0 00-4.118-4.118zM17.501 35C13.122 35 8.94 33.372 5.7 30.41a1.23 1.23 0 01-.39-1.026c.211-1.937 1.416-3.744 3.418-5.08 4.851-3.223 12.714-3.223 17.549 0 2.002 1.352 3.207 3.143 3.418 5.08.05.39-.097.765-.39 1.026A17.436 17.436 0 0117.5 35zm-9.637-5.942a14.99 14.99 0 009.637 3.5 14.99 14.99 0 009.638-3.5c-.293-.993-1.075-1.953-2.23-2.735-4.005-2.67-10.794-2.67-14.83 0-1.157.782-1.922 1.742-2.215 2.735z"></path>
    <path
      fill={props.selectedIcon ? "#448BCA" : "#6B778C"}
      d="M17.5 35C7.847 35 0 27.154 0 17.5 0 7.847 7.847 0 17.5 0 27.154 0 35 7.847 35 17.5 35 27.154 27.154 35 17.5 35zm0-32.558C9.198 2.442 2.442 9.198 2.442 17.5S9.198 32.558 17.5 32.558 32.558 25.802 32.558 17.5 25.802 2.442 17.5 2.442z"></path>
  </svg>
);

EmployeesIcon.propTypes = {
  selectedIcon: PropTypes.bool,
};
