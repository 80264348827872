import React from "react";

export const EnvelopeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    fill="none"
    viewBox="0 0 21 20"
    {...props}>
    <path
      fill="#9E9E9E"
      fillRule="evenodd"
      d="M15.11.821a5.11 5.11 0 015.115 5.103v7.983a5.11 5.11 0 01-5.114 5.104H5.128a5.11 5.11 0 01-5.113-5.104V5.924A5.103 5.103 0 015.128.821h9.983zm1.608 6.609l.081-.08a.782.782 0 00-.01-1.011.85.85 0 00-.535-.263.768.768 0 00-.567.202L11.13 9.916a1.583 1.583 0 01-2.02 0L4.561 6.278a.769.769 0 00-1.01.07.772.772 0 00-.072 1.011l.132.132 4.598 3.587a3.176 3.176 0 003.95 0l4.558-3.648z"
      clipRule="evenodd"></path>
  </svg>
);
