import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FilledInput, Button, FormHelperText } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import {
  acceptedImagesFilesTypes,
  acceptedImagesFilesMaxSize,
  acceptedPdfFilesType,
  acceptedPdfFilesMaxSize,
} from "../../utils/Constants";
import { PdfIcon } from "../../utils/Icons";
import messages from "../../assets/locale/messages";
import "./Upload.scss";

const Upload = ({
  accept,
  onChange,
  label,
  required,
  disabled,
  img,
  name,
  multiple,
  className,
  onChangeError,
  isFullWidthImage,
  fullWidthImageClassName,
  id,
  isInputHasErr,
  fieldLabel,
  pdf,
  isImage,
  isPdf,
  isBluredBackground,
  wrapperClasses,
  errorMsg,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared, inputsValidations } = messages[lang];
  const [error, setError] = useState(null);
  const [pdfFileName, setpdfFileName] = useState("");

  useEffect(() => {
    document.getElementById(id || "raised-button-file").accept = accept;
    if (multiple) {
      document.getElementById(id || "raised-button-file").multiple = true;
    }
  }, [accept]);

  const validateImagesFiles = (imagesFilesList) => {
    return Array.from(imagesFilesList).every((file) => {
      if (!acceptedImagesFilesTypes.includes(file.type)) {
        setError(shared.uploadTypeError);
        onChangeError && onChangeError(shared.uploadTypeError);
        return false;
      }
      if (file.size > acceptedImagesFilesMaxSize) {
        setError(shared.uploadSizeError);
        onChangeError && onChangeError(shared.uploadSizeError);
        return false;
      }
      setError(null);
      onChangeError && onChangeError(null);
      return true;
    });
  };

  const validatePdfFiles = (pdfFilesList) => {
    return Array.from(pdfFilesList).every((file) => {
      if (!acceptedPdfFilesType.includes(file.type)) {
        setError(shared.pdfUploadTypeError);
        onChangeError && onChangeError(shared.pdfUploadTypeError);
        return false;
      }
      if (file.size > acceptedPdfFilesMaxSize) {
        setError(shared.pdfUploadSizeError);
        onChangeError && onChangeError(shared.pdfUploadSizeError);
        return false;
      }
      setError(null);
      onChangeError && onChangeError(null);
      return true;
    });
  };

  return (
    <div className={`position-relative ${className}`}>
      <div className="field-label">
        {fieldLabel}{" "}
        {required && fieldLabel ? <span className="err-asterisk"> *</span> : ""}
      </div>
      <FilledInput
        style={{ display: "none" }}
        id={id || "raised-button-file"}
        type="file"
        accept={accept}
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            if (isImage) {
              if (!validateImagesFiles(e.target.files)) return;
              if (multiple) {
                const files = e.target.files;
                const filesAsArray = [...files];
                let filesSrc = [];
                filesAsArray.forEach((file) => {
                  filesSrc.push(URL.createObjectURL(file));
                });
                onChange(filesSrc, files);
              } else {
                var reader = new FileReader();
                reader.onload = (event) => {
                  onChange(event.target.result, e.target.files[0]);
                };
                reader.readAsDataURL(e.target.files[0]);
              }
            }

            if (isPdf) {
              if (!validatePdfFiles(e.target.files)) return;
              if (multiple) {
                const files = e.target.files;
                const filesAsArray = [...files];
                let filesSrc = [];
                filesAsArray.forEach((file) => {
                  filesSrc.push(URL.createObjectURL(file));
                });
                onChange(filesSrc, files);
              } else {
                var pdfReader = new FileReader();
                pdfReader.onload = (event) => {
                  onChange(event.target.result, e.target.files[0]);
                };
                pdfReader.readAsDataURL(e.target.files[0]);
                setpdfFileName(e.target.files[0]?.name);
              }
            }
          }
        }}
        required={required}
        disabled={disabled}
        name={name}
        error={isInputHasErr}
      />
      <div className={`position-relative ${wrapperClasses}`}>
        <label
          htmlFor={id || "raised-button-file"}
          className={`mt-2 ${
            isFullWidthImage
              ? "d-flex justify-content-center mega-uploaded-image-container position-relative grow-child-panner"
              : ""
          }`}
        >
          {img ? (
            <div
              className={`${
                isFullWidthImage
                  ? "position-relative  uploaded-image-container"
                  : ""
              }`}
            >
              <div
                style={{ backgroundImage: `url(${img})` }}
                className={`logoInput ${disabled ? "" : "pointer"} ${
                  isFullWidthImage ? fullWidthImageClassName : ""
                }`}
              ></div>
              {isBluredBackground && (
                <div
                  style={{ backgroundImage: `url(${img})` }}
                  className="blur-image"
                ></div>
              )}
            </div>
          ) : pdf ? (
            <div className="py-5 px-4 pointer pdf-upload-container">
              <span className="d-flex justify-content-center mb-3">
                <PdfIcon />
              </span>
              <span className="w-100">{pdfFileName.split(".pdf")[0]}</span>
            </div>
          ) : (
            <Button
              variant="contained"
              component="span"
              className={`btn-upload ${isInputHasErr && "error-border"} ${
                isFullWidthImage ? "btn-banner" : ""
              }`}
              disabled={disabled}
            >
              {label}
            </Button>
          )}
        </label>
        {(img || pdf) && !disabled && (
          <div className={`ovelay pointer ${pdf ? "pdf-remove" : ""}`}>
            <HighlightOffIcon
              onClick={(event) => {
                onChange(null);
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
              }}
            />
          </div>
        )}
      </div>
      <FormHelperText
        error={true}
        className={`font-medium fsize-12 ${lang === "en" ? "" : "text-end"}`}
      >
        {isInputHasErr ? inputsValidations[errorMsg] : error}
      </FormHelperText>
    </div>
  );
};

Upload.propTypes = {
  accept: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  img: PropTypes.any,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  className: PropTypes.string,
  onChangeError: PropTypes.func,
  isFullWidthImage: PropTypes.bool,
  fullWidthImageClassName: PropTypes.string,
  id: PropTypes.string,
  isInputHasErr: PropTypes.bool,
  fieldLabel: PropTypes.string,
  isImage: PropTypes.bool,
  isPdf: PropTypes.bool,
  pdf: PropTypes.any,
  isBluredBackground: PropTypes.bool,
  wrapperClasses: PropTypes.string,
  errorMsg: PropTypes.string,
};

Upload.defaultProps = {
  accept: "image/*",
  label: "Upload",
  required: false,
  disabled: false,
};

export default Upload;
