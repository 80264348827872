import {
  ADD_PUSH_NOTIFICATION,
  GET_DROPDOWN_NOTIFICATIONS_REQUEST,
  GET_DROPDOWN_NOTIFICATIONS_RESPONSE,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_RESPONSE,
  SET_NOTIFICATION_SEEN,
  SET_UNSEEN_NOTIFICATIONS_COUNT
} from "./types";

export const getNotificationsRequest = (payload) => ({
  type: GET_NOTIFICATIONS_REQUEST,
  payload,
});

export const getNotificationsResponse = (payload) => ({
  type: GET_NOTIFICATIONS_RESPONSE,
  payload,
});

export const setNotificationSeenRequest = (payload) => ({
  type: SET_NOTIFICATION_SEEN,
  payload,
});

export const addPushNotification = (payload) => ({
  type: ADD_PUSH_NOTIFICATION,
  payload,
});

export const setUnseenNotificationsCount = (payload) => ({
  type: SET_UNSEEN_NOTIFICATIONS_COUNT,
  payload,
});

export const getDropdownNotificationsRequest = (payload) => ({
  type: GET_DROPDOWN_NOTIFICATIONS_REQUEST,
  payload,
});

export const getDropdownNotificationsResponse = (payload) => ({
  type: GET_DROPDOWN_NOTIFICATIONS_RESPONSE,
  payload,
});