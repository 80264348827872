import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, useLocation } from "react-router-dom";

import { isAuth } from "../utils/Auth";
import { ROUTE_PATHS } from "../utils/RoutesPaths";
import { isAdmin } from "../utils/Permissions";
import store from "../store";

const PrivateRoute = ({ component: Component, permission, ...rest }) => {
  const location = useLocation();
  const mustChangePassword = store.getState().auth.login.must_change_password;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth() ? (
          // To force the user to change the temp password before routing to other locations
          mustChangePassword &&
          location.pathname !== ROUTE_PATHS.changePassword ? (
            <Redirect to={{ pathname: ROUTE_PATHS.changePassword }} />
          ) : permission || isAdmin() ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: ROUTE_PATHS.evaluations }} />
          )
        ) : (
          <Redirect to={{ pathname: ROUTE_PATHS.login }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
  permission: PropTypes.any,
};

export default PrivateRoute;
