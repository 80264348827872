import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import messages from "../../../assets/locale/messages";
import { forgetPasswordRequest } from "../../../store/Auth/actions";
import "./ForgetPassword.scss";

const ForgetPassword = () => {
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const { forgetPassword } = messages[lang];
  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("validEmailFormat").required("emailRequired"),
    }),
    onSubmit: ({ email }) => {
      dispatch(forgetPasswordRequest({ email }));
    },
  });

  return (
    <div className="reset-password-container w-100">
      <div className="w-100 text-center pt-3 mt-3 ">
        <h2 className="title reset-password-title fsize-32 text-primary">
          {forgetPassword.title}
        </h2>
        <p className="reset-password-subtitle">{forgetPassword.title2}</p>
      </div>
      <div className="box-contaienr px-4 pb-5 pt-3">
        <form className="w-100" onSubmit={handleSubmit} noValidate>
          <Input
            id="email"
            name="email"
            type="email"
            placeholder={forgetPassword.placeholders.email}
            onChange={(value) => {
              setFieldTouched("email");
              setFieldValue("email", value.trim());
            }}
            value={values["email"]}
            inputClass="mb-1"
            labelClassName="font-medium mb-2"
            inputWrapperClass="my-4"
            isInputHasErr={!!(touched["email"] && errors["email"])}
            errMsg={errors["email"]}
          />
          <Button
            label={forgetPassword.actionBTN}
            type="submit"
            labelClass="fsize-16 text-white py-1 fweight-600"
            className="mb-2 mt-5"
            block
            disabled={!dirty || !isValid}
          />
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
