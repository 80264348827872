import React from "react";
// import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import LangSwitcher from "../../LangSwitcher";
import welcomeLogo from "../../../assets/images/auth-screen/proline-big-logo.png";
import sideLeftLogo from "../../../assets/images/auth-screen/left-auth-image.svg";
import sideRightLogo from "../../../assets/images/auth-screen/right-auth-image.svg";
// import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import "./AuthLayout.scss";

const AuthLayout = ({ content }) => {
  // const location = useLocation();

  return (
    <div className="auth-container">
      <div className="w-100 d-flex justify-content-end px-4 py-1 langSwitch-container">
        <LangSwitcher />
      </div>
      <div className="d-flex justify-content-center align-items-start content-container">
        <img src={sideLeftLogo} alt="side-left-logo" className="sideLeftLogo" />
        <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center align-items-lg-start">
          <div className="logo-container d-flex justify-content-end align-items-center px-5 pt-4 pt-lg-5">
            <img
              src={welcomeLogo}
              className="w-80 mt-2 mt-lg-5"
              alt="proline-logo"
            />
          </div>
          <div className="d-flex justify-content-center align-items-center fields-container">
            <div className="d-flex flex-column align-items-start component-section">
              {/* location.pathname === ROUTE_PATHS.login  */}
              <div className="layout-card d-flex flex-column align-items-center">
                {content}
              </div>
            </div>
          </div>
        </div>
        <img
          src={sideRightLogo}
          alt="side-right-logo"
          className="sideRightLogo"
        />
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  content: PropTypes.element,
};

export default AuthLayout;
