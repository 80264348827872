const inputsValidations = {
  emailRequired: "البريد الإلكتروني مطلوب",
  nameRequired: "الاسم مطلوب",
  enNameRequired: "الاسم باللغة الانجليزية مطلوب",
  arNameRequired: "الاسم باللغة العربية مطلوب",
  pickOneCategoryAtLeast: "يجب اختيار فئة واحدة علي الأقل",
  specialtyRequired: "اختيار التخصص مطلوب",
  logoRequired: "اختيار الشعار مطلوب",
  bannerRequired: "اختيار صورة الغلاف مطلوب",
  categoryRequired: "اختيار الفئة مطلوبة",
  roleRequired: "الوظيفة مطلوبة",
  passwordRequired: "كلمة المرور مطلوبة",
  tempPasswordRequired: "كلمة المرور المؤقتة مطلوبة ",
  oldPasswordRequired: "كلمة المرور القديمة مطلوبة ",
  newPasswordRequired: "كلمة مرور الجديدة مطلوبة",
  validEmailFormat: "يجب أن يكون البريد الإلكتروني عنوان بريد إلكتروني صالحًا",
  uniqeEmailRequired:
    "يتم استخدام هذا البريد الإلكتروني من قبل، ويجب أن يكون البريد الإلكتروني موحدًا",
  confirmNewPasswordRequired: "تأكيد كلمة المرور الجديدة مطلوب",
  passwordIsNotMatched: "كلمات المرور يجب أن تتطابق",
  passwordNotFollowREGEX:
    "يجب أن تحتوي كلمات المرور على 8 أحرف على الأقل ؛ مزيج من الأحرف (الكبيرة والصغيرة) والأرقام والرموز",
  nameNotFollowREGEX: "الاسم لا يجب أن يحتوى علي رموز",
  nameNotFollowAlphabeticREGEX: "الاسم لا يجب أن يحتوى علي رموز او ارقام",
  englishLettersOnly: "هذا الحقل يقبل اللغة الانجليزية فقط",
  arabicLettersOnly: "هذا الحقل يقبل اللغة العربية فقط",
  onlyPositiveNumber: "هذا الحقل يقبل أرقام صحيحة فقط",
  onlyPositiveNumbersWithZero: "هذا الحقل يقبل صفر أو أرقام صحيحة فقط",
  onlyPositiveNumberWithMaxTwoDecimalPlaces:
    "هذا الحقل يقبل أرقام  فقط بحد أقصي رقميين عشريين",
  userTypeRequired: "نوع المستخدم مطلوب",
  eventNameRequired: "اسم الفعالية مطلوب",
  eventBriefRequired: "النبذة عن الفعالية مطلوبة",
  startBuildUpDateNotAfterEnd: "تاريخ البداية يجب أن يكون قبل تاريخ النهاية",
  endBuildUpDateNotBeforeStart: "تاريخ النهاية يجب أن يكون بعد تاريخ البداية",
  eventStartDateNotAfterEnd: "تاريخ البداية يجب أن يكون قبل تاريخ النهاية",
  eventEndDateNotBeforeStart: "تاريخ النهاية يجب أن يكون بعد تاريخ البداية",
  dismantlingStartDateNotAfterEnd:
    "تاريخ البداية يجب أن يكون قبل تاريخ النهاية",
  dismantlingEndtDateNotBeforeStart:
    "تاريخ النهاية يجب أن يكون بعد تاريخ البداية",
  shouldNotBeBeforeToday: "التاريخ لا يجب أن يكون قبل اليوم",
  invalidDate: "أدخل تاريخاً صحيحاً",
  invalidTime: "أدخل وقتاً صحيحاً",
  maxHundredChars: "الحد الأقصى 100حرف",
  maxFiveHundredChars: "الحد الأقصى 500حرف",
  maxTowHundredChars: "الحد الأقصى 200حرف",
  maxFiftyChars: "الحد الأقصى 50حرف",
  generalFieldRequired: "هذا الحقل مطلوب",
  invalidPhone: "رقم هاتف غير صحيح",
  requiredDate: "هذا التاريخ مطلوب",
  max1000: "الحد الأقصي 1000 حرف بدون علامات",
  max500: "الحد الأقصي 500 حرف بدون علامات",
  max300: "الحد الأقصي 300 حرف بدون علامات",
  generalFieldRequiredToPublish: "هذا الحقل مطلوب",
  eventImageRequired: "صورة الفعالية مطلوبة",
  verificationMethodRequired: "طريقة التحقق من تسجيل الوصول مطلوبة",
  checkinStartMarginMax: "يجب ألا يتجاوز هامش بدء تسجيل الوصول 240 دقيقة",
  checkinEndMarginMax: "يجب ألا يتجاوز هامش إنهاء تسجيل الوصول 240 دقيقة",
  checkinMarginsTotalMax:
    "يجب ألا يتجاوز إجمالي هوامش البداية والنهاية لتسجيل الوصول 240 دقيقة",
  checkinMarginsTotalMin:
    "إجمالي هوامش البداية والنهاية لتسجيل الوصول يجب أن يكون أكبر من 0",
  checkoutStartMarginMax: "يجب ألا يتجاوز هامش بدء تسجيل المغادرة 240 دقيقة",
  checkoutEndMarginMax: "يجب ألا يتجاوز هامش إنهاء تسجيل المغادرة 240 دقيقة",
  checkoutMarginsTotalMax:
    "يجب ألا يتجاوز إجمالي هوامش البداية والنهاية لتسجيل المغادرة 240 دقيقة",
  checkoutMarginsTotalMin:
    "إجمالي هوامش البداية والنهاية لتسجيل المغادرة يجب أن يكون أكبر من 0",
  marginsOverlapWithSlot:
    "يجب أن يكون إجمالي هامش نهاية تسجيل الوصول وهامش بدء تسجيل المغادرة أقل من عدد ساعات العمل",
};

export default inputsValidations;
