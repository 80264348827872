import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

import NotificationSupportAlert from "../../NotificationSupportAlert";

const MainSection = ({
  children,
  sideMenuOpen,
  drawerWidth,
  collapsedDrawerWidth,
  navbarHeight,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const customStyle = {
    backgroundColor: "#f4f4f4",
    mt: {
      xs: "50px",
      lg: "60px",
    },
    flexGrow: 1,
    pt: 0,
    pl: {
      xs: 2,
      md: 4,
      lg: 3,
    },
    pr: {
      xs: 2,
      md: 4,
      lg: 3,
    },
    transition: (theme) =>
      theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    width: {
      xs: "100%",
      md: `calc(100% - ${collapsedDrawerWidth}px)`,
    },
    minHeight: `calc(100vh - ${navbarHeight}px)`,
    height: "fit-content",
    ml: { md: lang === "en" && `${collapsedDrawerWidth}px` },
    mr: { md: lang === "ar" && `${collapsedDrawerWidth}px` },
    ...(sideMenuOpen && {
      ml: { md: lang === "en" && `${drawerWidth}px` },
      mr: { md: lang === "ar" && `${drawerWidth}px` },
      width: { xs: "100%", md: `calc(100% - ${drawerWidth}px)` },
      transition: (theme) =>
        theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
    }),
  };
  return (
    <Box component="main" sx={customStyle}>
      <NotificationSupportAlert />
      {children}
    </Box>
  );
};

MainSection.propTypes = {
  sideMenuOpen: PropTypes.bool.isRequired,
  drawerWidth: PropTypes.number.isRequired,
  collapsedDrawerWidth: PropTypes.number.isRequired,
  navbarHeight: PropTypes.number,
  children: PropTypes.element,
};

export default MainSection;
