import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Map, GoogleApiWrapper, Marker, Circle } from "google-maps-react";

import "./GoogleMaps.scss";

const GoogleMaps = ({
  className,
  viewOnly,
  isMarker,
  getCoords,
  savedCoords,
  wrapperClasses,
  markerMoved,
  updatedPosition,
  showCircle,
  circleRadius,
}) => {
  // the user positin in ordinary map
  const [position, setPosition] = useState({
    coords: { lng: 0, lat: 0 },
  });

  useEffect(() => {
    if (updatedPosition?.coords?.lat && updatedPosition?.coords?.lng) {
      setPosition(updatedPosition);
    }
  }, [updatedPosition]);

  useEffect(() => {
    if (isMarker && savedCoords?.coords) {
      // to set the user updated position in edit mode
      setPosition(savedCoords);
    } else {
      // to set the initial user position
      navigator.geolocation.getCurrentPosition((userPosition) => {
        const {
          coords: { longitude, latitude },
        } = userPosition;
        setPosition({
          coords: {
            lng: longitude,
            lat: latitude,
          },
        });
      });
    }
  }, []);

  const onMoveMarker = (props, marker) => {
    markerMoved();
    // DO NOT remove the props param even if it's not used
    setPosition({
      coords: {
        lng: marker.position.lng(),
        lat: marker.position.lat(),
      },
    });
    getCoords({
      coords: {
        lng: marker.position.lng(),
        lat: marker.position.lat(),
      },
    });
  };

  return (
    <div
      className={`google-maps-container position-relative ${wrapperClasses}`}>
      <Map
        google={window.google}
        zoom={14}
        center={{
          lat: position.coords.lat,
          lng: position.coords.lng,
        }}
        className={className}>
        {isMarker && (
          <Marker
            name="Current location"
            draggable={!viewOnly}
            onDragend={onMoveMarker}
            position={{
              lat: position.coords.lat,
              lng: position.coords.lng,
            }}
          />
        )}
        {showCircle && (
          <Circle
            radius={circleRadius}
            center={{
              lat: position.coords.lat,
              lng: position.coords.lng,
            }}
            strokeColor="#448bca"
            strokeOpacity={1}
            strokeWeight={1}
            fillColor="#448bca"
            fillOpacity={0.3}
          />
        )}
      </Map>
    </div>
  );
};
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(GoogleMaps);

GoogleMaps.propTypes = {
  className: PropTypes.string,
  wrapperClasses: PropTypes.string,
  viewOnly: PropTypes.bool,
  isMarker: PropTypes.bool,
  getCoords: PropTypes.func,
  savedCoords: PropTypes.object,
  updatedPosition: PropTypes.object,
  hasSearch: PropTypes.bool,
  markerMoved: PropTypes.func,
  showCircle: PropTypes.bool,
  circleRadius: PropTypes.number,
};
GoogleMaps.defaultProps = {
  viewOnly: false,
};
