import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { TextField, InputLabel, FormHelperText, Stack } from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import "dayjs/locale/ar-sa";

import { CalendarIcon } from "../../utils/Icons";
import messages from "../../assets/locale/messages";
import "./DateTimePicker.scss";

const DateTimePicker = ({
  value,
  onChange,
  name,
  id,
  label,
  required,
  placeholder,
  wrapperClass,
  labelClass,
  inputClass,
  helperTextClass,
  closeOnSelect,
  disabled,
  disableFuture,
  disablePast,
  disableMinToday,
  disableMaxToday,
  maxDate,
  minDate,
  shouldDisableDate,
  shouldDisableMonth,
  shouldDisableYear,
  shouldNotBeLessThanToday,
  readOnly,
  isInputHasErr,
  errMsg,
  helperText,
  preventManualDateEntry,
  onOpen,
  onClose,
  ampm,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations } = messages[lang];

  const [date, setDate] = useState(null);
  const [invalidDate, setInvalidDate] = useState(false);
  const [lessThanToday, setLessThanToday] = useState(false);

  const today = new Date();

  const isDateLessThanToday = (enteredDate) => {
    let nowDate = new Date();
    let nowYear = +nowDate.getFullYear();
    let nowMonth = +nowDate.getMonth() + 1;
    let nowDay = +nowDate.getDate();
    let enteredDateYear = +enteredDate?.split("-")[0];
    let enteredDateMonth = +enteredDate?.split("-")[1];
    let enteredDateDay = +enteredDate?.split("-")[2]?.split("T")[0];

    if (
      enteredDateYear < nowYear ||
      (enteredDateYear === nowYear && enteredDateMonth < nowMonth) ||
      (enteredDateYear === nowYear &&
        enteredDateMonth === nowMonth &&
        enteredDateDay < nowDay)
    ) {
      return true;
    } else return false;
  };

  const handleDateChange = (selectedDate) => {
    if (selectedDate && dayjs(selectedDate).format() === "Invalid Date") {
      setInvalidDate(true);
      setDate(selectedDate);
    } else if (
      shouldNotBeLessThanToday &&
      isDateLessThanToday(dayjs(selectedDate).format())
    ) {
      setInvalidDate(false);
      setLessThanToday(true);
      setDate(selectedDate);
    } else {
      setInvalidDate(false);
      setLessThanToday(false);
      setDate(selectedDate);
      onChange(selectedDate ? dayjs(selectedDate).format() : "");
    }
  };

  useEffect(() => {
    if (value) {
      setDate(dayjs(value));
    } else {
      setDate(null);
    }
  }, [value]);

  return (
    <div
      className={`date-picker-wrapper ${
        disabled ? "disabled" : ""
      } ${wrapperClass}`}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        // adapterLocale={lang === "en" ? "en" : "ar-sa"}
      >
        <InputLabel
          htmlFor={id || name}
          className={`date-picker-label ${labelClass}`}>
          <>
            {label}
            {required ? <span className="err"> *</span> : ""}
          </>
        </InputLabel>
        <Stack>
          <MuiDateTimePicker
            value={date}
            onChange={handleDateChange}
            className={`custom-date-picker ${inputClass} ${
              invalidDate || lessThanToday || isInputHasErr
                ? "error-border"
                : ""
            }`}
            inputFormat={"DD/MM/YYYY hh:mm a"}
            ampm={ampm}
            required={required}
            closeOnSelect={closeOnSelect}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            maxDate={
              disableMaxToday ? today.setDate(today.getDate() - 1) : maxDate
            }
            minDate={
              disableMinToday ? today.setDate(today.getDate() + 1) : minDate
            }
            readOnly={readOnly}
            shouldDisableDate={shouldDisableDate}
            shouldDisableMonth={shouldDisableMonth}
            shouldDisableYear={shouldDisableYear}
            components={{
              OpenPickerIcon: CalendarIcon,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={invalidDate || lessThanToday || isInputHasErr}
                inputProps={{
                  ...params.inputProps,
                  placeholder: placeholder,
                  readOnly: preventManualDateEntry,
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "0",
                    padding: "0",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              />
            )}
            onClose={onClose}
            onOpen={onOpen}
          />
        </Stack>
      </LocalizationProvider>
      <FormHelperText
        error={invalidDate || lessThanToday || isInputHasErr}
        className={`${helperTextClass || "font-medium fsize-12"} ${
          lang === "en" ? "" : "text-end"
        }`}>
        {invalidDate
          ? inputsValidations["invalidDate"]
          : lessThanToday
          ? inputsValidations["shouldNotBeBeforeToday"]
          : isInputHasErr
          ? inputsValidations[errMsg]
          : helperText}
      </FormHelperText>
    </div>
  );
};

DateTimePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  wrapperClass: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  helperTextClass: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableMinToday: PropTypes.bool,
  disableMaxToday: PropTypes.bool,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  shouldDisableDate: PropTypes.func,
  shouldDisableMonth: PropTypes.func,
  shouldDisableYear: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  shouldNotBeLessThanToday: PropTypes.bool,
  readOnly: PropTypes.bool,
  isInputHasErr: PropTypes.bool,
  errMsg: PropTypes.string,
  helperText: PropTypes.string,
  preventManualDateEntry: PropTypes.bool,
  ampm: PropTypes.bool,
};

DateTimePicker.defaultProps = {
  closeOnSelect: false,
  ampm: true,
};

export default DateTimePicker;
