import React from "react";
import PropTypes from "prop-types";

export const PromotionsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 33 32">
    <path
      stroke={props.selectedIcon ? "#448BCA" : "#6B778C"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22.363 3.156a10.458 10.458 0 014.117 8.322c0 2.171-.644 4.162-1.752 5.823-1.616 2.396-4.176 4.087-7.155 4.521a9.02 9.02 0 01-1.572.135 9.021 9.021 0 01-1.571-.135c-2.98-.434-5.539-2.125-7.156-4.52a10.43 10.43 0 01-1.751-5.824A10.47 10.47 0 0116 1"></path>
    <path
      stroke={props.selectedIcon ? "#448BCA" : "#6B778C"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M29.846 25.654l-2.47.584a1.469 1.469 0 00-1.107 1.108l-.524 2.2c-.285 1.198-1.811 1.557-2.605.614L16 21.957l-7.14 8.218c-.794.943-2.32.584-2.605-.614l-.524-2.2a1.491 1.491 0 00-1.108-1.108l-2.47-.584c-1.137-.27-1.541-1.691-.718-2.515l5.838-5.838c1.617 2.396 4.176 4.087 7.155 4.521a9.03 9.03 0 001.572.135 9.03 9.03 0 001.572-.135c2.979-.434 5.538-2.125 7.155-4.52l5.838 5.837c.823.809.42 2.23-.719 2.5zM16.87 6.958l.883 1.766c.12.24.434.48.718.524l1.602.27c1.018.164 1.257.913.524 1.646l-1.243 1.242c-.21.21-.329.614-.254.914l.36 1.541c.284 1.213-.36 1.692-1.438 1.048l-1.497-.883c-.27-.165-.718-.165-.988 0l-1.497.883c-1.077.629-1.721.165-1.437-1.048l.36-1.541c.06-.285-.045-.704-.255-.914l-1.242-1.242c-.734-.733-.494-1.467.524-1.647l1.601-.269c.27-.045.584-.284.704-.524l.883-1.766c.434-.958 1.212-.958 1.691 0z"></path>
  </svg>
);

PromotionsIcon.propTypes = {
  selectedIcon: PropTypes.bool,
};
