const login = {
  title: "مرحبا بك",
  subTitle: "سجل الدخول للبدء",
  subTitle2: "الدخول",
  labels: {
    email: "البريد الإلكتروني",
    password: "كلمة المرور",
    remember: "تذكرني المرة القادمة",
    forgetPassword: "نسيت كلمة المرور؟",
    signin: "تسجيل الدخول",
    admin: "مسئول",
    supervisor: "مشرف",
  },
  placeholders: {
    email: "ادخل البريد الإلكتروني",
    password: "ادخل كلمة المرور",
  },
};

export default login;
