const promotionRequests = {
  listPromotionRequests: {
    list: "List Promotions Requests",
    status: "Status",
    search: "Search by applicant name",
    all: "All",
    approved: "Accepted",
    pending: "Pending",
    rejected: "Rejected",
    applicantName: "Applicant Name",
    accept: "Accept",
    reject: "Reject",
    profileRating: "Rating",
    accepted: "Accepted",
    action: "Actions",
    male: "Male",
    female: "Female",
    prefer_not_to_say: "Not Provided",
    requestDate: "Request Date",
    noDataDescription: "Sorry, No promotions requests found.",
    accpetMsg: "Are you sure, you want to accept this promotion request?",
    rejectMsg: "Are you sure, you want to reject this promotion request?",
  },
};

export default promotionRequests;
