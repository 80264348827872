const events = {
  creatEvent: {
    stepOne: "معلومات أساسية ومديرين المشروع",
    verificationMethod: "طريقة التحقق من تسجيل الوصول",
    stepTwo: "الأدوار",
    stepThree: "المناطق والمتطلبات",
    basicInfo: "معلومات أساسية",
    projectManagers: "مديرين المشروع",
    roles: "الأدوار",
    zones: "المناطق",
    requirements: "المتطلبات",
    comments: "التعليقات",
    agreementFile: "ملف الاتفاقية",
    projectManager: "مدير المشروع",
    role: "الدور",
    zone: "المنطقة",
    requirement: "المتطلبات",
    publishModalConfirmMessage:
      "برجاء الانتباه انه عند نشر الفعاليه سوف يتم عرضها في قائمه الفعاليات القادمه علي تطبيق الموبيل",
    publishPrivateConfirmMsg:
      "يرجى ملاحظة أنه حدث خاص ، يجب دعوة المتقدمين من قبل المسؤولين!",
    confirm: "تأكيد",
    cancel: "إلغاء",
    optional: "(إختياري)",
    labels: {
      uploadLabel: "رفع صورة",
      eventImage: "صورة الفعالية",
      eventName: "اسم الفعالية",
      eventBrief: "نبذة عن الفعالية",
      eventLocation: "موقع الفعالية",
      organizerScanning: "مسح كود المنظم",
      supervisorScanning: "مسح كود المشرف",
      supervisorApproval: "موافقة المشرف",
      basicVerification: "التحقق الأساسي",
      diameter: "حدود الموقع (بالمتر)",
      eventType: "نوع الفعالية",
      publishingMode: "وضع النشر",
      startBuildUpStart: "تاريخ و وقت بدء البناء",
      startBuildUpEnd: "تاريخ و وقت انتهاء البناء",
      eventDayStart: "تاريخ و وقت بدء الفعالية",
      eventDayEnd: "تاريخ و وقت إنتهاء الفعالية",
      dismantlingDayStart: "تاريخ و وقت بدء التفكيك",
      dismantlingDayEnd: "تاريخ و وقت إنتهاء التفكيك",
      pmName: "اسم مدير المشروع",
      responsibility: "المسئولية",
      nameOfRole: "اسم الدور",
      numberOfMales: "عدد الذكور",
      numberOfFemales: "عدد الاناث",
      numberOfShifts: "عدد المناوبات",
      payrollAmount: "مبلغ الراتب",
      workingHours: "عدد ساعات العمل",
      uniformCode: "كود الزى الموحد",
      timeToArriveToTheSite: "وقت الوصول الي الموقع",
      requiredTraining: "التدريب المطلوب",
      zoneName: "اسم المنطقة",
      zoneManager: "مدير المنطقة",
      managerName: "إسم المدير",
      zoneManagerLabel: "اسم مدير المنطقة",
      email: "البريد الالكتروني",
      mobileNumber: "رقم الهاتف المحمول",
      supervisorName: "أسم المشرف",
      supervisorInfo: "معلومات المشرف",
      managerInfo: "معلومات مدير المنطقة",
      zonesMap: "خريطة المناطق",
      nameOfRequirement: "اسم المتطلب",
      quantity: "الكمية",
      explanation: "التوضيح",
      uploadFile: "رفع ملف",
      checkInTimeMargin: "الهامش الزمني لتسجيل الوصول",
      checkOutTimeMargin: "الهامش الزمني لتسجيل المغادرة",
      checkinStart: "قبل بداية وقت العمل (بالدقائق)",
      checkinEnd: "بعد بداية وقت العمل (بالدقائق)",
      checkoutStart: "قبل انتهاء وقت العمل (بالدقائق)",
      checkoutEnd: "بعد انتهاء وقت العمل (بالدقائق)",
    },
    placeholders: {
      eventName: "ادخل اسم الفعالية",
      eventBrief: "ادخل نبذة عن الفعالية",
      eventLocation: "اختر موقع الفعالية",
      diameter: "اختر حدود الموقع",
      eventType: "اختر نوع الفعالية",
      startBuildUpStart: "تاريخ و وقت بدء البناء",
      startBuildUpEnd: "تاريخ و وقت انتهاء البناء",
      eventDayStart: "تاريخ و وقت بدء الفعالية",
      eventDayEnd: "تاريخ و وقت إنتهاء الفعالية",
      dismantlingDayStart: "تاريخ و وقت بدء التفكيك",
      dismantlingDayEnd: "تاريخ و وقت إنتهاء التفكيك",
      pmName: "ادخل اسم مدير المشروع",
      responsibility: "ادخل المسئولية",
      nameOfRole: "اختر اسم الدور",
      numberOfMales: "ادخل عدد الذكور",
      numberOfFemales: "ادخل عدد الاناث",
      numberOfShifts: "ادخل عدد المناوبات",
      payrollAmount: "ادخل مبلغ الراتب",
      workingHours: "ادخل عدد ساعات العمل",
      uniformCode: "ادخل كود الزى الموحد",
      timeToArriveToTheSite: "اختر وقت الوصول الى الموقع",
      requiredTraining: "ادخل التدريب المطلوب",
      zoneName: "ادخل اسم المنطقة",
      zoneManager: "ادخل مدير المنطقة",
      email: "ادخل البريد الالكترونى",
      mobileNumber: "ادخل رقم الهاتف المحمول",
      nameOfRequirement: "ادخل اسم المتطلب",
      quantity: "ادخل الكمية",
      explanation: "ادخل التوضيح",
      enterYourComments: "ادخل تعليقاتك",
      selectSupervisor: "أختر مشرف",
      checkinStart: "قبل بداية وقت العمل",
      checkinEnd: "بعد بداية وقت العمل",
      checkoutStart: "قبل انتهاء وقت العمل",
      checkoutEnd: "بعد انتهاء وقت العمل",
    },
    singleDayEvent: "فعالية ليوم واحد",
    daily: "يومي",
    monthly: "شهري",
    public: "عام",
    private: "خاص",
    add: "إضافة",
    continue: "تابع",
    saveLater: "احفظ وأكمل لاحقا",
    start: "بداية",
    end: "نهاية",
    save: "حفظ",
    saveAndPublish: "حفظ ونشر",
    selectLocationOnMap:
      "برجاء إختيار موقع على الخريطة حتى تتمكن من نشر الفعالية",
    addAtListOnePm:
      "برجاء إضافة مدير مشروع واحد على الأقل حتى تتمكن من نشر الفعالية",
    addAtListOneRole:
      "برجاء إضافة دور واحد على الأقل حتى تتمكن من نشر الفعالية",
    addSlot: "برجاء إضافة الوقت لنشر الفعالية",
    addAtListOneZone:
      "برجاء إضافة منطقة واحدة على الأقل حتى تتمكن من نشر الفعالية",
    addAtListOneRequirement:
      "برجاء إضافة متطلب واحد على الأقل حتى تتمكن من نشر الفعالية",
    addAtListOnePmEditMode: "برجاء إضافة مدير مشروع واحد على الأقل",
    addAtListOneRoleEditMode: "برجاء إضافة دور واحد على الأقل",
    addAtListOneZoneEditMode: "برجاء إضافة منطقة واحدة على الأقل",
    addAtListOneRequirementEditMode: "برجاء إضافة متطلب واحد على الأقل",
    saveContinue: "حفظ ومتابعة",
    noPhoneProvided: "لم يتم توفير رقم هاتف لهذا الموظف",
  },
  listEvents: {
    listEventsBreadcrumb: "قائمة الفعاليات",
    eventType: "نوع الفاعليه",
    startDate: "تاريخ البدء",
    endDate: "تاريخ الانتهاء",
    details: "تفاصيل",
    projectManager: "مدير مشروع",
    searchPlaceHolder: "ابحث عن فعالية",
    projectManagers: "مديري المشروعات",
    type: "نوع",
    publishMode: "وضع النشر",
    daily: "يومي",
    monthly: "شهري",
    noDataDescription: "عذرا لاتوجد فعاليات",
    eventTabs: {
      ongoing: "الحاليه",
      upcoming: "القادمه",
      previous: "مكتمل",
      pending: "قيد الانتظار",
      cancelled: "ملغيه",
    },
    eventStatus: {
      ongoing: "الحاليه",
      upcoming: "القادمه",
      past: "مكتمل",
      pending: "قيد الانتظار",
      canceled: "ملغيه",
    },
  },
  viewEvents: {
    diameter: "حدود الموقع",
    viewEventsBreadcrumb: "تفاصيل الفعالية",
    deleteBtn: "حذف الفعالية",
    cancelBtn: "إلغاء الفعالية",
    editBtn: "تعديل الفعالية",
    basicInfo: "معلومات أساسية",
    projectManagers: "مديرين المشروع",
    roles: "الأدوار",
    zones: "المناطق",
    requirementsAndComments: "المتطلبات والتعليقات",
    add: "إضافة",
    edit: "تعديل",
    noLocation: "لم يتم تحديد الموقع بعد",
    noImage: "لم يتم إختيار صورة بعد",
    noProjectManagers: "لم يتم إضافة مديري المشروع",
    noRoles: "لم يتم إضافة أدوار",
    noZones: "لم يتم إضافة مناطق",
    noRequirements: "لم يتم إضافة متطلبات",
    managerInfo: "معلومات المدير",
    managerEmail: "البريد الإلكتروني",
    managerPhone: "رقم الهاتف المحمول",
    supervisorInfo: "معلومات المشرف",
    supervisorName: "إسم المشرف",
    applicants: "المتقدمين",
    totalNumberOfApplicants: "إجمالي عدد المتقدمين",
    viewApplicantsList: "قائمة المتقدمين",
    applicantsTab: {
      supervisor: "مشرف",
      others: "الوظائف الاخرى",
      status: "الحالة",
      gender: "الجنس",
      zone: "المنطقه",
      role: "الوظيفه",
      name: "الاسم",
      email: "البريد الالكتروني",
      note: "ملاحظة",
      placeholders: {
        supervisor: "ابحث باسم المشرف",
        others: "ابحث باستخدام اسم المتقدم او اسم المشرف",
        noData: "لا يوجد متقدمين مقبولين حاليا",
        noSearchResults: "عذرا لا يوجد متقدمين",
      },
      assign: "تحديد",
      assigned: "تعيين",
      notAssigned: "غير محدد",
      unassign: "إلغاء تعيين",
      reallocate: "إعادة تعيين",
      unassignApplicant: "إلغاء تعيين موقع مقدم الطلب",
      reallocateApplicant: "إعادة تعيين مقدم الطلب إلى موقع آخر",
      assignApplicant: "تعيين موقع مقدم الطلب",
      unassignConfirmationMsg: "هل أنت متأكد بإلغاء تفعيل منطقه مقدم الطلب ؟",
      inviteMoreApplicants: "ادعو المزيد من المتقدمين",
      inviteApplicants: "ادعو المتقدمين",
      invite: "ادعو",
      searchByApplicant: "ابحث باستخدام اسم المتقدم",
      hint: "لتغيير الوظيفه قبل دعوة المتقدمين برجاء التغيير من قائمه الوظائف",
      selectFile: "حدد الحقول لتصدير CSV",
      export: "تصدير",
    },
    sending: "إرسال",
    sendNotification: "إرسال إشعار",
    messageRequired: "الرسالة مطلوبة",
    pushNotification: "إشعار دفع",
    sendPushNotification: "إرسال إشعار دفع",
    notificationSend: "تم إرسال الإشعار",
    submitEventPushNotification: "هل أنت متأكد أنك تريد إرسال الإشعار؟",
    cancelModalConfirmMessage: "هل أنت متأكد أنك تريد إلغاء هذه الفعالية؟",
    deleteModalConfirmMessage:
      "الفعالية المحذوفة لا يمكن إسترجاعها , هل أنت متأكد أنك تريد حذف هذه الفعالية ؟",
    completedEvaluationBtn: "ابدأ جولة التقييم",
    startEvaluationRound: "هل انت متاكد انك تريد بدء جولة تقييم ل ",
    disbaledCreateEvaluationBtnHint: "الزر معطل لان أيام التفكيك قد بدأت",
    checkedinApplicants: "سجلوا الدخول",
    noncheckedinApplicants: "لم يسجلوا الدخول",
    checkinStatus: "حالة تسجيل الدخول",
    checkedIn: "سجل الدخول",
    nonCheckedIn: "لم يسجل الدخول",
  },
};

export default events;
