import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "@mui/material";

import {
  AlertBarBgShapeIcon,
  AlertBarInfoIcon,
  AlertBarNotificationsIcon,
} from "../../utils/Icons";
import messages from "../../assets/locale/messages";
import { showHideNotificationsSupportAlert } from "../../store/Snackbar/actions";
import "./NotificationSupportAlert.scss";

const NotificationSupportAlert = () => {
  const lang = useSelector((state) => state.locale.lang);
  const showNotificationsAlert = useSelector(
    (state) => state.snackbar.showNotificationsSupportAlert
  );
  const { notifications } = messages[lang];

  const dispatch = useDispatch();

  return (
    showNotificationsAlert && (
      <div className="notifications-support-alert-wrapper mt-4">
        <Alert
          severity="error"
          className={`notifications-support-alert ${
            lang === "en" ? "ps-5" : "pe-5"
          }`}
          icon={<AlertBarNotificationsIcon />}
          onClose={() => {
            dispatch(showHideNotificationsSupportAlert(false));
          }}>
          {notifications.notificationsSupportAlert}
        </Alert>
        <AlertBarBgShapeIcon className="bg-shape" />
        <AlertBarInfoIcon className="info-icon" />
      </div>
    )
  );
};

export default NotificationSupportAlert;
