import React, { useState } from "react";
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import PropTypes from "prop-types";

import "./Accordion.scss";

const Accordion = ({
  title,
  content,
  extraFunctionBtn,
  accordionNumber,
  isExpandedInitially,
}) => {
  const [expanded, setExpanded] = useState(
    isExpandedInitially ? accordionNumber : 0
  );
  const handleChange = (index) => (event, newExpanded) => {
    setExpanded(newExpanded ? index : false);
  };

  return (
    <div>
      <MuiAccordion
        expanded={expanded === accordionNumber}
        onChange={handleChange(accordionNumber)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize="large" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="d-flex justify-content-between align-items-center w-100">
            <Typography>{title}</Typography>
            <div className="mx-3 extra-function-btn">{extraFunctionBtn}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails>{content}</AccordionDetails>
      </MuiAccordion>
    </div>
  );
};

export default Accordion;

Accordion.propTypes = {
  title: PropTypes.string,
  accordionNumber: PropTypes.number,
  content: PropTypes.element,
  isExpandedInitially: PropTypes.bool,
  extraFunctionBtn: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
