import React from "react";
import { Avatar, AvatarGroup } from "@mui/material";
import PropTypes from "prop-types";

import { getNamesInitials } from "../../utils/Helpers";
import "./AvatarGroup.scss";

const GroupAvatars = ({ maxDisplyedWithLastOneNumber, dataList }) => (
  <AvatarGroup max={maxDisplyedWithLastOneNumber} className="avatars-container">
    {dataList.map(({ img, name }, index) =>
      img ? (
        <Avatar alt="avatar image" key={`avatar-${index}`} src={img} />
      ) : (
        <Avatar key={`avatar-${index}`}>{getNamesInitials(name)}</Avatar>
      )
    )}
  </AvatarGroup>
);

export default GroupAvatars;

GroupAvatars.propTypes = {
  maxDisplyedWithLastOneNumber: PropTypes.number,
  dataList: PropTypes.array,
};
