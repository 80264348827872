import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FilterList } from "@mui/icons-material";

import Button from "../../Button";
import messages from "../../../assets/locale/messages";
import "./FilterLabel.scss";

const FilterLabel = ({ onClick }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];
  return (
    <div className="filter-toggle-btn text-end">
      <Button
        label={
          <span className="d-flex align-items-center">
            <FilterList fontSize="large" />
            <span className="px-1 label">{shared.filter}</span>
          </span>
        }
        labelClass="text-secondary text-nowrap"
        className={`border filter-btn`}
        onClick={onClick}
        outlined
      />
    </div>
  );
};

FilterLabel.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default FilterLabel;
