import { SHOW_HIDE_SNACKBAR, SHOW_NOTIFICATIONS_SUPPORT_ALERT } from "./types";

export const showHideSnackbar = (payload) => ({
  type: SHOW_HIDE_SNACKBAR,
  payload,
});
export const showHideNotificationsSupportAlert = (payload) => ({
  type: SHOW_NOTIFICATIONS_SUPPORT_ALERT,
  payload,
});
