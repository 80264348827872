import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import {
  getEmployeeRequest,
  getEmployeeResponse,
} from "../../../store/Employees/actions";
import Breadcrumbs from "../../../components/BreadCrumbs";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import messages from "../../../assets/locale/messages";
import Button from "../../../components/Button";
import ViewInputData from "../../../components/ViewInputData";
import Avatar from "../../../components/Avatar";
import { isSuperAdmin } from "../../../utils/Permissions";
import "../Employees.scss";

const ViewEmployee = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const lang = useSelector((state) => state.locale.lang);
  const userRole = useSelector((state) => state.auth.login.role.name);
  const userId = useSelector((state) => state.auth.login.id);
  const employee = useSelector((state) => state.employees.employee);

  const { employees, changePassword } = messages[lang];

  useEffect(() => {
    if (id) {
      dispatch(getEmployeeRequest(id));
    } else if (!isSuperAdmin()) {
      dispatch(getEmployeeRequest(userId));
    }
  }, [id, userRole, userId]);

  useEffect(() => {
    return () => {
      id && dispatch(getEmployeeResponse(null));
    };
  }, []);

  return (
    <div className="employee-details-page-container">
      <div className="w-100 page-title-margin d-flex justify-content-between align-items-center flex-column flex-md-row">
        <div className="d-flex justify-content-start w-100">
          {id ? (
            <Breadcrumbs
              list={[
                {
                  path: ROUTE_PATHS.employees,
                  label: employees.employeesList,
                },
                {
                  path: "",
                  label: employees.viewEmployee,
                },
                {
                  path: "",
                  label: employee?.name,
                },
              ]}
              BreadCrumbHasBackIcon={false}
            />
          ) : (
            <Breadcrumbs
              list={[
                {
                  path: "",
                  label: employees.profile,
                },
              ]}
              BreadCrumbHasBackIcon={false}
            />
          )}
        </div>

        <div className=" btn-container d-flex justify-content-lg-between justify-content-end align-items-center">
          {!id && (
            <Button
              type="button"
              onClick={() => {
                history.push(ROUTE_PATHS.resetPassword);
              }}
              label={changePassword.resetPassword}
              className={" mx-3 reset-btn"}
              outlined
              small
            />
          )}
          {!id && (
            <Button
              type="button"
              onClick={() => {
                history.push(ROUTE_PATHS.editProfile);
              }}
              label={employees.editProfile}
              className="btn-block"
              outlined
              positive
              small
            />
          )}
        </div>
      </div>
      <div>
        <div className="bg-white p-4 mb-md-5 mb-3">
          <div className="row w-100">
            <div className="col-12 col-md-2 col-xl-1 mb-3 mb-md-0">
              <Avatar
                imgSrcAlt="employee name"
                imgSrc={employee?.image_url}
                name={employee?.name}
                size={70}
              />
            </div>
            <div className="col-12 col-md-10 col-xl-11">
              <p className="fsize-20 fweight-500 mb-0 capitalize">
                {employee?.name}
              </p>
              <p className="fsize-16 mb-0 text-black-50 pb-2 capitalize">
                {employee?.role?.name}
              </p>
              <ViewInputData
                label={employees.email}
                content={employee?.email}
              />
              <ViewInputData
                label={employees.mobileNumber}
                content={
                  employee?.phone_number ? (
                    <div className="phone-number-cell">
                      <span>{employee?.dial_code}</span>
                      <span className="mx-1">{employee?.phone_number}</span>
                    </div>
                  ) : null
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEmployee;
