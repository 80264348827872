import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";

import {
  setCreateEventFormStep,
  setCreatedEventId,
  setActivePublishMode,
} from "../../../store/Routing/actions";
import {
  getEventRequest,
  getEventResponse,
  setInitialPublishValidationStep,
} from "../../../store/Events/actions";
import {
  getLookupsRequest,
  getProjectManagersRequest,
} from "../../../store/Lookups/actions";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import messages from "../../../assets/locale/messages";
import { eventTypes, lookupsKeys } from "../../../utils/Constants";
import Stepper from "../../../components/Stepper";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import { isSuperAdmin } from "../../../utils/Permissions";
import StepThree from "./StepThree";

const CreateEvent = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  let location = useLocation();
  const history = useHistory();

  const lang = useSelector((state) => state.locale.lang);
  const step = useSelector((state) => state.routing.currentCreateEventFormStep);
  const activeFormStep = useSelector(
    (state) => state.routing.currentCreateEventFormStep
  );
  const createdEventId = useSelector((state) => state.routing.createdEventId);
  const event = useSelector((state) => state.events.event);
  const activePublishMode = useSelector(
    (state) => state.routing.activePublishMode
  );
  const isInitialStep = useSelector((state) => state.events.isInitialStep);
  const { creatEvent } = messages[lang].events;

  const [activeStep, setActiveStep] = useState(activeFormStep || 0);

  useEffect(() => {
    setActiveStep(activeFormStep);
  }, [activeFormStep]);

  useEffect(() => {
    if (createdEventId) {
      dispatch(getEventRequest(createdEventId));
    }
  }, [createdEventId, activeFormStep]);

  useEffect(() => {
    if (id) {
      dispatch(getEventRequest(id));
    }
  }, [id, activeFormStep]);

  useEffect(() => {
    if (id) {
      dispatch(getEventRequest(id));
    }
    if (!isSuperAdmin() && location.pathname === ROUTE_PATHS.createEvent) {
      history.push(ROUTE_PATHS.events);
    }
    return () => {
      dispatch(setCreatedEventId(null));
      dispatch(getEventResponse(null));
      dispatch(setActivePublishMode(false));
    };
  }, []);

  useEffect(() => {
    dispatch(getLookupsRequest([lookupsKeys.roles, lookupsKeys.requirements]));
    dispatch(getProjectManagersRequest({ page: -1 }));
  }, [lang]);

  useEffect(() => {
    dispatch(setCreateEventFormStep(step));

    return () => {
      dispatch(setCreateEventFormStep(0));
    };
  }, [step]);

  useEffect(() => {
    if (event && activePublishMode && isInitialStep) {
      if (
        !event.image_url ||
        !event.payment_period ||
        (!event.building_start_at &&
          !!(
            event?.building_end_at ||
            event?.dismantling_start_at ||
            event?.dismantling_end_at
          )) ||
        (!event.building_end_at &&
          !!(
            event?.building_start_at ||
            event?.dismantling_start_at ||
            event?.dismantling_end_at
          )) ||
        !event.event_start_at ||
        !event.event_end_at ||
        (!event.dismantling_start_at &&
          !!(
            event?.dismantling_end_at ||
            event?.building_start_at ||
            event?.building_end_at
          )) ||
        (!event.dismantling_end_at &&
          !!(
            event?.dismantling_start_at ||
            event?.building_start_at ||
            event?.building_end_at
          )) ||
        !event.address ||
        !event.event_radius ||
        !event.lat ||
        !event.lng ||
        !event.attendance_verification_method ||
        !event?.project_managers?.length
      ) {
        dispatch(setCreateEventFormStep(0));
      } else if (!event.event_roles.length) {
        dispatch(setCreateEventFormStep(1));
      } else if (event.event_roles.length) {
        let isMissingData = false;
        event.event_roles.forEach((role) => {
          if (
            !role.id ||
            !role.maximum_number_of_males ||
            !role.maximum_number_of_females ||
            // TODO: Uncomment when needed again
            // !role.number_of_shifts ||
            // !role.arriving_at ||
            (!role.payroll_amount && role.payroll_amount != 0) ||
            (((!role.before_check_in_margin &&
              role.before_check_in_margin != 0) ||
              (!role.after_check_in_margin &&
                role.after_check_in_margin != 0) ||
              (!role.before_check_out_margin &&
                role.before_check_out_margin != 0) ||
              (!role.after_check_out_margin &&
                role.after_check_out_margin != 0)) &&
              event.payment_period === eventTypes.daily)
          ) {
            isMissingData = true;
          }
          role.working_hours_intervals.forEach((time) => {
            if (!time.ends_at || !time.starts_at) {
              isMissingData = true;
            }
          });
        });
        if (isMissingData) {
          dispatch(setCreateEventFormStep(1));
        } else {
          dispatch(setCreateEventFormStep(2));
        }
      } else {
        dispatch(setCreateEventFormStep(2));
      }
      dispatch(setInitialPublishValidationStep(false));
    }
  }, [event, activePublishMode, isInitialStep]);

  const steps = [
    {
      id: "step-1",
      label: creatEvent.stepOne,
      clickAction: () => {
        id || createdEventId ? dispatch(setCreateEventFormStep(0)) : null;
      },
    },
    {
      id: "step-2",
      label: creatEvent.stepTwo,
      clickAction: () => {
        id || createdEventId ? dispatch(setCreateEventFormStep(1)) : null;
      },
    },
    {
      id: "step-3",
      label: creatEvent.stepThree,
      clickAction: () => {
        id || createdEventId ? dispatch(setCreateEventFormStep(2)) : null;
      },
    },
  ];
  return (
    <div className="createEvent-page-container pb-3">
      <div className="d-flex justify-content-center page-title-margin stepper-container">
        <Stepper
          steps={steps}
          activeStep={activeStep}
          WrapperClasses={id || createdEventId ? "pointer" : ""}
        />
      </div>
      <div className="steps-content-container">
        {activeStep === 0 && <StepOne />}
        {activeStep === 1 && <StepTwo />}
        {activeStep === 2 && <StepThree />}
      </div>
    </div>
  );
};

export default CreateEvent;
