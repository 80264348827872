import { call, put, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/note";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";
import * as types from "./types";
import {
  createNoteResponse,
  getNoteResponse,
  updateNoteResponse,
} from "./action";

function* createNoteSaga({ payload }) {
  try {
    const response = yield call(apis.createNote, payload);
    yield put(createNoteResponse(response.data.data));
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* getNoteSaga({ payload }) {
  try {
    const response = yield call(apis.getNote, payload.id);
    yield put(getNoteResponse(response.data.data));
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* deleteNoteSaga({ payload }) {
  try {
    yield call(apis.deleteNote, payload.id);
    yield showSuccessMsg("NoteDeletedSuccessfully");
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* updateNoteSaga({ payload }) {
  try {
    const response = yield call(apis.updateNote, payload);
    yield put(updateNoteResponse(response.data.data));
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

export default function* NoteSagas() {
  yield takeEvery(types.CREATE_NOTE_REQUEST, createNoteSaga);
  yield takeEvery(types.GET_NOTE_REQUEST, getNoteSaga);
  yield takeEvery(types.DELETE_NOTE_REQUEST, deleteNoteSaga);
  yield takeEvery(types.UPDATE_NOTE_REQUEST, updateNoteSaga);
}
