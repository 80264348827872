import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import messages from "../../../assets/locale/messages";
import { deleteApplicantTimeSheetRequest } from "../../../store/ApplicantsTimeSheet/actions";
import PropTypes from "prop-types";

function DeleteApplicantReportModal({
  open,
  selectedItem,
  eventId,
  userId,
  handleClose,
  isDaily,
  dateParam,
}) {
  const [openStatusModal, setOpenActionModal] = useState(false);

  const lang = useSelector((state) => state.locale.lang);
  const { timeSheet, shared } = messages[lang];

  const dispatch = useDispatch();

  useEffect(() => {
    setOpenActionModal(open);
  }, [open]);

  const handleConfirmModalAction = () => {
    dispatch(
      deleteApplicantTimeSheetRequest({
        id: selectedItem?.id,
        userId,
        eventId,
        isDaily,
        dateParam,
      })
    );

    setOpenActionModal(false);
  };
  const handleCloseModal = () => {
    handleClose(false);
    setOpenActionModal(false);
  };

  return (
    <div>
      <Modal
        open={openStatusModal}
        handleClose={handleCloseModal}
        handleCancel={handleCloseModal}
        content={timeSheet.deleteMsg}
        hasActions
        confirmBtnTxt={shared.confirm}
        cancelBtnTxt={shared.cancel}
        handleConfirm={handleConfirmModalAction}
      />
    </div>
  );
}

export default DeleteApplicantReportModal;

DeleteApplicantReportModal.propTypes = {
  open: PropTypes.bool,
  eventId: PropTypes.string,
  userId: PropTypes.string,
  selectedItem: PropTypes.string,
  handleClose: PropTypes.func,
  isDaily: PropTypes.bool,
  dateParam: PropTypes.string,
};
