import React from "react";
import { useSelector } from "react-redux";

import messages from "../../../../assets/locale/messages";
import RichText from "../../../../components/RichText";
import CreateFormSectionTitle from "../../CreateFormSectionTitle";
import ViewRequirementsFields from "./ViewRequirementsFields";
import DynamicFiledsPlaceholder from "../DynamicFiledsPlaceholder";
import "../ViewEvent.scss";

const RequirementsAndCommentsTab = () => {
  const lang = useSelector((state) => state.locale.lang);
  const event = useSelector((state) => state.events.event);
  const { events } = messages[lang];

  return (
    <>
      <div className="mb-4 step-part-content-wrapper">
        <div className="mb-4 d-flex justify-content-between top-wrapper">
          <CreateFormSectionTitle title={events.creatEvent.requirements} />
        </div>

        {event?.event_requirements?.length == 0 ? (
          <DynamicFiledsPlaceholder
            content={events.viewEvents.noRequirements}
          />
        ) : (
          event?.event_requirements?.map((requirement, index) => (
            <ViewRequirementsFields
              requirement={requirement}
              key={`requirement-${index}`}
            />
          ))
        )}
      </div>
      <div className="mb-3 step-part-content-wrapper">
        <div className="mb-4 d-flex justify-content-between top-wrapper">
          <CreateFormSectionTitle title={events.creatEvent.comments} />
        </div>
        <RichText
          value={event?.comments}
          noData={!event?.comments}
          readOnly
          hideToolBar
        />
      </div>
    </>
  );
};

export default RequirementsAndCommentsTab;
