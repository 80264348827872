import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Divider, Rating } from "@mui/material";
import { Block } from "@mui/icons-material";

import Button from "../../components/Button";
import RichText from "../../components/RichText";
import Breadcrumbs from "../../components/BreadCrumbs";
import Modal from "../../components/Modal";
import messages from "../../assets/locale/messages";
import Avatar from "../../components/Avatar";
import Rate from "../../components/Rate";
import {
  viewFormRequest,
  submitEvaluationEventRequest,
} from "../../store/Evaluations/actions";
import { convertHtmlToText } from "../../utils/Helpers";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { getApplicantRequest } from "../../store/applicants/actions";
import "./Evaluations.scss";

const EvaluationForm = () => {
  const location = useLocation();
  const { formid, id: idParam } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const userType = localStorage.getItem("userType");

  const formData = useSelector((state) => state.evaluations.formData);
  const lang = useSelector((state) => state.locale.lang);
  const { evaluations, shared, applicants } = messages[lang];
  const applicant = useSelector((state) => state.applicants.applicant);

  const [openSubmitModal, setOpenSubmitModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [prevBreadCrumbs, setPrevBreadCrumbs] = useState({});
  const [skillsRate, setSkillsRate] = useState([]);
  const [comment, setComment] = useState(null);
  const [isInProgress, setIsInProgress] = useState(false);
  const [isFromApplicants, setIsFromApplicants] = useState(false);

  useEffect(() => {
    if (isFromApplicants) {
      dispatch(getApplicantRequest({ id: idParam }));
    }
  }, [idParam, lang, isFromApplicants]);

  useEffect(() => {
    const pathnameArr = location?.pathname.split("/");

    setIsInProgress(pathnameArr.includes("in-progress"));
    setIsFromApplicants(pathnameArr.includes("applicants"));

    const mode = pathnameArr[pathnameArr.length - 1];
    setIsEdit(mode === "edit");

    if (location?.state?.secondBreadcrumb) {
      setPrevBreadCrumbs(location?.state?.secondBreadcrumb);
    }
  }, [location]);

  useEffect(() => {
    if (formid) {
      dispatch(viewFormRequest(formid));
    }
  }, [formid, lang]);

  const onRatingChange = (id, rate) => {
    const skillsRateCopy = [...skillsRate];
    const skillIndex = skillsRateCopy.findIndex((elt) => elt.id === id);
    if (skillIndex !== -1) {
      if (rate) {
        skillsRateCopy.splice(skillIndex, 1, { id, rate });
      } else {
        skillsRateCopy.splice(skillIndex, 1);
      }
    } else {
      skillsRateCopy.push({ id, rate });
    }
    setSkillsRate(skillsRateCopy);
  };

  const getSkillRate = (id) => {
    const skill = skillsRate.find((elt) => elt.id === id);
    return skill ? skill?.rate : 0;
  };

  const handleConfirmModalAction = () => {
    dispatch(
      submitEvaluationEventRequest({
        redirectionPath: isFromApplicants
          ? ROUTE_PATHS.viewApplicantEvents.replace(":id", idParam)
          : isInProgress
          ? ROUTE_PATHS.inprogressEvaluations.replace(":id", idParam)
          : ROUTE_PATHS.completedEvaluations.replace(":id", idParam),
        evaluation_name: prevBreadCrumbs.evaluationName,
        id: formid,
        data: {
          comment,
          evaluation_form_questions_attributes: [...skillsRate],
        },
      })
    );
    setOpenSubmitModal(false);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const areAllSkillsRated = () => {
    let disable = false;
    const originalRatesIds = formData?.evaluation_form_questions?.map(
      (ques) => ques.id
    );
    originalRatesIds?.forEach((id) => {
      const targetSkill = skillsRate.find((elt) => elt.id === id);
      if (!targetSkill) disable = true;
      return;
    });
    return disable;
  };

  const applicantsBreadCrumbs = [
    {
      path: ROUTE_PATHS.applicants,
      label: applicants.listApplicants.listApplicantsBreadcrumb,
    },
    {
      path: ROUTE_PATHS.viewApplicant.replace(":id", idParam),
      label: applicant?.["full_name_" + lang],
    },
    {
      path: ROUTE_PATHS.viewApplicantEvents.replace(":id", idParam),
      label: applicants.applicantDetails.events,
    },
    {
      path: "",
      label: `${location?.state?.applicantEventName} - ${applicants.applicantEvents.evaluationForm}`,
    },
  ];

  const evaluationsBreadCrumbs = [
    {
      path: ROUTE_PATHS.evaluations,
      label: evaluations.listForms.title,
    },
    {
      path: prevBreadCrumbs.path,
      label: `${prevBreadCrumbs.evaluationName} - ${
        evaluations.statusOptions[prevBreadCrumbs.status]
      }`,
      isClicked: true,
      onclick: () =>
        history.push({
          pathname: prevBreadCrumbs.path,
          state: {
            evaluation_name: prevBreadCrumbs.evaluationName,
          },
        }),
    },
    {
      path: "",
      label: formData?.applicant
        ? formData?.applicant?.[lang === "en" ? "full_name_en" : "full_name_ar"]
        : applicants.applicantEvents.evaluationForm,
    },
  ];

  return (
    <div className="form-wrapper evaluation-form-container">
      <div className="w-100 page-title-margin d-flex justify-content-between align-items-center">
        <Breadcrumbs
          list={
            isFromApplicants ? applicantsBreadCrumbs : evaluationsBreadCrumbs
          }
          BreadCrumbHasBackIcon={false}
        />
      </div>
      <div className="bg-white p-4 rounded-2 mb-4">
        <div className="row">
          <div className="title-wrapper col-12 mb-4">
            <span></span>
            <h2 className="mb-0">
              {evaluations.evaluationsForm.applicantInfo}
            </h2>
          </div>

          <div className="col-12 col-md-2 col-xl-1 mb-3 mb-md-0">
            <Avatar
              imgSrcAlt="user name"
              imgSrc={formData?.applicant?.image_url}
              name={formData?.applicant?.full_name_en}
              size={70}
            />
          </div>
          <div className="col-12 col-md-10 col-xl-11">
            <p className="applicant-name fsize-18 fweight-600 mb-0 d-flex align-items-center">
              {
                formData?.applicant?.[
                  lang === "en" ? "full_name_en" : "full_name_ar"
                ]
              }
              {formData?.applicant?.is_blocked && (
                <Block color="error" className="mx-3" fontSize="large" />
              )}
            </p>
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <div className="d-flex mb-4 mb-md-0 mt-3">
                {!isEdit && (
                  <>
                    <div className="rating-container  pe-sm-s">
                      <div className={`${lang === "en" ? "me-3" : ""}`}>
                        <p className="mb-0 fweight-600 fsize-16 d-flex">
                          <Rate
                            rating={
                              formData?.average_rate
                                ? formData?.average_rate.toFixed(2)
                                : 0
                            }
                          />
                        </p>
                      </div>
                    </div>
                    <Divider
                      className="divider-element"
                      orientation="vertical"
                      flexItem
                    />
                  </>
                )}
                <div>
                  <span
                    className={`${
                      isEdit ? (lang === "en" ? "me-3" : "ms-3") : "mx-3"
                    } fsize-14 text-black-50`}>
                    {formData?.role?.name}
                  </span>
                </div>
                {userType === "admin" && (
                  <>
                    <Divider
                      className="divider-element"
                      orientation="vertical"
                      flexItem
                    />
                    <div className="px-2 px-sm-4">
                      <span className="fsize-14  text-black-50">
                        {formData?.zone?.name}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            {!isEdit && (
              <div className="mt-3">
                <p className="fsize-14  text-black-50">
                  {evaluations.evaluationsForm.submittedBy}{" "}
                  {formData?.submitter?.name}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <form noValidate>
        <div className="bg-white p-4 rounded-2 mb-4 evaluations-skills-wrapper">
          <div className="row">
            <div className="title-wrapper col-12 mb-4">
              <span></span>
              <h2 className="mb-0">
                {evaluations.evaluationsForm.evaluationSkills}
              </h2>
            </div>
            {formData?.evaluation_form_questions?.map(({ id, rate, body }) => (
              <div className="col-12 mb-3" key={id}>
                <div className="d-flex flex-wrap  align-items-center">
                  <span className="skill-title w-20">{body}:</span>
                  {/* <span dir="ltr"> */}
                  <Rating
                    className="rating-stars"
                    id={id}
                    name={id}
                    readOnly={!isEdit}
                    value={isEdit ? getSkillRate(id) : rate}
                    onChange={(_, newValue) => {
                      onRatingChange(id, newValue);
                    }}
                  />
                  {/* </span> */}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-white p-4 rounded-2 mb-4">
          <div className="row">
            <div className="title-wrapper col-12">
              <span></span>
              <h2 className="mb-0">{evaluations.evaluationsForm.comments}</h2>
            </div>
            <div className="col-12">
              <div>
                {isEdit ? (
                  <RichText
                    id="comments"
                    name="comments"
                    value={comment}
                    onChange={(value) => {
                      setComment(value);
                    }}
                    className="comments-rich-text"
                    maxLength={300}
                  />
                ) : (
                  <p className="comments mt-4">
                    {formData?.comment
                      ? convertHtmlToText(formData?.comment)
                      : evaluations.evaluationsForm.noComment}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {isEdit && (
          <div className="d-flex justify-content-end align-items-center buttom-btns-container">
            <Button
              label={shared.submit}
              type="button"
              labelClass="px-2 text-white py-1 "
              className="mb-4"
              onClick={() => {
                setOpenSubmitModal(true);
              }}
              disabled={areAllSkillsRated()}
            />
            <Button
              type="button"
              label={shared.cancel}
              labelClass="px-2 py-1"
              outlined
              className="mb-4 mx-2 cancel-btn"
              onClick={handleCancel}
            />
          </div>
        )}
      </form>
      <Modal
        open={openSubmitModal}
        handleClose={() => {
          setOpenSubmitModal(false);
        }}
        content={
          <div className="modal-content">
            <p>
              <span>{evaluations.evaluationsForm.confirmMsg}</span>
              <span className="mx-1 fweight-500">
                {
                  formData?.applicant?.[
                    lang === "en" ? "full_name_en" : "full_name_ar"
                  ]
                }
              </span>
              <span>{evaluations.evaluationsForm.evaluation}</span>
            </p>
          </div>
        }
        hasActions
        confirmBtnTxt={shared.save}
        cancelBtnTxt={shared.cancel}
        handleConfirm={handleConfirmModalAction}
        handleCancel={() => {
          setOpenSubmitModal(false);
        }}
      />
    </div>
  );
};

export default EvaluationForm;
