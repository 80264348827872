import React from "react";
import PropTypes from "prop-types";

export const TimesheetIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="20"
    fill="none"
    viewBox="0 0 35 35"
    {...props}>
    <path
      fill={props.selectedIcon ? "#448BCA" : "#6B778C"}
      d="M18.631 5.797h-2.333v12.987l8.43 4.007 1.002-2.108-7.099-3.373V5.797z"></path>
    <path
      fill={props.selectedIcon ? "#448BCA" : "#6B778C"}
      d="M17.5 0C7.835 0 0 7.835 0 17.5S7.835 35 17.5 35 35 27.165 35 17.5 27.165 0 17.5 0zm0 32.667c-8.376 0-15.167-6.79-15.167-15.167 0-8.376 6.79-15.167 15.167-15.167 8.376 0 15.167 6.79 15.167 15.167 0 8.376-6.79 15.167-15.167 15.167z"></path>
  </svg>
);

TimesheetIcon.propTypes = {
  selectedIcon: PropTypes.bool,
};
