import { put, call, takeEvery } from "redux-saga/effects";

import * as apis from "../../network/apis/promotionRequests";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";

function* getPromotionRequestsListSaga({ payload }) {
  try {
    const response = yield call(apis.getPromotionRequestsList, payload);
    yield put(
      actions.getPromotionRequestsResponse({
        list: response.data.data,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"],
        },
      })
    );
    if (+response.headers["current-page"] > +response.headers["total-pages"]) {
      yield put(
        actions.getPromotionRequestsRequest({
          ...payload,
          page: +response.headers["total-pages"],
        })
      );
    }
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* editPromotionRequestSaga({ payload }) {
  try {
    yield call(apis.editPromotionRequest, payload);
    yield showSuccessMsg(payload.data.status == "rejected" ? "rejectRequest" : "acceptRequest");
    const { page, user_name, status, user_role_id } = payload;
    yield put(
      actions.getPromotionRequestsRequest({
        page,
        items: 10,
        user_name,
        status,
        user_role_id,
        order_by_created_at: "desc",
      })
    );
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

export default function* PromotionRequestsSagas() {
  yield takeEvery(
    types.GET_PROMOTION_REQUESTS_REQUEST,
    getPromotionRequestsListSaga
  );
  yield takeEvery(
    types.EDIT_PROMOTION_REQUEST_REQUEST,
    editPromotionRequestSaga
  );
}
