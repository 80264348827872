import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "@mui/material/styles";

import history from "../routes/History";
import Routes from "../routes/Routes";
import { setCurrentLang } from "../store/Lang/actions";
import messages from "../assets/locale/messages";
import Theme from "../utils/Theme";
import Snackbar from "./../components/Snackbar";
import Loader from "./../components/Loader";
import Layout from "../components/Layout";
import {
  clearFirebaseToken,
  generateFirebaseToken,
  subscribeOnPushNotifications,
} from "../firebase/helpers";
import { onMessageListener } from "../firebase/firebase";
import "./App.scss";

const App = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const isAuth = useSelector((state) => state.auth.login?.auth_token);
  const [ThemeDir, setThemedir] = useState({ ...Theme });

  useEffect(() => {
    generateFirebaseToken();
    onMessageListener();
    const subscribtion = subscribeOnPushNotifications();
    return () => {
      clearFirebaseToken();
      subscribtion.unsubscribe();
    };
  }, []);

  useEffect(() => {
    dispatch(setCurrentLang(lang));
    document.dir = lang === "en" ? "ltr" : "rtl";
    setThemedir({ ...Theme, direction: lang === "en" ? "ltr" : "rtl" });
  }, [dispatch, lang]);

  return (
    <IntlProvider locale={lang} messages={messages[lang]} defaultLocale="ar">
      <ThemeProvider theme={ThemeDir}>
        <Router history={history}>
          <Layout isAuth={!!isAuth}>{Routes}</Layout>
        </Router>
        <Snackbar />
      </ThemeProvider>
      <Loader />
    </IntlProvider>
  );
};

export default App;
