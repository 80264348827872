import React from "react";
import PropTypes from "prop-types";

import "./ImagePlaceHolder.scss";

const ImagePlaceHolder = ({
  img,
  placeHolderText,
  wrapperClassName,
  megaContainerClasses,
}) => {
  return (
    <div className={`view-image-mega-container ${megaContainerClasses}`}>
      <div
        style={{ backgroundImage: `url(${img})` }}
        className={`image-placeholder ${wrapperClassName}`}
      >
        <div className="overlay"></div>
        <p className="placeholder-text fweight-400 text-center">
          {placeHolderText}
        </p>
      </div>
    </div>
  );
};

export default ImagePlaceHolder;

ImagePlaceHolder.propTypes = {
  img: PropTypes.any,
  placeHolderText: PropTypes.string,
  wrapperClassName: PropTypes.string,
  megaContainerClasses: PropTypes.string,
};
