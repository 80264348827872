import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

const useLocalStorageBCK = (keyName, defaultValue, basePath) => {
  const location = useLocation();
  const history = useHistory(); // react-router-dom v5
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value && location.pathname.startsWith(basePath)) {
        return JSON.parse(value);
      }
    } catch (err) {
      console.error(err);
    }
    return defaultValue;
  });

  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
      setStoredValue(newValue);
    } catch (err) {
      console.error(`Error setting localStorage key "${keyName}":`, err);
    }
  };

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (!location.pathname.startsWith(basePath)) {
        window.localStorage.removeItem(keyName);
        setStoredValue(defaultValue);
      }
    });

    return () => unlisten();
  }, [history, basePath, keyName, defaultValue]);

  return [storedValue, setValue];
};

export default useLocalStorageBCK;
