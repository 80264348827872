import { axiosInstance } from "./";

const getEventsList = async (params) =>
  await axiosInstance.get("/events", { params });

const addEvent = async ({ data }) => await axiosInstance.post(`/events`, data);

const getEvent = async (id) => await axiosInstance.get(`/events/${id}`);

const editEvent = async ({ data, id }) =>
  await axiosInstance.patch(`/events/${id}`, data);

const publishEvent = async (id) =>
  await axiosInstance.post(`/events/${id}/publish`);

const deleteEvent = async (id) => await axiosInstance.delete(`/events/${id}`);

const cancelEvent = async (id) =>
  await axiosInstance.post(`/events/${id}/cancel`);

const inviteApplicants = async (data) =>
  await axiosInstance.post(`/event_invitations`, data);

const checkEventConflicts = async ({ id, params }) =>
  await axiosInstance.get(`/events/${id}/conflicting_resources`, { params });

const sendPushNotification = async ({ id, params }) =>
  await axiosInstance.post(`/events/${id}/send_push_notifications`, params);

export {
  getEventsList,
  getEvent,
  addEvent,
  editEvent,
  publishEvent,
  deleteEvent,
  cancelEvent,
  inviteApplicants,
  checkEventConflicts,
  sendPushNotification,
};
