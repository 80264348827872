import { axiosInstance } from "./";

const getNote = async (id) => await axiosInstance.get(`/applicants/${id}/note`);
const updateNote = async ({ id, data }) =>
  await axiosInstance.patch(`/applicants/${id}/note`, data);

const createNote = async ({ id, data }) =>
  await axiosInstance.post(`/applicants/${id}/note`, data);

const deleteNote = async (id) =>
  await axiosInstance.delete(`/applicants/${id}/note`);

export { createNote, deleteNote, getNote, updateNote };
