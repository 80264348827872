import { SHOW_HIDE_SNACKBAR, SHOW_NOTIFICATIONS_SUPPORT_ALERT } from "./types";

const INITIAL_STATE = {
  showSnackbar: false,
  message: "",
  type: "success",
  showNotificationsSupportAlert: false,
};

const snackbar = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_HIDE_SNACKBAR:
      return {
        ...state,
        showSnackbar: action.payload.isOpen,
        message: action.payload.message,
        type: action.payload.type,
      };
    case SHOW_NOTIFICATIONS_SUPPORT_ALERT:
      return {
        ...state,
        showNotificationsSupportAlert: action.payload,
      };
    default:
      return state;
  }
};

export default snackbar;
