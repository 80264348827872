import { put, call, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/lookups";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg } from "../../utils/Helpers";
import { lookupsKeys, reversedLookupsKeys } from "../../utils/Constants";

const getLookupsObjectFromList = (lookupsList, lookupsKey) =>
  lookupsList.find((elt) => elt[lookupsKey])[lookupsKey];

function* getLookupsSaga({ payload }) {
  try {
    const userType = localStorage.getItem("userType");
    const response = yield call(
      userType === "admin" ? apis.getLookups : apis.getLookupsSuperVisor,
      payload
    );

    const lookupsKeysList = payload.map((elt) => reversedLookupsKeys[elt]);
    const lookupsLists = lookupsKeysList.map((elt) =>
      getLookupsObjectFromList(response.data.data, lookupsKeys[elt])
    );
    const lookupsObject = {};
    lookupsKeysList.forEach((elt, i) => {
      lookupsObject[elt] = lookupsLists[i];
    });

    yield put(actions.getLookupsResponse(lookupsObject));
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* getCountriesSaga({ payload }) {
  try {
    const response = yield call(apis.getCountries, payload);
    yield put(actions.getCountriesResponse(response.data.data));
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* getCitiesSaga({ payload }) {
  try {
    const response = yield call(apis.getCities, payload);
    yield put(actions.getCitiesResponse(response.data.data));
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* getProjectManagersSaga({ payload }) {
  try {
    const response = yield call(apis.getProjectManagers, payload);
    yield put(actions.getProjectManagersResponse(response.data.data));
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* getEventZonesSaga({ payload }) {
  try {
    const response = yield call(apis.getEventZones, payload);
    yield put(actions.getEventZonesResponse(response.data.data));
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* getSpeakingProficiencySaga() {
  try {
    const response = yield call(apis.getSpeakingProficiency);
    yield put(actions.getSpeakingProficiencyResponse(response.data));
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

export default function* LookupsSagas() {
  yield takeEvery(types.GET_LOOKUPS_REQUEST, getLookupsSaga);
  yield takeEvery(types.GET_COUNTRIES_REQUEST, getCountriesSaga);
  yield takeEvery(types.GET_CITIES_REQUEST, getCitiesSaga);
  yield takeEvery(types.GET_PROJECT_MANAGERS_REQUEST, getProjectManagersSaga);
  yield takeEvery(types.GET_EVENT_ZONES_REQUEST, getEventZonesSaga);
  yield takeEvery(
    types.GET_SPEAKING_PROFICIENCY_REQUEST,
    getSpeakingProficiencySaga
  );
}
