import { EXPORT_APPLICANT_REQUEST, EXPORT_APPLICANT_RESPONSE } from "./types";

export const downloadDataRequest = ({ params, token }) => ({
  type: EXPORT_APPLICANT_REQUEST,
  payload: { params, token },
});

export const downloadDataResponse = (data) => ({
  type: EXPORT_APPLICANT_RESPONSE,
  payload: data,
});
