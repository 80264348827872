import React from "react";
import PropTypes from "prop-types";

import "./CreateFormSectionTitle.scss";

const CreateFormSectionTitle = ({ title }) => {
  return (
    <div className="forms-section-title-wrapper">
      <span></span>
      <h2 className="mb-0">{title}</h2>
    </div>
  );
};

export default CreateFormSectionTitle;

CreateFormSectionTitle.propTypes = {
  title: PropTypes.string,
};
