import React from "react";

export const GlobalIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      stroke="#6F767E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zM1 12h22"></path>
    <path
      stroke="#6F767E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 1a16.83 16.83 0 014.4 11A16.83 16.83 0 0112 23a16.83 16.83 0 01-4.4-11A16.83 16.83 0 0112 1v0z"></path>
  </svg>
);
