const evaluations = {
  listEvaluations: {
    title: "قائمة التقييمات",
    evaluationName: "اسم تقييم الفعالية",
    creationDate: "تاريخ الإنشاء",
    totalNumber: " إجمالي عدد المتقدمين",
    evaluatedApplicants: "المتقدمون الذين تم تقييمهم",
    remainingApplicants: "المتقدمون الباقيون",
    searchPlaceholder: "ابحث باسم التقييم",
    noData: "لا توجد تقييمات معلقة حتى الآن!",
  },
  listForms: {
    title: "قائمة تقييمات الفاعليه",
    searchPlaceholder: "ابحث بأسم المتقدم",
    event: "الفاعليه",
    applicantName: "أسم المتقدم",
    email: "البريد الالكتروني",
    zoneName: "اسم المنطقه",
    finalRate: "التقييم النهائي",
    zone: "المنطقه",
    noData: "عذرا لاتوجد تقييمات",
  },
  evaluationsForm: {
    applicantInfo: "معلومات المتقدم",
    evaluationSkills: "مهارات المتقدم",
    comments: "التعليقات",
    submittedBy: "مقدم من:",
    confirmMsg: "هل أنت متأكد أنك تريد أن تقدم تقييم ل ",
    evaluation: " ?",
    noComment: "لا يوجد تعليق",
  },
  statusOptions: {
    submitted: "مُكتمل",
    pending: "قيد التنفيذ",
  },
  inProgressStatusOptions: {
    submitted: "مُكتمل",
    pending: "قيد الانتظار",
  },
  noAccessMsg: "عذرا ليس لديك الصلاحية لتقديم هذا التقييم",
  evaluations: "التقييمات",
};

export default evaluations;
