const inputsValidations = {
  emailRequired: "Email is required",
  nameRequired: "Name is required",
  enNameRequired: "English name is required",
  arNameRequired: "Arabic name is required",
  pickOneCategoryAtLeast: "At least one category must be selected",
  specialtyRequired: "Specialty is required",
  roleRequired: "Role is requierd",
  logoRequired: "Logo is requierd",
  bannerRequired: "Banner is requierd",
  categoryRequired: "Category is requierd",
  passwordRequired: "Password is required",
  tempPasswordRequired: "Temporary password is required",
  oldPasswordRequired: "Old password is required",
  newPasswordRequired: "New password is required",
  validEmailFormat: "Please enter a valid email format",
  uniqeEmailRequired: "This email is used before, email should be uniqe",
  confirmNewPasswordRequired: "Confirm new password is required",
  passwordIsNotMatched: "Passwords must match",
  passwordNotFollowREGEX:
    "Password should contain at least 8 characters; mix of letters (upper and lower case), numbers, and symbols",
  nameNotFollowREGEX: "Name should not contain any special characters",
  nameNotFollowAlphabeticREGEX:
    "Name should not contain any special characters or numbers",
  englishLettersOnly: "This field only accepts English input",
  arabicLettersOnly: "This field only accepts Arabic input",
  onlyPositiveNumber: "This field only accepts integer numbers",
  onlyPositiveNumbersWithZero: "This field only accepts 0 or integer numbers",
  onlyPositiveNumberWithMaxTwoDecimalPlaces:
    "This field only accepts integer numbers",
  userTypeRequired: "User type is required",
  eventNameRequired: "Event name is required",
  eventBriefRequired: "Event brief is required",
  startBuildUpDateNotAfterEnd:
    "Start build up date cannot be after end build up date",
  endBuildUpDateNotBeforeStart:
    "End build up date cannot be before start build up date",
  eventStartDateNotAfterEnd: "Event start date cannot be after event end date",
  eventEndDateNotBeforeStart:
    "Event end date cannot be before event start date",
  dismantlingStartDateNotAfterEnd:
    "Dismantling start date cannot be after dismantling end date",
  dismantlingEndtDateNotBeforeStart:
    "Dismantling end date cannot be before dismantling start date",
  shouldNotBeBeforeToday: "Date cannot be before today",
  invalidDate: "Please enter a valid date",
  invalidTime: "Please enter a valid time",
  maxHundredChars: "Maximum 100 chars",
  maxFiveHundredChars: "Maximum 500 chars",
  maxTowHundredChars: "Maximum 200 chars",
  maxFiftyChars: "Maximum 50 chars",
  invalidPhone: "Invalid Phone Number",
  generalFieldRequired: "This field is required",
  requiredDate: "This Date is required",
  max1000: "Maximum length 1000 Chars",
  max500: "Maximum length 500 Chars",
  max300: "Maximum length 300 Chars",
  generalFieldRequiredToPublish: "This Field is required",
  eventImageRequired: "Event Image is required",
  verificationMethodRequired: "Check-In Verification Method is required",
  checkinStartMarginMax: "Check-in start margin shouldn't exceed 240 minutes",
  checkinEndMarginMax: "Check-in end margin shouldn't exceed 240 minutes",
  checkinMarginsTotalMax:
    "Check-in start and end margins total shouldn't exceed 240 minutes",
  checkinMarginsTotalMin:
    "Check-in start and end margins total should be greater than 0",
  checkoutStartMarginMax: "Check-out start margin shouldn't exceed 240 minutes",
  checkoutEndMarginMax: "Check-out end margin shouldn't exceed 240 minutes",
  checkoutMarginsTotalMax:
    "Check-out start and end margins total shouldn't exceed 240 minutes",
  checkoutMarginsTotalMin:
    "Check-out start and end margins total should be greater than 0",
  marginsOverlapWithSlot:
    "The total of check-in end margin and check-out start margin should be less than the time slot duration",
};

export default inputsValidations;
