import React from "react";

export const AssignIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3583 16.9949C10.0461 16.9949 9.73881 16.9998 9.42661 16.9949C9.04612 16.9901 8.81198 16.7559 8.8071 16.3754C8.80222 15.7462 8.8071 15.1218 8.8071 14.4925C8.8071 14.3071 8.87539 14.151 9.0071 14.0193C10.6022 12.4193 12.1973 10.8144 13.7973 9.21445C14.3534 8.66323 15.1681 8.66323 15.7339 9.20469C16.0169 9.47298 16.29 9.75103 16.5632 10.034C17.1583 10.6535 17.1534 11.4583 16.5437 12.0681C14.9681 13.6388 13.3925 15.2096 11.8169 16.7803C11.6656 16.9315 11.4949 17.0047 11.2803 16.9998C10.9681 16.9901 10.6656 16.9949 10.3583 16.9949ZM9.97783 15.8242C10.3242 15.8242 10.6412 15.834 10.9534 15.8193C11.0461 15.8144 11.1534 15.7705 11.2169 15.7071C12.2169 14.7218 13.2071 13.7315 14.1973 12.7413C14.2412 12.6974 14.2754 12.6535 14.29 12.6388C13.9095 12.2584 13.5339 11.8827 13.1583 11.5071C13.1632 11.5023 13.1486 11.512 13.1339 11.5266C12.1047 12.5559 11.0803 13.5901 10.051 14.6242C10.0169 14.6583 9.97295 14.712 9.97295 14.7608C9.97295 15.1071 9.97783 15.4535 9.97783 15.8242ZM14.0364 10.6096C14.4315 11.0047 14.8071 11.3754 15.1925 11.7608C15.3778 11.5754 15.573 11.3803 15.7681 11.1803C15.8656 11.0779 15.8461 10.9705 15.7486 10.873C15.4803 10.5998 15.2071 10.3315 14.9339 10.0583C14.8217 9.94615 14.7144 9.94615 14.6022 10.0583C14.412 10.2486 14.2217 10.434 14.0364 10.6096Z"
      fill="#6B778C"
      stroke="#6B778C"
      strokeWidth="0.3"
    />
    <path
      d="M7.07063 8.82416C7.76331 8.82416 8.46087 8.79489 9.15356 8.83392C9.5438 8.85343 9.92917 8.96563 10.3097 9.07294C10.6316 9.16075 10.7828 9.4827 10.6901 9.79977C10.6023 10.0925 10.2901 10.2778 9.97795 10.1803C9.37307 9.98514 8.75843 9.96562 8.13404 9.9705C7.18282 9.97538 6.2316 9.9705 5.28038 9.96563C3.53404 9.96075 2.13892 11.3559 2.16331 13.1071C2.16819 13.3364 2.17795 13.5705 2.20721 13.7998C2.29502 14.4973 2.71941 14.9217 3.41697 15.0095C3.61697 15.0339 3.81697 15.0486 4.01697 15.0486C5.01697 15.0534 6.01697 15.0486 7.01697 15.0534C7.2999 15.0534 7.52429 15.2144 7.60234 15.4632C7.68039 15.7022 7.59746 15.9705 7.38282 16.1071C7.28039 16.1705 7.14868 16.2193 7.02673 16.2193C5.93404 16.229 4.84624 16.2388 3.75356 16.2144C3.3877 16.2046 3.01209 16.1364 2.66087 16.0193C1.73404 15.7022 1.22185 15.0095 1.07063 14.0534C0.865751 12.7607 1.0999 11.551 1.86087 10.4681C2.53404 9.50709 3.49502 8.99489 4.64136 8.86806C5.44624 8.78026 6.26087 8.79489 7.07063 8.76562C7.07063 8.79002 7.07063 8.80953 7.07063 8.82416Z"
      fill="#6B778C"
      stroke="#6B778C"
      strokeWidth="0.3"
    />
    <path
      d="M7.05123 7.62927C5.24147 7.63415 3.74879 6.15122 3.74391 4.34634C3.73903 2.50244 5.21708 1 7.03171 1C8.89513 1 10.3683 2.46829 10.3732 4.32683C10.3732 6.15122 8.89513 7.62439 7.05123 7.62927ZM7.0561 6.45854C8.25123 6.45854 9.20245 5.50732 9.20245 4.3122C9.20245 3.12195 8.24147 2.17073 7.04635 2.17073C5.88049 2.17073 4.91464 3.14146 4.91464 4.30244C4.91464 5.50244 5.86586 6.45854 7.0561 6.45854Z"
      fill="#6B778C"
      stroke="#6B778C"
      strokeWidth="0.3"
    />
  </svg>
);
