import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useFormik } from "formik";
import * as Yup from "yup";

import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Breadcrumbs from "../../../components/BreadCrumbs";
import Button from "../../../components/Button";
import PhoneInputField from "../../../components/PhoneInputField";
import Upload from "../../../components/Upload";
import messages from "../../../assets/locale/messages";
import {
  addPm,
  getEmployeeRequest,
  getEmployeeResponse,
  editEmployeeRequest,
} from "../../../store/Employees/actions";
import { getLookupsRequest } from "../../../store/Lookups/actions";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { lookupsKeys } from "../../../utils/Constants";
import { ALPHABETIC_REGEX } from "../../../utils/Patterns";
import { uploadToS3, directories } from "../../../utils/S3";
import { isSuperAdmin } from "../../../utils/Permissions";
import "../Employees.scss";

const AddProjectManager = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();

  const lang = useSelector((state) => state.locale.lang);
  const jobTitlesList = useSelector(
    (state) => state.lookups.general.adminRoles
  );
  const employee = useSelector((state) => state.employees.employee);
  const userRole = useSelector((state) => state.auth.login.role.name);
  const userId = useSelector((state) => state.auth.login.id);

  const { employees, shared } = messages[lang];

  const [img, setImg] = useState();
  // flag to upload image in edit mode if image changed only
  const [imgChanged, setImgChanged] = useState(false);

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
    setValues,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone_number: null,
      country_id: null,
      image_url: null,
      jobTitle: {},
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(ALPHABETIC_REGEX, "nameNotFollowAlphabeticREGEX")
        .required("nameRequired"),
      email: Yup.string().email("validEmailFormat").required("emailRequired"),
      phone_number: Yup.string()
        .min(9, "invalidPhone")
        .max(9, "invalidPhone")
        .nullable(),
    }),
    onSubmit: async (values) => {
      let pmData = {};
      pmData.name = values.name;
      pmData.email = values.email;
      pmData.phone_number = values.phone_number || null;
      pmData.admin_role_id = values.jobTitle.id;
      pmData.country_id = 190;

      if (values.image_url) {
        if (imgChanged) {
          const data = await uploadToS3(
            values.image_url,
            // unique file name to avoid conflicts and without spaces as it makes errors
            `${new Date().getTime()}_${values.image_url.name
              .replace(/\s/g, "")
              .replace(/(\(|\))/g, "")}`,
            directories.employees
          );
          pmData.image_url = data?.link;
        } else {
          pmData.image_url = values.image_url;
        }
      } else {
        pmData.image_url = null;
      }

      if (employee?.id) {
        dispatch(
          editEmployeeRequest({
            data: pmData,
            id: employee?.id,
          })
        );
      } else {
        dispatch(addPm(pmData));
      }
    },
  });

  useEffect(() => {
    dispatch(getLookupsRequest([lookupsKeys.adminRoles]));
  }, [lang]);

  useEffect(() => {
    if (!isSuperAdmin()) {
      dispatch(getEmployeeRequest(userId));
    }
  }, [userRole, userId]);

  useEffect(() => {
    if (employee && jobTitlesList) {
      setValues({
        name: employee.name,
        email: employee.email,
        phone_number: employee.phone_number,
        image_url: employee.image_url,
        jobTitle: jobTitlesList.find((elt) => elt.id === 2),
      });
    }
  }, [employee, jobTitlesList]);

  useEffect(() => {
    if (jobTitlesList) {
      setFieldValue(
        "jobTitle",
        jobTitlesList.find((elt) => elt.id === 2)
      );
    }
  }, [jobTitlesList]);

  useEffect(() => {
    return () => {
      dispatch(getEmployeeResponse(null));
    };
  }, []);

  // Edit Profile Route is only Accessable by PM and this to handel if super admin tries to access it
  useEffect(() => {
    if (location.pathname === ROUTE_PATHS.editProfile && isSuperAdmin()) {
      history.push(ROUTE_PATHS.profile);
    }
  }, [location]);

  const breadcrumb = () => (
    <Breadcrumbs
      list={[
        {
          path: employee?.id ? ROUTE_PATHS.profile : ROUTE_PATHS.employees,
          label: employee?.id ? employees.profile : employees.employeesList,
        },
        {
          path: "",
          label: employee?.id
            ? employees.editProfile
            : employees.addProjectManager.createEmployee,
        },
      ]}
      BreadCrumbHasBackIcon={false}
    />
  );

  return (
    <div className="add-employee-page pb-3">
      <div className="page-title-margin">{breadcrumb()}</div>
      <div className="form-wrapper">
        <form noValidate onSubmit={handleSubmit}>
          <div className="step-part-content-wrapper addProjectManager-page-container mb-3">
            <Input
              id="name"
              name="name"
              label={employees.addProjectManager.labels.name}
              type="text"
              placeholder={employees.addProjectManager.placeholders.name}
              required
              onChange={(value) => {
                setFieldTouched("name");
                setFieldValue("name", value);
              }}
              value={values["name"]}
              inputClass="mb-1"
              labelClassName="font-medium mb-2 text-gray-title field-label"
              inputWrapperClass="addProjectManager-input"
              isInputHasErr={!!(touched["name"] && errors["name"])}
              errMsg={errors["name"]}
            />
            <Input
              id="email"
              name="email"
              label={employees.addProjectManager.labels.email}
              type="text"
              placeholder={employees.addProjectManager.placeholders.email}
              required
              onChange={(value) => {
                setFieldTouched("email");
                setFieldValue("email", value);
              }}
              value={values["email"]}
              inputClass="mb-1"
              labelClassName="font-medium mb-2 text-gray-title field-label"
              inputWrapperClass="addProjectManager-input"
              isInputHasErr={!!(touched["email"] && errors["email"])}
              errMsg={errors["email"]}
              disabled={employee?.id}
            />
            <PhoneInputField
              id={`phone_number`}
              name={"phone_number"}
              label={employees.addProjectManager.labels.mobileNumber}
              onChange={(phoneValue, country) => {
                setFieldTouched(`phone_number`);
                setValues({
                  ...values,
                  phone_number: phoneValue,
                  country_id: country.id,
                });
              }}
              country="sa"
              countryCode={employee?.dial_code || null}
              disableDropdown={true}
              countryCodeEditable={false}
              value={values["phone_number"] || ""}
              labelClassName="font-medium mb-2 text-primary field-label"
              inputWrapperClass="addProjectManager-input"
              isInputHasErr={
                !!(touched["phone_number"] && errors["phone_number"])
              }
              errMsg={errors["phone_number"]}
            />

            {employee?.id && (
              <Upload
                fieldLabel={employees.addProjectManager.labels.employeeImage}
                label={
                  <div className="pt-1 d-flex flex-column align-items-center justify-content-center employee-image-upload-initial-container">
                    <div className="d-flex justify-content-center align-items-center center-uplod-btn">
                      <ArrowUpwardIcon fontSize="small" />
                      <p className=" mb-0 text-center w-fit mx-2">
                        {employees.addProjectManager.labels.uploadLabel}
                      </p>
                    </div>
                  </div>
                }
                onChange={(img, value) => {
                  setImg(img);
                  setImgChanged(true);
                  setFieldValue("image_url", value);
                }}
                img={img || values["image_url"]}
                name="image_url"
                isFullWidthImage={true}
                className="mt-3 min-height-image"
                fullWidthImageClassName="employee-image-upload-container"
                wrapperClasses="employee-upload-wrapper"
                isImage
                isBluredBackground
                disabled={isSuperAdmin()}
              />
            )}

            <Select
              id="jobTitle"
              name="jobTitle"
              options={jobTitlesList || []}
              label={employees.addProjectManager.labels.jobTitle}
              variant
              block
              labelClassName="text-gray-title"
              placeholder={employees.addProjectManager.placeholders.jobTitle}
              value={values["jobTitle"]}
              disabled={true}
              inputWrapperClass="addProjectManager-input"
              onChange={(value) => {
                setFieldTouched("jobTitle");
                setFieldValue("jobTitle", value);
              }}
            />
          </div>
          <div className="d-flex justify-content-end align-items-center buttom-btns-container">
            <Button
              type="submit"
              block
              label={shared.save}
              labelClass="px-4"
              disabled={!dirty || !isValid}
              className="mx-2 continue-btn"
            />
            <Button
              onClick={() => {
                isSuperAdmin()
                  ? history.push(ROUTE_PATHS.employees)
                  : history.push(ROUTE_PATHS.profile);
              }}
              type="button"
              block
              label={shared.cancel}
              labelClass="px-4"
              outlined
              className="mx-2 cancel-btn"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddProjectManager;
