import * as types from "./types";

export const initialFetch = () => ({
  type: types.INITIAL_FETCH,
});

export const setOrder = (payload) => ({
  type: types.ORDER_BY,
  payload,
});

export const setFilters = (filters) => ({
  type: types.SET_FILTERS,
  payload: filters,
});

export const setPage = (page) => ({
  type: types.SET_PAGE,
  payload: page,
});

export const setSearchValue = (payload) => ({
  type: types.SET_SEARCH_VALUE,
  payload,
});

export const setRowsCount = (count) => ({
  type: types.SET_ROWS_COUNT,
  payload: count,
});
