import {
  CHANGE_PASSWORD_REQUEST,
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  LOGOUT_REQUEST,
  FORGET_PASSWORD_REQUEST
} from "./types";

export const loginRequest = (payload) => ({
  type: LOGIN_REQUEST,
  payload,
});

export const loginResponse = (payload) => ({
  type: LOGIN_RESPONSE,
  payload,
});

export const logoutRequest = (payload) => ({
  type: LOGOUT_REQUEST,
  payload,
});

export const changePasswordRequest = (payload) => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload,
});

export const forgetPasswordRequest = (payload) => ({
  type: FORGET_PASSWORD_REQUEST,
  payload,
})
