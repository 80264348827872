const changePassword = {
  title: "Create",
  subTitle: "New Password",
  resetPassword: "Reset Password",
  labels: {
    oldPassword: "Temp Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm new password",
  },
  placeholders: {
    oldPassword: "Enter your old password",
    tempPassword: "Enter your temp password",
    newPassword: "Enter your new password",
    confirmNewPassword: "Confirm new password",
  },
  actionBTN: "Save",
  resetPage: {
    oldPassword: "Old Password",
    newPassword: "New Password",
  },
};

export default changePassword;
