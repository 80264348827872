import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, Link } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { Menu as MenuIcon, Add, FiberManualRecord } from "@mui/icons-material";

import {
  setActivePublishMode,
  setCreateEventFormStep,
  setCreatedEventId,
} from "../../../store/Routing/actions";
import { getEventResponse } from "../../../store/Events/actions";
import { getEmployeeRequest } from "../../../store/Employees/actions";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import messages from "../../../assets/locale/messages";
import LangSwitcher from "../../LangSwitcher";
import Button from "../../Button";
import Avatar from "../../Avatar";
import NotificationsMenu from "../../NotificationsMenu";
import { getDropdownNotificationsRequest } from "../../../store/Notifications/actions";
import {
  handleNotificationsClickActions,
  setNotificationSeen,
} from "../../../firebase/helpers";
import { formatDate } from "../../../utils/Helpers";
import {
  NotificationsBgIcon,
  SeenNotificationIcon,
} from "../../../utils/Icons";
import { isSuperAdmin } from "../../../utils/Permissions";
import "./NavBar.scss";

const NavBar = ({
  sideMenuOpen,
  drawerWidth,
  collapsedDrawerWidth,
  openMobileSideMenu,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  const userType = localStorage.getItem("userType");
  const lang = useSelector((state) => state.locale.lang);
  const userRoleName = useSelector((state) => state.auth.login.role.name);
  const superVisorInfo = useSelector((state) => state.auth.login);

  const userImg = useSelector((state) => state.auth.login.image_url);
  const userLoginId = useSelector((state) => state.auth.login.id);
  const employee = useSelector((state) => state.employees.employee);

  const notificationsList = useSelector(
    (state) => state?.notifications?.dropdownNotifications
  );
  const unseenNotificationsCount = useSelector(
    (state) => state.notifications.unseenNotificationsCount
  );

  const { shared, notifications } = messages[lang];

  // const NotifcationTooltip = styled(({ className, ...props }) => (
  //   <Tooltip placement="left" {...props} classes={{ popper: className }} />
  // ))(() => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //      backgroundColor: "transparent",
  //     color: "#448bca",
  //     textAlign: "center",
  //   },
  // }));

  const handleGetNotifications = () => {
    dispatch(
      getDropdownNotificationsRequest({
        items: 5,
        page: 1,
      })
    );
  };

  const renderSingleNotification = (title, body, created_at) => (
    <div className="d-flex">
      <span>
        <NotificationsBgIcon />
      </span>
      <div style={{ paddingLeft: "0.5rem" }} className="me-3">
        <span className="mb-1 d-block notification-title font-bold fsize-15">
          {title}
        </span>
        <span className="mb-2 d-block notification-body">
          {body.length > 50 ? <>{body.substring(0, 50)} ...</> : body}
        </span>
        <span className="notification-date d-block fsize-12 font-regular">
          {formatDate(created_at, "DD MMM YYYY")}
        </span>
      </div>
    </div>
  );
  const getMenuItems = () =>
    notificationsList.map(({ id, title, body, created_at, is_seen }) => ({
      id,
      content: renderSingleNotification(title, body, created_at),
      className: is_seen ? "seen-notification" : "unseen-notification",
      adorment: !is_seen ? (
        <span className="adorment-notifaction">
          {/* <NotifcationTooltip title={<p>{notifications.markAsRead}</p>}> */}
          <FiberManualRecord
            className="seen-adorment"
            onClick={(e) => handleNotificationSeen(e, id)}
          />
          {/* </NotifcationTooltip> */}
        </span>
      ) : null,
    }));

  const renderMenuController = () => (
    <div
      className="d-flex flex-column align-items-center ms-3 pointer"
      onClick={handleGetNotifications}
    >
      <div className="position-relative bg-gray-mid hei-40 wid-40 rounded-circle notification-icon-wrapper mx-2 mx-sm-3">
        {unseenNotificationsCount ? (
          <div
            className={`notifications-count position-absolute text-center`}
          ></div>
        ) : null}
        <SeenNotificationIcon size="small" sx={{ ml: 2 }} className="" />
      </div>
    </div>
  );
  const renderMenuFooter = () => (
    <div className="py-4  text-center">
      <Link to={ROUTE_PATHS.notifications}>
        <p className="see-all-btn fsize-14 pointer mb-0">
          {notifications.seeAllNotifications}
        </p>
      </Link>
    </div>
  );

  const handleSelectNotification = (notificationId) => {
    const selectedNotification = notificationsList.find(
      (item) => item.id === notificationId
    );

    handleNotificationsClickActions(selectedNotification);
  };

  const handleNotificationSeen = (e, id) => {
    e.stopPropagation();
    setNotificationSeen(id);
  };

  useEffect(() => {
    if (!isSuperAdmin() && userType === "admin") {
      dispatch(getEmployeeRequest(userLoginId));
    }
  }, [userLoginId]);

  const customStyle = {
    backgroundColor: "white",
    transition: (theme) =>
      theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    width: { md: `calc(100% - ${collapsedDrawerWidth}px)` },
    ...(sideMenuOpen && {
      ml: { md: `${drawerWidth}px` },
      width: { md: `calc(100% - ${drawerWidth}px)` },
      transition: (theme) =>
        theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
    }),
  };

  return (
    <MuiAppBar position="fixed" sx={customStyle} className="nav-bar">
      <Toolbar className="">
        <IconButton
          color="primary"
          aria-label="open drawer"
          edge="start"
          onClick={() => openMobileSideMenu(true)}
          sx={{ display: { md: "none" } }}
          className="mobile-toggle-icon"
        >
          <MenuIcon />
        </IconButton>
        <div className="w-100 d-flex justify-content-end">
          <div className="d-flex flex-sm-row justify-content-end align-items-center nav-items-container">
            {isSuperAdmin() &&
              location.pathname !== ROUTE_PATHS.createEvent && (
                <div className="mx-2 mx-sm-3 create-event-btn-container">
                  <Button
                    label={
                      <span className="d-flex align-items-center create-event-btn">
                        <Add fontSize="small" />
                        <span className="ps-1">{shared.creatEvent}</span>
                      </span>
                    }
                    labelClass="text-white text-nowrap"
                    onClick={() => {
                      dispatch(setActivePublishMode(false));
                      dispatch(setCreateEventFormStep(0));
                      dispatch(setCreatedEventId(null));
                      dispatch(getEventResponse(null));
                      history.push(ROUTE_PATHS.createEvent);
                    }}
                    block
                  />
                </div>
              )}

            <div className="d-flex justify-content-between align-items-center nav-icons-container">
              {userType == "admin" && (
                <NotificationsMenu
                  menuController={renderMenuController()}
                  menuItems={getMenuItems()}
                  handleSelectItem={handleSelectNotification}
                  menuFooter={renderMenuFooter()}
                  emptyState={notifications.noNotifications}
                />
              )}
              <div className="mx-2 mx-sm-3">
                <LangSwitcher internalSwitcher />
              </div>
              <div
                className={`d-flex flex-column align-items-center ${
                  lang === "en" ? "ms-2 ms-sm-3" : "me-2 me-sm-3"
                } ${!isSuperAdmin() && userType === "admin" && "pointer"}`}
                onClick={() => {
                  !isSuperAdmin() &&
                    userType === "admin" &&
                    history.push(ROUTE_PATHS["profile"]);
                }}
              >
                <Avatar
                  imgSrc={
                    userType === "admin"
                      ? isSuperAdmin()
                        ? userImg
                        : employee?.image_url
                      : superVisorInfo.image
                  }
                  imgSrcAlt="user-profile"
                  name={
                    userType === "admin"
                      ? isSuperAdmin()
                        ? userRoleName
                        : employee?.name
                      : `${superVisorInfo.first_name_en} ${superVisorInfo.last_name_en}`
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};

NavBar.propTypes = {
  sideMenuOpen: PropTypes.bool.isRequired,
  drawerWidth: PropTypes.number.isRequired,
  collapsedDrawerWidth: PropTypes.number.isRequired,
  openMobileSideMenu: PropTypes.func.isRequired,
};

export default NavBar;
