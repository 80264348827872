import React from "react";

export const Cv = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="19"
    fill="none"
    viewBox="0 0 16 19"
  >
    <path
      fill="#4E5D78"
      stroke="#4E5D78"
      strokeWidth="0.2"
      d="M1.828 18.527c-.132-.058-.27-.1-.386-.184-.305-.205-.442-.51-.442-.884V8.422c0-.231.086-.363.254-.405.178-.042.34.047.386.226.02.074.02.158.02.237v8.87c0 .394.097.499.478.499h11.741c.381 0 .483-.1.483-.495V2.19c0-.395-.097-.5-.478-.5H7.483c-.061 0-.122 0-.183-.005a.328.328 0 01-.29-.337c0-.174.117-.305.295-.331.05-.006.096-.006.147-.006h6.483c.67 0 1.082.426 1.082 1.12v15.288c0 .583-.203.857-.828 1.12H1.829v-.01z"
    ></path>
    <path
      fill="#4E5D78"
      stroke="#4E5D78"
      strokeWidth="0.2"
      d="M5.126 9.374c.259-.289.492-.605.777-.857.285-.247.62-.42.91-.61-.117-.248-.29-.5-.356-.784-.178-.784-.046-1.552.569-2.062.848-.7 2.195-.29 2.51.789.172.6.162 1.194-.153 1.752-.06.105-.142.2-.228.32.726.3 1.3.779 1.712 1.49.294-.353.508-.737.65-1.153.777-2.23-.518-4.65-2.759-5.113-.37-.079-.767-.042-1.148-.058-.066 0-.132.01-.198.01-.188 0-.325-.115-.346-.294a.326.326 0 01.275-.368 3.94 3.94 0 01.655-.058c2.088-.01 3.902 1.515 4.324 3.635.492 2.472-1.072 4.908-3.47 5.387-2.393.478-4.7-1.142-5.162-3.635-.046-.237.046-.416.234-.458.208-.047.37.074.416.331a3.898 3.898 0 00.788 1.747v-.01zm.523.532c1.31 1.188 3.45 1.167 4.725-.01-.513-.964-1.3-1.495-2.363-1.49-1.067 0-1.849.537-2.357 1.5h-.005zM8.972 6.56v-.19c-.026-.552-.437-.978-.956-.978-.513 0-.934.421-.96.973-.005.142-.01.284 0 .426.051.626.63 1.047 1.204.879.463-.132.707-.52.707-1.115l.005.005z"
    ></path>
    <path
      fill="#4E5D78"
      stroke="#4E5D78"
      strokeWidth="0.2"
      d="M3.718 1c1.494 0 2.713 1.273 2.708 2.82-.005 1.546-1.235 2.809-2.723 2.798C2.209 6.602 1 5.345 1 3.804 1 2.263 2.224 1 3.718 1zm-.015.684c-1.128 0-2.048.963-2.043 2.13 0 1.168.93 2.12 2.058 2.115 1.128 0 2.048-.963 2.042-2.13 0-1.168-.93-2.12-2.057-2.115zM8.707 14.228h-2.2c-.056 0-.112 0-.162-.005a.338.338 0 01.005-.673c.05-.006.096 0 .147 0H11.06c.199.015.325.147.33.336 0 .195-.126.337-.335.348-.152.005-.31 0-.462 0H8.707v-.006zM4.952 14.228a.33.33 0 01-.32-.342c0-.184.152-.342.325-.337.178 0 .325.158.325.342 0 .184-.152.342-.325.337h-.005z"
    ></path>
    <path
      fill="#4E5D78"
      stroke="#4E5D78"
      strokeWidth="0.2"
      d="M3.332 4.099c.35-.363.671-.7.996-1.031.046-.048.097-.095.147-.137.133-.09.3-.069.407.042a.35.35 0 01.035.426.855.855 0 01-.106.131c-.391.406-.778.81-1.169 1.21-.223.232-.38.232-.61 0-.162-.168-.33-.336-.492-.51-.163-.173-.178-.373-.046-.515.142-.153.335-.142.508.031.107.105.203.221.315.347l.015.006z"
    ></path>
  </svg>
);
