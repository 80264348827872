import * as types from "./types";

export const setCreateEventFormStep = (payload) => ({
  type: types.SET_CREATE_EVENT_FORM_STEP,
  payload,
});

export const setCreatedEventId = (payload) => ({
  type: types.SET_CREATED_EVENT_ID,
  payload,
});

export const setActivePublishMode = (payload) => ({
  type: types.SET_ACTIVE_PUBLISH_MODE,
  payload,
});
