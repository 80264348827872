import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import Button from "../Button";
import "./Modal.scss";

const Modal = ({
  open,
  handleClose,
  title,
  isContentText,
  content,
  hasActions,
  confirmBtnTxt,
  cancelBtnTxt,
  handleConfirm,
  handleCancel,
  showCloseIcon,
  maxWidth,
  scrollMode,
  modalClass,
  titleClass,
  contentClass,
  actionsClass,
  confirmBtnClass,
  cancelBtnClass,
  disableEscapeKeyDown,
  isDisabled,
  type,
}) => {
  const lang = useSelector((state) => state.locale.lang);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={true}
      scroll={scrollMode}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={`modal-container ${modalClass}`}
      disableEscapeKeyDown={disableEscapeKeyDown}>
      {title && (
        <DialogTitle id="alert-dialog-title" className={titleClass}>
          {title}

          {showCloseIcon && (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={
                lang === "en"
                  ? {
                      position: "absolute",
                      top: 8,
                      right: 8,
                    }
                  : {
                      position: "absolute",
                      top: 8,
                      left: 8,
                    }
              }>
              <Close />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <div className="modal-content text-center">
        {content && (
          <DialogContent id="alert-dialog-description" className={contentClass}>
            {isContentText ? (
              <DialogContentText>{content}</DialogContentText>
            ) : (
              <>{content}</>
            )}
          </DialogContent>
        )}
      </div>
      {hasActions && (
        <DialogActions className={`justify-content-center ${actionsClass}`}>
          {confirmBtnTxt && (
            <Button
              label={confirmBtnTxt}
              onClick={handleConfirm}
              className={`${confirmBtnClass} mx-2 py-1 confirm-btn`}
              labelClass="px-2"
              disabled={isDisabled}
              type={type}
            />
          )}
          {cancelBtnTxt && (
            <Button
              label={cancelBtnTxt}
              outlined
              negative
              small
              onClick={handleCancel}
              className={`${cancelBtnClass} mx-2 py-1 cancel-btn`}
              labelClass="px-2"
            />
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isContentText: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hasActions: PropTypes.bool,
  confirmBtnTxt: PropTypes.string,
  cancelBtnTxt: PropTypes.string,
  handleConfirm: PropTypes.func,
  handleCancel: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  scrollMode: PropTypes.oneOf(["paper", "body"]),
  modalClass: PropTypes.string,
  titleClass: PropTypes.string,
  contentClass: PropTypes.string,
  actionsClass: PropTypes.string,
  confirmBtnClass: PropTypes.string,
  cancelBtnClass: PropTypes.string,
  disableEscapeKeyDown: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Modal.defaultProps = {
  isContentText: true,
  hasActions: true,
  showCloseIcon: true,
  isDisabled: false,
  maxWidth: "sm",
  scrollMode: "paper",
};

export default Modal;
