import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { InputLabel, FormHelperText } from "@mui/material";

import messages from "../../assets/locale/messages";
import "./RichText.scss";

const RichText = ({
  id,
  name,
  value,
  onChange,
  richTextWrapperClass,
  labelClassName,
  label,
  required,
  labelAdornment,
  className,
  readOnly,
  noData,
  maxLength,
  helperTextClass,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared, inputsValidations } = messages[lang];
  const quil = useRef(null);

  const [filedLength, setFiledLength] = useState(null);

  useEffect(() => {
    if (!readOnly) {
      setFiledLength(quil?.current?.unprivilegedEditor.getLength() - 1);
    }
  }, [quil, value]);

  return (
    <div className={`rich-text-wrapper ${richTextWrapperClass}`}>
      <InputLabel htmlFor={id || name} className={labelClassName}>
        <>
          {label}
          {required ? <span className="err-asterisk"> *</span> : ""}
          {labelAdornment && <span className="ms-2">{labelAdornment}</span>}
        </>
      </InputLabel>

      {noData ? (
        <div className="dimed-color">{shared.none}</div>
      ) : (
        <>
          <ReactQuill
            id={id}
            name={name}
            theme="snow"
            value={value}
            onChange={onChange}
            className={`${className} ${readOnly ? "read-only" : ""} ${
              filedLength > maxLength ? "error-border" : ""
            }`}
            readOnly={readOnly}
            ref={quil}
          />
          <FormHelperText
            error={filedLength > maxLength}
            className={`${helperTextClass || "font-medium fsize-12"} ${
              lang === "en" ? "" : "text-end"
            }`}
          >
            {filedLength > maxLength && inputsValidations[`max${maxLength}`]}
          </FormHelperText>
        </>
      )}
    </div>
  );
};

export default RichText;

RichText.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  richTextWrapperClass: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  required: PropTypes.bool,
  labelAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  noData: PropTypes.bool,
  maxLength: PropTypes.number,
  helperTextClass: PropTypes.string,
};
