import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

import SideMenu from "./SideMenu";
import NavBar from "./NavBar";
import AuthLayout from "./AuthLayout";
import MainSection from "./MainSection";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import "./Layout.scss";

const drawerWidth = 215;
const collapsedDrawerWidth = 72;
const navbarHeight = 60;

const Layout = ({ isAuth, children }) => {
  const [sideMenuOpen, setSideMenuOpen] = useState(true);
  const [mobileSideMenuOpen, setMobileSideMenuOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const isViewEventRoute = /^\/events\/(\d+)\/view$/.test(location.pathname);
    const storedViewEventTab = sessionStorage.getItem("viewEventTab");
    if (storedViewEventTab && !isViewEventRoute) {
      sessionStorage.removeItem("viewEventTab");
    }
  }, [location]);

  const detectSideMenuOpen = (isOpen) => {
    setSideMenuOpen(isOpen);
  };
  const detectMobileSideMenuOpen = (isOpen) => {
    setMobileSideMenuOpen(isOpen);
  };

  // Checking for changePassword as it's an authenticated page but needs the usual auth pages layout
  return isAuth && location.pathname !== ROUTE_PATHS.changePassword ? (
    <Box sx={{ display: "flex", backgroundColor: "#f4f4f4" }}>
      <NavBar
        sideMenuOpen={sideMenuOpen}
        drawerWidth={drawerWidth}
        collapsedDrawerWidth={collapsedDrawerWidth}
        openMobileSideMenu={detectMobileSideMenuOpen}
      />
      <SideMenu
        detectSideMenuOpen={detectSideMenuOpen}
        closeMobileSideMenu={() => detectMobileSideMenuOpen(false)}
        drawerWidth={drawerWidth}
        mobileSideMenuOpen={mobileSideMenuOpen}
      />
      <MainSection
        sideMenuOpen={sideMenuOpen}
        drawerWidth={drawerWidth}
        collapsedDrawerWidth={collapsedDrawerWidth}
        navbarHeight={navbarHeight}>
        {children}
      </MainSection>
    </Box>
  ) : (
    <AuthLayout content={children} />
  );
};

Layout.propTypes = {
  isAuth: PropTypes.bool,
  children: PropTypes.node,
};

export default Layout;
