import React from "react";
import PropTypes from "prop-types";

import Button from "../Button";
import "./EmptyState.scss";

const EmptyState = ({
  subTitle,
  description,
  buttonLabel,
  handleCreate,
  showActionButton,
  imgSrc,
  containerClasses,
  actionBtnDisabled,
  imgClass,
}) => (
  <div
    className={`d-flex justify-content-center align-items-center flex-column ${containerClasses}`}>
    <img src={imgSrc} className={`img-fluid px-4 ${imgClass}`} />
    <p className="fsize-18 font-medium subTitle">{subTitle}</p>
    <p className="fsize-16 font-medium">{description}</p>
    {showActionButton && (
      <div>
        <Button
          label={buttonLabel}
          type="button"
          labelClass="text-white"
          onClick={handleCreate}
          block={true}
          disabled={actionBtnDisabled}
        />
      </div>
    )}
  </div>
);

export default EmptyState;

EmptyState.propTypes = {
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  handleCreate: PropTypes.func,
  showActionButton: PropTypes.bool,
  actionBtnDisabled: PropTypes.bool,
  imgSrc: PropTypes.string,
  containerClasses: PropTypes.string,
  imgClass: PropTypes.string,
};
EmptyState.defaultProps = {
  showActionButton: true,
};
