import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import messages from "../../../../assets/locale/messages";

const ExportForm = ({
  selectedFields,
  handleChange,
  handleSubmit,
  isSupervisor,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { events, shared } = messages[lang];
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-column justify-content-start  gap-4">
          <label className="d-flex align-items-center gap-2">
            <input
              type="checkbox"
              name="applicantID"
              checked={selectedFields.applicantID}
              onChange={handleChange}
            />
            {shared.id}
          </label>
          <label className="d-flex align-items-center gap-2">
            <input
              type="checkbox"
              name="imageUrl"
              checked={selectedFields.imageUrl}
              onChange={handleChange}
            />
            {shared.lists.image}
          </label>
          <label className="d-flex align-items-center gap-2">
            <input
              type="checkbox"
              name="name"
              checked={selectedFields.name}
              onChange={handleChange}
            />
            {shared.lists.name}
          </label>
          <label className="d-flex align-items-center gap-2">
            <input
              type="checkbox"
              name="email"
              checked={selectedFields.email}
              onChange={handleChange}
            />
            {shared.lists.email}
          </label>
          <label className="d-flex align-items-center gap-2">
            <input
              type="checkbox"
              name="gender"
              checked={selectedFields.gender}
              onChange={handleChange}
            />
            {shared.gender}
          </label>
          <label className="d-flex align-items-center gap-2">
            <input
              type="checkbox"
              name="zone"
              checked={selectedFields.zone}
              onChange={handleChange}
            />
            {events.creatEvent.zone}
          </label>
          <label className="d-flex align-items-center gap-2">
            <input
              type="checkbox"
              name="checkInStatus"
              checked={selectedFields.checkInStatus}
              onChange={handleChange}
            />
            {events.viewEvents.checkinStatus}
          </label>
          <label className="d-flex align-items-center gap-2">
            <input
              type="checkbox"
              name="status"
              checked={selectedFields.status}
              onChange={handleChange}
            />
            {events.viewEvents.applicantsTab.status}
          </label>

          {!isSupervisor && (
            <>
              <label className="d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  name="role"
                  checked={selectedFields.role}
                  onChange={handleChange}
                />
                {events.viewEvents.applicantsTab.role}
              </label>
              <label className="d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  name="supervisor"
                  checked={selectedFields.supervisor}
                  onChange={handleChange}
                />
                {events.viewEvents.applicantsTab.supervisor}
              </label>
            </>
          )}
          {isSupervisor && (
            <>
              <label className="d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  name="note"
                  checked={selectedFields.note}
                  onChange={handleChange}
                />
                {shared.note}
              </label>

              <label className="d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  name="phoneNumber"
                  checked={selectedFields.phoneNumber}
                  onChange={handleChange}
                />
                {shared.phone_number}
              </label>
            </>
          )}
          <label className="d-flex align-items-center gap-2">
            <input
              type="checkbox"
              name="stcNumber"
              checked={selectedFields.stcNumber}
              onChange={handleChange}
            />
            {shared.stcNumber}
          </label>
          <label className="d-flex align-items-center gap-2">
            <input
              type="checkbox"
              name="dailyAmount"
              checked={selectedFields.dailyAmount}
              onChange={handleChange}
            />
            {shared.dailyAmount}
          </label>
          <label className="d-flex align-items-center gap-2">
            <input
              type="checkbox"
              name="totalAmount"
              checked={selectedFields.totalAmount}
              onChange={handleChange}
            />
            {shared.totalAmount}
          </label>
          <label className="d-flex align-items-center gap-2">
            <input
              type="checkbox"
              name="numberOfWorking"
              checked={selectedFields.numberOfWorking}
              onChange={handleChange}
            />
            {shared.numberOfWorkingDays}
          </label>
        </div>
      </form>
    </div>
  );
};

export default ExportForm;

ExportForm.propTypes = {
  handleSubmit: PropTypes.func,
  setSelectedFields: PropTypes.any,
  selectedFields: PropTypes.any,
  handleChange: PropTypes.func,
  isSupervisor: PropTypes.bool,
};
