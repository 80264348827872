const promotionRequests = {
  listPromotionRequests: {
    list: "قائمة طلبات الترقية",
    status: "حاله",
    search: "ابحث باستخدام اسم المتقدم ",
    all: "الكل",
    approved: "مقبول",
    pending: "قيد الانتظار",
    rejected: "مرفوض",
    applicantName: "اسم المتقدم ",
    accept: " قبول",
    reject: " رفض",
    profileRating: "التقييم",
    accepted: " مقبول",
    action: "أفعال",
    male: "ذكر",
    female: "أنثى",
    prefer_not_to_say: "غير مُزود",
    requestDate: "تاريخ الطلب",
    noDataDescription: "عذرا لا توجد طلبات ترقية",
    accpetMsg: "هل أنت متأكد أنك تريد قبول طلب الترقية هذا؟",
    rejectMsg: "هل أنت متأكد أنك تريد رفض  طلب الترقية هذا؟",
  },
};

export default promotionRequests;
