import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { InfoOutlined } from "@mui/icons-material";

import RequestsTable from "../../../../../components/RequestsTable";
import Tooltip from "../../../../../components/Tooltip";
import {
  getEventRequest,
  getEventResponse,
} from "../../../../../store/Events/actions";
import messages from "../../../../../assets/locale/messages";
import { ROUTE_PATHS } from "../../../../../utils/RoutesPaths";

const InviteApplicants = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const event = useSelector((state) => state.events.event);
  const lang = useSelector((state) => state.locale.lang);

  const { events, shared, requests } = messages[lang];

  useEffect(() => {
    dispatch(getEventRequest(id));

    return () => {
      dispatch(getEventResponse(null));
    };
  }, [id, lang]);

  const breadcrumbsList = [
    {
      path: ROUTE_PATHS.events,
      label: events.listEvents.listEventsBreadcrumb,
    },
    {
      path: "",
      label: shared.eventStatusOptions[event?.status],
      isClicked: true,
      onclick: () =>
        history.push({
          pathname: ROUTE_PATHS.events,
          state: event?.status,
        }),
    },
    {
      path: ROUTE_PATHS.viewEvent.replace(":id", event?.id),
      label: `${event?.name} ${events.listEvents.details}`,
    },
    {
      path: "",
      label: `${events.viewEvents.applicantsTab.inviteApplicants}`,
    },
  ];

  const headLines = [
    shared.select,
    shared.image,
    requests.listRequests.applicantName,
    requests.listRequests.cityOfResidence,
    shared.gender,
    <Tooltip
      key={1}
      title={<p>{events.viewEvents.applicantsTab.hint}</p>}
      content={
        <span>
          {shared.role}
          <span className="px-1">
            <InfoOutlined fontSize="small" />
          </span>
        </span>
      }
    />,
    requests.listRequests.rating,
  ];

  return (
    <RequestsTable
      breadcrumbsList={breadcrumbsList}
      searchPlaceholder={events.viewEvents.applicantsTab.searchByApplicant}
      headlines={headLines}
      isInviteApplicants={true}
    />
  );
};

export default InviteApplicants;
