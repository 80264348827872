import { axiosInstance } from "./index";

const getDocument = async ({ token, params }) => {
  try {
    const response = await axiosInstance.get(`/event_requests/export_xls`, {
      params,
      headers: {
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    });
    return response;
  } catch (error) {
    console.error("Error while fetching the api:", error);
    throw error;
  }
};

export { getDocument };
