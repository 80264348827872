import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { generateDeviceId } from "../../../utils/Auth";
import messages from "../../../assets/locale/messages";
import { loginRequest } from "../../../store/Auth/actions";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import RadioButtonsGroup from "../../../components/RadioButton";
import { EnvelopeIcon } from "../../../utils/Icons";
import { getFirebaseToken } from "../../../firebase/helpers";
import "./Login.scss";

const Login = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { login } = messages[lang];

  const dispatch = useDispatch();

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      userType: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("validEmailFormat").required("emailRequired"),
      password: Yup.string().required("passwordRequired"),
      userType: Yup.string().required("userTypeRequired:"),
    }),
    onSubmit: ({ email, password, userType }) => {
      dispatch(
        loginRequest({
          data: {
            email,
            password,
            device_id: generateDeviceId(),
            fcm_token: getFirebaseToken(),
          },
          userType,
        })
      );
    },
  });

  const listofUsers = [
    {
      value: "admin",
      label: login.labels.admin,
    },
    {
      value: "supervisor",
      label: login.labels.supervisor,
    },
  ];

  return (
    <div className="login-page w-100">
      <h2 className="text-center mb-3 welcome-title">{login.title}</h2>
      <div className="box-contaienr px-4 pb-4 pt-3">
        <p className="my-2 text-center  fsize-20 font-urbanist-semibold text-dark-primary ">
          {login.subTitle}
        </p>
        <form className="form-login" onSubmit={handleSubmit} noValidate>
          <Input
            id="email"
            name="email"
            type="email"
            placeholder={login.placeholders.email}
            onChange={(value) => {
              setFieldTouched("email");
              setFieldValue("email", value.trim());
            }}
            value={values["email"]}
            inputClass="mb-1"
            labelClassName="font-medium mb-2 text-primary"
            inputWrapperClass="my-3"
            startAdornment={<EnvelopeIcon />}
            isInputHasErr={!!(touched["email"] && errors["email"])}
            errMsg={errors["email"]}
          />
          <Input
            id="password"
            name="password"
            type="password"
            placeholder={login.placeholders.password}
            onChange={(value) => {
              setFieldTouched("password");
              setFieldValue("password", value.trim());
            }}
            value={values["password"]}
            inputClass="mb-1"
            labelClassName="font-medium mb-2 text-primary"
            isInputHasErr={!!(touched["password"] && errors["password"])}
            errMsg={errors["password"]}
          />

          <RadioButtonsGroup
            wrapperClassName="d-flex align-items-center flex-row col-8 "
            labelClassName="d-none"
            className="d-flex align-items-start flex-row justify-content-between flex-nowrap w-75 mx-3 my-2 "
            required
            id="userType"
            name="userType"
            value={values["userType"]}
            handleChange={(e) => {
              setFieldTouched("userType");
              setFieldValue("userType", e.target.value);
            }}
            listOfRadios={listofUsers}
            hideStar
          />

          <Button
            label={login.labels.signin}
            type="submit"
            labelClass="fsize-16 text-white py-1 fweight-600 sign-in-btn-text"
            className="my-2"
            block
            disabled={!dirty || !isValid}
          />
          <div className="text-center mt-3">
            <Link
              to={ROUTE_PATHS.forgetPassword}
              className="text-secondary fsize-16 font-urbanist-regular text-underline">
              {login.labels.forgetPassword}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
