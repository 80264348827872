import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TableCell, TableRow, Chip } from "@mui/material";
import dayjs from "dayjs";

import { getLookupsRequest } from "../../store/Lookups/actions";
import {
  getPromotionRequestsRequest,
  editPromotionRequestsRequest,
} from "../../store/PromotionRequests/actions";
import messages from "../../assets/locale/messages";
import { lookupsKeys, promotionRequestsStatus } from "../../utils/Constants.js";
import { renderTextEllipsis, textContainOnlySpaces } from "../../utils/Helpers";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import Breadcrumbs from "../../components/BreadCrumbs";
import Filters from "../../components/Filter";
import Table from "../../components/Table";
import Button from "../../components/Button";
import Rate from "../../components/Rate";
import Pagination from "../../components/Pagination";
import Avatar from "../../components/Avatar";
import EmptyState from "../../components/EmptyState";
import promotionRequestsPlaceholder from "../../assets/images/employees/placeholder.png";
import Modal from "../../components/Modal";
import "./PromotionsRequests.scss";

const PromotionRequests = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const { promotionRequests, shared } = messages[lang];

  const isLoading = useSelector((state) => state.loader);
  const meta = useSelector((state) => state.promotionRequests.meta);
  const list = useSelector((state) => state.promotionRequests.list);
  const roles = useSelector((state) => state.lookups.general.roles);

  const [searchValue, setSearchValue] = useState("");
  const [filterRoles, setFilterRoles] = useState(null);
  const [filterStatus, setFilterStatus] = useState({
    id: 3,
    name: promotionRequests.listPromotionRequests.pending,
    value: promotionRequestsStatus.notAccepted,
  });
  const [rowId, setRowId] = useState();
  const [page, setPage] = useState(1);

  const statusOptions = [
    { id: 1, name: promotionRequests.listPromotionRequests.all, value: null },
    {
      id: 2,
      name: promotionRequests.listPromotionRequests.approved,
      value: promotionRequestsStatus.accepted,
    },
    {
      id: 3,
      name: promotionRequests.listPromotionRequests.pending,
      value: promotionRequestsStatus.notAccepted,
    },
    {
      id: 4,
      name: promotionRequests.listPromotionRequests.rejected,
      value: promotionRequestsStatus.rejected,
    },
  ];
  const [openActionModal, setOpenActionModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const getPromotionRequests = (pageNo, search, status, role) => {
    dispatch(
      getPromotionRequestsRequest({
        page: pageNo,
        items: 10,
        user_name: search,
        status: status?.value,
        user_role_id: role?.id,
        order_by_created_at: "desc",
      })
    );
  };

  const handleConfirmModalAction = () => {
    modalType == "accept"
      ? dispatch(
          editPromotionRequestsRequest({
            data: { status: "approved" },
            id: rowId,
            page,
            user_name: searchValue,
            status: filterStatus?.value,
            user_role_id: filterRoles?.id,
          })
        )
      : dispatch(
          editPromotionRequestsRequest({
            data: { status: "rejected" },
            id: rowId,
            page,
            user_name: searchValue,
            status: filterStatus?.value,
            user_role_id: filterRoles?.id,
          })
        );

    setOpenActionModal(false);
    setRowId(null);
    setModalType(null);
  };
  useEffect(() => {
    dispatch(getLookupsRequest([lookupsKeys.roles]));
    if (filterStatus) {
      const localizedFilterValue = statusOptions.find(
        (option) => option.id === filterStatus.id
      );
      setFilterStatus(localizedFilterValue);
    }
    getPromotionRequests(page, searchValue, filterStatus, filterRoles);
  }, [lang]);

  useEffect(() => {
    getPromotionRequests(page, searchValue, filterStatus, filterRoles);
  }, [page]);

  useEffect(() => {
    if (
      (searchValue.length >= 3 || searchValue.length === 0) &&
      !textContainOnlySpaces(searchValue)
    ) {
      setPage(1);
      getPromotionRequests(1, searchValue, filterStatus, filterRoles);
    }
  }, [searchValue]);

  useEffect(() => {
    setPage(1);
    getPromotionRequests(1, searchValue, filterStatus, filterRoles);
  }, [filterStatus]);

  useEffect(() => {
    setPage(1);
    getPromotionRequests(1, searchValue, filterStatus, filterRoles);
  }, [filterRoles]);

  const renderPromotionRequestsList = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column my-4">
            <EmptyState
              description={
                promotionRequests.listPromotionRequests.noDataDescription
              }
              imgSrc={promotionRequestsPlaceholder}
              showActionButton={false}
              containerClasses="py-5"
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="bg-white rounded-4 mt-2 pb-3">
            <Table
              className="rounded-4"
              headlines={[
                shared.image,
                promotionRequests.listPromotionRequests.applicantName,
                shared.role,
                shared.gender,
                promotionRequests.listPromotionRequests.profileRating,

                promotionRequests.listPromotionRequests.requestDate,
                shared.status,
                shared.actions,
              ]}
              hasActions={false}
              rows={list.map((row, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      <Avatar
                        imgSrc={row?.user?.image}
                        imgSrcAlt="user-picture"
                        size={30}
                        name={row?.user?.full_name_en}
                      />
                    </TableCell>
                    <TableCell
                      className={`name-cell ${
                        !row.user.deleted_at && "pointer"
                      }`}
                      onClick={() => {
                        if (!row.user.deleted_at) {
                          history.push(
                            ROUTE_PATHS.viewApplicant.replace(
                              ":id",
                              row?.user?.id
                            )
                          );
                        }
                      }}>
                      {renderTextEllipsis(
                        lang === "ar"
                          ? row?.user?.full_name_ar
                          : row?.user?.full_name_en
                      )}
                    </TableCell>
                    <TableCell>{row?.user?.role?.name}</TableCell>
                    <TableCell>
                      {row?.user?.gender
                        ? shared.genderOptions[row?.user?.gender]
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {row?.user?.rating ? (
                        <div className="MuiDataGrid-cellContent d-flex">
                          <Rate rating={row.user?.rating.toFixed(2)} />
                        </div>
                      ) : (
                        "-"
                      )}
                    </TableCell>

                    <TableCell className="date-cell">
                      {dayjs(row?.created_at).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={
                          promotionRequests.listPromotionRequests[row.status]
                        }
                        className={`fsize-14 fweight-500 text-white status-container ${row.status}`}
                      />
                    </TableCell>
                    <TableCell>
                      <div className="d-flex">
                        <Button
                          label={promotionRequests.listPromotionRequests.accept}
                          disabled={
                            row.status !== "pending" || !!row.user.deleted_at
                          }
                          //color="success"
                          small
                          className="accept-btn mx-3"
                          onClick={() => {
                            setModalType("accept");
                            setRowId(row.id);
                            setOpenActionModal(true);
                          }}
                        />
                        <Button
                          label={promotionRequests.listPromotionRequests.reject}
                          outlined
                          disabled={
                            row.status !== "pending" || !!row.user.deleted_at
                          }
                          negative
                          small
                          onClick={() => {
                            setModalType("reject");
                            setRowId(row.id);
                            setOpenActionModal(true);
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            />
          </div>
          <div className="d-flex justify-content-center py-3 mt-4">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
          <Modal
            open={openActionModal}
            handleClose={() => {
              setOpenActionModal(false);
              setRowId(null);
              setModalType(null);
            }}
            content={
              modalType == "accept" ? (
                <p>{promotionRequests.listPromotionRequests.accpetMsg}</p>
              ) : (
                <p>{promotionRequests.listPromotionRequests.rejectMsg}</p>
              )
            }
            hasActions
            confirmBtnTxt={shared.confirm}
            cancelBtnTxt={shared.cancel}
            handleConfirm={handleConfirmModalAction}
            handleCancel={() => {
              setOpenActionModal(false);
              setRowId(null);
              setModalType(null);
            }}
          />
        </>
      );
    }
  };

  return (
    <div className="conatiner promotion-requests-list-container ">
      <div className="my-3">
        <Breadcrumbs
          list={[
            {
              path: "",
              label: promotionRequests.listPromotionRequests.list,
            },
          ]}
          BreadCrumbHasBackIcon={false}
          className="mb-4"
        />
      </div>
      <Filters
        searchInput={{
          placeholder: promotionRequests.listPromotionRequests.search,
          name: "search",
          value: searchValue,
          onChange: (value) => setSearchValue(value),
        }}
        filters={[
          {
            type: "filter",
            name: "status",
            placeholder: promotionRequests.listPromotionRequests.status,
            value: filterStatus,
            options: statusOptions,
            onChange: (value) => setFilterStatus(value),
          },
          {
            type: "filter",
            name: "role",
            placeholder: shared.role,
            value: filterRoles,
            options: roles,
            onChange: (value) => setFilterRoles(value),
          },
        ]}
      />
      <div className="d-flex flex-column justify-content-between">
        {renderPromotionRequestsList()}
      </div>
    </div>
  );
};

export default PromotionRequests;
