import React from "react";
import { useSelector } from "react-redux";

import messages from "../../../../assets/locale/messages";
import CreateFormSectionTitle from "../../CreateFormSectionTitle";
import ImagePlaceHolder from "../ImagePlaceHolder";
import DynamicFiledsPlaceholder from "../DynamicFiledsPlaceholder";
import ViewZonesFields from "./ViewZonesFields";
import zonesImgPlaceholder from "../../../../assets/images/event-screen/zones-img.png";
import "../ViewEvent.scss";

const ZonesTab = () => {
  const lang = useSelector((state) => state.locale.lang);
  const event = useSelector((state) => state.events.event);
  const { events } = messages[lang];

  return (
    <>
      <div className="mb-3 step-part-content-wrapper">
        <div className="mb-4 d-flex justify-content-between top-wrapper">
          <CreateFormSectionTitle title={events.viewEvents.zones} />
        </div>
        {event?.zones?.length == 0 ? (
          <DynamicFiledsPlaceholder content={events.viewEvents.noZones} />
        ) : (
          event?.zones?.map((zone, index) => (
            <ViewZonesFields zone={zone} key={`zone-${index}`} />
          ))
        )}
        {event?.zones_image_url ? (
          <div className="view-image-mega-container">
            <div
              style={{ backgroundImage: `url(${event?.zones_image_url})` }}
              className="view-image-event"
            ></div>
            <div
              style={{ backgroundImage: `url(${event?.zones_image_url})` }}
              className="blur-image"
            ></div>
          </div>
        ) : (
          <ImagePlaceHolder
            img={zonesImgPlaceholder}
            placeHolderText={events.viewEvents.noImage}
            wrapperClassName="min-height-placeholder-event-image"
          />
        )}
      </div>
    </>
  );
};

export default ZonesTab;
