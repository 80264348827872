const notifications = {
  title: "الاشعارات",
  markAsRead: "تحديد كمقروء",
  seeAllNotifications: "مشاهدة جميع الاشعارات",
  seeMore: "مشاهدة المزيد",
  noNotifications: "لا توجد اشعارات حاليا",
  notificationsSupportAlert:
    "يرجى ملاحظة أن الإشعارات غير مدعومة في إصدار متصفحك الحالي، يرجى التحديث إلى الإصدار الأحدث",
};

export default notifications;
