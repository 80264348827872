import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import messages from "../../../../assets/locale/messages";
import CreateFormSectionTitle from "../../CreateFormSectionTitle";
import ViewInputData from "../../../../components/ViewInputData";
import GoogleMaps from "../../../../components/GoogleMaps";
import ImagePlaceHolder from "../ImagePlaceHolder";
import ViewProjectManagersFields from "./ViewProjectManagersFields";
import DynamicFiledsPlaceholder from "../DynamicFiledsPlaceholder";
import eventImgPlaceholder from "../../../../assets/images/event-screen/image-placeholder.png";
import mapImgPlaceholder from "../../../../assets/images/event-screen/map-image.png";
import { eventCheckinVerificationMethodsLocalization } from "../../../../utils/Constants";
import "../ViewEvent.scss";

const BasicInfoTab = () => {
  const lang = useSelector((state) => state.locale.lang);
  const event = useSelector((state) => state.events.event);
  const { events, shared } = messages[lang];

  const [eventCoords, setEventCoords] = useState({
    coords: null,
  });

  useEffect(() => {
    setEventCoords({
      coords: {
        lat: event?.lat,
        lng: event?.lng,
      },
    });
  }, [event]);

  return (
    <>
      <div className="mb-4 step-part-content-wrapper">
        <div className="mb-4 d-flex justify-content-between top-wrapper">
          <CreateFormSectionTitle title={events.viewEvents.basicInfo} />
        </div>
        <ViewInputData
          label={events.creatEvent.labels.eventImage}
          content={
            event?.image_url ? (
              <div className="view-image-mega-container">
                <div
                  style={{ backgroundImage: `url(${event.image_url})` }}
                  className="view-image-event"></div>
                <div
                  style={{ backgroundImage: `url(${event.image_url})` }}
                  className="blur-image"></div>
              </div>
            ) : (
              <ImagePlaceHolder
                img={eventImgPlaceholder}
                placeHolderText={events.viewEvents.noImage}
                wrapperClassName="min-height-placeholder-event-image"
              />
            )
          }
        />
        <ViewInputData
          label={events.creatEvent.labels.eventName}
          content={event?.name}
        />
        <ViewInputData
          label={events.creatEvent.labels.eventBrief}
          content={event?.description}
        />
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <ViewInputData
              label={events.creatEvent.labels.eventType}
              content={
                event?.payment_period && events.creatEvent[event.payment_period]
              }
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <ViewInputData
              label={events.creatEvent.labels.publishingMode}
              content={
                event?.is_private
                  ? events.creatEvent.private
                  : events.creatEvent.public
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-3">
            <ViewInputData
              label={events.creatEvent.labels.startBuildUpStart}
              content={
                event?.building_start_at &&
                dayjs(event.building_start_at).format("DD-MM-YYYY ,LT")
              }
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <ViewInputData
              label={events.creatEvent.labels.startBuildUpEnd}
              content={
                event?.building_end_at &&
                dayjs(event.building_end_at).format("DD-MM-YYYY ,LT")
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <ViewInputData
              label={events.creatEvent.labels.eventDayStart}
              content={
                event?.event_start_at &&
                dayjs(event.event_start_at).format("DD-MM-YYYY ,LT")
              }
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <ViewInputData
              label={events.creatEvent.labels.eventDayEnd}
              content={
                event?.event_end_at &&
                dayjs(event.event_end_at).format("DD-MM-YYYY ,LT")
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <ViewInputData
              label={events.creatEvent.labels.dismantlingDayStart}
              content={
                event?.dismantling_start_at &&
                dayjs(event.dismantling_start_at).format("DD-MM-YYYY ,LT")
              }
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <ViewInputData
              label={events.creatEvent.labels.dismantlingDayEnd}
              content={
                event?.dismantling_end_at &&
                dayjs(event.dismantling_end_at).format("DD-MM-YYYY ,LT")
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <ViewInputData
              label={events.creatEvent.labels.eventLocation}
              content={event?.address}
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <ViewInputData
              label={events.viewEvents.diameter}
              content={
                event?.event_radius && (
                  <>
                    {event?.event_radius} {shared.meter}
                  </>
                )
              }
            />
          </div>
        </div>
        {event?.lat && event?.lng ? (
          <GoogleMaps
            viewOnly
            className="my-3"
            isMarker={true}
            wrapperClasses="min-height-map mb-4"
            savedCoords={eventCoords}
          />
        ) : (
          <ImagePlaceHolder
            img={mapImgPlaceholder}
            placeHolderText={events.viewEvents.noLocation}
            wrapperClassName="min-height-placeholder-map"
            megaContainerClasses="map-placeholder-container"
          />
        )}
      </div>
      <div className="mb-3 step-part-content-wrapper">
        <div className="mb-4 d-flex justify-content-between top-wrapper">
          <CreateFormSectionTitle
            title={events.creatEvent.verificationMethod}
          />
        </div>
        <ViewInputData
          label={events.creatEvent.verificationMethod}
          content={
            events.creatEvent.labels[
              eventCheckinVerificationMethodsLocalization[
                event?.attendance_verification_method
              ]
            ]
          }
        />
      </div>
      <div className="mb-3 step-part-content-wrapper">
        <div className="mb-4 d-flex justify-content-between top-wrapper">
          <CreateFormSectionTitle title={events.viewEvents.projectManagers} />
        </div>
        {event?.project_managers?.length == 0 ? (
          <DynamicFiledsPlaceholder
            content={events.viewEvents.noProjectManagers}
          />
        ) : (
          event?.project_managers?.map((projectManager, index) => (
            <ViewProjectManagersFields
              projectManager={projectManager}
              key={`project-manager-${index}`}
            />
          ))
        )}
      </div>
    </>
  );
};

export default BasicInfoTab;
