import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tabs as MuiTabs, Tab, Box } from "@mui/material";

import "./Tabs.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ pt: children ? 3 : 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Tabs = ({ onSelectTab, tabs, activeTab, tabsClassName }) => {
  const [tabValue, setTabValue] = useState(activeTab || tabs[0].value);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    onSelectTab(newValue);
  };

  useEffect(() => {
    if(activeTab)
      setTabValue(activeTab);
  }, [activeTab])

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <MuiTabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={`tabs-container ${tabsClassName}`}>
          {tabs.map(({ value, label }, index) => (
            <Tab
              key={index}
              value={value}
              label={label}
              {...a11yProps(tabValue)}
            />
          ))}
        </MuiTabs>
      </Box>
      {tabs.map(({ value, content }, index) => (
        <TabPanel key={index} value={tabValue} index={value}>
          {content}
        </TabPanel>
      ))}
    </Box>
  );
};

Tabs.propTypes = {
  onSelectTab: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      content: PropTypes.element,
    })
  ).isRequired,
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabsClassName: PropTypes.string,
};
export default Tabs;
