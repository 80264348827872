import { all } from "redux-saga/effects";
import AuthSagas from "./Auth/sagas";
import EmployeesSagas from "./Employees/sagas";
import EventsSagas from "./Events/sagas";
import LookupsSagas from "./Lookups/sagas";
import RequestsSagas from "./Requests/sagas";
import ApplicantsSagas from "./applicants/sagas";
import PromotionRequestsSagas from "./PromotionRequests/sagas";
import NotificationsSagas from "./Notifications/sagas";
import DailyReportsRequestsSagas from "./TimeSheet/sagas";
import ApplicantsTimeSheetRequestsListSaga from "./ApplicantsTimeSheet/sagas";
import EvaluationsEventSagas from "./Evaluations/sagas";
import LocationsSagas from "./Locations/sagas";
import NoteSagas from "./Notes/sagas";
import DocumentFileSagas from "./Download/sagas";
import filterSaga from "./Filter/sagas";

export default function* rootSaga() {
  yield all([
    AuthSagas(),
    EmployeesSagas(),
    LookupsSagas(),
    EventsSagas(),
    RequestsSagas(),
    ApplicantsSagas(),
    PromotionRequestsSagas(),
    EvaluationsEventSagas(),
    NotificationsSagas(),
    DailyReportsRequestsSagas(),
    ApplicantsTimeSheetRequestsListSaga(),
    LocationsSagas(),
    NoteSagas(),
    DocumentFileSagas(),
    filterSaga(),
  ]);
}
