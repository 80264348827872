const zoneApplicants = {
  assignApplicants: "تحديد مقدم الطلب ",
  assignSupervisor: "تحديد مشرف ",
  applicants: "المتقدمين",
  search: "ابحث بأسم المتقدم",
  name: "الاسم",
  email: "البريد الالكتروني",
  applicantsRole:"وظيفة المتقدم",
  roles: "الوظائف",
  selected: "محدد",
  noApplicants: "لا يوجد متقدمين مقبولين حاليا",
  noASupervisors: "لا يوجد مشرفين مقبولين حاليا",
  unassignModal: {
    unassignConfirmationMsg: "هل أنت متأكد بإلغاء تفعيل منطقه مقدم الطلب ؟",
    title: "الغاء تفعيل مقدم الطلب"
  }, 
  assignModal: {
    title: "تعيين منظمين"
  },
  placeholder: "!لايوجد منظمين في هذه المنطقه حاليا",
  notAssigned: "!لايوجد مشرفين في هذه المنطقه حاليا"
};

export default zoneApplicants;
