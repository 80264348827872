import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Note.scss";
import Select from "@mui/material/Select";
import { FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Modal from "../Modal";
import messages from "../../assets/locale/messages";
import { useSelector } from "react-redux";

const Note = ({
  location,
  otherValue,
  handleSubmit,
  selectValue,
  setSelectValue,
  setOtherValue,
  handleDelete,
  validate,
  setValidate,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [required] = useState(20);
  const handleOpen = (id) => {
    setIsModalDelete(true);
    setDeleteID(id);
  };
  const handleValidate = (e) => {
    const value = e.target.value;
    setOtherValue(value);
    if (value.length >= required) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="form-group">
          <label htmlFor="location">{shared.location}</label>
          <FormControl fullWidth>
            <Select
              labelId="location"
              id="location"
              className="select-mui"
              value={selectValue}
              onChange={(e) => setSelectValue(e.target.value)}
              MenuProps={{
                PaperProps: {
                  className: "custom-scroll",
                },
              }}
            >
              <MenuItem value="--Select Location--" disabled={true}>
                {shared.selectedLocation}
              </MenuItem>
              {location.map((item) => (
                <MenuItem
                  className="position-relative"
                  key={item.id}
                  value={item.name}
                >
                  {item.name}
                  {item?.name !== selectValue && (
                    <button
                      className={`delete-item ${
                        lang === "en" ? "btn-r" : "btn-l"
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpen(item.id);
                      }}
                    >
                      x
                    </button>
                  )}
                </MenuItem>
              ))}
              <MenuItem value={shared.other}>{shared.other}</MenuItem>
            </Select>
          </FormControl>
        </div>

        {selectValue === shared.other && (
          <div className="form-group mt-4">
            <label htmlFor="input">{shared.other}</label>
            <input
              type="text"
              id="input"
              value={otherValue}
              onChange={handleValidate}
              placeholder={shared.locationPlaceholderOther}
            />
            {validate && (
              <span className="error-text">{shared.locationError}</span>
            )}
          </div>
        )}
      </form>
      <Modal
        handleClose={() => setIsModalDelete(false)}
        open={isModalDelete}
        content={<h4 className="text-center">{shared.sureMessage}</h4>}
        cancelBtnTxt={shared.cancel}
        handleCancel={() => setIsModalDelete(false)}
        confirmBtnTxt={shared.confirm}
        handleConfirm={() => handleDelete(deleteID)}
      />
    </>
  );
};

Note.propTypes = {
  location: PropTypes.array,
  otherValue: PropTypes.string,
  handleSubmit: PropTypes.func,
  selectValue: PropTypes.number,
  setSelectValue: PropTypes.func,
  setOtherValue: PropTypes.func,
  handleDelete: PropTypes.func,
  validate: PropTypes.bool,
  setValidate: PropTypes.func,
};
export default Note;
