import React from "react";
import PropTypes from "prop-types";
import { Button as MuiButton } from "@mui/material";

import "./Button.scss";

const Button = ({
  className,
  label,
  labelClass,
  type,
  outlined,
  disabled,
  onClick,
  block,
  color,
  negative,
  positive,
  small,
}) => (
  <MuiButton
    className={`btn ${block ? "btn-block" : ""} ${
      negative ? "btn-negative" : ""
    } ${positive ? "btn-positive" : ""} ${
      small ? "btn-small fsize-12 fweight-500" : ""
    } ${className}`}
    variant={outlined ? "outlined" : "contained"}
    type={type}
    disabled={disabled}
    onClick={onClick}
    color={color}
  >
    <span className={labelClass}>{label}</span>
  </MuiButton>
);

Button.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]),
  labelClass: PropTypes.string,
  type: PropTypes.string,
  outlined: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  block: PropTypes.bool,
  negative: PropTypes.bool,
  positive: PropTypes.bool,
  small: PropTypes.bool,
  color: PropTypes.string,
};

Button.defaultProps = { type: "button", color: "mainBlue" };

export default Button;
