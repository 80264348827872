import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik, FormikProvider, FieldArray } from "formik";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { ArrowUpward, Add } from "@mui/icons-material";

import {
  editEventRequest,
  publishEventRequest,
} from "../../../../store/Events/actions";
import messages from "../../../../assets/locale/messages";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import CreateFormSectionTitle from "../../CreateFormSectionTitle";
import { nonEmptyObject } from "../../../../utils/Helpers";
import Button from "../../../../components/Button";
import Upload from "../../../../components/Upload";
import RichText from "../../../../components/RichText";
import Modal from "../../../../components/Modal";
import publishEventModalImage from "../../../../assets/images/event-screen/publish-event.png";
import { uploadToS3, directories } from "../../../../utils/S3";
import ZonesFields from "../ZonesFields";
import RequirementsFields from "../RequirementsFields";
import { eventStatuses } from "../../../../utils/Constants";
import { getRequestsRequest } from "../../../../store/Requests/actions";
import "../CreateEvent.scss";

const StepThree = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const event = useSelector((state) => state.events.event);
  const list = useSelector((state) => state.requests.applicantsTab.list);
  const createdEventId = useSelector((state) => state.routing.createdEventId);
  const activePublishMode = useSelector(
    (state) => state.routing.activePublishMode
  );
  const { creatEvent } = messages[lang].events;

  const [img, setImg] = useState();
  // flag to upload image in edit mode if image changed only
  const [imgChanged, setImgChanged] = useState(false);

  // Remove comment if need PDF Again
  // const [pdf, setPdf] = useState();
  // flag to upload pdf in edit mode if image changed only
  // const [pdfChanged, setPdfChanged] = useState(false);

  const [submitAction, setSubmitAction] = useState();
  const [openPublishConfirm, setOpenPublishConfirm] = useState(false);
  const [removedZonesArray, setRemovedZonesArray] = useState([]);
  const [removedRequirementsArray, setremovedRequirementsArray] = useState([]);
  const [isEventUpcoming, setIsEventUpcoming] = useState(false);

  const zonesArrayHelpersRef = useRef(null);
  const requirementsArrayHelpersRef = useRef(null);

  useEffect(() => {
    if (event) setIsEventUpcoming(event?.status === eventStatuses.upcoming);
  }, [event]);

  const formThirdStepData = (values) => {
    let thirdStepData = {};
    thirdStepData.comments = values.comments;
    return thirdStepData;
  };

  const formik = useFormik({
    initialValues: {
      zones_attributes: [],
      zones_image_url: event?.zones_image_url || "",
      event_requirements_attributes: [],
      comments: event?.comments || null,
      // agreement_file_url: event?.agreement_file_url || "",
    },
    validationSchema: Yup.object({
      zones_attributes: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
            .max(50, "maxFiftyChars")
            .when([], {
              is: () => activePublishMode || isEventUpcoming,
              then: Yup.string()
                .max(50, "maxFiftyChars")
                .required("generalFieldRequired"),
              otherwise: Yup.string().max(50, "maxFiftyChars").notRequired(),
            }),
          zone_manager_id: Yup.string().when([], {
            is: () => activePublishMode || isEventUpcoming,
            then: Yup.string().required("generalFieldRequiredToPublish"),
            otherwise: Yup.string().nullable(),
          }),
          manager_name: Yup.string().when(["zone_manager_id"], {
            is: (val) => (activePublishMode || isEventUpcoming) && val == "0",
            then: Yup.string()
              .max(50, "maxFiftyChars")
              .nullable()
              .required("generalFieldRequiredToPublish"),
            otherwise: Yup.string().max(50, "maxFiftyChars").nullable(),
          }),
          manager_email: Yup.string().when(["zone_manager_id"], {
            is: (val) => (activePublishMode || isEventUpcoming) && val == "0",
            then: Yup.string()
              .email("validEmailFormat")
              .nullable()
              .required("generalFieldRequiredToPublish"),
            otherwise: Yup.string().email("validEmailFormat").nullable(),
          }),
          manager_phone_number: Yup.string().when(["zone_manager_id"], {
            is: (val) => (activePublishMode || isEventUpcoming) && val == "0",
            then: Yup.string()
              .min(9, "invalidPhone")
              .nullable()
              .required("generalFieldRequiredToPublish"),
            otherwise: Yup.string().min(9, "invalidPhone").nullable(),
          }),
          supervisor_id: Yup.number().nullable(),
        })
      ),
      zones_image_url: Yup.mixed().when([], {
        is: () => activePublishMode || isEventUpcoming,
        then: Yup.mixed().required("generalFieldRequiredToPublish"),
        otherwise: Yup.mixed().notRequired(),
      }),
      event_requirements_attributes: Yup.array().of(
        Yup.object().shape({
          requirement_id: Yup.string().when([], {
            is: () => activePublishMode || isEventUpcoming,
            then: Yup.string().required("generalFieldRequired"),
            otherwise: Yup.string().notRequired(),
          }),
          quantity: Yup.string().when([], {
            is: () => activePublishMode || isEventUpcoming,
            then: Yup.string()
              .nullable()
              .required("generalFieldRequiredToPublish"),
            otherwise: Yup.string().nullable(),
          }),
          explanation: Yup.string().max(100, "maxHundredChars").nullable(),
        })
      ),
      comments: Yup.string().nullable(),
      // agreement_file_url: Yup.mixed(),
    }),
    onSubmit: async (values) => {
      let thirdStepData = formThirdStepData(values);

      if (values.zones_image_url) {
        if (imgChanged) {
          const imgExt = values.zones_image_url.name.split(".").pop();
          const imgName = `zone_image_${new Date().getTime()}.${imgExt}`;
          const data = await uploadToS3(
            values.zones_image_url,
            // unique file name to avoid conflicts and without spaces as it makes errors
            // `${new Date().getTime()}_${values.zones_image_url.name
            //   .replace(/\s/g, "")
            //   .replace(/(\(|\))/g, "")}`,
            imgName,
            directories.events
          );
          thirdStepData.zones_image_url = data?.link;
        } else {
          thirdStepData.zones_image_url = values.zones_image_url;
        }
      } else {
        thirdStepData.zones_image_url = null;
      }

      // Remove comment if need PDF Again
      // if (values.agreement_file_url) {
      //   if (pdfChanged) {
      //     const data = await uploadToS3(
      //       values.agreement_file_url,
      //       // unique file name to avoid conflicts and without spaces as it makes errors
      //       `${new Date().getTime()}_${values.agreement_file_url.name
      //         .replace(/\s/g, "")
      //         .replace(/(\(|\))/g, "")}`,
      //       directories.events
      //     );
      //     thirdStepData.agreement_file_url = data?.link;
      //   } else {
      //     thirdStepData.agreement_file_url = values.agreement_file_url;
      //   }
      // }

      let filteredZones = [];
      let filteredRequirements = [];

      if (values.zones_attributes.length) {
        filteredZones = values.zones_attributes
          .filter((elt) => !!elt.name)
          .map((zone) => ({
            ...zone,
            zone_manager_id: zone.zone_manager_id || null,
            manager_name: zone.zone_manager_id ? null : zone.manager_name,
            manager_email: zone.zone_manager_id ? null : zone.manager_email,
            manager_phone_number: zone.zone_manager_id
              ? null
              : zone.manager_phone_number,
          }));
      }
      if (values.event_requirements_attributes.length) {
        filteredRequirements = values.event_requirements_attributes.filter(
          (elt) => !!elt.requirement_id
        );
      }

      thirdStepData.zones_attributes = [...filteredZones, ...removedZonesArray];

      thirdStepData.event_requirements_attributes = [
        ...filteredRequirements,
        ...removedRequirementsArray,
      ];

      if (id) {
        if (submitAction === "saveLater") {
          dispatch(
            editEventRequest({
              data: nonEmptyObject(JSON.parse(JSON.stringify(thirdStepData))),
              id: id,
              nextStep: "view-event",
            })
          );
        } else {
          dispatch(
            publishEventRequest({
              data: nonEmptyObject(JSON.parse(JSON.stringify(thirdStepData))),
              id: id,
            })
          );
        }
      } else if (createdEventId) {
        if (submitAction === "saveLater") {
          dispatch(
            editEventRequest({
              data: nonEmptyObject(JSON.parse(JSON.stringify(thirdStepData))),
              id: createdEventId,
              nextStep: "events",
            })
          );
        } else {
          dispatch(
            publishEventRequest({
              data: nonEmptyObject(JSON.parse(JSON.stringify(thirdStepData))),
              id: createdEventId,
            })
          );
        }
      }
    },
  });

  const onAddZoneBtnClick = () => {
    zonesArrayHelpersRef.current.push({
      name: "",
      zone_manager_id: null,
      manager_name: null,
      manager_email: null,
      manager_phone_number: null,
      manager_phone_number_country_id: 190,
      manager_phone_number_country_code: null,
    });
  };

  useEffect(() => {
    if (!formik.values.zones_attributes.length && !event?.zones?.length) {
      const addZones = setTimeout(() => {
        onAddZoneBtnClick();
      }, 200);
      return () => {
        clearTimeout(addZones);
      };
    }
  }, [formik.values.zones_attributes.length, event?.zones?.length]);

  const onAddRequirementBtnClick = () => {
    requirementsArrayHelpersRef.current.push({
      requirement_id: "",
      quantity: null,
      explanation: null,
    });
  };

  useEffect(() => {
    if (
      !formik.values.event_requirements_attributes.length &&
      !event?.event_requirements?.length
    ) {
      const addRequirements = setTimeout(() => {
        onAddRequirementBtnClick();
      }, 200);
      return () => {
        clearTimeout(addRequirements);
      };
    }
  }, [
    formik.values.event_requirements_attributes.length,
    event?.event_requirements?.length,
  ]);

  useEffect(() => {
    if (!event?.zones?.length == 0) {
      let zonesArray = event?.zones?.map((zone) => {
        return {
          id: zone.id,
          name: zone.name,
          zone_manager_id: zone.zone_manager
            ? zone.zone_manager.id
            : zone.manager_name ||
              zone.manager_email ||
              zone.manager_phone_number
            ? 0
            : null,
          manager_name: zone.zone_manager ? null : zone.manager_name,
          manager_email: zone.zone_manager
            ? zone.zone_manager.email
            : zone.manager_email,
          manager_phone_number: zone.zone_manager
            ? zone.zone_manager.phone_number
            : zone.manager_phone_number,
          manager_phone_number_country_id: 190,
          manager_phone_number_country_code: zone.manager_phone_dial_code,
          supervisor_id: zone?.supervisor?.id,
        };
      });
      formik.setFieldValue("zones_attributes", zonesArray);
    } else {
      formik.setFieldValue("zones_attributes", [
        {
          name: "",
          zone_manager_id: null,
          manager_name: null,
          manager_email: null,
          manager_phone_number: null,
          manager_phone_number_country_id: 190,
          manager_phone_number_country_code: null,
        },
      ]);
    }

    if (!event?.event_requirements?.length == 0) {
      let requirementsArray = event?.event_requirements?.map((requirement) => {
        return {
          id: requirement.id,
          requirement_id: requirement.requirement.id,
          quantity: requirement.quantity,
          explanation: requirement.explanation,
        };
      });
      formik.setFieldValue("event_requirements_attributes", requirementsArray);
    } else {
      formik.setFieldValue("event_requirements_attributes", [
        {
          requirement_id: "",
          quantity: null,
          explanation: null,
        },
      ]);
    }
    formik.setFieldValue("zones_image_url", event?.zones_image_url);
    formik.setFieldValue("comments", event?.comments);

    if (event && activePublishMode) addPublishValidations();
  }, [event]);

  const addPublishValidations = () => {
    const formikZonesTouchedArr = [];
    const formikRequirementsTouchedArr = [];

    if (!event.zones.length) {
      formikZonesTouchedArr.push({
        name: true,
        zone_manager_id: true,
      });
    } else {
      event.zones.forEach((zone) => {
        formikZonesTouchedArr.push({
          name: !zone.name,
          zone_manager_id:
            !zone.zone_manager &&
            !zone.manager_name &&
            !zone.manager_email &&
            !zone.manager_phone_number,
          manager_name: !zone.manager_name && !zone.zone_manager,
          manager_email: !zone.manager_email && !zone.zone_manager,
          manager_phone_number:
            !zone.manager_phone_number && !zone.zone_manager,
        });
      });
    }

    if (!event.event_requirements.length) {
      formikRequirementsTouchedArr.push({
        requirement_id: true,
        quantity: true,
      });
    } else {
      event.event_requirements.forEach((req) => {
        formikRequirementsTouchedArr.push({
          requirement_id: !req.requirement?.id,
          quantity: !req.quantity,
        });
      });
    }

    formik.setTouched({
      zones_image_url: !event.zones_image_url,
      zones_attributes: [...formikZonesTouchedArr],
      event_requirements_attributes: [...formikRequirementsTouchedArr],
    });
  };

  const getApplicantsList = () => {
    dispatch(
      getRequestsRequest({
        page: -1,
        event_id: id,
        is_supervisor: true,
        order_by_created_at: "asc",
        status: "approved",
        is_assigned_to_zone: false,
        responseType: "applicantsTab",
      })
    );
  };

  useEffect(() => {
    getApplicantsList();
  }, [id]);

  return (
    <div className="form-wrapper zones-container">
      <FormikProvider value={formik}>
        <form noValidate>
          <div className="mb-4 step-part-content-wrapper">
            <div className="mb-4 d-flex justify-content-between top-wrapper">
              <CreateFormSectionTitle title={creatEvent.zones} />

              {id &&
              [
                eventStatuses.ongoing,
                eventStatuses.cancelled,
                eventStatuses.past,
              ].includes(event?.status) ? (
                <></>
              ) : (
                <Button
                  label={
                    <>
                      <Add />
                      {creatEvent.add}
                    </>
                  }
                  labelClass="px-2 fsize-16 font-medium"
                  outlined
                  className="add-btn"
                  onClick={() => {
                    onAddZoneBtnClick();
                  }}
                />
              )}
            </div>
            <div className="dynamic-added-data-container ">
              <FieldArray
                name="zones_attributes"
                render={(arrayHelpers) => {
                  zonesArrayHelpersRef.current = arrayHelpers;

                  return formik.values.zones_attributes?.map((zone, index) => {
                    return (
                      <ZonesFields
                        id={`zone-${index}`}
                        key={`zone-${index}`}
                        zonesCount={formik.values.zones_attributes?.length}
                        zone={zone}
                        zoneSupervisor={event?.zones?.[index]?.["supervisor"]}
                        eventRequests={list}
                        removeClickFunction={(removeField) => {
                          if (zone.id) {
                            setRemovedZonesArray([
                              ...removedZonesArray,
                              { ...zone, _destroy: true },
                            ]);
                          }
                          removeField && arrayHelpers.remove(index);
                        }}
                        formik={formik}
                        index={index}
                        isEventUpcoming={isEventUpcoming}
                      />
                    );
                  });
                }}
              />
              {/* {activePublishMode &&
                formik.values.zones_attributes?.length == 0 && (
                  <div className="err fsize-20 d-flex justify-content-center my-4 px-3">
                    {creatEvent.addAtListOneZone}
                  </div>
                )} */}

              {id &&
                [eventStatuses.upcoming].includes(event?.status) &&
                formik.values.zones_attributes?.length == 0 && (
                  <div className="err fsize-20 d-flex justify-content-center my-4 px-3">
                    {creatEvent.addAtListOneZoneEditMode}
                  </div>
                )}
              <h6 className="text-dark-gray my-3">
                {creatEvent.labels.zonesMap}
              </h6>
              <Upload
                label={
                  <div className="pt-1 d-flex flex-column align-items-center justify-content-center event-image-upload-initial-container ">
                    <div className="d-flex justify-content-center align-items-center center-uplod-btn">
                      <ArrowUpward fontSize="small" />
                      <p className=" mb-0 text-center w-fit mx-2">
                        {creatEvent.labels.uploadLabel}
                      </p>
                    </div>
                  </div>
                }
                onChange={(img, value) => {
                  setImg(img);
                  setImgChanged(true);
                  formik.setFieldTouched("zones_image_url");
                  formik.setFieldValue("zones_image_url", value);
                }}
                img={img || formik.values["zones_image_url"]}
                name="zones_image_url"
                id="zones_image_url"
                isFullWidthImage={true}
                className="mt-3 min-height-image"
                fullWidthImageClassName="event-image-upload-container"
                wrapperClasses="mege-upload-container"
                isImage
                isBluredBackground
                disabled={
                  id &&
                  [
                    eventStatuses.ongoing,
                    eventStatuses.cancelled,
                    eventStatuses.past,
                  ].includes(event?.status)
                }
                required={activePublishMode || isEventUpcoming}
                errorMsg={formik.errors["zones_image_url"]}
                isInputHasErr={
                  !!(
                    formik.touched["zones_image_url"] &&
                    formik.errors["zones_image_url"]
                  )
                }
              />
            </div>
          </div>
          <div className="mb-4 step-part-content-wrapper">
            <div className="mb-4 d-flex justify-content-between top-wrapper">
              <CreateFormSectionTitle title={creatEvent.requirements} />

              {id &&
              [
                eventStatuses.ongoing,
                eventStatuses.cancelled,
                eventStatuses.past,
              ].includes(event?.status) ? (
                <></>
              ) : (
                <Button
                  label={
                    <>
                      <Add />
                      {creatEvent.add}
                    </>
                  }
                  labelClass="px-2 fsize-16 font-medium"
                  outlined
                  className="add-btn"
                  onClick={() => {
                    onAddRequirementBtnClick();
                  }}
                />
              )}
            </div>
            <div className="dynamic-added-data-container ">
              <FieldArray
                name="event_requirements_attributes"
                render={(arrayHelpers) => {
                  requirementsArrayHelpersRef.current = arrayHelpers;

                  return formik.values.event_requirements_attributes?.map(
                    (requirement, index) => (
                      <RequirementsFields
                        id={`requirement-${index}`}
                        key={`requirement-${index}`}
                        requirementsCount={
                          formik.values.event_requirements_attributes?.length
                        }
                        requirement={requirement}
                        removeClickFunction={(removeField) => {
                          if (requirement.id) {
                            setremovedRequirementsArray([
                              ...removedRequirementsArray,
                              { ...requirement, _destroy: true },
                            ]);
                          }
                          removeField && arrayHelpers.remove(index);
                        }}
                        formik={formik}
                        index={index}
                        isEventUpcoming={isEventUpcoming}
                      />
                    )
                  );
                }}
              />
              {/* {activePublishMode &&
                formik.values.event_requirements_attributes?.length == 0 && (
                  <div className="err fsize-20 d-flex justify-content-center my-4 px-3">
                    {creatEvent.addAtListOneRequirement}
                  </div>
                )} */}

              {id &&
                [eventStatuses.upcoming].includes(event?.status) &&
                formik.values.event_requirements_attributes?.length == 0 && (
                  <div className="err fsize-20 d-flex justify-content-center my-4 px-3">
                    {creatEvent.addAtListOneRequirementEditMode}
                  </div>
                )}
            </div>
          </div>
          <div className="mb-4 step-part-content-wrapper">
            <div className="mb-4 d-flex justify-content-between top-wrapper">
              <CreateFormSectionTitle title={creatEvent.comments} />
            </div>

            <RichText
              id="comments"
              name="comments"
              value={formik.values["comments"] || ""}
              onChange={(value) => {
                formik.setFieldTouched("comments");

                if (value?.replace(/(<([^>]+)>)/gi, "").length > 0) {
                  formik.setFieldValue("comments", value);
                } else {
                  formik.setFieldValue("comments", null);
                }
              }}
              className="comments-rich-text"
              maxLength={500}
              readOnly={
                id &&
                [
                  eventStatuses.ongoing,
                  eventStatuses.cancelled,
                  eventStatuses.past,
                ].includes(event?.status)
              }
              noData={
                id &&
                [
                  eventStatuses.ongoing,
                  eventStatuses.cancelled,
                  eventStatuses.past,
                ].includes(event?.status) &&
                !event?.comments
              }
            />
          </div>
          {/* Remove comment if need PDF Again */}
          {/* <div className="my-5 step-part-content-wrapper">
            <div className="mb-4 d-flex justify-content-between top-wrapper">
              <CreateFormSectionTitle title={creatEvent.agreementFile} />
            </div>
            <div className="file-upload">
              <Upload
                label={
                  <div className="pt-1 d-flex flex-column align-items-center justify-content-center  file-upload">
                    <div className="d-flex justify-content-center align-items-center center-uplod-btn">
                      <p className=" mb-0 text-center w-fit mx-2">
                        {creatEvent.labels.uploadFile}
                      </p>
                    </div>
                  </div>
                }
                onChange={(pdf, value) => {
                  setPdf(pdf);
                  setPdfChanged(true);
                  formik.setFieldValue("agreement_file_url", value);
                }}
                pdf={pdf || formik.values["agreement_file_url"]}
                name="agreement_file_url"
                id="agreement_file_url"
                accept="application/pdf"
                isPdf
              />
            </div>
          </div> */}
          <div className="d-flex justify-content-end align-items-center buttom-btns-container">
            {!activePublishMode && (
              <Button
                type="button"
                onClick={() => {
                  setSubmitAction("saveLater");
                  formik.handleSubmit();
                }}
                label={creatEvent.save}
                disabled={
                  (id &&
                    [eventStatuses.upcoming].includes(event?.status) &&
                    formik.values.zones_attributes?.length == 0) ||
                  (id &&
                    [eventStatuses.upcoming].includes(event?.status) &&
                    formik.values.event_requirements_attributes?.length == 0) ||
                  !formik.isValid
                }
                outlined
                className="mx-2 px-4 py-1  save-later-btn save"
              />
            )}

            {id && (
              <Button
                type="button"
                onClick={() => {
                  history.push(ROUTE_PATHS["viewEvent"].replace(":id", id));
                }}
                label={creatEvent.cancel}
                outlined
                className="mx-2 px-4 py-1  save-later-btn"
              />
            )}

            {id &&
            [
              eventStatuses.ongoing,
              eventStatuses.upcoming,
              eventStatuses.cancelled,
              eventStatuses.past,
            ].includes(event?.status) ? (
              <></>
            ) : (
              <Button
                onClick={() => {
                  setOpenPublishConfirm(true);
                }}
                label={creatEvent.saveAndPublish}
                labelClass="px-4"
                disabled={!formik.isValid}
                className="mx-2 py-1 continue-btn save-publish"
              />
            )}
          </div>
          <Modal
            open={openPublishConfirm}
            handleClose={() => {
              setOpenPublishConfirm(false);
            }}
            content={
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img alt="publish event image" src={publishEventModalImage} />
                <p className="mt-4 mb-0">
                  {event?.is_private
                    ? creatEvent.publishPrivateConfirmMsg
                    : creatEvent.publishModalConfirmMessage}
                </p>
              </div>
            }
            hasActions
            confirmBtnTxt={creatEvent.confirm}
            cancelBtnTxt={creatEvent.cancel}
            handleConfirm={() => {
              setSubmitAction("publish");
              formik.handleSubmit();
              setOpenPublishConfirm(false);
            }}
            handleCancel={() => {
              setOpenPublishConfirm(false);
            }}
          />
        </form>
      </FormikProvider>
    </div>
  );
};

export default StepThree;
