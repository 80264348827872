const changePassword = {
  title: "تعيين",
  subTitle: "كلمة مرور جديدة",
  resetPassword: "إعادة تعيين كلمة المرور",
  labels: {
    oldPassword: "كلمة المرور المؤقتة",
    newPassword: "كلمة المرور الجديدة",
    confirmNewPassword: "تأكيد كلمة المرور الجديدة",
  },
  placeholders: {
    oldPassword: "أدخل كلمة المرور القديمة",
    tempPassword: "أدخل كلمة المرور المؤقتة",
    newPassword: "أدخل كلمة المرور الجديدة",
    confirmNewPassword: "تأكيد كلمة المرور الجديدة",
  },
  actionBTN: "حفظ",
  resetPage: {
    oldPassword: "كلمة المرور القديمة",
    newPassword: "كلمة المرور الجديدة",
  },
};

export default changePassword;
