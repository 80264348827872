import React from "react";

export const AlertBarBgShapeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="none"
    viewBox="0 0 51 38"
    {...props}>
    <g fill="#FF5B50" clipPath="url(#clip0_937_7135)">
      <path d="M23.087 29.032c-2.5 0-21.208-4.008-26-1-.667 6.666 2.2 20.5 19 22.5h29c-2.516-5.094-10.5-13.5-13-15.5s-2-6.5 0-9 6.5-2 9.5-3.5 5-4.4 5-8c0-4.5-3.5-10-9.5-9.5s-9 6.5-8.5 9.5 3.5 7.5 0 11.5-3 3-5.5 3z"></path>
      <circle cx="48.25" cy="32.25" r="2.25"></circle>
      <circle cx="9.5" cy="5.5" r="5.5"></circle>
    </g>
    <defs>
      <clipPath id="clip0_937_7135">
        <path
          fill="#fff"
          d="M0 0H53.5V50.532H0z"
          transform="translate(-3)"></path>
      </clipPath>
    </defs>
  </svg>
);
