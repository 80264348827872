import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#448BCA",
    },
    secondary: {
      main: "#e95b25",
    },

    mainBlue: {
      main: "#448BCA",
    },

    grayIcon: {
      main: "#6F767E",
    },
    // define rest of theme colors and add the brand own colors
  },
  tooltip: {
    // add tooltip colors
  },
  typography: {
    fontFamily: "Roboto",
    h1: {
      fontWeight: "bold",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 991,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default Theme;
