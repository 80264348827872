const admins = {
  admins: "المسؤولين",
  list: {
    username: "الاسم",
    mail: "البريد الالكتروني",
    role: "الوظيفة",
    superAdmin: "مسؤول رئيسي",
    admin: "مسؤول",
    search: "ابحث عن مسؤول",
    noDataSubTitle: "لا يوجد مسؤولين حتي الان",
    noDataDescription: "ابدأ بإضافة مسؤول جديد",
    CreateAdminButton: "إضافة مسؤول جديد",
  },
  addAdmin: {
    title: "إضافة مسؤول جديدة",
    labels: {
      email: "البريد الالكتروني",
      username: "الاسم",
      role: "الوظيفة",
    },
    placeholders: {
      email: "أدخل البريد الإلكتروني",
      username: "أدخل الاسم",
      role: "اختر الوظيفة",
    },
  },
  editAdmin: {
    title: "تعديل مسؤول",
    labels: {
      edit: "حفظ",
    },
  },
  deleteAdmin: {
    title: "حذف مسؤول",
    deleteConfirmationMsg: "هل انت متأكد من حذف هذا المسؤول؟",
  },
};

export default admins;
