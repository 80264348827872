import React from "react";

export const AlertBarInfoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    fill="none"
    viewBox="0 0 45 58"
    {...props}>
    <path
      fill="#FF5B50"
      d="M25.37 41.92c1.364.612 1.599 2.45.432 3.385l-9.881 7.919c-1.219.977-3.037.242-3.235-1.308l-1.671-13.102c-.198-1.55 1.378-2.718 2.803-2.078L25.37 41.92z"></path>
    <circle cx="22.819" cy="23.16" r="22.181" fill="#FF5B50"></circle>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M23 13v13M23 32h.02"></path>
  </svg>
);
