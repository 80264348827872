import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import messages from "../../assets/locale/messages";
import EvaluationFormsList from "./EvaluationFormsList";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";

const CompletedEvaluation = () => {
  const location = useLocation();
  const { id } = useParams();

  const list = useSelector((state) => state.evaluations.forms.list);
  const lang = useSelector((state) => state.locale.lang);
  const { evaluations, shared } = messages[lang];

  const userType = localStorage.getItem("userType");

  const headlinesPM = [
    evaluations.listForms.applicantName,
    evaluations.listForms.email,
    shared.role,
    evaluations.listForms.zoneName,
    evaluations.listForms.finalRate,
  ];
  const headlinesSV = [
    evaluations.listForms.applicantName,
    evaluations.listForms.email,
    shared.role,
    evaluations.listForms.finalRate,
  ];

  const breadCrumbs = [
    {
      path: ROUTE_PATHS.evaluations,
      label: evaluations.listForms.title,
    },
    {
      path: "",
      label: `${
        location?.state?.evaluation_name
          ? location?.state?.evaluation_name
          : list.length
          ? list[0]?.evaluation_name
          : evaluations.evaluations
      } - ${evaluations.statusOptions.submitted}`,
    },
  ];

  return (
    <>
      <EvaluationFormsList
        isCompleted={true}
        breadcrumbs={breadCrumbs}
        secondBreadcrumb={{
          path: ROUTE_PATHS.completedEvaluations.replace(":id", id),
          evaluationName: location?.state?.evaluation_name,
          status: "submitted",
        }}
        headlines={userType === "admin" ? headlinesPM : headlinesSV}
      />
    </>
  );
};

export default CompletedEvaluation;
