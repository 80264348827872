export const ADD_PM = "ADD_PM";

export const GET_EMPLOYEES_REQUEST = "GET_EMPLOYEES_REQUEST";

export const GET_EMPLOYEES_RESPONSE = "GET_EMPLOYEES_RESPONSE";

export const GET_EMPLOYEE_REQUEST = "GET_EMPLOYEE_REQUEST";

export const GET_EMPLOYEE_RESPONSE = "GET_EMPLOYEE_RESPONSE";

export const EDIT_EMPLOYEE_REQUEST = "EDIT_EMPLOYEE_REQUEST";

export const EDIT_EMPLOYEE_RESPONSE = "EDIT_EMPLOYEE_RESPONSE";
