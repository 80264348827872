import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { TableCell, TableRow } from "@mui/material";

import messages from "../../../../assets/locale/messages";
import {
  renderTextEllipsis,
  textContainOnlySpaces,
} from "../../../../utils/Helpers";
import {
  evaluationFormStatus,
  eventStatuses,
  lookupsKeys,
} from "../../../../utils/Constants";
import Table from "../../../../components/Table";
// import Rate from "../../../../components/Rate";
import Pagination from "../../../../components/Pagination";
import Breadcrumbs from "../../../../components/BreadCrumbs";
import eventPlaceholder from "../../../../assets/images/event-screen/events-placeholder.svg";
import EmptyState from "../../../../components/EmptyState";
import Filters from "../../../../components/Filter";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import { getEventsRequest } from "../../../../store/Events/actions";
import { getLookupsRequest } from "../../../../store/Lookups/actions";
import { getApplicantRequest } from "../../../../store/applicants/actions";

const PastEvents = () => {
  const lang = useSelector((state) => state.locale.lang);
  const roles = useSelector((state) => state.lookups.general.roles);
  const isLoading = useSelector((state) => state.loader);
  const meta = useSelector((state) => state.events.meta);
  const list = useSelector((state) => state.events.list);
  const applicant = useSelector((state) => state.applicants.applicant);
  const { applicants, events, shared } = messages[lang];
  const [page, setPage] = useState(1);
  const [filterRoles, setFilterRoles] = useState(null);
  const [filterEventStatus, setFilterEventStatus] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const statusOptions = [
    {
      id: 1,
      value: eventStatuses.ongoing,
      name: events.listEvents.eventTabs.ongoing,
    },
    {
      id: 2,
      value: eventStatuses.upcoming,
      name: events.listEvents.eventTabs.upcoming,
    },
    {
      id: 3,
      value: eventStatuses.past,
      name: events.listEvents.eventTabs.previous,
    },
  ];

  useEffect(() => {
    dispatch(getLookupsRequest([lookupsKeys.roles]));
  }, [lang]);

  const getApplicant = () => {
    dispatch(
      getApplicantRequest({
        id,
      })
    );
  };

  useEffect(() => {
    getApplicant();
  }, [id, lang]);

  const getEvents = (pageNo, search, eventStatus, filterRoles) => {
    dispatch(
      getEventsRequest({
        page: pageNo,
        items: 10,
        name: search,
        status: eventStatus?.value || "ongoing,upcoming,past",
        role_id: filterRoles?.id,
        user_id: id,
        with_evaluation_forms: true,
        order_by_evaluation_forms_created_at: "asc",
      })
    );
  };

  useEffect(() => {
    if (filterEventStatus) {
      const localizedFilterValue = statusOptions.find(
        (option) => option.value === filterEventStatus.value
      );
      setFilterEventStatus(localizedFilterValue);
    }
  }, [lang]);

  useEffect(() => {
    if (
      (searchValue.length >= 3 || searchValue.length === 0) &&
      !textContainOnlySpaces(searchValue)
    ) {
      setPage(1);
      getEvents(1, searchValue, filterEventStatus, filterRoles);
    }
  }, [searchValue]);

  useEffect(() => {
    setPage(1);
    getEvents(1, searchValue, filterEventStatus, filterRoles);
  }, [lang, page]);

  useEffect(() => {
    setPage(1);
    getEvents(1, searchValue, filterEventStatus, filterRoles);
  }, [filterRoles]);

  useEffect(() => {
    setPage(1);
    getEvents(1, searchValue, filterEventStatus, filterRoles);
  }, [filterEventStatus]);

  const renderPastEventsList = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 mt-4 pb-3">
            <EmptyState
              description={events.listEvents.noDataDescription}
              imgSrc={eventPlaceholder}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else
      return (
        <>
          <div className="bg-white rounded-4 mt-2 pb-3">
            <Table
              className="rounded-4"
              headlines={[
                applicants.applicantEvents.eventName,
                applicants.applicantEvents.eventStatus,
                applicants.applicantEvents.role,
                applicants.applicantEvents.totalWorkingHours,
                applicants.applicantEvents.evaluationRounds,
              ]}
              hasActions={false}
              rows={list?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell
                    className="pointer"
                    onClick={() => {
                      history.push(
                        ROUTE_PATHS["viewEvent"].replace(":id", row.id)
                      );
                    }}>
                    {renderTextEllipsis(row.name)}
                  </TableCell>
                  <TableCell>{shared.eventStatusOptions[row.status]}</TableCell>
                  <TableCell>{row.user_role_in_event?.name}</TableCell>
                  <TableCell>
                    {row.workingHours ? row.workingHours : "-"}
                  </TableCell>
                  <TableCell>
                    {row.evaluation_forms?.length
                      ? row.evaluation_forms.map((round, i) => (
                          <p key={i}>
                            <a
                              className="text-decoration-underline text-main pointer"
                              onClick={() => {
                                // redirect to link
                                history.push(
                                  ROUTE_PATHS[
                                    round.status ===
                                    evaluationFormStatus.pending
                                      ? "viewApplicantEvaluationEdit"
                                      : "viewApplicantEvaluationView"
                                  ]
                                    .replace(":id", id)
                                    .replace(":formid", round.id),
                                  { applicantEventName: row.name }
                                );
                              }}>
                              {applicants.applicantEvents.round}
                              {i + 1}
                            </a>
                          </p>
                        ))
                      : "-"}
                  </TableCell>
                </TableRow>
              ))}
            />
          </div>
          <div className="d-flex justify-content-center py-3 mt-4">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
        </>
      );
  };

  return (
    <div className="conatiner d-flex flex-column justify-content-between">
      <div className="w-100 page-title-margin">
        <Breadcrumbs
          list={[
            {
              path: ROUTE_PATHS.applicants,
              label: applicants.listApplicants.listApplicantsBreadcrumb,
            },
            {
              path: ROUTE_PATHS.viewApplicant.replace(":id", id),
              label: applicant?.["full_name_" + lang],
            },
            {
              path: "",
              label: applicants.applicantDetails.events,
            },
          ]}
          BreadCrumbHasBackIcon={false}
        />
      </div>
      <Filters
        searchInput={{
          placeholder: applicants.search,
          name: "search",
          value: searchValue,
          onChange: (value) => setSearchValue(value),
        }}
        filters={[
          {
            type: "filter",
            name: "status",
            placeholder: shared.lists.status,
            value: filterEventStatus,
            options: statusOptions,
            onChange: (value) => setFilterEventStatus(value),
          },
          {
            type: "filter",
            name: "role",
            placeholder: shared.role,
            value: filterRoles,
            options: roles,
            onChange: (value) => setFilterRoles(value),
          },
        ]}
      />
      {renderPastEventsList()}
    </div>
  );
};

export default PastEvents;
