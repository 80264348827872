import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { TableCell, TableRow } from "@mui/material";

import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import Breadcrumbs from "../../components/BreadCrumbs";
import Tabs from "../../components/Tabs";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import GroupAvatars from "../../components/AvatarGroup";
import EmptyState from "../../components/EmptyState";
import eventPlaceholder from "../../assets/images/event-screen/events-placeholder.svg";
import messages from "../../assets/locale/messages";
import "./Events.scss";
import Tooltip from "../../components/Tooltip";
import Filters from "../../components/Filter";
import { getEventsRequest } from "../../store/Events/actions";
import { getProjectManagersRequest } from "../../store/Lookups/actions";
import {
  textContainOnlySpaces,
  formatDate,
  renderTextEllipsis,
} from "../../utils/Helpers";
import {
  eventPublishingModes,
  eventStatuses,
  eventTypes,
} from "../../utils/Constants";

const Events = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isNotJoining = history.location.pathname !== ROUTE_PATHS.requestsEvents;

  const lang = useSelector((state) => state.locale.lang);
  const { events, shared } = messages[lang];
  const isLoading = useSelector((state) => state.loader);
  const meta = useSelector((state) => state.events.meta);
  const list = useSelector((state) => state.events.list);
  const projectManagersList = useSelector(
    (state) => state.lookups.projectManagers
  );

  const [filterEventType, setFilterEventType] = useState("");
  const [filterPublishingMode, setFilterPublishingMode] = useState("");
  const [filterProjectManager, setFilterProjectManager] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [filterEventStatus, setFilterEventStatus] = useState(
    location?.state || eventStatuses.ongoing
  );
  // TO clear date filters with status change
  const [clearfilters, setClearFilters] = useState(false);
  useEffect(() => {
    if (clearfilters) setClearFilters(false);
  }, [clearfilters]);

  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const eventTypeOptions = [
    { id: 1, name: events.listEvents.daily, value: eventTypes.daily },
    { id: 2, name: events.listEvents.monthly, value: eventTypes.monthly },
  ];
  const publishingModeOptions = [
    {
      id: 1,
      name: events.creatEvent.private,
      value: eventPublishingModes.private,
    },
    {
      id: 2,
      name: events.creatEvent.public,
      value: eventPublishingModes.public,
    },
  ];
  const eventTabs = [
    {
      value: eventStatuses.ongoing,
      label: events.listEvents.eventTabs.ongoing,
    },
    {
      value: eventStatuses.upcoming,
      label: events.listEvents.eventTabs.upcoming,
    },
    {
      value: eventStatuses.past,
      label: events.listEvents.eventTabs.previous,
    },
    {
      value: eventStatuses.pending,
      label: events.listEvents.eventTabs.pending,
    },
    {
      value: eventStatuses.cancelled,
      label: events.listEvents.eventTabs.cancelled,
    },
  ];
  const joiningTabs = [
    {
      value: eventStatuses.ongoing,
      label: events.listEvents.eventTabs.ongoing,
    },
    {
      value: eventStatuses.upcoming,
      label: events.listEvents.eventTabs.upcoming,
    },
  ];

  useEffect(() => {
    dispatch(getProjectManagersRequest({ page: -1 }));
  }, []);

  useEffect(() => {
    if (filterEventType) {
      const localizedFilterValue = eventTypeOptions.find(
        (option) => option.id === filterEventType.id
      );
      setFilterEventType(localizedFilterValue);
    }
    if (filterPublishingMode) {
      const localizedFilterValue = publishingModeOptions.find(
        (option) => option.id === filterPublishingMode.id
      );
      setFilterPublishingMode(localizedFilterValue);
    }
  }, [lang]);

  useEffect(() => {
    getEvents(
      page,
      searchValue,
      filterEventType,
      filterStartDate,
      filterEndDate,
      filterEventStatus,
      filterProjectManager,
      filterPublishingMode
    );
  }, [page]);

  useEffect(() => {
    if (
      (searchValue.length >= 3 || searchValue.length === 0) &&
      !textContainOnlySpaces(searchValue)
    ) {
      setPage(1);
      getEvents(
        1,
        searchValue,
        filterEventType,
        filterStartDate,
        filterEndDate,
        filterEventStatus,
        filterProjectManager,
        filterPublishingMode
      );
    }
  }, [searchValue]);

  useEffect(() => {
    setPage(1);
    getEvents(
      1,
      searchValue,
      filterEventType,
      filterStartDate,
      filterEndDate,
      filterEventStatus,
      filterProjectManager,
      filterPublishingMode
    );
  }, [
    filterEventType,
    filterPublishingMode,
    filterStartDate,
    filterEndDate,
    filterEventStatus,
    filterProjectManager,
  ]);

  const getEvents = (
    pageNo,
    search,
    eventType,
    startDate,
    endDate,
    eventStatus,
    projectManager,
    publishingMode
  ) => {
    dispatch(
      getEventsRequest({
        page: pageNo,
        items: 10,
        name_query: search,
        status: eventStatus,
        payment_period: eventType?.value,
        project_manager_id: projectManager?.id,
        event_start_date: startDate || null,
        event_end_date: endDate || null,
        publish_mode: publishingMode?.value || null,
      })
    );
  };

  const handleSelect = (status) => {
    setFilterEventStatus(status);
    setSearchValue("");
    setFilterEventType("");
    setFilterProjectManager("");
    setFilterStartDate(null);
    setFilterEndDate(null);
    setClearFilters(true);
    setFilterPublishingMode("");
  };

  const renderEventsList = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column my-4">
            <EmptyState
              description={events.listEvents.noDataDescription}
              imgSrc={eventPlaceholder}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="bg-white rounded-4 mt-2 pb-3">
            <Table
              className="rounded-4"
              headlines={[
                shared.events,
                events.listEvents.projectManagers,
                events.listEvents.type,
                events.listEvents.startDate,
                events.listEvents.endDate,
                events.listEvents.publishMode,
              ]}
              hasActions={false}
              hasSizeLimitation={false}
              rows={list?.map((row, i) => (
                <TableRow
                  key={i}
                  className="row-hover"
                  onClick={() => {
                    isNotJoining
                      ? history.push(
                          ROUTE_PATHS["viewEvent"].replace(":id", row.id)
                        )
                      : history.push(
                          ROUTE_PATHS["viewRequests"].replace(":id", row.id)
                        );
                  }}
                >
                  <TableCell className=" w-20">
                    {renderTextEllipsis(row.name)}
                  </TableCell>
                  <TableCell className="w-20">
                    {row.project_managers?.length ? (
                      <Tooltip
                        title={
                          <div>
                            {row.project_managers.map((pm, i) => (
                              <p className="mb-0" key={i}>
                                {pm?.admin?.name}
                              </p>
                            ))}
                          </div>
                        }
                        content={
                          <div className="d-flex justify-content-start px-3">
                            <GroupAvatars
                              maxDisplyedWithLastOneNumber={3}
                              dataList={row.project_managers.map((manager) => ({
                                img: manager.admin.image_url,
                                name: manager.admin.name,
                              }))}
                            />
                          </div>
                        }
                      />
                    ) : (
                      <span className="px-4">-</span>
                    )}
                  </TableCell>
                  <TableCell className="w-20">
                    <span
                      className={`type-container fsize-14 ${row.payment_period}`}
                    >
                      {row.payment_period
                        ? events.listEvents[row.payment_period]
                        : "-"}
                    </span>
                  </TableCell>
                  <TableCell className="w-20 mobile-style">
                    {row.event_start_at ? formatDate(row.event_start_at) : "-"}
                  </TableCell>
                  <TableCell className="w-20 mobile-style">
                    {row.event_end_at ? formatDate(row.event_end_at) : "-"}
                  </TableCell>
                  <TableCell className={`w-20 mobile-style publish-mode-cell`}>
                    {row.is_private
                      ? events.creatEvent.private
                      : events.creatEvent.public}
                  </TableCell>
                </TableRow>
              ))}
            />
          </div>
          <div className="d-flex justify-content-center py-3 mt-4">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div className="events-list-container">
      <div className="page-title-margin">
        <Breadcrumbs
          list={[
            {
              path: "",
              label: events.listEvents.listEventsBreadcrumb,
            },
          ]}
          BreadCrumbHasBackIcon={false}
        />
      </div>
      <Tabs
        onSelectTab={handleSelect}
        tabs={isNotJoining ? eventTabs : joiningTabs}
        activeTab={location?.state || eventStatuses.ongoing}
        tabsClassName="mb-4"
      />
      <Filters
        searchInput={{
          placeholder: events.listEvents.searchPlaceHolder,
          name: "search",
          value: searchValue,
          onChange: (value) => setSearchValue(value),
        }}
        filters={[
          {
            type: "filter",
            name: "eventType",
            placeholder: events.listEvents.eventType,
            value: filterEventType,
            options: eventTypeOptions,
            onChange: (value) => setFilterEventType(value),
          },
          {
            type: "date",
            name: "startDate",
            placeholder: events.listEvents.startDate,
            label: events.listEvents.startDate,
            value: filterStartDate,
            onChange: (value) => setFilterStartDate(value),
            clearfilters,
          },
          {
            type: "date",
            name: "endDate",
            placeholder: events.listEvents.endDate,
            label: events.listEvents.endDate,
            value: filterEndDate,
            onChange: (value) => setFilterEndDate(value),
            clearfilters,
          },
          {
            type: "filter",
            name: "projectManager",
            placeholder: events.listEvents.projectManager,
            value: filterProjectManager,
            options: projectManagersList,
            onChange: (value) => setFilterProjectManager(value),
          },
          {
            type: "filter",
            name: "publishMode",
            placeholder: events.listEvents.publishMode,
            value: filterPublishingMode,
            options: publishingModeOptions,
            onChange: (value) => setFilterPublishingMode(value),
          },
        ]}
      />
      <div className="d-flex flex-column justify-content-between">
        {renderEventsList()}
      </div>
    </div>
  );
};

export default Events;
