import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { TextField, InputLabel, FormHelperText } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopTimePicker as MuiTimePicker } from "@mui/x-date-pickers";

import messages from "../../assets/locale/messages";
import "./TimePicker.scss";

const TimePicker = ({
  value,
  onChange,
  name,
  id,
  label,
  required,
  placeholder,
  wrapperClass,
  labelClass,
  inputClass,
  helperTextClass,
  closeOnSelect,
  disabled,
  readOnly,
  isInputHasErr,
  errMsg,
  helperText,
  innerLabel,
  ampm,
  onOpen,
  onClose,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations } = messages[lang];

  const [time, setTime] = useState(null);
  const [invalidTime, setInvalidTime] = useState(false);

  // To handle any required formatting on date
  useEffect(() => {
    const formattedDate = dayjs(value, "HH:mm");
    setTime(formattedDate);
  }, [value]);

  const handleTimeChange = (selectedTime) => {
    if (selectedTime && dayjs(selectedTime).format() === "Invalid Date") {
      setInvalidTime(true);
      setTime(selectedTime);
    } else {
      setInvalidTime(false);
      const formattedDate = selectedTime
        ? dayjs(selectedTime).format("HH:mm")
        : "";
      onChange(formattedDate);
    }
  };

  return (
    <div
      className={`time-picker-wrapper ${
        disabled ? "disabled" : ""
      } ${wrapperClass}`}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <InputLabel
          htmlFor={id || name}
          className={`time-picker-label ${labelClass}`}>
          <>
            {label}
            {required && label ? <span className="err"> *</span> : ""}
          </>
        </InputLabel>
        <MuiTimePicker
          value={time}
          label={innerLabel}
          onChange={handleTimeChange}
          closeOnSelect={closeOnSelect}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          placeholder="dfsf"
          ampm={ampm}
          onClose={onClose}
          onOpen={onOpen}
          inputProps={{ placeholder: placeholder }}
          className={`custom-time-picker ${inputClass} ${
            invalidTime || isInputHasErr ? "error-border" : ""
          }`}
          renderInput={(params) => (
            <TextField
              error={invalidTime || isInputHasErr}
              inputProps={{
                ...params.inputProps,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "0",
                  padding: "0",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
      <FormHelperText
        error={invalidTime || isInputHasErr}
        className={`${
          helperTextClass || "font-medium fsize-12 position-absolute"
        } ${lang === "en" ? "" : "text-end"}`}>
        {invalidTime
          ? inputsValidations["invalidTime"]
          : isInputHasErr
          ? inputsValidations[errMsg]
          : helperText}
      </FormHelperText>
    </div>
  );
};

export default TimePicker;

TimePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  wrapperClass: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  helperTextClass: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  isInputHasErr: PropTypes.bool,
  errMsg: PropTypes.string,
  helperText: PropTypes.string,
  innerLabel: PropTypes.string,
  ampm: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

TimePicker.defaultProps = {
  closeOnSelect: false,
  ampm: true,
};
