const zoneApplicants = {
  assignApplicants: "Assign Applicant",
  assignSupervisor: "Assign Supervisor",
  applicants: "Applicants",
  search: "Search by applicant name",
  name: "Name",
  email: "Email",
  applicantsRole: "Applicant's Role",
  roles: "Roles",
  selected: "Selected",
  noApplicants: "No applicants accepted yet",
  noASupervisors: "No Supervisors accepted yet",
  unassignModal: {
    unassignConfirmationMsg:
      "Are you sure that you want to unassign this applicant from the zone?",
    title: "Unassign Applicant",
  },
  assignModal: {
    title: "Assign zone Applicants",
  },
  placeholder: "No assigned applicants to this zone!",
  notAssigned: "Not assigned yet",
};

export default zoneApplicants;
