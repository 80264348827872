import * as types from "./types";

export const addEvaluationEventRequest = (payload) => ({
  type: types.ADD_EVALUATION_EVENT_REQUEST,
  payload,
});

//Submit form
export const submitEvaluationEventRequest = (payload) => ({
  type: types.SUBMIT_EVALUATION_EVENT_REQUEST,
  payload,
});

// list
export const getEvaluationsRoundsListRequest = (payload) => ({
  type: types.GET_EVALUATION_LIST_ROUNDS_REQUEST,
  payload,
});

export const getEvaluationsRoundsListResponse = (payload) => ({
  type: types.GET_EVALUATION_LIST_ROUNDS_RESPONSE,
  payload,
});

export const viewFormRequest = (payload) => ({
  type: types.VIEW_FORM_REQUEST,
  payload,
});

export const viewFormResponse = (payload) => ({
  type: types.VIEW_FORM_RESPONSE,
  payload,
});
//Completed_Pending_list
export const getEvaluationsCompletedPendingListRequest = (payload) => ({
  type: types.GET_EVALUATION_COMPLETED_PENDING_LIST_REQUEST,
  payload,
});

export const getEvaluationsCompletedPendingListResponse = (payload) => ({
  type: types.GET_EVALUATION_COMPLETED_PENDING_LIST_RESPONSE,
  payload,
});


