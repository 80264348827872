import React, { useState } from "react";
import PropTypes from "prop-types";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Tooltip as MuiTooltip } from "@mui/material";
import "./Tooltip.scss";

const Tooltip = ({ title, content, placement, className, hasArrow }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <div className="mobile-screen">
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <MuiTooltip
            title={title}
            placement={placement}
            classes={{ popper: className }}
            arrow={hasArrow}
            open={open}
          >
            <div onClick={handleTooltipOpen}>{content}</div>
          </MuiTooltip>
        </ClickAwayListener>
      </div>
      <div className="large-screen">
        <MuiTooltip
          title={title}
          placement={placement}
          classes={{ popper: className }}
          arrow={hasArrow}
        >
          {content}
        </MuiTooltip>
      </div>
    </>
  );
};

Tooltip.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]).isRequired,
  content: PropTypes.element.isRequired,
  placement: PropTypes.oneOf(["top", "left", "right", "bottom"]),
  className: PropTypes.string,
  hasArrow: PropTypes.bool,
  open: PropTypes.bool,
};

Tooltip.defaultProps = {
  placement: "bottom",
  hasArrow: true,
  className: "default-tooltip",
};

export default Tooltip;
