import {
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_RESPONSE,
  GET_LOOKUPS_REQUEST,
  GET_LOOKUPS_RESPONSE,
  GET_PROJECT_MANAGERS_REQUEST,
  GET_PROJECT_MANAGERS_RESPONSE,
  GET_EVENT_ZONES_REQUEST,
  GET_EVENT_ZONES_RESPONSE,
  GET_CITIES_REQUEST,
  GET_CITIES_RESPONSE,
  GET_SPEAKING_PROFICIENCY_REQUEST,
  GET_SPEAKING_PROFICIENCY_RESPONSE,
} from "./types";

// list
export const getLookupsRequest = (payload) => ({
  type: GET_LOOKUPS_REQUEST,
  payload,
});

export const getLookupsResponse = (payload) => ({
  type: GET_LOOKUPS_RESPONSE,
  payload,
});

export const getCountriesRequest = (payload) => ({
  type: GET_COUNTRIES_REQUEST,
  payload,
});

export const getCountriesResponse = (payload) => ({
  type: GET_COUNTRIES_RESPONSE,
  payload,
});

export const getCitiesRequest = (payload) => ({
  type: GET_CITIES_REQUEST,
  payload,
});

export const getCitiesResponse = (payload) => ({
  type: GET_CITIES_RESPONSE,
  payload,
});

export const getProjectManagersRequest = (payload) => ({
  type: GET_PROJECT_MANAGERS_REQUEST,
  payload,
});

export const getProjectManagersResponse = (payload) => ({
  type: GET_PROJECT_MANAGERS_RESPONSE,
  payload,
});

export const getEventZonesRequest = (payload) => ({
  type: GET_EVENT_ZONES_REQUEST,
  payload,
});

export const getEventZonesResponse = (payload) => ({
  type: GET_EVENT_ZONES_RESPONSE,
  payload,
});

export const getSpeakingProficiencyRequest = (payload) => ({
  type: GET_SPEAKING_PROFICIENCY_REQUEST,
  payload,
});

export const getSpeakingProficiencyResponse = (payload) => ({
  type: GET_SPEAKING_PROFICIENCY_RESPONSE,
  payload,
});
