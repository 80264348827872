import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  FilledInput,
  InputLabel,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  EmailOutlined,
} from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";

import messages from "../../assets/locale/messages";
import "./Input.scss";

const Input = ({
  name,
  value,
  onChange,
  id,
  label,
  type,
  placeholder,
  required,
  defaultValue,
  isInputHasErr,
  errMsg,
  disabled,
  multiline,
  rows,
  maxRows,
  minRows,
  startAdornment,
  endAdornment,
  fullWidth,
  helperText,
  inputClass,
  inputWrapperClass,
  labelClassName,
  helperTextClass,
  labelAdornment,
  inputEndAdorment,
  ...props
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations } = messages[lang];

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={`input-wrapper ${inputWrapperClass}`}>
      <InputLabel htmlFor={id || name} className={labelClassName}>
        <>
          {label}
          {required ? <span className="err-asterisk"> *</span> : ""}
          {labelAdornment && <span className="ms-2">{labelAdornment}</span>}
        </>
      </InputLabel>
      <>
        <FilledInput
          id={id || name}
          name={name}
          type={showPassword ? "text" : type}
          value={value}
          defaultValue={defaultValue}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          error={isInputHasErr}
          fullWidth={fullWidth}
          multiline={multiline}
          rows={rows}
          maxRows={maxRows}
          minRows={minRows}
          className={`${inputClass} ${isInputHasErr ? "error-border" : ""}`}
          disableUnderline={true}
          autoComplete="off"
          endAdornment={
            type === "password" ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOutlined className="end-icon-space" />
                  ) : (
                    <VisibilityOffOutlined className="end-icon-space" />
                  )}
                </IconButton>
              </InputAdornment>
            ) : (
              endAdornment
            )
          }
          startAdornment={
            startAdornment ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : type === "email" ? (
              <EmailOutlined className="icon-space" />
            ) : type === "password" ? (
              <LockIcon color="gray" className="icon-space" />
            ) : null
          }
          {...props}
        />
        {inputEndAdorment}
      </>
      <FormHelperText
        error={isInputHasErr}
        className={`${helperTextClass || "font-medium fsize-12"} ${
          lang === "en" ? "" : "text-end"
        }`}
      >
        {isInputHasErr ? inputsValidations[errMsg] : helperText}
      </FormHelperText>
    </div>
  );
};

export default Input;

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  isInputHasErr: PropTypes.bool,
  errMsg: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  inputEndAdorment: PropTypes.element,
  startAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  endAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  inputClass: PropTypes.string,
  inputWrapperClass: PropTypes.string,
  labelClassName: PropTypes.string,
  helperTextClass: PropTypes.string,
  labelAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Input.defaultProps = {
  type: "text",
  fullWidth: true,
  value: "",
};
