import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useFormik, FormikProvider, FieldArray } from "formik";
import * as Yup from "yup";
import { Add } from "@mui/icons-material";

import { editEventRequest } from "../../../../store/Events/actions";
import messages from "../../../../assets/locale/messages";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import CreateFormSectionTitle from "../../CreateFormSectionTitle";
import Button from "../../../../components/Button";
import {
  // TODO: Uncomment when needed again
  // ONLY_POSITIVE_NUMBERS,
  ONLY_POSITIVE_NUMBERS_WITH_ZERO,
  ONLY_POSITIVE_NUMBERS_WITH_MAX_TWO_DECIMAL_PLACES_WITH_ZERO,
} from "../../../../utils/Patterns";
import { nonEmptyObject } from "../../../../utils/Helpers";
import { eventStatuses, eventTypes } from "../../../../utils/Constants";
import RoleFields from "../RoleFields";
// import dayjs from "dayjs";

const StepTwo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const event = useSelector((state) => state.events.event);
  const createdEventId = useSelector((state) => state.routing.createdEventId);
  const activePublishMode = useSelector(
    (state) => state.routing.activePublishMode
  );
  const { creatEvent } = messages[lang].events;

  const [submitAction, setSubmitAction] = useState();
  const [removedRolesِِArray, setRemovedRolesarray] = useState([]);
  const [isEventUpcoming, setIsEventUpcoming] = useState(false);
  const [isEventOngoing, setIsEventOngoing] = useState(false);
  const [isEventDaily, setIsEventDaily] = useState(false);
  const [areMarginsValid, setAreMarginsValid] = useState(true);

  const rolesArrayHelpersRef = useRef(null);

  useEffect(() => {
    if (event) {
      setIsEventUpcoming(event?.status === eventStatuses.upcoming);
      setIsEventOngoing(event.status === eventStatuses.ongoing);
      setIsEventDaily(event.payment_period === eventTypes.daily);
    }
  }, [event]);

  const formSecondStepData = () => {
    let SecondStepData = {};

    return SecondStepData;
  };

  const getInitialValues = () => {
    return {
      event_roles_attributes: [],
    };
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: Yup.object({
      event_roles_attributes: Yup.array().of(
        Yup.object().shape({
          role_id: Yup.string().when([], {
            is: () => activePublishMode || isEventUpcoming,
            then: Yup.string().required("generalFieldRequiredToPublish"),
            otherwise: Yup.string().notRequired(),
          }),
          maximum_number_of_males: Yup.string().when([], {
            is: () => activePublishMode || isEventUpcoming,
            then: Yup.string()
              .matches(
                ONLY_POSITIVE_NUMBERS_WITH_ZERO,
                "onlyPositiveNumbersWithZero"
              )
              .nullable()
              .required("generalFieldRequiredToPublish"),
            otherwise: Yup.string()
              .matches(
                ONLY_POSITIVE_NUMBERS_WITH_ZERO,
                "onlyPositiveNumbersWithZero"
              )
              .nullable(),
          }),
          maximum_number_of_females: Yup.string().when([], {
            is: () => activePublishMode || isEventUpcoming,
            then: Yup.string()
              .matches(
                ONLY_POSITIVE_NUMBERS_WITH_ZERO,
                "onlyPositiveNumbersWithZero"
              )
              .nullable()
              .required("generalFieldRequiredToPublish"),
            otherwise: Yup.string()
              .matches(
                ONLY_POSITIVE_NUMBERS_WITH_ZERO,
                "onlyPositiveNumbersWithZero"
              )
              .nullable(),
          }),
          // TODO: Uncomment when needed again
          // number_of_shifts: Yup.string().when([], {
          //   is: () => activePublishMode || isEventUpcoming,
          //   then: Yup.string()
          //     .matches(ONLY_POSITIVE_NUMBERS, "onlyPositiveNumber")
          //     .nullable()
          //     .required("generalFieldRequiredToPublish"),
          //   otherwise: Yup.string()
          //     .matches(ONLY_POSITIVE_NUMBERS, "onlyPositiveNumber")
          //     .nullable(),
          // }),
          payroll_amount: Yup.string().when([], {
            is: () => activePublishMode || isEventUpcoming,
            then: Yup.string()
              .matches(
                ONLY_POSITIVE_NUMBERS_WITH_MAX_TWO_DECIMAL_PLACES_WITH_ZERO,
                "onlyPositiveNumberWithMaxTwoDecimalPlaces"
              )
              .nullable()
              .required("generalFieldRequiredToPublish"),
            otherwise: Yup.string()
              .matches(
                ONLY_POSITIVE_NUMBERS_WITH_MAX_TWO_DECIMAL_PLACES_WITH_ZERO,
                "onlyPositiveNumberWithMaxTwoDecimalPlaces"
              )
              .nullable(),
          }),
          working_hours_intervals: Yup.array().of(
            Yup.object().shape({
              starts_at: Yup.string().when([], {
                is: () =>
                  activePublishMode || isEventUpcoming || isEventOngoing,
                then: Yup.string()
                  .required("generalFieldRequiredToPublish")
                  .nullable(),
                otherwise: Yup.string().nullable(),
              }),
              ends_at: Yup.string().when([], {
                is: () =>
                  activePublishMode || isEventUpcoming || isEventOngoing,
                then: Yup.string()
                  .required("generalFieldRequiredToPublish")
                  .nullable(),
                otherwise: Yup.string().nullable(),
              }),
            })
          ),
          // TODO: Uncomment when needed again
          // arriving_at: Yup.string().when([], {
          //   is: () => activePublishMode || isEventUpcoming,
          //   then: Yup.string()
          //     .required("generalFieldRequiredToPublish")
          //     .nullable(),
          //   otherwise: Yup.string().nullable(),
          // }),
          before_check_in_margin: Yup.string().when([], {
            is: () =>
              (activePublishMode || isEventUpcoming || isEventOngoing) &&
              isEventDaily,
            then: Yup.string()
              .required("generalFieldRequiredToPublish")
              .matches(
                ONLY_POSITIVE_NUMBERS_WITH_ZERO,
                "onlyPositiveNumbersWithZero"
              )
              .nullable(),
            otherwise: Yup.string()
              .matches(
                ONLY_POSITIVE_NUMBERS_WITH_ZERO,
                "onlyPositiveNumbersWithZero"
              )
              .nullable(),
          }),
          after_check_in_margin: Yup.string().when([], {
            is: () =>
              (activePublishMode || isEventUpcoming || isEventOngoing) &&
              isEventDaily,
            then: Yup.string()
              .required("generalFieldRequiredToPublish")
              .matches(
                ONLY_POSITIVE_NUMBERS_WITH_ZERO,
                "onlyPositiveNumbersWithZero"
              )
              .nullable(),
            otherwise: Yup.string()
              .matches(
                ONLY_POSITIVE_NUMBERS_WITH_ZERO,
                "onlyPositiveNumbersWithZero"
              )
              .nullable(),
          }),
          before_check_out_margin: Yup.string().when([], {
            is: () =>
              (activePublishMode || isEventUpcoming || isEventOngoing) &&
              isEventDaily,
            then: Yup.string()
              .required("generalFieldRequiredToPublish")
              .matches(
                ONLY_POSITIVE_NUMBERS_WITH_ZERO,
                "onlyPositiveNumbersWithZero"
              )
              .nullable(),
            otherwise: Yup.string()
              .matches(
                ONLY_POSITIVE_NUMBERS_WITH_ZERO,
                "onlyPositiveNumbersWithZero"
              )
              .nullable(),
          }),
          after_check_out_margin: Yup.string().when([], {
            is: () =>
              (activePublishMode || isEventUpcoming || isEventOngoing) &&
              isEventDaily,
            then: Yup.string()
              .required("generalFieldRequiredToPublish")
              .matches(
                ONLY_POSITIVE_NUMBERS_WITH_ZERO,
                "onlyPositiveNumbersWithZero"
              )
              .nullable(),
            otherwise: Yup.string()
              .matches(
                ONLY_POSITIVE_NUMBERS_WITH_ZERO,
                "onlyPositiveNumbersWithZero"
              )
              .nullable(),
          }),
          uniform_code: Yup.string().max(200, "maxTowHundredChars").nullable(),
          required_training: Yup.string().nullable(),
        })
      ),
    }),
    onSubmit: (values) => {
      // let secondStepData = nonEmptyObject(JSON.parse(JSON.stringify(values)));
      let secondStepData = formSecondStepData();

      let filteredRoles = [];
      if (values.event_roles_attributes.length) {
        filteredRoles = values.event_roles_attributes.filter(
          (elt) => !!elt.role_id
        );
      }

      secondStepData.event_roles_attributes = [
        ...filteredRoles,
        ...removedRolesِِArray,
      ];

      if (id) {
        dispatch(
          editEventRequest({
            data: nonEmptyObject(JSON.parse(JSON.stringify(secondStepData))),
            id: id,
            nextStep: submitAction === "continue" ? 2 : "view-event",
          })
        );
      } else if (createdEventId) {
        dispatch(
          editEventRequest({
            data: nonEmptyObject(JSON.parse(JSON.stringify(secondStepData))),
            id: createdEventId,
            nextStep: submitAction === "continue" ? 2 : "events",
          })
        );
      }
    },
  });

  const onAddRoleBtnClick = () => {
    rolesArrayHelpersRef.current.push({
      role_id: "",
      maximum_number_of_males: null,
      maximum_number_of_females: null,
      // TODO: Uncomment when needed again
      // number_of_shifts: null,
      payroll_amount: null,
      working_hours_intervals: [
        {
          starts_at: null,
          ends_at: null,
        },
      ],
      // TODO: Uncomment when needed again
      // arriving_at: null,
      before_check_in_margin: null,
      after_check_in_margin: null,
      before_check_out_margin: null,
      after_check_out_margin: null,
      uniform_code: null,
      required_training: null,
    });
  };

  useEffect(() => {
    if (
      !formik.values.event_roles_attributes.length &&
      !event?.event_roles?.length
    ) {
      const addRoles = setTimeout(() => {
        onAddRoleBtnClick();
      }, 200);
      return () => {
        clearTimeout(addRoles);
      };
    }
  }, [formik.values.event_roles_attributes.length, event?.event_roles?.length]);

  useEffect(() => {
    if (!event?.event_roles?.length == 0) {
      let eventRolesArray = event?.event_roles?.map((role) => {
        return {
          id: role.id,
          role_id: role.role?.id,
          maximum_number_of_males:
            role.maximum_number_of_males == 0
              ? "0"
              : role.maximum_number_of_males,
          maximum_number_of_females:
            role.maximum_number_of_females == 0
              ? "0"
              : role.maximum_number_of_females,
          // TODO: Uncomment when needed again
          // number_of_shifts: role.number_of_shifts,
          payroll_amount: role.payroll_amount,
          uniform_code: role.uniform_code,
          required_training: role.required_training,
          // TODO: Uncomment when needed again
          // arriving_at: role.arriving_at,
          before_check_in_margin: role.before_check_in_margin,
          after_check_in_margin: role.after_check_in_margin,
          before_check_out_margin: role.before_check_out_margin,
          after_check_out_margin: role.after_check_out_margin,
          working_hours_intervals:
            role.working_hours_intervals.length !== 0
              ? role.working_hours_intervals
              : [
                  {
                    starts_at: null,
                    ends_at: null,
                  },
                ],
        };
      });
      formik.setFieldValue("event_roles_attributes", eventRolesArray);
    }
    if (event && activePublishMode) addPublishValidations();
  }, [event]);

  const addPublishValidations = () => {
    if (!event.event_roles.length) {
      formik.setTouched({
        ...formik.touched,
        event_roles_attributes: [
          {
            role_id: true,
            maximum_number_of_males: true,
            maximum_number_of_females: true,
            // TODO: Uncomment when needed again
            // number_of_shifts: true,
            payroll_amount: true,
            // TODO: Uncomment when needed again
            // arriving_at: true,
            before_check_in_margin: true,
            after_check_in_margin: true,
            before_check_out_margin: true,
            after_check_out_margin: true,
            working_hours_intervals: [
              {
                starts_at: true,
                ends_at: true,
              },
            ],
          },
        ],
      });
    } else {
      const formikRolesTouchedArr = [];
      event.event_roles.forEach((role) => {
        const roleIntervals = [];
        role.working_hours_intervals.forEach((slot) => {
          roleIntervals.push({
            starts_at: !slot.starts_at,
            ends_at: !slot.ends_at,
          });
        });
        formikRolesTouchedArr.push({
          role_id: !role.id,
          maximum_number_of_males: !role.maximum_number_of_males,
          maximum_number_of_females: !role.maximum_number_of_females,
          // TODO: Uncomment when needed again
          // number_of_shifts: !role.number_of_shifts,
          payroll_amount: !role.payroll_amount && role.payroll_amount != 0,
          // TODO: Uncomment when needed again
          // arriving_at: !role.arriving_at,
          before_check_in_margin: !role.before_check_in_margin,
          after_check_in_margin: !role.after_check_in_margin,
          before_check_out_margin: !role.before_check_out_margin,
          after_check_out_margin: !role.after_check_out_margin,
          working_hours_intervals: [...roleIntervals],
        });
      });

      formik.setTouched({
        ...formik.touched,
        event_roles_attributes: [...formikRolesTouchedArr],
      });
    }
  };

  return (
    <div className="form-wrapper">
      <FormikProvider value={formik}>
        <form noValidate>
          <div className="mb-4 step-part-content-wrapper">
            <div className="mb-4 d-flex justify-content-between top-wrapper">
              <CreateFormSectionTitle title={creatEvent.roles} />

              {id &&
              [
                eventStatuses.ongoing,
                eventStatuses.cancelled,
                eventStatuses.past,
              ].includes(event?.status) ? (
                <></>
              ) : (
                <Button
                  label={
                    <>
                      <Add />
                      {creatEvent.add}
                    </>
                  }
                  labelClass="px-2 fsize-16 font-medium"
                  outlined
                  className="add-btn"
                  onClick={() => {
                    onAddRoleBtnClick();
                  }}
                />
              )}
            </div>
            <div className="dynamic-added-data-container ">
              <FieldArray
                name="event_roles_attributes"
                render={(arrayHelpers) => {
                  rolesArrayHelpersRef.current = arrayHelpers;
                  return formik.values.event_roles_attributes?.map(
                    (role, index) => (
                      <RoleFields
                        id={`role-${index}`}
                        key={`role-${index}`}
                        rolesCount={formik.values.event_roles_attributes.length}
                        role={role}
                        removeClickFunction={(removeField) => {
                          if (role.id) {
                            setRemovedRolesarray([
                              ...removedRolesِِArray,
                              { ...role, _destroy: true },
                            ]);
                          }
                          removeField && arrayHelpers.remove(index);
                        }}
                        formik={formik}
                        roleFieldindex={index}
                        isEventUpcoming={isEventUpcoming}
                        isEventOngoing={isEventOngoing}
                        isEventDaily={isEventDaily}
                        setAreMarginsValid={setAreMarginsValid}
                      />
                    )
                  );
                }}
              />
              {/* {activePublishMode &&
                formik.values.event_roles_attributes?.length == 0 && (
                  <div className="err fsize-20 d-flex justify-content-center my-4 px-3">
                    {creatEvent.addAtListOneRole}
                  </div>
                )} */}
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center buttom-btns-container">
            {!activePublishMode && (
              <Button
                type="button"
                onClick={() => {
                  setSubmitAction("saveLater");
                  formik.handleSubmit();
                }}
                label={id ? creatEvent.save : creatEvent.saveLater}
                disabled={!formik.isValid || !areMarginsValid}
                outlined
                className="mx-2 px-4 py-1 save-later-btn"
              />
            )}

            {id && (
              <Button
                type="button"
                onClick={() => {
                  history.push(ROUTE_PATHS["viewEvent"].replace(":id", id));
                }}
                label={creatEvent.cancel}
                outlined
                className="mx-2 px-4 py-1  save-later-btn"
              />
            )}

            <Button
              onClick={() => {
                setSubmitAction("continue");
                formik.handleSubmit();
              }}
              label={id ? creatEvent.saveContinue : creatEvent.continue}
              disabled={!formik.isValid || !areMarginsValid}
              className="mx-2  continue-btn"
              labelClass="px-4"
            />
          </div>
        </form>
      </FormikProvider>
    </div>
  );
};

export default StepTwo;
