export const GET_REQUESTS_REQUEST = "GET_REQUESTS_REQUEST";
export const GET_REQUESTS_RESPONSE = "GET_REQUESTS_RESPONSE";
export const GET_ZONE_APPLICANTS_RESPONSE = "GET_ZONE_APPLICANTS_RESPONSE";
export const GET_APPLICANTS_TAB_RESPONSE = "GET_APPLICANTS_TAB_RESPONSE";
export const GET_UNASSIGN_APPLICANTS_RESPONSE =
  "GET_UNASSIGN_APPLICANTS_RESPONSE";

export const APPROVE_REQUEST = "APPROVE_REQUEST";

export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";

export const UPDATE_SUPERVISOR_EVENT_REQUEST =
  "UPDATE_SUPERVISOR_EVENT_REQUEST";

export const BULK_ASSIGN = "BULK_ASSIGN";

export const GET_EVENT_REQUEST_REQUEST = "GET_EVENT_REQUEST_REQUEST";
export const GET_EVENT_REQUEST_RESPONSE = "GET_EVENT_REQUEST_RESPONSE";
export const BULK_APPROVED_REQUESTS_REQUEST = "BULK_APPROVED_REQUESTS_REQUEST";
export const BULK_APPROVED_REQUESTS_RESPONSE =
  "BULK_APPROVED_REQUESTS_RESPONSE";
