import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";

import messages from "../../assets/locale/messages";
import "./DynamicTable.scss";

const DynamicTable = ({
  rows,
  columns,
  autoHeight,
  checkboxSelection,
  hideFooter,
  hideFooterPagination,
  hide,
  hideFooterSelectedRowCount,
  sortingMode,
  paginationMode,
  isRowSelectable,
  clickCellHandler,
  getRowClassName,
}) => {
  const [TableColumns, setTableColumns] = useState(columns || []);

  const [visibilityModel, setVisibilityModel] = useState(
    sessionStorage.getItem("applicantsVisibleCols")
      ? JSON.parse(sessionStorage.getItem("applicantsVisibleCols"))
      : null
  );

  const onClickCellHandler = (params) => {
    clickCellHandler(params);
  };
  const lang = useSelector((state) => state.locale.lang);
  const {
    shared: { dynamicTableActions },
  } = messages[lang];

  useEffect(() => {
    const columnsCopy = columns.map((col) => ({
      ...col,
      width: col.width || 190,
      hide: visibilityModel ? !visibilityModel[col.field] : col.hide,
    }));
    setTableColumns(columnsCopy);
  }, [columns]);

  // This section is used to manually locaise the "Hide" option in the column menu
  let hideLocaleInterval;
  const handleHideLocale = () => {
    hideLocaleInterval = setInterval(() => {
      if (lang === "ar") {
        const menuFirstItem =
          document.getElementsByClassName("MuiMenuItem-root")[0];
        if (menuFirstItem && menuFirstItem.textContent === "Hide") {
          menuFirstItem.textContent = "اخفاء";
        }
      }
    }, 10);
  };
  const handleClearInterval = () => {
    clearInterval(hideLocaleInterval);
  };

  return (
    <div
      className="dynamic-table-container rounded-4 bg-white rounded-4 mt-2 pb-3"
      style={{ width: "100%" }}>
      <DataGrid
        rows={rows}
        hide={hide}
        columns={TableColumns}
        experimentalFeatures={{ newEditingApi: true }}
        autoHeight={autoHeight}
        checkboxSelection={checkboxSelection}
        hideFooter={hideFooter}
        hideFooterPagination={hideFooterPagination}
        hideFooterSelectedRowCount={hideFooterSelectedRowCount}
        // Function that takes the row data as a parameter and
        // returns a bool to set if the row is selectable or not
        isRowSelectable={isRowSelectable}
        sortingMode={sortingMode}
        paginationMode={paginationMode}
        localeText={dynamicTableActions}
        onCellClick={(params) => onClickCellHandler(params)}
        headerHeight={45}
        rowHeight={45}
        columnBuffer={columns.length}
        onMenuOpen={handleHideLocale}
        onMenuClose={handleClearInterval}
        getRowClassName={getRowClassName}
        onColumnVisibilityModelChange={(model) => {
          sessionStorage.setItem(
            "applicantsVisibleCols",
            JSON.stringify(model)
          );
          setVisibilityModel(model);
        }}
      />
    </div>
  );
};

DynamicTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  autoHeight: PropTypes.bool,
  hide: PropTypes.bool,
  checkboxSelection: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideFooterPagination: PropTypes.bool,
  hideFooterSelectedRowCount: PropTypes.bool,
  sortingMode: PropTypes.oneOf(["client", "server"]),
  paginationMode: PropTypes.oneOf(["client", "server"]),
  isRowSelectable: PropTypes.func,
  clickCellHandler: PropTypes.func,
  getRowClassName: PropTypes.func,
  dynamicTable: PropTypes.object,
};

DynamicTable.defaultProps = {
  autoHeight: true,
  hide: false,
  hideFooterPagination: true,
  hideFooter: true,
  checkboxSelection: true,
  sortingMode: "server",
  paginationMode: "server",
};

export default DynamicTable;

// GridColDef Interface
// ColumnObject =  {
//     /*To connect column with the rows data*/
//     field: "name",

//     /*The title rendered in the header*/
//     headerName: "Name",

//     /*To set if each row cells are editable or not*/
//     /*If set to true each cell with click will render input to edit value*/
//     editable: false,

//     /*To set if each column should be shown or hidden by default*/
//     /*If hidden it can be re-shown from the column menu*/
//     hide: true,

//     /*To set if the column can be hidden or not*/
//     /*To set if the column menu will have option "hide" or not*/
//     /*If set to false the column menu won't have option "hide" & it can't be hidden even from menu toggles*/
//     hideable: false,

//     /*To show or hide the whole column editing menu*/
//     /*If set to false the drop down menu and the 3 dots will be removed from header*/
//     disableColumnMenu: false,

//     /*To set if the column will be filterable or not*/
//     /*If set to false dd menu will not have filter option*/
//     filterable: false,

//     /*To set if the column is sortable or not*/
//     /*If set to false the sorting options will be removed from dd menu*/
//     sortable: false,

//     /*To hide or show the sorting icon in the header*/
//     hideSortIcons: true,

//     /*To set the alignment of the rows cells*/
//     /*Values are "start" or "center"*/
//     align: "start",

//     /*To set the alignment of the column header*/
//     /*Values are "start" or "center"*/
//     headerAlign: "start",

//     /*The class name added to the cells of that column*/
//     cellClassName: "class",

//     /*The class name added to the cells of that column*/
//     headerClassName: "class",

//     /*Define spans the column should take*/
//     colSpan: 2,

//     /*If the header title is too long it'll will show the full title in a tooltip*/
//     description: "full column header title",

//     /*Allows to override the component rendered in edit cell mode for this column*/
//     renderEditCell: React element,

//     /*Allows to render a component in the column header cell*/
//     renderHeader: React element,
//   },
