import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Chip } from "@mui/material";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import "dayjs/locale/ar";

import messages from "../../../../assets/locale/messages";
import ViewInputData from "../../../../components/ViewInputData";
import { Certificate } from "../../../../utils/Icons/Certificate";
import { Cv } from "../../../../utils/Icons/Cv";
import { Education } from "../../../../utils/Icons/Education";
import { OtherDocument } from "../../../../utils/Icons/OtherDocument";
import { getFileName } from "../../../../utils/Helpers";
import "./Profile.scss";

const Profile = ({ applicant }) => {
  const lang = useSelector((state) => state.locale.lang);
  const [certificates, setCertificates] = useState([]);
  const [isSaudian, setIsSaudian] = useState(false);
  const { applicants, shared } = messages[lang];

  const renderApplicantProfile = (
    section,
    label,
    content,
    contentIcon = null,
    className = "col-12",
    multipleContents
  ) => {
    return (
      <div className={className}>
        <ViewInputData
          label={
            applicants.applicantDetails.applicantProfile.labels?.[section]?.[
              label
            ]
          }
          content={content}
          contentIcon={contentIcon}
          className="label-color"
          contentClassName="fweight-500"
          multipleContents={multipleContents}
        />
      </div>
    );
  };

  useEffect(() => {
    if (applicant) {
      setIsSaudian(
        applicant?.nationality?.name === "Saudi Arabia" ||
          applicant?.nationality?.name === "المملكة العربية السعودية"
      );
    }
    if (
      !applicant?.safety_and_security_documents?.length &&
      !applicant?.step_tofel_ielts_documents?.length
    )
      return;
    const allCertificates = [
      ...applicant.safety_and_security_documents,
      ...applicant.step_tofel_ielts_documents,
    ];
    setCertificates(allCertificates);
  }, [applicant]);

  return (
    <>
      <div className="bg-white p-4 rounded-2 mb-4">
        <div className="row">
          <div className="title-wrapper col-12 mb-4">
            <span></span>
            <h2 className="mb-0">
              {
                applicants.applicantDetails.applicantProfile.labels.basicInfo
                  .title
              }
            </h2>
          </div>
          {renderApplicantProfile(
            "basicInfo",
            "enName",
            applicant?.full_name_en,
            null,
            "col-sm-12 col-md-6"
          )}
          {renderApplicantProfile(
            "basicInfo",
            "arName",
            applicant?.full_name_ar,
            null,
            "col-sm-12 col-md-6"
          )}
          {renderApplicantProfile(
            "basicInfo",
            "dateOfBirthHijri",
            applicant?.hijri_date_of_birth,
            null,
            "col-sm-12 col-md-6"
          )}
          {renderApplicantProfile(
            "basicInfo",
            "dateOfBirthGregorian",
            dayjs(applicant?.gregorian_date_of_birth)
              .locale(lang)
              .format("DD MMMM YYYY"),
            null,
            "col-sm-12 col-md-6"
          )}
          {renderApplicantProfile("basicInfo", "age", applicant?.age)}
          {renderApplicantProfile(
            "basicInfo",
            "gender",
            shared.genderOptions[applicant?.gender]
          )}
          {renderApplicantProfile(
            "basicInfo",
            "weight",
            `${applicant?.weight} ${shared.kg}`
          )}
          {renderApplicantProfile(
            "basicInfo",
            "height",
            `${applicant?.height} ${shared.cm}`
          )}
          {renderApplicantProfile(
            "basicInfo",
            "nationality",
            applicant?.nationality?.name,
            null
          )}
          {isSaudian
            ? renderApplicantProfile(
                "basicInfo",
                "nationalId",
                applicant?.national_id,
                null,
                "col-sm-12 col-md-6"
              )
            : renderApplicantProfile(
                "basicInfo",
                "iqamaNumber",
                applicant?.iqama_id,
                null,
                "col-sm-12 col-md-6"
              )}
          {renderApplicantProfile(
            "basicInfo",
            "expiryDate",
            isSaudian
              ? applicant?.national_id_expiration_date
              : applicant?.iqama_expiration_date,
            null,
            "col-sm-12 col-md-6"
          )}
          {renderApplicantProfile(
            "basicInfo",
            "cityOfResidence",
            applicant?.city?.name
          )}
          {renderApplicantProfile(
            "basicInfo",
            "whatsAppNumber",
            applicant?.phone_number
          )}
          {renderApplicantProfile(
            "basicInfo",
            "matlooobNumber",
            applicant?.matloob_number
          )}
          {renderApplicantProfile("basicInfo", "role", applicant?.role?.name)}
          {renderApplicantProfile(
            "basicInfo",
            "disability",
            "",
            null,
            null,
            !!applicant?.disabilities?.length && (
              <>
                {applicant?.disabilities.map((disability, index) => (
                  <Chip
                    key={index}
                    label={disability?.name}
                    size="small"
                    variant="outlined"
                    className="mx-1 badge-content"
                  />
                ))}
              </>
            )
          )}
          {renderApplicantProfile(
            "basicInfo",
            "chronicDisease",
            "",
            null,
            null,
            !!applicant?.chronic_diseases?.length && (
              <>
                {applicant?.chronic_diseases.map((disease, index) => (
                  <Chip
                    key={index}
                    label={disease?.name}
                    size="small"
                    className="mx-1 badge-content "
                  />
                ))}
              </>
            )
          )}
        </div>
      </div>
      <div className="bg-white p-4 rounded-2 mb-4">
        <div className="row">
          <div className="title-wrapper col-12 mb-4">
            <span></span>
            <h2 className="mb-0">
              {
                applicants.applicantDetails.applicantProfile.labels
                  .educationInfo.title
              }
            </h2>
          </div>
          {renderApplicantProfile(
            "educationInfo",
            "education",
            applicant?.educational_level?.name
          )}
          {renderApplicantProfile(
            "educationInfo",
            "filedsOfStudy",
            applicant?.field_of_study_name || applicant?.field_of_study?.name
          )}
        </div>
      </div>
      <div className="bg-white p-4 rounded-2 mb-4">
        <div className="row">
          <div className="title-wrapper col-12 mb-4">
            <span></span>
            <h2 className="mb-0">
              {
                applicants.applicantDetails.applicantProfile.labels.languageInfo
                  .title
              }
            </h2>
          </div>
          {renderApplicantProfile(
            "",
            "",
            "",
            null,
            null,
            !!applicant?.user_languages?.length && (
              <>
                {applicant?.user_languages?.map((language, index) => (
                  <div
                    key={index}
                    className={
                      index !== applicant?.user_languages?.length - 1
                        ? "language-skills"
                        : ""
                    }>
                    <div className="fsize-16">{language?.language}</div>
                    <div className="proficiency-text fsize-14 fweight-400 text-gray text-capitalize">
                      {lang === "ar"
                        ? `${language.localized_speaking_proficiency}`
                        : `${language.speaking_proficiency}`}
                    </div>
                  </div>
                ))}
              </>
            )
          )}
        </div>
      </div>
      <div className="bg-white p-4 rounded-2 mb-4">
        <div className="row">
          <div className="title-wrapper col-12 mb-4">
            <span></span>
            <h2 className="mb-0">
              {
                applicants.applicantDetails.applicantProfile.labels.documentInfo
                  .title
              }
            </h2>
          </div>
          {renderApplicantProfile(
            "documentInfo",
            "certificates",
            "",
            null,
            null,
            certificates?.length && (
              <>
                {certificates?.map((document, index) => (
                  <div key={index}>
                    <Certificate />
                    <a
                      href={document}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-2 pb-1 text-decoration-underline text-main pointer">
                      {getFileName(document)}
                    </a>
                  </div>
                ))}
              </>
            )
          )}
          {renderApplicantProfile(
            "documentInfo",
            "academicQualifications",
            "",
            null,
            null,
            applicant?.academic_qualification_documents?.length && (
              <>
                {applicant.academic_qualification_documents.map(
                  (document, index) => (
                    <div key={index}>
                      <Education />
                      <a
                        href={document}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-2 pb-1 text-decoration-underline text-main pointer">
                        {getFileName(document)}
                      </a>
                    </div>
                  )
                )}
              </>
            )
          )}
          {renderApplicantProfile(
            "documentInfo",
            "cv",
            "",
            null,
            null,
            applicant?.cv_documents?.length && (
              <>
                {applicant.cv_documents.map((document, index) => (
                  <div key={index}>
                    <Cv />
                    <a
                      href={document}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-2 pb-1 text-decoration-underline text-main pointer">
                      {getFileName(document)}
                    </a>
                  </div>
                ))}
              </>
            )
          )}
          {renderApplicantProfile(
            "documentInfo",
            "other",
            "",
            null,
            null,
            applicant?.other_documents?.length && (
              <>
                {applicant.other_documents.map((document, index) => (
                  <div key={index}>
                    <OtherDocument />
                    <a
                      href={document}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-2 pb-1 text-decoration-underline text-main pointer">
                      {getFileName(document)}
                    </a>
                  </div>
                ))}
              </>
            )
          )}
        </div>
      </div>
      <div className="bg-white p-4 rounded-2">
        <div className="row">
          <div className="title-wrapper col-12 mb-4">
            <span></span>
            <h2 className="mb-0">
              {
                applicants.applicantDetails.applicantProfile.labels.bankInfo
                  .title
              }
            </h2>
          </div>
          {renderApplicantProfile(
            "bankInfo",
            "bankName",
            applicant?.bank?.name
          )}
          {renderApplicantProfile("bankInfo", "IBAN", applicant?.iban)}
          {renderApplicantProfile(
            "bankInfo",
            "cardHolder",
            applicant?.card_holder_name
          )}
        </div>
      </div>
    </>
  );
};

Profile.propTypes = {
  applicant: PropTypes.object,
};
export default Profile;
