import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import { InputLabel, FormHelperText } from "@mui/material";

import messages from "../../assets/locale/messages";
import { getCountriesRequest } from "../../store/Lookups/actions";
import "./PhoneInputField.scss";

const PhoneInputField = ({
  id,
  name,
  country,
  value,
  onChange,
  label,
  required,
  labelAdornment,
  inputWrapperClass,
  labelClassName,
  isInputHasErr,
  helperTextClass,
  helperText,
  errMsg,
  countryCodeEditable,
  disableDropdown,
  disabled,
  countryCode,
  ...props
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const countries = useSelector((state) => state.lookups.countries);
  const { inputsValidations } = messages[lang];
  const dispatch = useDispatch();

  const [dialCode, setDialCode] = useState("");
  const [countriesShortCodes, setCountriesShortCodes] = useState([]);

  useEffect(() => {
    dispatch(getCountriesRequest({ page: -1 }));
  }, []);

  useEffect(() => {
    if (countries.length) {
      setCountriesShortCodes(
        countries.map((country) => country.short_code.toLowerCase())
      );
    }
  }, [countries]);

  useEffect(() => {
    setDialCode(countryCode || "966");
  }, [countryCode]);

  return (
    <div
      className={`phone-input-wrapper ${
        disabled ? "disabled" : ""
      } ${inputWrapperClass}`}>
      <InputLabel htmlFor={id || name} className={labelClassName}>
        <>
          {label}
          {required ? <span className="err-asterisk"> *</span> : ""}
          {labelAdornment && <span className="ms-2">{labelAdornment}</span>}
        </>
      </InputLabel>
      <PhoneInput
        id={id}
        name={name}
        className={isInputHasErr ? "error-border" : ""}
        country={country}
        value={`${dialCode}${value}`}
        onChange={(fullValue, countryData) => {
          setDialCode(countryData.dialCode);
          // onChange(phoneNumber, selectedCountry)
          onChange(
            fullValue.slice(countryData?.dialCode?.length),
            countries.find((country) =>
              country.dial_code.includes(countryData.dialCode)
            )
          );
        }}
        onlyCountries={countriesShortCodes}
        preferredCountries={["sa"]}
        countryCodeEditable={countryCodeEditable}
        disableDropdown={disableDropdown}
        disabled={disabled}
        {...props}
      />
      <FormHelperText
        error={isInputHasErr}
        className={`${helperTextClass || "font-medium fsize-12"} ${
          lang === "en" ? "" : "text-end"
        }`}>
        {isInputHasErr ? inputsValidations[errMsg] : helperText}
      </FormHelperText>
    </div>
  );
};

export default PhoneInputField;

PhoneInputField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  country: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  required: PropTypes.bool,
  labelAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelClassName: PropTypes.string,
  inputWrapperClass: PropTypes.string,
  isInputHasErr: PropTypes.bool,
  countryCodeEditable: PropTypes.bool,
  disableDropdown: PropTypes.bool,
  errMsg: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  helperTextClass: PropTypes.string,
  phoneObj: PropTypes.object,
  disabled: PropTypes.bool,
  countryCode: PropTypes.string,
};
