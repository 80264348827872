import React from "react";
import PropTypes from "prop-types";

const DynamicFiledsPlaceholder = ({ content }) => {
  return (
    <div className="d-flex justify-content-center align-items-center ">
      <h3 className="m-5 text-center fsize-28 dimed-text">{content}</h3>
    </div>
  );
};

export default DynamicFiledsPlaceholder;

DynamicFiledsPlaceholder.propTypes = {
  content: PropTypes.string,
};
