import { v4 as uuid } from "uuid";
import { browserName } from "react-device-detect";

import store from "../store";

export const isAuth = () => {
  const state = store.getState();
  return !!state.auth.login?.auth_token;
};

export const generateDeviceId = () => {
  return `${browserName}-${uuid()}`;
};

export const setDeviceId = (device_id) => {
  return localStorage.setItem("device_id", device_id);
};

export const getDeviceId = () => {
  return localStorage.getItem("device_id");
};

export const setAdmin = (admin) => {
  localStorage.setItem("admin", JSON.stringify(admin));
};

export const clearAdminData = () => {
  localStorage.removeItem("device_id");
  sessionStorage.removeItem("device_id");
  localStorage.removeItem("admin");
  sessionStorage.removeItem("admin");
  localStorage.removeItem("userType");
  sessionStorage.removeItem("userType");

};