const employees = {
  employeesList: "قائمه الموظفين",
  viewEmployee: "عرض بيانات الموظف",
  editProfile: "تعديل الملف الشخصي",
  searchPlaceholder: "ابحث عن موظف",
  noDataDescription: "عذرا لاتوجد نتائج",
  profile: "الملف الشخصي",
  email: "البريد الالكتروني",
  mobileNumber: "رقم الهاتف",
  addProjectManager: {
    createEmployee: "أضف موظف",
    addEmployee: "اضافه موظف",
    name: "الاسم",
    email: "البريد الالكتروني",
    mobileNumber: "رقم الهاتف",
    jobTitle: "المسمى الوظيفي",
    labels: {
      name: "الاسم",
      email: "البريد الالكتروني",
      mobileNumber: "رقم الهاتف",
      jobTitle: "المسمى الوظيفي",
      employeeImage: "صورة الموظف",
      uploadLabel: "رفع صورة",
    },
    placeholders: {
      name: "ادخل الاسم",
      email: "ادخل البريد الالكتروني",
      mobileNumber: "ادخل رقم الهاتف",
      jobTitle: "ادخل المسمى الوظيفي",
    },
  },
  listEmployees: {
    name: "الاسم",
    email: "البريد الالكتروني",
    mobile: "رقم الهاتف",
    jobTitle: "المسمى الوظيفي",
  },
};
export default employees;
