import * as types from "./types";

const INITIAL_STATE = {
  rounds: {
    list: [],
    meta: {},
  },
  forms: {
    list: [],
    meta: {},
  },
  formData: {},
};

const evaluations = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_EVALUATION_LIST_ROUNDS_RESPONSE:
      return {
        ...state,
        rounds: {
          list: action.payload.list,
          meta: action.payload.meta,
        },
      };
    case types.GET_EVALUATION_COMPLETED_PENDING_LIST_RESPONSE:
      return {
        ...state,
        forms: {
          list: action.payload.list,
          meta: action.payload.meta,
        },
      };
    case types.VIEW_FORM_RESPONSE:
      return {
        ...state,
        formData: { ...action.payload },
      };
    default:
      return state;
  }
};

export default evaluations;
