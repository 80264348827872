export const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST";
export const GET_EVENTS_RESPONSE = "GET_EVENTS_RESPONSE";

export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST";
export const GET_EVENT_RESPONSE = "GET_EVENT_RESPONSE";

export const ADD_EVENT_REQUEST = "ADD_EVENT_REQUEST";

export const EDIT_EVENT_REQUEST = "EDIT_EVENT_REQUEST";
export const EDIT_EVENT_RESPONSE = "EDIT_EVENT_RESPONSE";

export const PUBLISH_EVENT_REQUEST = "PUBLISH_EVENT_REQUEST";

export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";

export const CANCEL_EVENT_REQUEST = "CANCEL_EVENT_REQUEST";

export const SET_INITIAL_PUBLISH_VALIDATION_STEP =
  "SET_INITIAL_PUBLISH_VALIDATION_STEP";

export const INVITE_APPLICANTS_REQUEST = "INVITE_APPLICANTS_REQUEST";

export const SHOW_EVENT_CONFLICT_WARNING_ALERT =
  "SHOW_EVENT_CONFLICT_WARNING_ALERT";

export const SEND_NOTIFICATION_REQUEST = "SEND_NOTIFICATION_REQUEST";
