import {
  ADD_PUSH_NOTIFICATION,
  GET_DROPDOWN_NOTIFICATIONS_RESPONSE,
  GET_NOTIFICATIONS_RESPONSE,
  SET_UNSEEN_NOTIFICATIONS_COUNT,
} from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  unseenNotificationsCount: localStorage.getItem("unseenNotificationsCount")
    ? +localStorage.getItem("unseenNotificationsCount")
    : 0,
  dropdownNotifications: [],
};

const notifications = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_RESPONSE: {
      const notificationsList = [...state.list];
      action.payload.list.forEach((elt) => {
        if (notificationsList.findIndex((el) => el.id === elt.id) === -1) {
          notificationsList.push(elt);
        }
      });
      return {
        ...state,
        list: action.payload.clear
          ? []
          : action.payload.meta.currentPage === 1
          ? [...action.payload.list]
          : [...notificationsList],
        meta: action.payload.meta,
      };
    }
    case ADD_PUSH_NOTIFICATION:
      var notificationsListCopy = [...state.list];
      if (
        notificationsListCopy.findIndex(
          (el) => el.id === action.payload.data.id
        ) === -1
      ) {
        notificationsListCopy.unshift(action.payload.data);
      }
      return {
        ...state,
        list: [...notificationsListCopy],
        unseenNotificationsCount: action.payload.badge,
      };
    case SET_UNSEEN_NOTIFICATIONS_COUNT:
      return {
        ...state,
        unseenNotificationsCount: action.payload,
      };
    case GET_DROPDOWN_NOTIFICATIONS_RESPONSE:
      return {
        ...state,
        dropdownNotifications: [...action.payload],
      };
    default:
      return state;
  }
};

export default notifications;
