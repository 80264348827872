import { put, call, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/evaluation";
import * as types from "./types";
import * as actions from "./actions";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import history from "../../routes/History";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";

function* addEvaluationEventSaga({ payload }) {
  try {
    const response = yield call(apis.createEvaluation, payload);
    sessionStorage.setItem("zonesEventId", payload);
    history.push(
      ROUTE_PATHS.inprogressEvaluations.replace(":id", response.data.data.id),
      {
        evaluation_name: response.data.data.name,
      }
    );
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}
//Submit evaluation form
function* submitEvaluationFormSaga({ payload }) {
  try {
    const userType = localStorage.getItem("userType");
    yield call(
      userType === "admin"
        ? apis.submitEvaluation
        : apis.submitSupervisorEvaluation,
      payload
    );
    yield showSuccessMsg("addedSuccessfully");
    const { redirectionPath, evaluation_name } = payload;
    yield history.push(redirectionPath, { evaluation_name });
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

//Evaluations Rounds List
function* getEvaluationsRoundsRequestsListSaga({ payload }) {
  try {
    const userType = localStorage.getItem("userType");
    const response = yield call(
      userType === "admin"
        ? apis.getEvaluationListRounds
        : apis.getEvaluationListRoundsSupervisor,
      payload
    );
    yield put(
      actions.getEvaluationsRoundsListResponse({
        list: response.data.data,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"],
        },
      })
    );

    if (+response.headers["current-page"] > +response.headers["total-pages"]) {
      yield put(
        actions.getEvaluationsRoundsListRequest({
          ...payload,
          page: +response.headers["total-pages"],
        })
      );
    }
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* getViewFormDetails({ payload }) {
  try {
    const userType = localStorage.getItem("userType");
    const response = yield call(
      userType === "admin"
        ? apis.getViewFormDetails
        : apis.getViewFormDetailsSupervisor,
      payload
    );

    yield put(actions.viewFormResponse(response.data.data));
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* getEvaluationsCompletedPendingRequestsListSaga({ payload }) {
  try {
    const userType = localStorage.getItem("userType");
    const response = yield call(
      userType === "admin"
        ? apis.getEvaluationCompletedPendingList
        : apis.getEvaluationCompletedPendingSuperVisorList,
      payload
    );
    yield put(
      actions.getEvaluationsCompletedPendingListResponse({
        list: response.data.data,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"],
        },
      })
    );
    if (+response.headers["current-page"] > +response.headers["total-pages"]) {
      yield put(
        actions.getEvaluationsCompletedPendingListRequest({
          ...payload,
          page: +response.headers["total-pages"],
        })
      );
    }
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

export default function* EvaluationsEventSagas() {
  yield takeEvery(types.ADD_EVALUATION_EVENT_REQUEST, addEvaluationEventSaga);
  yield takeEvery(
    types.SUBMIT_EVALUATION_EVENT_REQUEST,
    submitEvaluationFormSaga
  );
  yield takeEvery(
    types.GET_EVALUATION_LIST_ROUNDS_REQUEST,
    getEvaluationsRoundsRequestsListSaga
  );
  yield takeEvery(types.VIEW_FORM_REQUEST, getViewFormDetails);
  yield takeEvery(
    types.GET_EVALUATION_COMPLETED_PENDING_LIST_REQUEST,
    getEvaluationsCompletedPendingRequestsListSaga
  );
}
