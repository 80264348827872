import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { AccessTime } from "@mui/icons-material";

import messages from "../../../../../assets/locale/messages";
import Accordion from "../../../../../components/Accordion";
import ViewInputData from "../../../../../components/ViewInputData";
import { eventTypes } from "../../../../../utils/Constants";
import { checkFor12amIssue } from "../../../../../utils/Helpers";

const ViewRolesFields = ({ role }) => {
  const lang = useSelector((state) => state.locale.lang);
  const event = useSelector((state) => state.events.event);
  const { events, shared } = messages[lang];

  const renderMarginMinTime = (slot, margin, isBefore) => {
    if (slot && (margin || margin == 0)) {
      const slotDate = dayjs(slot, "HH:mm");
      const marginDate = isBefore
        ? slotDate.subtract(margin, "minute")
        : slotDate.add(margin, "minute");
      const marginTime =
        lang === "en"
          ? dayjs(marginDate).format("hh:mm a")
          : checkFor12amIssue(marginDate);

      return (
        <div className="show-time-margin d-flex align-items-center">
          <div
            className={`minutes margin-minutes-width ${
              lang === "en" ? "me-3" : "ms-3"
            }`}>
            {margin} {shared.minutes}
          </div>
          <div className="margin-time">
            <AccessTime
              className={`clock-icon ${lang === "en" ? "me-1" : "ms-1"}`}
            />
            <span className="time">{marginTime}</span>
          </div>
        </div>
      );
    } else {
      return margin;
    }
  };

  const formatTimeSlot = (slot) => {
    return slot
      ? lang === "en"
        ? dayjs(slot, "HH:mm").format("hh:mm a")
        : checkFor12amIssue(dayjs(slot, "HH:mm"))
      : null;
  };

  return (
    <div className="view-roles-tab">
      <Accordion
        title={role.role.name}
        content={
          <>
            <ViewInputData
              label={events.creatEvent.labels.nameOfRole}
              content={role.role.name}
            />
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <ViewInputData
                  label={events.creatEvent.labels.numberOfMales}
                  content={role.maximum_number_of_males}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <ViewInputData
                  label={events.creatEvent.labels.numberOfFemales}
                  content={role.maximum_number_of_females}
                />
              </div>
            </div>
            <div className="row">
              {/* <div className="col-sm-12 col-md-4">
                <ViewInputData
                  label={events.creatEvent.labels.numberOfShifts}
                  content={role.number_of_shifts}
                />
              </div> */}
              <div className="col-sm-12 col-md-4">
                <ViewInputData
                  label={events.creatEvent.labels.payrollAmount}
                  content={role.payroll_amount}
                />
              </div>
            </div>
            {/* Start Working Hours Slots */}
            <div className="working-hours-view">
              <label className="view-field-label">
                {events.creatEvent.labels.workingHours}
              </label>
              {role.working_hours_intervals?.length == 0 ? (
                <div className="row">
                  <div className="col-sm-12 col-md-4">
                    <ViewInputData
                      label={events.creatEvent.start}
                      content={null}
                      labelBesideField
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <ViewInputData
                      label={events.creatEvent.end}
                      content={null}
                      labelBesideField
                      dotClass
                    />
                  </div>
                </div>
              ) : (
                role.working_hours_intervals?.map((singleSlot, index) => (
                  <div className="row" key={`single-slot-${index}`}>
                    <div className="col-sm-12 col-md-4">
                      <ViewInputData
                        label={events.creatEvent.start}
                        content={formatTimeSlot(singleSlot.starts_at)}
                        labelBesideField
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <ViewInputData
                        label={events.creatEvent.end}
                        content={formatTimeSlot(singleSlot.ends_at)}
                        labelBesideField
                        dotClass
                      />
                    </div>
                  </div>
                ))
              )}
              {event?.payment_period === eventTypes.daily && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <p className="margins-labels fsize-16 mb-2 mt-4">
                        {events.creatEvent.labels.checkInTimeMargin}
                      </p>
                    </div>
                    <div className="col-12">
                      <ViewInputData
                        label={events.creatEvent.labels.checkinStart}
                        content={renderMarginMinTime(
                          role.working_hours_intervals[0].starts_at,
                          role.before_check_in_margin,
                          true
                        )}
                        labelBesideField
                        className="margin-label-width"
                      />
                    </div>
                    <div className="col-12">
                      <ViewInputData
                        label={events.creatEvent.labels.checkinEnd}
                        content={renderMarginMinTime(
                          role.working_hours_intervals[0].starts_at,
                          role.after_check_in_margin,
                          false
                        )}
                        labelBesideField
                        dotClass
                        className="margin-label-width"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p className="margins-labels fsize-16 mb-2 mt-3">
                        {events.creatEvent.labels.checkOutTimeMargin}
                      </p>
                    </div>
                    <div className="col-12">
                      <ViewInputData
                        label={events.creatEvent.labels.checkoutStart}
                        content={renderMarginMinTime(
                          role.working_hours_intervals[0].ends_at,
                          role.before_check_out_margin,
                          true
                        )}
                        labelBesideField
                        className="margin-label-width"
                      />
                    </div>
                    <div className="col-12">
                      <ViewInputData
                        label={events.creatEvent.labels.checkoutEnd}
                        content={renderMarginMinTime(
                          role.working_hours_intervals[0].ends_at,
                          role.after_check_out_margin,
                          false
                        )}
                        labelBesideField
                        dotClass
                        className="margin-label-width"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* End Working Hours Slots */}
            <ViewInputData
              label={events.creatEvent.labels.uniformCode}
              content={role.uniform_code}
            />
            <div className="row">
              {/* <div className="col-sm-12 col-md-4">
                <ViewInputData
                  label={events.creatEvent.labels.timeToArriveToTheSite}
                  content={role.arriving_at}
                />
              </div> */}
              <div className="col-sm-12 col-md-4">
                <ViewInputData
                  label={events.creatEvent.labels.requiredTraining}
                  content={role.required_training}
                />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default ViewRolesFields;

ViewRolesFields.propTypes = {
  role: PropTypes.any,
};
