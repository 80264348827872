import { put, call, takeEvery } from "redux-saga/effects";

import * as apis from "../../network/apis/timeSheets";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg } from "../../utils/Helpers";

function* getReportsRequestsListSaga({ payload }) {
  try {
    const response = yield call(apis.getDailyReportsRequestsList, payload);

    yield put(
      actions.getDailyAndMonthlyReportsResponse({
        list: response?.data?.data,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"],
        },
      })
    );
    if (+response.headers["current-page"] > +response.headers["total-pages"]) {
      yield put(
        actions.getDailyAndMonthlyReportsRequests({
          ...payload,
          page: +response.headers["total-pages"],
        })
      );
    }
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* excelReportRequest({ payload }) {
  try {
    const response = yield call(apis.exportExcelRequest, payload);
    yield put(actions.exportExcelResponse(response.data.link));
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

export default function* DailyAndMonthlyReportsRequestsSagas() {
  yield takeEvery(
    types.GET_DAILY_EVENTS_WORKING_LOGS_REQUEST,
    getReportsRequestsListSaga
  );
  yield takeEvery(types.EXPORT_EXCEL_REQUEST, excelReportRequest);
}
