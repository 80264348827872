import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import "./Table.scss";

const Table = ({
  headlines,
  rows,
  hasActions,
  hasSizeLimitation,
  className,
  hasCheckbox,
  handleSelectAllClick,
  handleCheckboxClick,
  isSelected,
  selected,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const numSelected = selected?.length;
  const rowCount = rows?.length;
  return (
    <TableContainer
      component={Paper}
      className={`table-container ${className}`}
    >
      <MuiTable>
        <TableHead>
          <TableRow>
            {hasCheckbox && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={(event) =>
                    handleSelectAllClick(
                      event,
                      rows.map((row) => row.key)
                    )
                  }
                  inputProps={{
                    "aria-label": "select all rows",
                  }}
                />
              </TableCell>
            )}
            {headlines.map((head, index) => (
              <TableCell
                className={`text-gray font-regular fsize-14 ${
                  hasSizeLimitation ? "w-25" : ""
                } ${lang === "ar" && "text-end"}`}
                key={index}
              >
                {head}
              </TableCell>
            ))}
            {hasActions && (
              <TableCell
                className={`actions-cell text-gray font-regular fsize-14 ${
                  lang === "ar" && "text-end"
                }`}
              ></TableCell>
            )}
          </TableRow>
        </TableHead>
        {hasCheckbox ? (
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.key}
                {...(row.props.rowClick ? { onClick: row.props.rowClick } : {})}
              >
                {hasCheckbox && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isSelected(row.key)}
                      onClick={(event) => handleCheckboxClick(event, row.key)}
                      inputProps={{
                        "aria-label": "select row",
                      }}
                    />
                  </TableCell>
                )}
                {row.props.children}
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>{rows}</TableBody>
        )}
      </MuiTable>
    </TableContainer>
  );
};

Table.propTypes = {
  headlines: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      props: PropTypes.shape({
        children: PropTypes.node,
      }),
    })
  ),
  hasActions: PropTypes.bool,
  hasSizeLimitation: PropTypes.bool,
  className: PropTypes.string,
  hasCheckbox: PropTypes.bool,
  handleSelectAllClick: PropTypes.func,
  handleCheckboxClick: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
  isSelected: PropTypes.any,
};

Table.defaultProps = {
  headlines: [],
  hasActions: false,
};

export default Table;
