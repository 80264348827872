import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  TextField,
  FormHelperText,
  Autocomplete,
  InputLabel,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

import messages from "../../assets/locale/messages";
import "./Select.scss";

const Select = ({
  labelClassName,
  value,
  inputClassName,
  disabled,
  label,
  options,
  placeholder,
  id,
  name,
  hasError,
  required,
  onChange,
  errMsg,
  helperText,
  helperTextClass,
  isMultipleSelect,
  wrapperClass,
  disableClearable,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations, shared } = messages[lang];

  return (
    <div
      className={`d-flex justify-content-center flex-column selectWrapper ${
        isMultipleSelect ? "multiple-select-wrapper" : "single-select-wrapper"
      } ${wrapperClass}`}>
      <InputLabel htmlFor={id || name} className={labelClassName}>
        <>
          {label}
          {required ? <span className="mx-1 err">*</span> : ""}
        </>
      </InputLabel>
      <Autocomplete
        className={`${inputClassName} ${
          isMultipleSelect && value.length && "removed-placeholder"
        }`}
        name={name}
        disableClearable={disableClearable}
        multiple={isMultipleSelect}
        id={id || name}
        options={options}
        disabled={disabled}
        getOptionLabel={(option) => option.name || ""}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.id == value.id}
        noOptionsText={shared.noOptions}
        value={value}
        onChange={(e, value) => {
          onChange(value);
        }}
        popupIcon={!disabled && <KeyboardArrowDown />}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            required={required}
            error={hasError}
            className={`${hasError ? "error-border" : ""} ${
              isMultipleSelect === true ? "multi-select-style" : ""
            } ${lang === "en" ? "" : "rtl-icons-direction-handel"}`}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "0",
            padding: "0",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      />
      <FormHelperText
        error={hasError}
        className={`${helperTextClass || "font-medium fsize-12"} ${
          lang === "en" ? "" : "text-end"
        }`}>
        {hasError ? inputsValidations[errMsg] : helperText}
      </FormHelperText>
    </div>
  );
};

export default Select;

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  inputClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  disableClearable: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  helperTextClass: PropTypes.string,
  errMsg: PropTypes.string,
  value: PropTypes.any,
  isMultipleSelect: PropTypes.bool,
  wrapperClass: PropTypes.string,
};
