import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import messages from "../../../../../assets/locale/messages";
import RichText from "../../../../../components/RichText";
import Accordion from "../../../../../components/Accordion";
import ViewInputData from "../../../../../components/ViewInputData";

const ViewProjectManagersFields = ({ projectManager }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { events } = messages[lang];
  return (
    <div>
      <Accordion
        title={projectManager.admin.name}
        content={
          <>
            <ViewInputData
              label={events.creatEvent.labels.pmName}
              content={projectManager.admin.name}
            />
            <RichText
              label={events.creatEvent.labels.responsibility}
              value={projectManager.responsibility}
              noData={!projectManager.responsibility}
              readOnly
              hideToolBar
            />
          </>
        }
      />
    </div>
  );
};

export default ViewProjectManagersFields;

ViewProjectManagersFields.propTypes = {
  projectManager: PropTypes.any,
};
