import { axiosInstance, axiosUserAppInstance } from "./";

//Create Evaluation Manually
const createEvaluation = async (id) =>
  await axiosInstance.post(`/evaluations`, { event_id: id });

//Submit Evaluation Form
const submitEvaluation = async ({ id, data }) =>
  await axiosInstance.patch(`/evaluation_forms/${id}`, data);

const submitSupervisorEvaluation = async ({ id, data }) =>
  await axiosUserAppInstance.patch(`/evaluation_forms/${id}`, data);

const getEvaluationListRounds = async (params) =>
  await axiosInstance.get("/evaluations", { params });

const getEvaluationListRoundsSupervisor = async (params) =>
  await axiosUserAppInstance.get("/evaluations", { params });

const getViewFormDetails = async (id) =>
  await axiosInstance.get(`/evaluation_forms/${id}`);

const getViewFormDetailsSupervisor = async (id) =>
  await axiosUserAppInstance.get(`/evaluation_forms/${id}`);

const getEvaluationCompletedPendingList = async (params) =>
  await axiosInstance.get("/evaluation_forms", { params });

const getEvaluationCompletedPendingSuperVisorList = async (params) =>
  await axiosUserAppInstance.get("/evaluation_forms", { params });

export {
  createEvaluation,
  submitEvaluation,
  submitSupervisorEvaluation,
  getEvaluationListRounds,
  getEvaluationListRoundsSupervisor,
  getViewFormDetails,
  getViewFormDetailsSupervisor,
  getEvaluationCompletedPendingList,
  getEvaluationCompletedPendingSuperVisorList,
};
