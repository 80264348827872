import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Checkbox as MuiCheckbox,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

import messages from "../../assets/locale/messages";

const Checkbox = ({
  label,
  checked,
  onChange,
  name,
  color,
  labelClass,
  id,
  disabled,
  checkboxClass,
  isInputHasErr,
  errMsg,
  helperText,
  helperTextClass,
  ...props
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { inputsValidations } = messages[lang];

  return (
    <>
      <FormGroup className={checkboxClass}>
        <FormControlLabel
          control={
            <MuiCheckbox
              className={labelClass}
              checked={checked}
              onChange={(e) => onChange(e.target.checked)}
              color={color}
              name={name}
              id={id || name}
              disabled={disabled}
              {...props}
            />
          }
          label={label}
        />
      </FormGroup>
      <FormHelperText
        error={isInputHasErr}
        className={`${helperTextClass || "font-medium fsize-12"} ${
          lang === "en" ? "" : "text-end"
        }`}>
        {isInputHasErr ? inputsValidations[errMsg] : helperText}
      </FormHelperText>
    </>
  );
};

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  labelClass: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  checkboxClass: PropTypes.string,
  isInputHasErr: PropTypes.bool,
  errMsg: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  helperTextClass: PropTypes.string,
};

Checkbox.defaultProps = {
  color: "primary",
};

export default Checkbox;
